import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const HeaderFavicon = ({ favicon, notificationFavicon, unreadCount }) => {
  const image = unreadCount < 1 ? favicon : notificationFavicon

  return (
    <Helmet>
      <link rel='icon' type='image/png' sizes='192x192' href={image} />
    </Helmet>
  )
}

HeaderFavicon.defaultProps = {
  unreadCount: 0
}

HeaderFavicon.propTypes = {
  favicon: PropTypes.string.isRequired,
  notificationFavicon: PropTypes.string.isRequired,
  unreadCount: PropTypes.number
}

export default HeaderFavicon
