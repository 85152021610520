import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from '@ytb/axios'

import './css'
import FileInput from './components/file-input'
import ThumbnailList from './components/thumbnail-list'
import uploader from './helpers/uploader'
import useBeforeUnload from './helpers/use-before-unload-hook'

const Job = ({
  initialFiles,
  attachableType,
  attachableId,
  sharedByDefault,
  endpoint,
  companyHasFieldStaff
}) => {
  const [files, setFiles] = useState(initialFiles)
  const [uploading, setUploading] = useState(false)

  const onUploadEnd = file => {
    setFiles(currentFiles => [file, ...currentFiles])
    setUploading(false)
  }

  const fileUploader = useMemo(() => (
    uploader({
      fileType: 'file',
      onUploadStart: () => setUploading(true),
      onUploadEnd,
      endpoint: '/attachments',
      params: {
        attachable_id: attachableId,
        attachable_type: attachableType,
        shared: sharedByDefault
      }
    })
  ), [files])

  useEffect(() => () => fileUploader.close)
  useBeforeUnload(uploading)

  const handleDelete = id => {
    axios.delete(`/attachments/${id}`)
      .then(() => {
        const filteredFiles = files.filter(file => file.id !== id)
        setFiles([...filteredFiles])
      })
  }

  return (
    <div className='file-uploader u-mrgn-top--x6'>
      <section className='u-align-center'>
        <FileInput
          uploader={fileUploader}
          buttonClass='btn btn--lrg btn--green'
          label='Upload a file'
          uploading={uploading}
          iconColorClass='icon--white'
        />
      </section>
      <ThumbnailList
        files={files}
        endpoint={endpoint}
        companyHasFieldStaff={companyHasFieldStaff}
        onDelete={handleDelete}
      >
        <section className='u-mrgn-top--x6 u-mrgn-btm--x2'>
          <div className='c-container u-align-center'>
            <h3 className='t--xl c--metal'>
              No files uploaded for this job... (yet!)
            </h3>
          </div>
        </section>
      </ThumbnailList>
    </div>
  )
}

Job.defaultProps = {
  sharedByDefault: false,
  companyHasFieldStaff: false
}

Job.propTypes = {
  initialFiles: PropTypes.array.isRequired,
  attachableType: PropTypes.string.isRequired,
  attachableId: PropTypes.number.isRequired,
  sharedByDefault: PropTypes.bool,
  endpoint: PropTypes.string.isRequired,
  companyHasFieldStaff: PropTypes.bool
}

export default Job
