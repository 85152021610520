import { Controller } from 'stimulus'

export default class ExpandableController extends Controller {
  static classes = ['expand', 'collapse']

  static values = {
    expanded: Boolean
  }

  toggle () {
    this.expandedValue = !this.expandedValue
  }

  submitEnd (event) {
    if (event.detail.success) {
      this.expandedValue = false
    }
  }

  expandedValueChanged (expanded) {
    if (expanded) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand () {
    this.element.classList.remove(this.collapseClass)
    this.element.classList.add(this.expandClass)
  }

  collapse () {
    this.element.classList.remove(this.expandClass)
    this.element.classList.add(this.collapseClass)
  }
}
