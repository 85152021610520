import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import EditSvg from '@/components/svg/edit'
import ViewJobSvg from '@/components/svg/view'
import FileSvg from '@/components/svg/file'
import PrintSvg from '@/components/svg/print'
import EnvelopeSvg from '@/components/svg/envelope'
import DeleteButton from './delete_button'
import Dropdown from '../../dropdown'
import DropdownItem from '../../dropdown/item'
import ColorSelector from '../../color-selector'

const EventActions = ({
  eventId,
  onDelete,
  onColorChange,
  isLinkedToJob,
  isFieldStaff,
  viewJobPath,
  viewJobSheetPath,
  printJobSheetPath,
  sendConfirmationPath,
  editEventPath,
  canSendConfirmationEmail,
  colors,
  selectedColor
}) => (
  <Dropdown
    type='ellipsis'
    testKey='event-menu-btn'
    containerClassName='u-float-right u-mrgn-up'
    buttonClassName='btn--no-border btn--sml btn--ellipsis-only'
    buttonTestKey='event-menu-button'
  >
    {
      (canSendConfirmationEmail) && (
        <>
          <DropdownItem>
            <a href={sendConfirmationPath}>
              <EnvelopeSvg className='icon' />
              Confirm via email & SMS
            </a>
          </DropdownItem>
          <DropdownItem className='menu__item-divide' />
        </>
      )
    }
    {
      isLinkedToJob && (
        <DropdownItem>
          <a href={viewJobPath}>
            <ViewJobSvg className='icon' />
            View Job
          </a>
        </DropdownItem>
      )
    }
    {
      isLinkedToJob && (
        <DropdownItem>
          <a href={viewJobSheetPath}>
            <FileSvg className='icon' />
            View job sheet
          </a>
        </DropdownItem>
      )
    }
    {
      isLinkedToJob && (
        <DropdownItem>
          <a href={printJobSheetPath}>
            <PrintSvg className='icon' />
            Print job sheet
          </a>
        </DropdownItem>
      )
    }
    {(isLinkedToJob || canSendConfirmationEmail) && (
      <DropdownItem className='menu__item-divide' />
    )}
    <DropdownItem>
      <div className='color-selector__holder'>
        <ColorSelector
          colors={colors}
          selectedColor={selectedColor}
          onChange={color => onColorChange(eventId, color)}
        />
      </div>
    </DropdownItem>

    {
      !isFieldStaff && (
        <>
          <DropdownItem className='menu__item-divide' />
          <DropdownItem>
            <a href={editEventPath}>
              <EditSvg className='icon' />
              Edit
            </a>
          </DropdownItem>
          <DeleteButton onDelete={() => { onDelete(eventId) }} />
        </>
      )
    }
  </Dropdown>
)

EventActions.defaultProps = {
  eventId: null,
  onDelete: () => {},
  onColorChange: () => {},
  isLinkedToJob: true,
  isFieldStaff: false,
  viewJobPath: '',
  viewJobSheetPath: '',
  printJobSheetPath: '',
  sendConfirmationPath: '',
  editEventPath: '',
  canSendConfirmationEmail: false,
  colors: [],
  selectedColor: ''
}

EventActions.propTypes = {
  eventId: PropTypes.number,
  onDelete: PropTypes.func,
  onColorChange: PropTypes.func,
  isLinkedToJob: PropTypes.bool,
  isFieldStaff: PropTypes.bool,
  viewJobPath: PropTypes.string,
  viewJobSheetPath: PropTypes.string,
  printJobSheetPath: PropTypes.string,
  sendConfirmationPath: PropTypes.string,
  editEventPath: PropTypes.string,
  canSendConfirmationEmail: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  selectedColor: PropTypes.string
}

export default EventActions
