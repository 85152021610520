import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getHelpPrompt } from '../selectors'
import HelpPrompt from '../../help-prompts'

const PaperworkHelpPrompt = ({ name }) => {
  const helpPrompt = useSelector(state => getHelpPrompt(state, name))

  return (<HelpPrompt name={name} {...helpPrompt} />)
}

PaperworkHelpPrompt.propTypes = {
  name: PropTypes.string.isRequired
}

export default PaperworkHelpPrompt
