import { takeLatest, all, call } from 'redux-saga/effects'

import { PAPERWORK_FETCH_FAILURE, PAPERWORK_SAVE_FAILURE } from '../types'

function notifySentry (err) {
  if (!window.Sentry) return
  if (err && err.message) {
    Sentry.captureException(err)
  } else if (err) {
    Sentry.captureMessage(`${err}`)
  }
}

export function * errorHandlerSaga (action) {
  yield call(notifySentry, action.payload.error)
}

export default function * itemsSaga () {
  yield all([
    takeLatest(PAPERWORK_FETCH_FAILURE, errorHandlerSaga),
    takeLatest(PAPERWORK_SAVE_FAILURE, errorHandlerSaga)
  ])
}
