import { FETCH_COMPANY_USERS_SUCCESS } from '../types'

const companyUsers = (state = [], action) => {
  switch (action.type) {
    case FETCH_COMPANY_USERS_SUCCESS:
      return [...action.payload.data]
    default:
      return state
  }
}

export default companyUsers
