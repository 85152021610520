import React from 'react'
import PropTypes from 'prop-types'
import Notification from './notification'

const Previous = ({ notifications }) => {
  if (notifications.length === 0) { return null }
  return (
    <div className='notifications-menu--previous' data-test='notifications-menu-items-previous'>
      <div className='notifications-menu__header'>
        <h4>Previous</h4>
      </div>
      {
        notifications.map(
          notification => <Notification key={notification.id} {...notification} />
        )
      }
    </div>
  )
}

Previous.propTypes = {
  notifications: PropTypes.array.isRequired
}

export default Previous
