/* eslint-disable no-restricted-syntax */
import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { denormalize } from 'normalizr'
import { decamelizeKeys } from 'humps'

import { labourSave } from '../services'
import schema from '../schemas'
import {
  PAPERWORK_FETCH_SUCCESS,
  LABOUR_ITEM_SAVE,
  LABOUR_ITEM_FORM_CHANGED,
  LABOUR_SEARCH_CHOOSE
} from '../types'
import {
  labourItemAdd,
  labourItemSaveSuccess,
  labourItemSaveFailure,
  labourItemRemove
} from '../actions'
import { getConfiguration } from '../selectors'
import { getItemLabourItems } from '../selectors/items'
import { getPaperwork } from '../selectors/paperwork'
import { getAllLabourItems, isLabourItemEmpty } from '../selectors/labour-items'

export function * labourItemSaveSaga (action) {
  const { labourItemUUID } = action.payload

  const allState = yield (select(state => state))
  const labourItem = yield (select(state => getAllLabourItems(state)[labourItemUUID]))
  const denormalized = yield call(denormalize, labourItem, schema, allState)
  const decamelized = yield call(decamelizeKeys, denormalized)

  const { response, error } = yield call(labourSave, decamelized)

  if (response) {
    yield put(labourItemSaveSuccess(labourItemUUID))
  } else {
    yield put(labourItemSaveFailure(labourItemUUID, error))
  }
}

export function * ensureEmptyItem (itemUUID) {
  const { paperworkUUID } = yield select(getConfiguration)
  const labourItems = yield select(state => getItemLabourItems(state, itemUUID))
  const changedLabourItems = labourItems.filter(labourItem => labourItem.changed)
  if (labourItems.length === changedLabourItems.length) {
    yield put(labourItemAdd(paperworkUUID, itemUUID, false))
  }
}

export function * labourItemFormChangedSaga (action) {
  const { itemUUID } = action.payload
  yield ensureEmptyItem(itemUUID)
}

export function * paperworkFetchSuccessSaga () {
  const { paperworkUUID } = yield select(getConfiguration)
  const { items } = yield select(getPaperwork, paperworkUUID)

  yield all(items.map(ensureEmptyItem))
}

export function * labourSearchChooseSaga (action) {
  const { itemUUID } = action.payload
  const labourItems = yield (select(state => getItemLabourItems(state, itemUUID)))
  const emptyLabourItems = yield (select(state => labourItems.filter(labourItem => isLabourItemEmpty(state, labourItem.uuid))))

  if (emptyLabourItems.length > 0) {
    const emptyLabourItemUUID = emptyLabourItems[emptyLabourItems.length - 1].uuid

    yield put(labourItemRemove(itemUUID, emptyLabourItemUUID))
  }
}

export default function * labourItemSaga () {
  yield all([
    takeLatest(PAPERWORK_FETCH_SUCCESS, paperworkFetchSuccessSaga),
    takeLatest(LABOUR_ITEM_SAVE, labourItemSaveSaga),
    takeLatest(LABOUR_ITEM_FORM_CHANGED, labourItemFormChangedSaga),
    takeLatest(LABOUR_SEARCH_CHOOSE, labourSearchChooseSaga)

  ])
}
