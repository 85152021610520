import React from 'react'

const FiltersSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='-1 -2 16 16'>
      <path d='M5.688 11.786c-1.198 0-2.243-.812-2.539-1.929H1.095a.65.65 0 0 1-.657-.643.65.65 0 0 1 .657-.643H3.15c.296-1.116 1.34-1.928 2.538-1.928 1.2 0 2.244.812 2.54 1.928h4.68a.65.65 0 0 1 .655.643.65.65 0 0 1-.656.643h-4.68c-.295 1.117-1.34 1.929-2.539 1.929Zm0-3.857c-.724 0-1.312.576-1.312 1.285 0 .71.589 1.286 1.312 1.286.724 0 1.313-.577 1.313-1.286 0-.709-.589-1.285-1.314-1.285v-.322.322ZM10.938 5.357c-1.198 0-2.242-.812-2.538-1.928H1.095a.65.65 0 0 1-.657-.643.65.65 0 0 1 .657-.643h7.304C8.695 1.026 9.74.214 10.94.214c1.447 0 2.624 1.154 2.624 2.572 0 1.417-1.178 2.571-2.626 2.571Zm0-3.857c-.723 0-1.312.577-1.312 1.286 0 .709.589 1.285 1.312 1.285.724 0 1.313-.576 1.313-1.285 0-.71-.589-1.286-1.313-1.286Z' />
    </svg>
  </span>
)

export default FiltersSvg
