import React from 'react'

const CheckCircleSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M9.325 17.916a1.79 1.79 0 0 1-1.498-.799l-2.44-3.462a.75.75 0 0 1 1.227-.864l2.45 3.477a.306.306 0 0 0 .261.148.294.294 0 0 0 .236-.119l7.852-9.935a.75.75 0 1 1 1.175.93l-7.843 9.923a1.781 1.781 0 0 1-.423.396 1.78 1.78 0 0 1-.997.305z' />
      <path d='M12 23.999c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22.5c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z' />
    </svg>
  </span>
)

export default CheckCircleSvg
