import React from 'react'

const AppointmentSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M22.941 18.982H1a1 1 0 0 0 0 2h21.941a1 1 0 1 0 0-2zM10.007 17.469a7.739 7.739 0 0 0 5.024-2.328.5.5 0 0 1 .355-.149h3.627a4.987 4.987 0 0 0 0-9.974h-1.059a.5.5 0 0 1-.5-.5v-.5a1 1 0 0 0-1-1H2.493a1 1 0 0 0-1 1v5.488a7.981 7.981 0 0 0 8.514 7.963zm9.006-10.456a2.992 2.992 0 0 1 0 5.984h-1.679a.5.5 0 0 1-.464-.681 8.421 8.421 0 0 0 .585-3.083V7.511a.5.5 0 0 1 .5-.5z' />
    </svg>
  </span>
)

export default AppointmentSvg
