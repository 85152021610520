import React from 'react'
import PropTypes from 'prop-types'

import ViewSvg from '../../../svg/view'
import ViewOffSvg from '../../../svg/view-off'

const ShareToggleButton = ({ shared, onToggle }) => {
  const sharedClassName = shared ? 'icon--green' : null

  return (
    <span
      role='button'
      className={`icon ${sharedClassName}`}
      onClick={event => onToggle(event)}
      tabIndex={0}
    >
      {shared ? <ViewSvg data-tooltip='Shared with field team' /> : <ViewOffSvg data-tooltip='Field team cannot view this file' />}
    </span>
  )
}

ShareToggleButton.propTypes = {
  shared: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default ShareToggleButton
