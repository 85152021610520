import { Controller } from 'stimulus'

const ESC_KEY = 27

export default class ModalController extends Controller {
  static values = {
    open: Boolean
  }

  connect () {
    this.element[this.identifier] = this

    this.escClose = event => {
      if (event.keyCode !== ESC_KEY) { return }
      this.close(event)
    }

    this.historyClose = event => {
      this.close(event)
    }

    if (this.openValue) {
      this.open()
    }
  }

  open () {
    const appBody = document.getElementsByTagName('body')[0]
    const modalContainer = document.getElementById('modal-container')
    document.dispatchEvent(new Event('modal-open'))
    appBody.style.top = `-${window.scrollY}px`
    appBody.style.width = '100%'
    appBody.style.position = 'fixed'

    const modalContent = this.element.cloneNode(true)
    modalContent.classList.remove('modal__source')
    modalContent.removeAttribute('data-modal-open-value')
    modalContainer.innerHTML = ''
    modalContainer.appendChild(modalContent)

    window.addEventListener('keyup', this.escClose, { passive: true })
    window.addEventListener('popstate', this.historyClose)
    window.history.pushState('modal-open', null, null)
  }

  close (event) {
    event.preventDefault()
    const appBody = document.getElementsByTagName('body')[0]
    const modalContainer = document.getElementById('modal-container')
    modalContainer.innerHTML = ''
    const scrollY = appBody.style.top
    appBody.style.position = ''
    appBody.style.width = ''
    appBody.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
    document.dispatchEvent(new Event('modal-close'))
    window.removeEventListener('keyup', this.escClose)
    window.removeEventListener('popstate', this.historyClose)
  }
}
