import moment from 'moment'

import { INIT, ROUTE_CHANGED } from '../types'
import { fetchAssignee, fetchStartDate, fetchType } from '../helpers/date-range'

function buildDateRange (payload) {
  switch (payload.dateMode) {
    case 'week': {
      const startDate = fetchStartDate(payload)

      return {
        startDate: moment(startDate).startOf('day').subtract(1, 'week').format(),
        endDate: moment(startDate).endOf('isoweek').add(2, 'week').format()
      }
    }
    case 'day': {
      const startDate = fetchStartDate(payload)

      return {
        startDate: moment(startDate).startOf('day').subtract(2, 'day').format(),
        endDate: moment(startDate).endOf('day').add(2, 'day').format()
      }
    }
    case 'all': {
      return {
        startDate: fetchStartDate(payload),
        endDate: null
      }
    }
    default: {
      throw new Error(`Unknown dateMode: ${payload.dateMode}`)
    }
  }
}

const eventApiFilters = (state = {}, action) => {
  switch (action.type) {
    case INIT: {
      const type = fetchType(action.payload)
      const assignee = fetchAssignee(action.payload)

      return {
        ...action.payload,
        ...buildDateRange(action.payload),
        type,
        assignee
      }
    }
    case ROUTE_CHANGED: {
      const type = fetchType(action.payload)
      const assignee = fetchAssignee(action.payload)

      return {
        ...action.payload,
        ...buildDateRange(action.payload),
        type,
        assignee
      }
    }
    default: {
      return state
    }
  }
}

export default eventApiFilters
