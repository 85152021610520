import React from 'react'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'

import Section from './section'

const SortableSection = SortableElement(Section)

const List = SortableContainer(({ sections, ...otherProps }) => (
  <div>
    {
      sections.map(({ uuid }, index) => (
        <SortableSection
          sectionUUID={uuid}
          key={uuid}
          index={index}
          sortIndex={index}
          {...otherProps}
        />
      ))
    }
  </div>
))

export default List
