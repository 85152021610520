document.addEventListener('app-connect', () => {
  if (document.getElementById('payment-form')) {
    // eslint-disable-next-line
    const stripe = Stripe(window.App.stripePublicKey, {
      stripeAccount: document.querySelector('[data-user-id]').dataset.userId
    })

    const elements = stripe.elements()

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#f62d19',
        iconColor: '#f62d19'
      }
    }

    const card = elements.create('card', { style })
    card.mount('#card-element')

    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors')

      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    const successfulPaymentHandler = (form, paymentIntent) => {
      const paymentIntentId = document.createElement('input')
      const amount = document.createElement('input')

      paymentIntentId.setAttribute('type', 'hidden')
      paymentIntentId.setAttribute('name', 'payment_intent_id')
      paymentIntentId.setAttribute('value', paymentIntent.id)

      amount.setAttribute('type', 'hidden')
      amount.setAttribute('name', 'payment_intent_amount')
      amount.setAttribute('value', paymentIntent.amount)

      form.appendChild(paymentIntentId)
      form.appendChild(amount)
      form.submit()
    }

    const form = document.getElementById('payment-form')
    form.addEventListener('submit', event => {
      event.preventDefault()

      const clientSecret = document.querySelector('[data-secret]').dataset.secret
      const submitButton = form.querySelector('[data-submit-button]')
      const submitButtonText = document.querySelector('[data-payment-amount]')
      const amount = submitButtonText.dataset.paymentAmount

      submitButtonText.innerText = 'Submitting...'
      submitButton.disabled = true

      stripe.confirmCardPayment(clientSecret, { payment_method: { card } }).then(result => {
        if (result.error) {
          const errorElement = document.getElementById('card-errors')
          submitButtonText.innerText = `Pay ${amount}`
          submitButton.disabled = false

          errorElement.textContent = result.error.message
        } else if (result.paymentIntent.status === 'succeeded') {
          successfulPaymentHandler(form, result.paymentIntent)
        }
      })
    })
  }
})
