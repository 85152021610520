import React, { useContext } from 'react'

import { SectionContext } from '../../contexts'
import WorkItem from '../items/item'

const Item = props => {
  const { sectionUUID } = useContext(SectionContext)

  return (
    <WorkItem itemUUID={sectionUUID} {...props} />
  )
}

export default Item
