import axios from '@ytb/axios'
import Rails from '@rails/ujs'

document.addEventListener('app-connect', () => {
  const form = document.querySelector('dialog #new_contact_custom_field_definition')
  if (form !== null) {
    const updateCustomFieldList = response => {
      const { id, label } = response

      const customFields = document.querySelector('[data-custom-fields]')
      const customFieldNodes = customFields.querySelectorAll('[data-custom-field]')
      const newCustomFieldNode = customFieldNodes[0].cloneNode(true)

      let content = newCustomFieldNode.innerHTML
      content = content.replace(/custom_fields_0/g, `custom_fields_${customFieldNodes.length}`)
      content = content.replace(/\[0\]/g, `[${customFieldNodes.length}]`)
      content = content.replace('{{ID}}', id)
      content = content.replace('{{LABEL}}', label)
      newCustomFieldNode.innerHTML = content

      newCustomFieldNode.classList.remove('hidden')
      customFields.appendChild(newCustomFieldNode)
    }

    const enableButton = () => {
      const button = form.querySelector('button[type="submit"]')
      Rails.enableElement(button)
      button.disabled = false
    }

    const resetForm = () => {
      form.querySelector('input[type="text"]').value = ''
      enableButton()
    }

    const closeDialog = () => { form.closest('dialog').close() }

    const showErrors = errors => {
      const wrapper = document.createElement('div')
      wrapper.classList.add('field_with_errors')

      Object.keys(errors).forEach(key => {
        const element = form.querySelector(`input[name="contact_custom_field_definition[${key}]"]`)
        element.parentNode.insertBefore(wrapper, element)
        wrapper.appendChild(element)

        const errorMessage = document.createElement('span')
        errorMessage.classList.add('frm__error-msg')
        errorMessage.innerHTML = errors[key]
        wrapper.parentNode.insertBefore(errorMessage, wrapper.nextSibling)
      })
    }

    const clearErrorMessages = () => {
      form.querySelectorAll('.frm__error-msg').forEach(error => error.remove())
    }

    form.addEventListener('submit', e => {
      e.preventDefault()

      const formData = new FormData(form)

      axios.post(
        `${form.action}.json`,
        formData
      ).then(response => {
        updateCustomFieldList(response.data)
        resetForm()
        closeDialog()
      }).catch(error => {
        clearErrorMessages()
        enableButton()
        showErrors(error.response.data)
      })
    })
  }
})
