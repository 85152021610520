import 'trix'

import { DirectUpload } from '@rails/activestorage'

// Custom AttachmentUpload which emits events the same as ActiveStorage Direct File Upload
class AttachmentUpload {
  constructor (attachment, element) {
    this.attachment = attachment
    this.element = element
    this.directUpload = new DirectUpload(attachment.file, this.directUploadUrl, this)
  }

  start () {
    this.dispatchEvent('direct-upload:start')
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.dispatchEvent('direct-upload:error', { error })
      } else {
        this.dispatchEvent('direct-upload:end')
      }
      this.directUploadDidComplete(error, attributes)
    })
  }

  directUploadWillStoreFileWithXHR (xhr) {
    xhr.upload.addEventListener('progress', event => {
      const progress = event.loaded / event.total * 100
      this.attachment.setUploadProgress(progress)
      this.dispatchEvent('direct-upload:progress', { progress })
    })
  }

  directUploadDidComplete (error, attributes) {
    if (error) {
      throw new Error(`Direct upload failed: ${error}`)
    }

    this.attachment.setAttributes({
      sgid: attributes.attachable_sgid,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename)
    })
  }

  createBlobUrl (signedId, filename) {
    return this.blobUrlTemplate
      .replace(':signed_id', signedId)
      .replace(':filename', encodeURIComponent(filename))
  }

  dispatchEvent (type, detail = {}) {
    const event = new CustomEvent(type, { bubbles: true, cancelable: true, detail })
    this.element.dispatchEvent(event)
  }

  get directUploadUrl () {
    return this.element.dataset.directUploadUrl
  }

  get blobUrlTemplate () {
    return this.element.dataset.blobUrlTemplate
  }
}

window.addEventListener('trix-attachment-add', event => {
  const { attachment, target } = event

  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target)
    upload.start()
  }
})
