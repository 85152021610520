import { connect } from 'react-redux'
import { clearAll } from '../actions'
import List from '../components/list'

const mapStateToProps = state => ({
  show: (state.showPanel && state.notifications.length > 0),
  unreadNotifications: state.notifications.filter(n => !n.readAt),
  previousNotifications: state.notifications.filter(n => n.readAt).slice(0, 15)
})

const mapDispatchToProps = dispatch => ({
  onClearAllClick () {
    dispatch(clearAll())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(List)
