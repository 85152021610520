import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ReactivatedMessage extends Component {
  render () {
    const { email } = this.props
    return (
      <span className='frm__error-msg'>
        <span className='c--green'>
          {email}
          {' '}
          re-activated.
          You can now attempt to send to this email.
        </span>
      </span>
    )
  }
}

ReactivatedMessage.propTypes = {
  email: PropTypes.string.isRequired
}

export default ReactivatedMessage
