import React from 'react'
import PropTypes from 'prop-types'

const Menu = ({ children }) => (
  <div className='navbar-menu'>
    <div className='notifications-menu'>
      {children}
    </div>
  </div>
)

Menu.defaultProps = {
  children: null
}

Menu.propTypes = {
  children: PropTypes.node
}

export default Menu
