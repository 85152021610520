import { put, takeLatest, all } from 'redux-saga/effects'
import axios from '@ytb/axios'

import { FETCH } from '../types'
import { fetchSuccess, fetchFailure } from '../actions'

export function * fetchSaga () {
  try {
    const response = yield axios.get(
      '/notifications.json',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    yield put(fetchSuccess(response.data))
  } catch (e) {
    yield put(fetchFailure(e))
  }
}

export default function * notificationsSaga () {
  yield all([
    takeLatest(FETCH, fetchSaga)
  ])
}
