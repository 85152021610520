/* eslint-disable no-case-declarations */

import React, { useRef, useCallback, useEffect, useReducer, Fragment } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { camelizeKeys } from 'humps'
import { Waypoint } from 'react-waypoint'

import { labourSearch } from '../../services'
import Results from './results'
import EmptyLabourRates from './empty-labour-rates'
import Loading from '../search/loading'
import NoMoreItems from '../search/no-more-items'
import NoResults from '../search/no-results'
import ClearSearchButton from '../search/clear-search-button'
import KeyboardHints from '../search/keyboard-hints'

const perPage = 25

const initialState = {
  page: 1,
  query: '',
  results: [],
  atEnd: false,
  loading: true
}

function reducer (state, { type, payload }) {
  switch (type) {
    case 'search':
      return {
        ...initialState,
        query: payload,
        loading: true
      }
    case 'results':
      let results = []
      if (state.query === payload.query) {
        results = [
          ...new Map([
            ...state.results,
            ...payload.results
          ].map(result => [result.uuid, result])).values()
        ]
      } else {
        results = payload.results
      }

      return {
        ...state,
        atEnd: payload.results.length < perPage,
        results,
        loading: false
      }
    case 'nextPage':
      return {
        ...state,
        page: state.page + 1,
        loading: true
      }
    default:
      throw new Error()
  }
}

const LabourSearch = ({ onResultClick }) => {
  const inputRef = useRef(null)

  const [state, dispatch] = useReducer(reducer, initialState)
  const { results, query, page, loading, atEnd } = state

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus()
    }, 200)
  }, [query])

  const performSearch = useCallback(debounce((newQuery, newPage, newPerPage) => {
    labourSearch(newQuery, newPage, newPerPage)
      .then(response => {
        dispatch({
          type: 'results',
          payload: {
            query: newQuery,
            results: camelizeKeys(response.data.items)
          }
        })
      })
  }, 400), [])

  useEffect(() => performSearch(query, page, perPage), [query, page])

  const search = str => dispatch({ type: 'search', payload: str })

  return (
    <div data-test='labour-search-modal'>
      <div className='c-panel__header c-panel__header--fixed'>
        <h1 className='c-panel__header-title u-mrgn-btm--x6'>
          <strong>Add from labour rates</strong>
        </h1>
        <div className='srch-tlbr'>
          <div className='input--search'>
            <input
              ref={inputRef}
              value={query}
              data-test='labour-search-query'
              onChange={event => search(event.target.value)}
              className='frm__input frm__input--light'
            />
            <span className='srch-tlbr__filters'>
              {query !== '' && (
                <ClearSearchButton onClick={() => search('')} />
              )}
            </span>
          </div>
        </div>
        {results.length !== 0 && (
          <KeyboardHints />
        )}
      </div>
      <Results items={results} onResultClick={onResultClick} selectFirst={query !== ''} />
      {loading && <Loading />}
      {loading || (
        <>
          {query === '' && results.length === 0 && <EmptyLabourRates />}
          {query !== '' && results.length === 0 && <NoResults resource='labour rates' query={query} />}
          {atEnd && (results.length > perPage) && <NoMoreItems />}
          {atEnd || <Waypoint onEnter={() => dispatch({ type: 'nextPage' })} bottomOffset='-750px' />}
        </>
      )}
    </div>
  )
}

LabourSearch.propTypes = {
  onResultClick: PropTypes.func.isRequired
}

export default LabourSearch
