import moment from 'moment'
import { put, putResolve, takeLatest, all, select, call, delay } from 'redux-saga/effects'
import { normalize, denormalize } from 'normalizr'
import { camelizeKeys, decamelizeKeys } from 'humps'
import createFlashMessage from '@/application/flashes'
import setNewUuids from '../helpers/set-new-uuids'

import schema from '../schemas'
import {
  INIT,
  RESET,
  PAPERWORK_FETCH,
  PAPERWORK_DISCARD,
  PAPERWORK_SUBMIT,
  PAPERWORK_SAVE_SUCCESS,
  PAPERWORK_SAVE_FAILURE,
  PAPERWORK_DUPLICATE,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_FORM_CHANGED
} from '../types'
import {
  paperworkFetch,
  paperworkFetchSuccess,
  paperworkFetchFailure,
  paperworkDuplicate,
  paperworkRemove,
  paperworkDuplicateSuccess,
  paperworkDuplicateFailure,
  paperworkReferenceUnavailable,
  paperworkSaveFailure,
  paperworkSaveSuccess,
  itemAdd,
  sectionRemove
} from '../actions'
import { getConfiguration } from '../selectors'
import { getAllItems } from '../selectors/items'
import { getPaperwork, getAllPaperwork } from '../selectors/paperwork'
import {
  paperworkFetch as paperworkFetchService,
  paperworkSubmit,
  numberAvailable
} from '../services'

export function * initSaga () {
  const { paperworkData } = yield select(getConfiguration)

  if (paperworkData) {
    const jsonCamelized = yield call(camelizeKeys, paperworkData)
    const jsonNormalized = yield call(normalize, jsonCamelized, schema)
    yield put(paperworkFetchSuccess(jsonNormalized))
  } else {
    yield put(paperworkFetch())
  }
}

export function * resetSaga () {
  yield window.location.reload()
}

export function * paperworkSubmitSaga () {
  const {
    paperworkUUID,
    paperworkSubmitPath
  } = yield select(getConfiguration)
  const allState = yield (select(state => state))
  const paperwork = yield (select(state => getPaperwork(state, paperworkUUID)))
  const denormalized = yield call(denormalize, paperwork, schema, allState)
  const decamelized = yield call(decamelizeKeys, denormalized)

  const { response, error } = yield call(
    paperworkSubmit,
    paperworkSubmitPath,
    decamelized
  )

  if (response) {
    const jsonCamelized = yield call(camelizeKeys, response.data)
    const jsonNormalized = yield call(normalize, jsonCamelized, schema)
    yield put(paperworkSaveSuccess(paperworkUUID, jsonNormalized))
  } else {
    yield put(paperworkSaveFailure(paperworkUUID, error))
  }
}

export function * paperworkFetchSaga () {
  const { paperworkFetchPath } = yield select(getConfiguration)
  const { response, error } = yield call(paperworkFetchService, paperworkFetchPath)

  if (response) {
    const jsonCamelized = yield call(camelizeKeys, response.data)
    const jsonNormalized = yield call(normalize, jsonCamelized, schema)
    yield put(paperworkFetchSuccess(jsonNormalized))
  } else {
    yield put(paperworkFetchFailure(error))
  }
}

export function * paperworkFetchSuccessSaga () {
  const {
    paperworkUUID,
    paperworkDuplicateFetchPath
  } = yield select(getConfiguration)
  const { items } = yield select(getPaperwork, paperworkUUID)
  if (items.length === 0) {
    yield put(itemAdd(paperworkUUID, false))
  }
  if (paperworkDuplicateFetchPath) {
    yield put(paperworkDuplicate(paperworkDuplicateFetchPath))
  }
}

function * checkInvoiceNumber () {
  yield delay(400)

  const { paperworkType, paperworkUUID } = yield select(getConfiguration)

  if (paperworkType !== 'invoice') { return }

  const { reference } = yield select(getPaperwork, paperworkUUID)
  if (reference === '') { return }

  const response = yield call(numberAvailable, paperworkUUID, reference)

  if (!response.data.available) {
    yield put(paperworkReferenceUnavailable(paperworkUUID, response.data.nextNumber))
  }
}

export function * paperworkDuplicateSaga (action) {
  const { paperworkType, paperworkUUID } = yield select(getConfiguration)
  const { path } = action.payload

  const { response, error } = yield call(paperworkFetchService, path)

  if (response) {
    const jsonCamelized = yield call(camelizeKeys, response.data)
    const jsonNewUuids = yield call(setNewUuids, jsonCamelized)
    const jsonNormalized = yield call(normalize, { ...jsonNewUuids, uuid: paperworkUUID }, schema)
    yield put(paperworkDuplicateSuccess(jsonNormalized, paperworkType))
  } else {
    yield put(paperworkDuplicateFailure(error))
  }
}

export function * paperworkSaveSuccessSaga (action) {
  const { paperworkUUID, trackFirstCreation } = yield select(getConfiguration)
  let { url } = action.payload.data.entities.paperwork[paperworkUUID]
  if (url) {
    url = trackFirstCreation ? `${url}/?tracking_first=true` : url
    yield window.location = url
  }
}

const UNAUTHORIZED_STATUS_CODE = 401

export function * paperworkSaveFailureSaga ({ payload: error }) {
  if (error.response && error.response.status === UNAUTHORIZED_STATUS_CODE) {
    yield window.location = `/login?return_to=${encodeURIComponent(window.location)}`
    return
  }

  createFlashMessage('Sorry, the paperwork could not be saved, please try again.', 'alert')
}

export function * paperworkDiscardSaga () {
  const { paperworkUUID, paperworkDiscardPath } = yield select(getConfiguration)
  yield putResolve(paperworkRemove(paperworkUUID))
  yield window.location = paperworkDiscardPath
}

export function * checkItemIntegritySaga () {
  const allPaperwork = yield select(state => getAllPaperwork(state))
  const allItems = yield select(state => getAllItems(state))

  yield all(Object.keys(allPaperwork).flatMap(uuid => (
    allPaperwork[uuid].items.filter(itemUuid => !allItems[itemUuid]).map(itemUuid => (
      put(sectionRemove(uuid, itemUuid))
    ))
  )))
}

export function * removeExpiredSaga () {
  const allPaperwork = yield select(state => getAllPaperwork(state))
  const maxAge = 30
  const expiredPaperwork = Object.keys(allPaperwork).filter(uuid => (
    moment(allPaperwork[uuid].updatedAt).isBefore(moment().subtract(maxAge, 'days'))
  ))

  yield all(expiredPaperwork.map(uuid => put(paperworkRemove(uuid))))
}

export default function * paperworkSaga () {
  yield all([
    takeLatest(INIT, initSaga),
    takeLatest(INIT, checkItemIntegritySaga),
    takeLatest(INIT, removeExpiredSaga),
    takeLatest(RESET, resetSaga),
    takeLatest(PAPERWORK_FETCH, paperworkFetchSaga),
    takeLatest(PAPERWORK_FETCH_SUCCESS, paperworkFetchSuccessSaga),
    takeLatest(PAPERWORK_FETCH_SUCCESS, checkInvoiceNumber),
    takeLatest(PAPERWORK_FORM_CHANGED, checkInvoiceNumber),
    takeLatest(PAPERWORK_DUPLICATE, paperworkDuplicateSaga),
    takeLatest(PAPERWORK_DISCARD, paperworkDiscardSaga),
    takeLatest(PAPERWORK_SUBMIT, paperworkSubmitSaga),
    takeLatest(PAPERWORK_SAVE_SUCCESS, paperworkSaveSuccessSaga),
    takeLatest(PAPERWORK_SAVE_FAILURE, paperworkSaveFailureSaga)
  ])
}
