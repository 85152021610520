import { Controller } from 'stimulus'
import axios from '@ytb/axios'

export default class StripeController extends Controller {
  static targets = [
    'errors',
    'submitButton',
    'paymentElement'
  ]

  static values = {
    account: String,
    publicKey: String,
    currency: String,
    paymentAmount: Number,
    formattedPaymentAmount: String,
    googlePayEnabled: { type: Boolean, default: false },
    applePayEnabled: { type: Boolean, default: false },
    submitting: { type: Boolean, default: false }
  }

  connect () {
    // eslint-disable-next-line
    this.stripe = Stripe(this.publicKeyValue, { stripeAccount: this.accountValue });
    this.stripeElements = this.stripe.elements({
      mode: 'payment',
      currency: this.currencyValue,
      amount: this.paymentAmountValue
    })

    this.paymentElement = this.stripeElements.create('payment', {
      layout: 'tabs',
      wallets: {
        applePay: this.applePayEnabledValue ? 'auto' : 'never',
        googlePay: this.googlePayEnabledValue ? 'auto' : 'never'
      }
    })

    this.paymentElement.mount(this.paymentElementTarget)
  }

  async submit (event) {
    event.preventDefault()

    this.submittingValue = true

    const { error: submitError } = await this.stripeElements.submit()
    if (submitError) {
      this.submittingValue = false
      this.displayErrors(submitError)
      return
    }

    const response = await axios.post(this.element.action)
    const { client_secret: clientSecret, return_url: returnUrl } = response.data

    const { error } = await this.stripe.confirmPayment({
      elements: this.stripeElements,
      clientSecret,
      confirmParams: {
        return_url: returnUrl
      }
    })

    if (error) {
      this.submittingValue = false
      this.displayErrors(error)
    }
  }

  // private

  submittingValueChanged () {
    if (this.submittingValue) {
      this.submitButtonTarget.innerText = 'Submitting...'
    } else {
      this.submitButtonTarget.innerText = `Pay ${this.formattedPaymentAmountValue}`
    }

    this.submitButtonTarget.disabled = this.submittingValue
  }

  displayErrors (error) {
    this.errorsTarget.textContent = error?.message
  }
}
