import { put, takeLatest, all } from 'redux-saga/effects'
import axios from '@ytb/axios'

import { SHOW_MESSAGE_TEMPLATE_PANEL } from '../types'
import { fetchEmailTemplatesSuccess } from '../actions'

function * fetchEmailTemplates ({ payload }) {
  const json = yield axios.get(payload.templatesUrl).then(response => response.data)
  yield put(fetchEmailTemplatesSuccess(json))
}

export default function * emailTemplatesSaga () {
  yield all([
    takeLatest(SHOW_MESSAGE_TEMPLATE_PANEL, fetchEmailTemplates)
  ])
}
