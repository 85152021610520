import React from 'react'
import PropTypes from 'prop-types'

import Accreditation from './accreditation'

const Accreditations = ({ accreditationData, tradeAccreditations }) => {
  function dataForPosition (accreditations, position) {
    return accreditations.filter(data => data.position === position)[0]
  }

  return (
    <>
      <div className='accreditations'>
        {
          [0, 1, 2, 3, 4].map(position => (
            <div key={position} className='accreditations__item'>
              <Accreditation
                position={position}
                {...dataForPosition(accreditationData, position)}
                tradeAccreditations={tradeAccreditations}
              />
            </div>
          ))
        }
      </div>
    </>
  )
}

Accreditations.propTypes = {
  accreditationData: PropTypes.array,
  tradeAccreditations: PropTypes.array
}

Accreditations.defaultProps = {
  accreditationData: [],
  tradeAccreditations: []
}

export default Accreditations
