const App = {
  railsEnv: '',
  intercomAppId: '',
  mixpanelProjectToken: '',
  logRocketAppId: '',
  currentCompany: '',
  currentUser: {
    supportMode: false,
    loggedIn: false
  },
  revision: '',
  assetHost: '',
  publicHost: '',
  ...window.App
}

export default App
