import React from 'react'
import PropTypes from 'prop-types'

import EnvelopeSvg from '@/components/svg/envelope'
import PhoneSvg from '@/components/svg/phone'
import InfoCircleSvg from '@/components/svg/info-circle'

const formatLabel = label => {
  switch (label) {
    case 'Email':
    case 'Email invoices to':
      return (
        <span>
          <EnvelopeSvg className='icon' />
          Email&nbsp;
        </span>
      )
    case 'Home':
    case 'Mobile':
    case 'Office':
    case 'Work':
      return (
        <span>
          <PhoneSvg className='icon' />
          Call&nbsp;
        </span>
      )
    default:
      return ''
  }
}

const otherLabel = (label, value) => {
  switch (label) {
    case 'Email':
    case 'Email invoices to':
    case 'Home':
    case 'Mobile':
    case 'Office':
    case 'Work':
      return ''
    default:
      return (
        <li className='c-popup__meta-item'>
          <a href={`tel:${value}`}>
            <InfoCircleSvg className='icon' />
            {label}
            :
            {' '}
            {value}
          </a>
        </li>
      )
  }
}

const formatValue = (label, value) => {
  if (['Email', 'Email invoices to', 'Home', 'Office', 'Work', 'Mobile'].includes(label)) {
    const prefix = (label === 'Email' || label === 'Email invoices to') ? 'mailto' : 'tel'
    return (
      <a href={`${prefix}:${value}`}>
        {formatLabel(label)}
        {value}
      </a>
    )
  }

  return (
    <span>
      {otherLabel(label, value)}
    </span>
  )
}

const ContactDetail = ({ label, value }) => (
  <li className='c-popup__meta-item'>
    {formatValue(label, value)}
  </li>
)

ContactDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default ContactDetail
