/* eslint-disable react/jsx-handler-names */

import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import SaveStatusConfirmation from './save-status-confirmation'

const RadioInput = createReactClass({
  displayName: 'RadioInput',
  propTypes: {
    componentId: PropTypes.number,
    inputId: PropTypes.string,
    name: PropTypes.string,
    initialValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    attribute: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    type: PropTypes.string,
    class: PropTypes.string,
    helpers: PropTypes.arrayOf(PropTypes.element),
    noCreate: PropTypes.bool,
    noRemove: PropTypes.bool,
    status: PropTypes.string
  },
  getDefaultProps () {
    return {
      value: '',
      noCreate: false
    }
  },
  getInitialState () {
    const { initialValue } = this.props
    return {
      errors: [],
      value: initialValue,
      saving: false,
      saved: false
    }
  },
  float () {
    const { type } = this.props
    if (type === 'float') {
      return 1
    }
    return 0
  },
  isSelected (option) {
    const { attribute, value } = this.props
    return value[attribute].toString() === option.toString()
  },
  saveStatusConfirmation (optionValue) {
    const { status } = this.props
    const { selected } = this.state
    if (selected === optionValue.toString()) {
      return React.createElement(SaveStatusConfirmation, {
        inline: true,
        status
      })
    }
  },
  onEdit (event) {
    const { onEdit } = this.props
    this.setState({
      selected: event.target.value
    })
    return onEdit(event)
  },
  optionValue (option) {
    if (option instanceof Array) {
      return this.typedOption(option[1])
    }
    return this.typedOption(option)
  },
  optionLabel (option) {
    if (option instanceof Array) {
      return option[0]
    }
    return option
  },
  typedOption (option) {
    if (typeof option === 'number') {
      return option.toFixed(this.float())
    }
    return option
  },
  options () {
    const { options, name } = this.props
    return (
      options.map((option, i) => (
        <label className='frm__label' key={`${name}-radio-option-${i}`}>
          <input
            type='radio'
            onChange={this.onEdit}
            name={name}
            value={this.optionValue(option)}
            checked={this.isSelected(this.optionValue(option))}
          />
          {this.optionLabel(option)}
          {this.saveStatusConfirmation(this.optionValue(option))}
        </label>
      ))
    )
  },
  render () {
    const { inputId } = this.props
    return (
      <div id={inputId} type='text' className='settings__reminder'>
        {this.options()}
      </div>
    )
  }
})

export default RadioInput
