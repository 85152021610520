import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DragDrop } from '@uppy/react'
import axios from '@ytb/axios'

import './css'
import uploader from './helpers/uploader'
import Thumbnail from './components/thumbnail/drag-and-drop-preview'
import ErrorList from './components/error-list'
import AnimatedSpinnerSvg from '../svg/animated-spinner'
import useBeforeUnload from './helpers/use-before-unload-hook'

const AccreditationUploader = ({
  initialFile,
  endpoint,
  createParams,
  maxFileSize,
  onFileUploaded,
  onFileDeleted,
  containerClassName
}) => {
  const [file, setFile] = useState(initialFile)
  const [errors, setErrors] = useState([])
  const [uploading, setUploading] = useState(false)

  const onUploadEnd = uploadedFile => {
    setFile(uploadedFile)
    setErrors([])
    onFileUploaded(uploadedFile)
    setUploading(false)
  }

  const handleError = error => {
    setErrors([...errors, error])
    setUploading(false)
  }

  const fileUploader = useMemo(() => (
    uploader({
      fileType: 'image',
      onUploadStart: () => setUploading(true),
      onUploadEnd,
      endpoint,
      params: createParams,
      restrictions: { maxFileSize, allowedFileTypes: ['image/*'] },
      handleError
    })
  ), [file, errors])

  useEffect(() => () => fileUploader.close)

  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to delete this file?\n\nIt cannot be undone...')) {
      return
    }

    axios.delete(`/accreditations/${file.id}`)
      .then(() => {
        setFile(null)
        onFileDeleted(file)
      })
  }

  useBeforeUnload(uploading)

  if (file) {
    return (

      <div className={containerClassName}>
        <div className='accreditation-settings-upldr__thumbnail'>
          <Thumbnail
            file={file}
            onDelete={handleDelete}
            showDelete={!uploading}
          />
          <DragDrop
            width='100%'
            uppy={fileUploader}
            locale={{ strings: { dropHereOr: '' } }}
          />
        </div>
        {uploading && (
          <span>
            <AnimatedSpinnerSvg className='icon icon--sml' />
            {' '}
            Uploading
          </span>
        )}
        <ErrorList errors={errors} />
      </div>
    )
  }
  return (
    <div className={containerClassName}>
      <div className='accreditation-settings-upldr__placeholder'>
        <DragDrop
          width='100%'
          uppy={fileUploader}
          locale={{ strings: { dropHereOr: `Upload badge #${createParams.position + 1}` } }}
        />
      </div>
      {uploading && (
        <span>
          <AnimatedSpinnerSvg className='icon icon--sml' />
          {' '}
          Uploading
        </span>
      )}
      <ErrorList errors={errors} />
    </div>
  )
}

AccreditationUploader.defaultProps = {
  initialFile: null,
  containerClassName: 'accreditation-settings-upldr'
}

AccreditationUploader.propTypes = {
  endpoint: PropTypes.string.isRequired,
  initialFile: PropTypes.object,
  maxFileSize: PropTypes.number.isRequired,
  createParams: PropTypes.object.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  onFileDeleted: PropTypes.func.isRequired,
  containerClassName: PropTypes.string
}

export default AccreditationUploader
