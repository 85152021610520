import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Store from './components/store'

const App = ({ children }) => {
  const content = useSelector(state => state.content[state.content.current])

  if (!content) {
    return (
      <div className='skeleton skeleton--index-count'>
        <span className='skeleton__bones--one' />
      </div>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired
}

export default props => (
  <Store>
    <App {...props} />
  </Store>
)
