/* global IntersectionObserver */

import { Controller } from 'stimulus'
import axios from '@ytb/axios'

import renderPaperworkGroupHeadings from '@/application/paperwork-group-headings'

export default class InfiniteScrollController extends Controller {
  static targets = ['entries', 'bottom', 'skeleton', 'pagination']

  initialize () {
    const options = {
      rootMargin: '200px'
    }

    this.intersectionObserver = new IntersectionObserver(
      entries => this.processIntersectionEntries(entries),
      options
    )
  }

  connect () {
    const isGrouped = this.data.get('grouped')

    this.intersectionObserver.observe(this.bottomTarget)

    if (isGrouped) {
      renderPaperworkGroupHeadings()
    }
  }

  disconnect () {
    this.intersectionObserver.unobserve(this.bottomTarget)
  }

  processIntersectionEntries (entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore () {
    const nextPage = this.paginationTarget.querySelector("a[rel='next']")
    const isGrouped = this.data.get('grouped')

    if (nextPage == null) {
      return
    }
    const url = nextPage.href

    if (this.hasSkeletonTarget) {
      this.skeletonTarget.classList.remove('hidden')
    }

    axios.get(url,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.entriesTarget.insertAdjacentHTML('beforeend', response.data.entries)
        this.paginationTarget.innerHTML = response.data.pagination

        if (this.hasSkeletonTarget) {
          this.skeletonTarget.classList.add('hidden')
        }

        if (isGrouped) {
          renderPaperworkGroupHeadings()
        }
      })
  }
}
