/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OnClickOutside from 'react-outside-click-handler'

import CloseSvg from '@/components/svg/close'
import Content from './content'

class ContactPopup extends Component {
  constructor (props) {
    super(props)

    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.state = {
      isOpen: false
    }
  }

  toggleOpen (event) {
    const { state: { isOpen } } = this
    this.setState({ isOpen: !isOpen })

    if (event) {
      event.preventDefault()
    }
  }

  handleClickOutside () {
    const { state: { isOpen } } = this

    if (isOpen) {
      this.toggleOpen()
    }
  }

  handleKeyPress (event) {
    const { state: { isOpen } } = this

    if (isOpen) {
      if (event.keyCode === 27) {
        this.toggleOpen()
      }
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleKeyPress, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyPress, false)
  }

  render () {
    const { displayName, url } = this.props
    const { isOpen } = this.state

    return (
      <OnClickOutside onOutsideClick={this.handleClickOutside}>
        <span className='popup-container'>
          <a href={url} onClick={this.toggleOpen} className='link link--no-uline'>
            {displayName}
          </a>
          <div className={`popup ${isOpen ? 'popup--open' : ''}`}>
            <button type='button' className='btn btn--no-border btn--clear popup__close' onClick={this.toggleOpen}>
              <CloseSvg className='icon icon--sml icon--dark-metal' />
            </button>
            <div className='popup__content'>
              <Content {...this.props} />
            </div>
          </div>
        </span>
      </OnClickOutside>
    )
  }
}

ContactPopup.propTypes = {
  displayName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default ContactPopup
