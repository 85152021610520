import { Controller } from 'stimulus'

export default class HelpMenuController extends Controller {
  static targets = ['menu', 'launcher']

  toggle (event) {
    if (!this.hasMenuTarget) {
      // allow navigation so turbo-frame#help_menu is populated
      return
    }
    this.close(event)
  }

  close (event) {
    if (this.hasMenuTarget) {
      this.menuTarget.remove()
    }
    event.preventDefault()
  }

  clickOutside (event) {
    if (!this.element.contains(event.target)) {
      this.close(event)
      event.preventDefault()
    }
  }

  menuTargetConnected () {
    this.dispatch('open')
    if (this.hasLauncherTarget) {
      this.launcherTarget.classList.add('help-launcher--open')
    }
  }

  menuTargetDisconnected () {
    this.dispatch('close')
    if (this.hasLauncherTarget) {
      this.launcherTarget.classList.remove('help-launcher--open')
    }
  }
}
