import parseMoney from '../helpers/parse-money'
import { createSelector } from './shared'

export const getAllLabourItems = state => state.labourItems
export const getLabourItem = (state, labourItemUUID) => (
  getAllLabourItems(state)[labourItemUUID]
)

export const calculateLabourItemTotal = labourItem => {
  const units = parseMoney(labourItem.units)
  const rate = parseMoney(labourItem.rate)
  const markupPercentage = parseMoney(labourItem.markupPercentage)
  const rateWithMarkup = parseMoney(rate * (1 + markupPercentage / 100))
  return parseMoney(units * rateWithMarkup)
}

export const calculateLabourItemSubTotal = labourItem => {
  const units = parseMoney(labourItem.units)
  const rate = parseMoney(labourItem.rate)
  return parseMoney(units * rate)
}

export const calculateLabourItemCisTotal = (labourItem, cisRate) => {
  const total = calculateLabourItemTotal(labourItem)
  return parseMoney(total * (parseMoney(cisRate) / 100))
}

export const isLabourItemEmpty = (state, labourItemUUID) => {
  const { description, rate } = getLabourItem(state, labourItemUUID)

  return (description === '' && (parseMoney(rate) === 0.00))
}

export const getLabourItemTotal = createSelector(
  getLabourItem,
  calculateLabourItemTotal
)

export const labourItemTotals = createSelector(
  getLabourItemTotal,
  total => ({ total })
)
