import moment from 'moment'
import sortBy from 'lodash/sortBy'

import { INIT, FETCH_EVENTS_SUCCESS, ROUTE_CHANGED } from '../types'
import { fetchStartDate, fetchDateMode, fetchType, fetchAssignee } from '../helpers/date-range'

function handleInit (payload) {
  const dateMode = fetchDateMode(payload)
  const type = fetchType(payload)
  const assignee = fetchAssignee(payload)

  switch (dateMode) {
    case 'week': {
      const startDate = fetchStartDate(payload)

      return {
        start: moment(startDate).startOf('day').format(),
        end: moment(startDate).endOf('isoweek').format(),
        dateMode,
        type,
        assignee
      }
    }
    case 'day': {
      const startDate = fetchStartDate(payload)

      return {
        start: moment(startDate).startOf('day').format(),
        end: moment(startDate).endOf('day').format(),
        dateMode,
        type,
        assignee
      }
    }
    case 'all': {
      return {
        start: fetchStartDate(payload),
        end: null,
        dateMode,
        type,
        assignee
      }
    }
    default: {
      throw new Error(`Unknown dateMode: ${dateMode}`)
    }
  }
}

const handleFetchEventsSuccess = (state, action) => {
  const events = action.payload.data
  let { start, end } = state

  if ((!start || start === '') && events.length > 0) {
    ({ start } = sortBy(events, ['start']).shift())
  }

  if ((!end || end === '') && events.length > 0) {
    ({ end } = sortBy(events, ['end']).pop())
  }

  return { ...state, start, end }
}

const display = (state = {}, action) => {
  switch (action.type) {
    case INIT: {
      return handleInit(action.payload)
    }
    case ROUTE_CHANGED: {
      return handleInit(action.payload)
    }
    case FETCH_EVENTS_SUCCESS: {
      return handleFetchEventsSuccess(state, action)
    }
    default: {
      return state
    }
  }
}

export default display
