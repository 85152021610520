import React from 'react'

const AddSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M8.5 22a1.5 1.5 0 0 0 1.5 1.5h4a1.5 1.5 0 0 0 1.5-1.5v-6.25a.25.25 0 0 1 .25-.25H22a1.5 1.5 0 0 0 1.5-1.5v-4A1.5 1.5 0 0 0 22 8.5h-6.25a.25.25 0 0 1-.25-.25V2A1.5 1.5 0 0 0 14 .5h-4A1.5 1.5 0 0 0 8.5 2v6.25a.25.25 0 0 1-.25.25H2A1.5 1.5 0 0 0 .5 10v4A1.5 1.5 0 0 0 2 15.5h6.25a.25.25 0 0 1 .25.25z' />
    </svg>
  </span>
)

export default AddSvg
