import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import MoveUpDownSvg from '@/components/svg/move-up-down'

const DragHandle = SortableHandle(() => (
  <a
    className='btn btn--sml btn--icon-only u-mrgn-r--x2'
    data-tooltip='Click and drag to reorder'
    title='Click and drag to reorder'
  >
    <MoveUpDownSvg className='icon icon--tiny' />
  </a>
))

export default DragHandle
