import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FailedToReactivateMessage extends Component {
  constructor (props) {
    super(props)
    this.handleReactivate = this.handleReactivate.bind(this)
  }

  handleReactivate (event) {
    const { onReactivate } = this.props
    event.preventDefault()
    onReactivate()
  }

  render () {
    const { email } = this.props
    return (
      <span className='frm__error-msg'>
        Sorry, we haven&#39;t been able to activate
        {' '}
        {email}
        ,&nbsp;
        <a onClick={this.handleReactivate} className='link' role='link' tabIndex={0}>
          Try again?
        </a>
      </span>
    )
  }
}

FailedToReactivateMessage.propTypes = {
  email: PropTypes.string.isRequired,
  onReactivate: PropTypes.func.isRequired
}

export default FailedToReactivateMessage
