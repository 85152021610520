import { connect } from 'react-redux'
import OnClickOutside from 'react-outside-click-handler'
import { hidePanel } from '../actions'

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => ({
  onOutsideClick () {
    dispatch(hidePanel())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(OnClickOutside)
