/* eslint react/no-array-index-key: 0, react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'

const ErrorMessages = createReactClass({
  displayName: 'ErrorMessages',
  propTypes: {
    errors: PropTypes.arrayOf(PropTypes.string)
  },
  render () {
    const { errors } = this.props
    if (errors.length === 0) { return null }
    return (
      <ul className='frm__error-msg'>
        {
          errors.map((error, i) => (
            <li key={`error-${i}`}>{error}</li>
          ))
        }
      </ul>
    )
  }
})

export default ErrorMessages
