import React from 'react'
import PropTypes from 'prop-types'

const DropdownItem = ({ className, children }) => (
  <li className={`menu__item ${className}`}>
    {children}
  </li>
)

DropdownItem.defaultProps = {
  className: ''
}

DropdownItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default DropdownItem
