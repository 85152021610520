const typeToParam = {
  appointments: 'Appointment',
  workdates: 'WorkDate',
  general: 'GeneralEvent',
  'automation-tasks': 'AutomationTask'
}

const createEventParams = ({
  assignee,
  endDate,
  includeAllGeneralEvents,
  startDate,
  type
}) => {
  const params = {}

  if (assignee && assignee !== 'all' && assignee !== 'unassigned') { params.assigned_to = assignee }
  if (assignee && assignee === 'unassigned') { params.unassigned = true }
  if (endDate) { params.end_date = endDate }
  if (includeAllGeneralEvents) { params.include_general_events = true }
  if (startDate) { params.start_date = startDate }
  if (type && type !== 'all') { params.types = typeToParam[type] }

  return params
}

export default createEventParams
