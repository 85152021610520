import { FETCH_EMAIL_TEMPLATES_SUCCESS } from '../types'

const emailTemplates = (state = [], action) => {
  switch (action.type) {
    case FETCH_EMAIL_TEMPLATES_SUCCESS:
      return [...action.payload]
    default:
      return state
  }
}

export default emailTemplates
