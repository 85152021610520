import React from 'react'
import PropTypes from 'prop-types'

function getNoEventsMessage (message) {
  switch (message) {
    case 'dashboard': {
      return ''
    }
    case 'job': {
      return 'You have no work dates, appointments or general events scheduled for this job'
    }
    case null: {
      return 'Nothing scheduled'
    }
    default: {
      return message
    }
  }
}

const NoEventsMessage = ({ message }) => (
  <p className='c--metal u-mrgn-btm--x4'>
    {getNoEventsMessage(message)}
  </p>
)

NoEventsMessage.defaultProps = {
  message: null
}

NoEventsMessage.propTypes = {
  message: PropTypes.string
}

export default NoEventsMessage
