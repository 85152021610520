import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Preview from './preview'
import DeleteLink from './delete-link'

const DragAndDropPreview = ({ file, onDelete, handleOnClick, showDelete }) => (
  <>
    <Preview
      name={file.name}
      url={file.url}
      thumbnailUrl={file.thumbnailUrl}
      type={file.type}
      iconClass={file.iconClass}
      handleOnClick={handleOnClick}
    />
    {showDelete &&
      <DeleteLink onDelete={onDelete} id={file.id} />}
  </>
)

DragAndDropPreview.defaultProps = {
  handleOnClick: () => {},
  showDelete: true
}

DragAndDropPreview.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    iconClass: PropTypes.string
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func,
  showDelete: PropTypes.bool
}

export default DragAndDropPreview
