import { Controller } from 'stimulus'

export default class StripeBillingController extends Controller {
  static targets = [
    'errors',
    'submitButton',
    'cardElement',
    'paymentMethodIdField'
  ]

  static values = {
    publicKey: String,
    clientSecret: String,
    initialSubmitButtonText: String,
    submitting: { type: Boolean, default: false }
  }

  connect () {
    // eslint-disable-next-line
    this.stripe = Stripe(this.publicKeyValue)
    const elements = this.stripe.elements()

    this.card = elements.create('card', { style: this.cardElementStyle() })
    this.card.mount(this.cardElementTarget)

    this.card.addEventListener('change', event => {
      if (event.error) {
        this.displayErrors(event.error)
      } else {
        this.displayErrors(null)
      }
    })
  }

  async submit (event) {
    event.preventDefault()

    this.submittingValue = true

    this.stripe.confirmCardSetup(
      this.clientSecretValue,
      {
        payment_method: {
          card: this.card
        }
      }
    ).then(result => {
      if (result.error) {
        this.displayErrors(result.error)
        this.submittingValue = false
      } else {
        this.paymentMethodIdFieldTarget.value = result.setupIntent.payment_method
        this.element.requestSubmit()
      }
    })
  }

  // private

  submittingValueChanged () {
    if (this.submittingValue) {
      this.submitButtonTarget.innerText = 'Processing...'
    } else {
      this.submitButtonTarget.innerText = this.initialSubmitButtonTextValue
    }

    this.submitButtonTarget.disabled = this.submittingValue
  }

  cardElementStyle () {
    return {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#aab7c4',
          fontSize: '18px'
        }
      },
      invalid: {
        color: '#f62d19',
        iconColor: '#f62d19'
      }
    }
  }

  displayErrors (error) {
    this.errorsTarget.textContent = error?.message
  }
}
