import createCachedSelector from 're-reselect'

export const cacheKey = (_state, uuid) => uuid
export const getState = state => state
export function reduce (func) {
  return array => array.reduce((acc, item) => acc + func(item), 0)
}
export function multiply (a, b) {
  return a * b
}

export function createSelector (...args) {
  return createCachedSelector(
    ...args
  )(cacheKey)
}
