import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import InitGate from './components/init-gate'
import FetchGate from './components/fetch-gate'
import Paperwork from './components/paperwork'
import createStore from './store'

const App = ({ configuration }) => {
  const { store, persistor } = createStore(configuration)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <InitGate configuration={configuration}>
          <FetchGate>
            <Paperwork />
          </FetchGate>
        </InitGate>
      </PersistGate>
    </Provider>
  )
}

App.propTypes = {
  configuration: PropTypes.object.isRequired
}

export default App
