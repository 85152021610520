import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormContext, useForm } from 'react-hook-form'
import axios from '@ytb/axios'

import createFlashMessage from '@/application/flashes'
import Initials from './components/initials'
import Name from './components/name'
import Role from './components/role'

const Form = ({
  adminPrice,
  fieldPrice,
  url,
  formData,
  canManageAdmins,
  canManageFieldStaff,
  proRataCredit,
  initialRole
}) => {
  const methods = useForm({ defaultValues: { ...formData } })

  const [manualInitials, setManualInitials] = useState(false)

  const setErrors = errors => {
    Object.entries(errors).forEach(([field, messages]) => {
      messages.forEach(message => {
        methods.setError(`company_user[${field}]`, 'server', message)
      })
    })
  }

  const onSubmit = data => {
    axios.put(url, data).then(response => {
      // on success redirect to url provided
      window.location.href = response.data.redirect_to
    }).catch(error => {
      if (error.response) {
        setErrors(error.response.data.errors)
      } else if (error.request) {
        createFlashMessage('A network error prevented the form from being submitted. Please try again.', 'alert')
      } else {
        createFlashMessage('A unknown error prevented the form from being submitted. Please try again.', 'alert')
      }
    })
  }

  const handleNameChange = event => {
    if (manualInitials) return
    const generatedInitials = event.target.value
      .split(' ')
      .map(n => n[0])
      .join('')
      .substring(0, 3)
      .toUpperCase()
    const revalidate = generatedInitials.length > 0
    methods.setValue('company_user[initials]', generatedInitials, revalidate)
  }

  const handleInitialsChange = () => {
    setManualInitials(true)
  }

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className='frm' id='company-user'>
        <fieldset className='u-mrgn-btm--x2'>
          <Name handleNameChange={handleNameChange} />
          <div className='frm__block'>
            <label className='frm__label' htmlFor='user_email'>Email address</label>
            <p className='t--bold'>{`${formData.user.email}`}</p>
            <div className='frm__input-primer u-mrgn-top'>
              Add a new team member to change the email address&nbsp;
              <a className='help-link' data-controller='helpscout' data-action='helpscout#modal:prevent' data-helpscout-id-param='5a76e5f42c7d3a4a4198c81b' href='#'>Adding team members</a>
            </div>
          </div>
          <Initials handleInitialsChange={handleInitialsChange} />
        </fieldset>
        <Role
          adminPrice={adminPrice}
          fieldPrice={fieldPrice}
          permissions={formData.company_user.permissions}
          enabledPermissions={formData.company_user.enabledPermissions}
          canManageAdmins={canManageAdmins}
          canManageFieldStaff={canManageFieldStaff}
          proRataCredit={proRataCredit}
          initialRole={initialRole}
        />
      </form>
    </FormContext>
  )
}

Form.propTypes = {
  adminPrice: PropTypes.string.isRequired,
  fieldPrice: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  formData: PropTypes.object,
  canManageAdmins: PropTypes.bool.isRequired,
  canManageFieldStaff: PropTypes.bool.isRequired,
  initialRole: PropTypes.string.isRequired,
  proRataCredit: PropTypes.number
}

Form.defaultProps = {
  formData: null,
  proRataCredit: ''
}

export default Form
