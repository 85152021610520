import { buildUrl } from './url-builders'

const types = [
  { name: 'All', path: 'all' },
  { name: 'Appointment', path: 'appointments' },
  { name: 'General', path: 'general' },
  { name: 'Work Date', path: 'workdates' },
  { name: 'Auto-messages', path: 'automation-tasks' }
]

const buildTypes = ({ type: currentType, ...urlParts }) => types.map(({ name, path }) => (
  { name, path, url: buildUrl({ ...urlParts, type: path }), selected: (currentType === path) }
))

export default buildTypes
