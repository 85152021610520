import $ from '@ytb/cash-dom'

document.addEventListener('app-connect', () => {
  // switch tabs in the display options
  $(document).on('click', '[data-display-options-nav] a', event => {
    $('[data-display-options-nav] a').removeClass('active')
    $(event.target).addClass('active')
    $('.c-doc-menu__display-section').removeClass('active')
    const target = $(event.target).closest('a').data('target')
    $(`[${target}]`).addClass('active')
    event.preventDefault()
  })
})
