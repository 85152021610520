import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { chooseTemplate, hideTemplatePanel } from '../actions'
import EmailTemplate from './email-template'
import TemplatePanelCloseButton from './template-panel-close-button'

const TemplatePanel = () => {
  const dispatch = useDispatch()
  const showTemplatePanel = useSelector(state => state.showTemplatePanel)
  const emailTemplates = useSelector(state => state.emailTemplates)
  const {
    subjectEdited,
    bodyEdited
  } = useSelector(state => state.content[state.content.current])

  useEffect(() => {
    if (showTemplatePanel) {
      document.dispatchEvent(new Event('modal-open'))
    } else {
      document.dispatchEvent(new Event('modal-close'))
    }
  }, [showTemplatePanel])

  const onCloseClick = event => {
    event.preventDefault()
    dispatch(hideTemplatePanel())
  }

  const onEmailTemplateClick = (event, emailTemplate) => {
    event.preventDefault()
    dispatch(chooseTemplate(emailTemplate))
  }

  if (!showTemplatePanel) { return null }
  return (
    <div>
      <div className='choose-template-overlay' onClick={onCloseClick} />
      <ul className='choose-template'>
        <li className='choose-template__header'>
          <TemplatePanelCloseButton onCloseClick={onCloseClick} />
          <h5 className='t--lrg'>
            <strong>Choose a template to use…</strong>
          </h5>
          <p className='t--sml c--dark-metal'>
            Modify email templates, or create new ones, via
            {' '}
            <a href='/email_templates' className='link' target='_blank'>Settings &gt; Email templates</a>
          </p>
        </li>
        <ul className='choose-template__list'>
          {
            emailTemplates.map(
              emailTemplate => (
                <EmailTemplate
                  key={emailTemplate.id}
                  onClick={e => { onEmailTemplateClick(e, emailTemplate) }}
                  confirmClick={subjectEdited || bodyEdited}
                  {...emailTemplate}
                />
              )
            )
          }
        </ul>
      </ul>
    </div>
  )
}

export default TemplatePanel
