import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Announcement from '../announcement'
import AnnouncementButtons from '../announcement/buttons'

class InactiveMessage extends Component {
  constructor (props) {
    super(props)
    this.handleReactivate = this.handleReactivate.bind(this)
  }

  handleReactivate (event) {
    const { onReactivate } = this.props
    event.preventDefault()
    onReactivate()
  }

  renderAnnouncementButtons () {
    const { updatePath } = this.props
    return (
      <AnnouncementButtons>
        <a
          onClick={this.handleReactivate}
          className='btn btn--sml btn--clear c--white reactivate-link'
          style={{ borderColor: 'rgba(255,255,255,0.6)' }}
          role='link'
          tabIndex={0}
        >
          Yes
        </a>
        <a
          href={updatePath}
          className='btn btn--sml btn--clear c--white'
          style={{ borderColor: 'rgba(255,255,255,0.6)' }}
        >
          No, update
        </a>
      </AnnouncementButtons>
    )
  }

  render () {
    const { email } = this.props
    return (
      <Announcement type='red'>
        We are having problems sending emails to
        {' '}
        <strong>{email}</strong>
        .
        {' '}
        Is this the correct address?
        {this.renderAnnouncementButtons()}
      </Announcement>
    )
  }
}

InactiveMessage.propTypes = {
  email: PropTypes.string.isRequired,
  updatePath: PropTypes.string.isRequired,
  onReactivate: PropTypes.func.isRequired
}

export default InactiveMessage
