/* global LogRocket */

import App from './app'

const {
  logRocketAppId,
  currentCompany,
  currentUser
} = App

if (window.LogRocket && currentUser.loggedIn && logRocketAppId !== '') {
  LogRocket.init(logRocketAppId)

  LogRocket.identify(currentUser.id, {
    name: currentUser.name,
    email: currentUser.email,

    // Add your own custom user variables here:
    companyId: currentCompany.id,
    companyName: currentCompany.name,
    companyPlan: currentCompany.plan
  })
};
