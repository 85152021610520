import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Announcement from '../announcement'
import AnnouncementButtons from '../announcement/buttons'

class SubmittingMessage extends Component {
  render () {
    const { email } = this.props
    return (
      <Announcement type='red'>
        We are having problems sending emails to
        {' '}
        <strong>{email}</strong>
        .
        {' '}
        Is this the correct address?
        <AnnouncementButtons>
          <a
            className='btn btn--sml btn--clear c--white'
            style={{ borderColor: 'rgba(255,255,255,0.6)' }}
            role='link'
            tabIndex={0}
          >
            updating...
          </a>
        </AnnouncementButtons>
      </Announcement>
    )
  }
}

SubmittingMessage.propTypes = {
  email: PropTypes.string.isRequired
}

export default SubmittingMessage
