import {
  TOGGLE_PANEL,
  HIDE_PANEL
} from '../types'

const showPanel = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_PANEL:
      return !state
    case HIDE_PANEL:
      return false
    default:
      return state
  }
}

export default showPanel
