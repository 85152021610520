import { v4 as uuidv4 } from 'uuid'

import * as types from '../types'

export const init = (id, fetchUrl) => ({
  type: types.INIT,
  payload: { id, fetchUrl }
})

export const reset = id => ({
  type: types.RESET,
  payload: { id }
})

export const fetchContentSuccess = json => ({
  type: types.FETCH_CONTENT_SUCCESS,
  payload: {
    uuid: uuidv4(),
    json
  }
})

export const showTemplatePanel = templatesUrl => ({
  type: types.SHOW_MESSAGE_TEMPLATE_PANEL,
  payload: { templatesUrl }
})

export const hideTemplatePanel = () => ({
  type: types.HIDE_MESSAGE_TEMPLATE_PANEL
})

export const fetchEmailTemplatesSuccess = json => ({
  type: types.FETCH_EMAIL_TEMPLATES_SUCCESS,
  payload: json
})

export const chooseTemplate = emailTemplate => ({
  type: types.TEMPLATE_CHOSEN,
  payload: {
    uuid: uuidv4(),
    emailTemplate
  }
})

export const subjectChanged = text => ({
  type: types.SUBJECT_CHANGED,
  payload: { text }
})

export const bodyChanged = text => ({
  type: types.BODY_CHANGED,
  payload: { text }
})
