import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PaperworkContext } from '../contexts'
import { getPaperwork } from '../selectors'
import Field from './final-form-field'

const ReferenceField = ({
  defaultPrefix,
  placeholder,
  maxLength,
  className,
  validate,
  type
}) => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const paperwork = useSelector(state => getPaperwork(state, paperworkUUID))
  let prefix = null

  if (paperwork) { ({ prefix } = paperwork) }

  return (
    <Field
      name='reference'
      testKey='reference'
      label={prefix || defaultPrefix}
      validate={validate}
      placeholder={placeholder}
      className={`pwrk-frm__input ${className}`}
      type={type}
      required
      maxLength={maxLength}
    />
  )
}

ReferenceField.defaultProps = {
  className: '',
  validate: () => {},
  type: 'number',
  maxLength: ''
}

ReferenceField.propTypes = {
  defaultPrefix: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.string
}

export default ReferenceField
