import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import formatDate from '../helpers/format-date'

const DayDate = ({
  date
}) => (
  <>
    {formatDate(date)}
  </>
)

DayDate.propTypes = {
  date: PropTypes.string.isRequired
}

export default DayDate
