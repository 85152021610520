import React from 'react'

import App from './app'
import TemplatePanel from './components/template-panel'

export default props => (
  <App>
    <TemplatePanel {...props} />
  </App>
)
