import React from 'react'
import PropTypes from 'prop-types'

const ErrorList = ({ errors }) => (
  <ul className='frm__error-msg'>
    {errors.map(error => <li key='error'>{error}</li>)}
  </ul>
)

ErrorList.propTypes = {
  errors: PropTypes.array.isRequired
}

export default ErrorList
