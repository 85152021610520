import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Thumbnail from './thumbnail'

const ThumbnailList = ({ files, endpoint, companyHasFieldStaff, onDelete, children }) => {
  if (files.length > 0) {
    return (
      <ol className='attachment-thumbs'>
        {files.map(file => (
          <Thumbnail
            key={file.id}
            file={file}
            endpoint={endpoint}
            companyHasFieldStaff={companyHasFieldStaff}
            onDelete={onDelete}
          />
        ))}
      </ol>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

ThumbnailList.defaultProps = {
  files: [],
  companyHasFieldStaff: false
}

ThumbnailList.propTypes = {
  files: PropTypes.array,
  endpoint: PropTypes.string.isRequired,
  companyHasFieldStaff: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default ThumbnailList
