import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import AddSvg from '@/components/svg/add'

const AddEventButton = ({ addEventPath, currentDay }) => {
  if (!addEventPath) { return null }

  return (
    <a
      href={`${addEventPath}?start_date=${moment(currentDay).format('Y-MM-D')}`}
      className='btn__add btn__add--sml'
      data-test-date={moment(currentDay).format('Y-MM-D')}
    >
      <AddSvg className='icon icon--add' />
      {' Add event'}
    </a>
  )
}

AddEventButton.defaultProps = {
  addEventPath: null
}

AddEventButton.propTypes = {
  addEventPath: PropTypes.string,
  currentDay: PropTypes.string.isRequired
}

export default AddEventButton
