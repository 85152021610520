import { Controller } from 'stimulus'

export default class SwitchController extends Controller {
  static targets = ['from', 'to']

  connect () {
    this.toTarget.classList.add('hidden')
  }

  from (event) {
    event.preventDefault()
    this.fromTarget.classList.add('hidden')
    this.toTarget.classList.remove('hidden')
  }

  to (event) {
    event.preventDefault()
    this.toTarget.classList.add('hidden')
    this.fromTarget.classList.remove('hidden')
  }
}
