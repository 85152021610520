/* eslint consistent-return: 0 */
/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
/* eslint react/no-unused-state: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import TextareaAutosize from 'react-autosize-textarea'

import RichText from '@/components/rich-text'

const TextInput = createReactClass({
  displayName: 'TextInput',
  propTypes: {
    componentId: PropTypes.number,
    inputId: PropTypes.string,
    initialValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    placeholder: PropTypes.string,
    attribute: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    class: PropTypes.string,
    noCreate: PropTypes.bool,
    noRemove: PropTypes.bool,
    fieldWrapperClass: PropTypes.string,
    autofocus: PropTypes.bool,
    type: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  },
  getDefaultProps () {
    return {
      initialValue: '',
      noCreate: false,
      errors: []
    }
  },
  getInitialState () {
    const { errors, initialValue } = this.props
    return {
      errors,
      value: initialValue,
      saving: false,
      saved: false
    }
  },
  value () {
    const { attribute } = this.props
    const { value } = this.state
    return value[attribute]
  },
  render () {
    const { autofocus, class: className, inputId, onEdit, placeholder, type } = this.props
    if (type === 'string') {
      return (
        <input
          placeholder={placeholder}
          className={className}
          id={inputId}
          type='text'
          onChange={onEdit}
          defaultValue={this.value()}
          autoFocus={autofocus}
        />
      )
    }
    if (type === 'text') {
      return (
        <TextareaAutosize
          placeholder={placeholder}
          className={className}
          id={inputId}
          onChange={onEdit}
          defaultValue={this.value()}
          autoFocus={autofocus}
        />
      )
    }
    if (type === 'richtext') {
      return (
        <RichText
          id={inputId}
          name={inputId}
          onChange={onEdit}
          value={this.value()}
          autoFocus={false}
        />
      )
    }
  }
})

export default TextInput
