import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Dropdown from '../../dropdown'
import DropdownItem from '../../dropdown/item'

import { selectedItemName, selectedButtonClassName, menuItemClassName } from '../helpers/filters'

const TypeSelector = ({ types }) => (
  <Dropdown
    label={selectedItemName(types)}
    menuTitle='Filter by event type'
    buttonClassName={`btn__filter ${selectedButtonClassName(types, 'All Events')}`}
    buttonTestKey='schedule-type-selector-button'
  >
    {
      types.map(
        type => (
          <DropdownItem
            key={type.name}
            className={menuItemClassName(type.selected)}
          >
            <Link
              to={type.url}
              data-test={`schedule-type-selector-${type.path}`}
              data-action='dropdown#toggle'
            >
              {type.name}
            </Link>
          </DropdownItem>
        )
      )
    }
  </Dropdown>
)

TypeSelector.defaultProps = {
  types: []
}

TypeSelector.propTypes = {
  types: PropTypes.array
}

export default TypeSelector
