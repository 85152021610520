import { formatMoney } from 'accounting-js'
import ChartController from './chart_controller'

export default class ChartWithAxesController extends ChartController {
  chartTypeOptions () {
    return {
      scales: {
        x: {
          stacked: false,
          beginAtZero: true,
          grid: {
            color: '#ffffff'
          },
          ticks: {
            color: '#8f919d'
          }
        },
        y: {
          stacked: false,
          beginAtZero: true,
          grid: {
            color: '#e9eff7'
          },
          ticks: {
            color: '#8f919d',
            callback: value => {
              if (this.unitValue === 'currency') {
                return formatMoney(value, { symbol: this.currencyUnitValue, precision: 0 })
              }

              return value
            }
          }
        }
      }
    }
  }
}
