import { Controller } from 'stimulus'

export default class InlineEditController extends Controller {
  static targets = ['link']

  enable (event) {
    if (event.target.nodeName !== 'A') {
      this.linkTarget.click()
    }
  }
}
