/* global Element */

import React from 'react'
import PropTypes from 'prop-types'
import { Field as FinalFormField } from '../modules/react-final-form'

const Field = ({
  name,
  label,
  testKey,
  prefix,
  inputMode,
  maxLength,
  validateFields,
  className,
  onChange,
  selectOnFocus,
  domReference,
  autoFocus,
  pattern,
  ...props
}) => (
  <FinalFormField {...props} name={name} validateFields={validateFields}>
    {({ input, meta, placeholder }) => (
      <>
        {label !== '' && (
          <label htmlFor={name} className='pwrk-frm__label'>{label}</label>
        )}
        {prefix.length > 0 && (
          <span className='pwrk-frm__input-prefix'>{prefix}</span>
        )}
        <input
          {...input}
          onChange={
            event => {
              input.onChange(event)
              onChange(event)
            }
          }
          autoFocus={autoFocus}
          placeholder={placeholder}
          autoComplete='off'
          inputMode={inputMode}
          maxLength={maxLength}
          className={meta.error && meta.touched ? `pwrk-frm__input pwrk-frm__input--error ${className}` : `pwrk-frm__input ${className}`}
          data-test={`${testKey}-field`}
          ref={domReference}
          id={name}
          pattern={pattern}
          onFocus={event => {
            if (selectOnFocus) {
              event.target.select()
            }
          }}
        />
        {meta.error && meta.touched && <span className='pwrk-frm__error-msg' data-test={`${testKey}-field-errors`}>{meta.error}</span>}
      </>
    )}
  </FinalFormField>
)

Field.propTypes = {
  name: PropTypes.string.isRequired,
  testKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  prefix: PropTypes.string,
  maxLength: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  validateFields: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  selectOnFocus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  domReference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

Field.defaultProps = {
  label: '',
  prefix: '',
  maxLength: '',
  inputMode: '',
  pattern: '',
  validateFields: [],
  className: '',
  onChange: () => {},
  selectOnFocus: false,
  domReference: () => {},
  autoFocus: false
}

export default Field
