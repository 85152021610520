import React from 'react'
import PropTypes from 'prop-types'
import Bell from './containers/bell'
import Blank from './containers/blank'
import List from './containers/list'
import HeaderFavicon from './containers/header-favicon'
import WithStore from './components/with-store'

const NotificationsPanel = ({ favicon, notificationFavicon, mobile = false }) => {
  if (mobile) {
    return (
      <WithStore>
        <Bell mobile={mobile} />
      </WithStore>
    )
  }
  return (
    <WithStore>
      <HeaderFavicon favicon={favicon} notificationFavicon={notificationFavicon} />
      <Bell />
      <Blank />
      <List />
    </WithStore>
  )
}

NotificationsPanel.propTypes = {
  favicon: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  notificationFavicon: PropTypes.string.isRequired
}

export default NotificationsPanel
