import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AnnouncementButtons extends Component {
  render () {
    const { children } = this.props
    return (
      <span className='announcement__buttons'>
        {children}
      </span>
    )
  }
}

AnnouncementButtons.propTypes = {
  children: PropTypes.node
}

AnnouncementButtons.defaultProps = {
  children: null
}

export default AnnouncementButtons
