import React from 'react'
import { useSelector } from 'react-redux'

import { getConfiguration } from '../../selectors'
import Select from '../select'

const UnitTypeSelect = () => {
  const { unitTypeOptions } = useSelector(getConfiguration)

  return (
    <Select options={unitTypeOptions} className='pwrk-frm__input m-table__input m-table__input--qty' name='unitType' testKey='unit-type' />
  )
}

export default UnitTypeSelect
