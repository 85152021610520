import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TagsInput from 'react-tagsinput'
import Autosuggest from 'react-autosuggest'

const autoSuggestTheme = {
  container: 'ui-autosuggest__container',
  containerOpen: 'ui-autosuggest__container--open',
  suggestionsContainer: 'ui-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'ui-autosuggest__suggestions-container--open',
  suggestion: 'ui-autosuggest__suggestion',
  suggestionHighlighted: 'ui-autosuggest__suggestion--highlighted'
}

class ComposeEmailTo extends Component {
  constructor (props) {
    super(props)
    this.state = { tags: props.emails }
    this.handleChange = this.handleChange.bind(this)
    this.autocompleteRenderInput = this.autocompleteRenderInput.bind(this)
  }

  handleChange (tags) {
    this.setState({ tags })
  }

  handleAutocompleteOnChange (event, method, handler) {
    if (method === 'enter') {
      event.preventDefault()
    } else {
      handler(event)
    }
  }

  getFilteredSuggestions (value) {
    const { suggestions } = this.props
    const { tags } = this.state
    const inputValue = (value && value.trim().toLowerCase()) || ''
    const inputLength = inputValue.length
    return suggestions
      .filter(tag => tag.toLowerCase().slice(0, inputLength) === inputValue)
      .filter(tag => !tags.includes(tag))
  }

  autocompleteRenderInput ({ addTag, onChange: autocompleteOnChange, ...props }) {
    return (
      <Autosuggest
        theme={autoSuggestTheme}
        ref={props.ref}
        suggestions={this.getFilteredSuggestions(props.value)}
        shouldRenderSuggestions={() => true}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={suggestion => <span>{suggestion}</span>}
        shouldKeepSuggestionsOnSelect={() => true}
        inputProps={{
          ...props,
          onChange: (event, { method }) => {
            this.handleAutocompleteOnChange(event, method, autocompleteOnChange)
          }
        }}
        onSuggestionSelected={(e, { suggestion }) => { addTag(suggestion) }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
      />
    )
  }

  render () {
    const { tags } = this.state
    const inputProps = {
      className: 'email-to__tag-add',
      placeholder: 'Add an email address',
      id: 'message_form_to',
      'data-test': 'to-field'
    }
    const tagProps = {
      className: 'email-to__tag',
      classNameRemove: 'email-to__tag-remove'
    }

    return (
      <div>
        <input type='hidden' value={tags.join(', ')} name='message_form[to]' />
        <TagsInput
          value={tags}
          addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
          onlyUnique
          addOnPaste
          addOnBlur
          pasteSplit={data => data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())}
          onChange={this.handleChange}
          inputProps={inputProps}
          tagProps={tagProps}
          className='frm__input email-to'
          renderInput={this.autocompleteRenderInput}
        />
      </div>
    )
  }
}

ComposeEmailTo.propTypes = {
  emails: PropTypes.array.isRequired,
  suggestions: PropTypes.array.isRequired
}

export default ComposeEmailTo
