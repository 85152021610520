import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { normalize } from 'normalizr'
import capitalize from 'lodash/capitalize'
import truncate from 'lodash/truncate'
import AddSvg from '@/components/svg/add'

import {
  labourItemAdd,
  labourItemPositionChange,
  labourSearchChoose
} from '../../actions'
import { getConfiguration, getItem, getTranslation } from '../../selectors'
import { PaperworkContext, ItemContext } from '../../contexts'
import Item from './item'
import HelpPrompt from '../help-prompt'
import LabourSearchButton from '../labour-search/button'
import { labourItem as labourItemSchema } from '../../schemas'
import setNewUuids from '../../helpers/set-new-uuids'
import ItemTotal from '../totals/items'

const SortableLabourItem = SortableElement(Item)
const LabourItemList = SortableContainer(({ labourItems }) => (
  <div>
    {
      labourItems.map((labourItemUUID, index) => (
        <SortableLabourItem
          labourItemUUID={labourItemUUID}
          key={labourItemUUID}
          index={index}
          isFirstItem={index === 0}
        />
      ))
    }
  </div>
))

const LabourItems = () => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)
  const { itemUUID } = useContext(ItemContext)
  const item = useSelector(state => getItem(state, itemUUID))
  const t = key => useSelector(state => getTranslation(state, key))
  const [rowAddedClass, setRowAddedClass] = useState('')
  const { currencyUnit } = useSelector(getConfiguration)
  const isMultipliedByItemQuantity = item.quantity > 1

  return (
    <div data-test='labour-panel'>
      <div className='c-panel__header c-panel__header--fixed c-panel__header--pad-right'>
        <h1 className='t--lrg u-mrgn-btm' data-test='labour-panel-title'>
          {item.title.trim().length > 0
            ? <strong>{`${capitalize(t('labour'))} costs for ${truncate(item.title, { length: 75 })}`}</strong>
            : <strong>{`${capitalize(t('labour'))} costs`}</strong>}
        </h1>
        <div className='u-visible-sml-scrn-only'>
          {isMultipliedByItemQuantity
            ? (
              <div>
                <h2 className='t--reg'>
                  <span className='bg--light-yellow u-pad--x3'>
                    {capitalize(t('labour'))}
                    multiplied by item qty
                    <strong>
                      &nbsp;&times;
                      {item.quantity}
                    </strong>
                  </span>
                </h2>
                <h2 className='t--reg'>
                  <span className='bg--light-yellow u-pad--x3'>
                    <span data-test='labour-items-line-total'>
                      <strong><ItemTotal name='labourItemsLineTotal' /></strong>
                    </span>
                    &nbsp; &middot; &nbsp;
                    <span className='c--dark-metal' data-test='labour-items-line-subtotal'>
                      <ItemTotal name='labourItemsLineSubTotal' />
                      {' ex. markup'}
                    </span>
                  </span>
                </h2>
              </div>
              )
            : (
              <div>
                <h2 className='t--reg'>
                  <span className='u-pad--x3'>
                    <span data-test='labour-items-line-total'>
                      <strong><ItemTotal name='labourItemsTotal' /></strong>
                    </span>
                    &nbsp; &middot; &nbsp;
                    <span className='c--dark-metal' data-test='labour-items-line-subtotal'>
                      <ItemTotal name='labourItemsSubTotal' />
                      {' ex. markup'}
                    </span>
                  </span>
                </h2>
              </div>
              )}
        </div>
      </div>

      <HelpPrompt name='using_labour_rates' />

      <div className={`m-table ${rowAddedClass}`} data-test='labour-items'>
        <ul className='m-table__head'>
          <li className='m-table__cell m-table__cell--title'>
            {capitalize(t('labour'))}
          </li>
          <li className='m-table__cell m-table__cell--qty--sml'>{isMultipliedByItemQuantity ? 'Qty/item' : 'Qty'}</li>
          <li className='m-table__cell m-table__cell--units'>Units</li>
          <li className='m-table__cell m-table__cell--price'>
            {currencyUnit}
            &nbsp;per unit
          </li>
          <li className='m-table__cell m-table__cell--markup'>
            &#37; markup
          </li>
          <li className='m-table__cell m-table__cell--total'>Total</li>
          {isMultipliedByItemQuantity && (
            <li className='m-table__cell m-table__cell--total'>
              Total &times;&nbsp;
              {item.quantity}
            </li>
          )}
          <li className='m-table__cell m-table__cell--actions'>&nbsp;</li>
        </ul>
        <LabourItemList
          labourItems={item.labourItems}
          useDragHandle
          helperClass='m-table__row--dragging'
          lockAxis='y'
          onSortEnd={({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) { return }
            dispatch(labourItemPositionChange(itemUUID, oldIndex, newIndex))
          }}
        />
        <div className='m-table__totals'>
          <div className='m-table__totals-filler' />
          <div className='m-table__totals-material'>
            &nbsp;
            <br />
            <span className='t--sml c--dark-metal'>
              excl. markup:
            </span>
          </div>
          <div className='m-table__totals-material'>
            <span data-test='labour-items-total'>
              <strong><ItemTotal name='labourItemsTotal' /></strong>
            </span>
            <br />
            <span className='t--sml c--dark-metal' data-test='labour-items-subtotal'>
              <ItemTotal name='labourItemsSubTotal' />
            </span>
          </div>
          {isMultipliedByItemQuantity && (
            <div className='m-table__totals-material bg--light-yellow'>
              <strong><ItemTotal name='labourItemsLineTotal' /></strong>
              <br />
              <span className='t--sml c--dark-metal' data-test='labour-items-line-subtotal'>
                <ItemTotal name='labourItemsLineSubTotal' />
              </span>
            </div>
          )}
        </div>
        <div className='pwrk-frm__add-section pwrk-frm__add-section--materials'>
          <button
            data-test='add-labour-button'
            className='btn__add'
            type='button'
            onClick={() => {
              setRowAddedClass('m-table--row-added')
              dispatch(labourItemAdd(paperworkUUID, itemUUID))
            }}
          >
            <AddSvg className='icon icon--add u-mrgn-r' />
            Add new row
          </button>

          <LabourSearchButton
            onResultClick={labourItem => {
              dispatch(labourSearchChoose(itemUUID, normalize(setNewUuids(labourItem), labourItemSchema)))
            }}
          >
            <AddSvg className='icon icon--add' />
            {`Add from ${t('labour')} rates`}
          </LabourSearchButton>

        </div>
      </div>

    </div>
  )
}

export default LabourItems
