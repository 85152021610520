import React from 'react'
import { useSelector } from 'react-redux'

import { getConfiguration } from '../selectors'
import Select from './select'

const TaxRateSelect = props => {
  const { taxRates } = useSelector(getConfiguration)

  return (
    <Select options={taxRates} {...props} />
  )
}

export default TaxRateSelect
