import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class DatePickerController extends Controller {
  static values = {
    min: String,
    max: String,
    showMonths: { type: Number, default: 1 }
  }

  connect () {
    this.fp = flatpickr(
      this.element,
      {
        showMonths: this.showMonthsValue,
        defaultDate: this.element.value,
        minDate: this.minValue,
        maxDate: this.maxValue,
        disableMobile: true,
        altInput: true,
        altFormat: this.dateFormat(),
        dateFormat: 'Y-m-d',
        locale: {
          firstDayOfWeek: 1 // start week on Monday
        }
      }
    )
  }

  disconnect () {
    this.fp.destroy()
  }

  dateFormat () {
    return window.App.locale === 'en-us' ? 'M j, Y' : 'j F Y'
  }
}
