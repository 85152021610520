/* global Element */

import React from 'react'
import PropTypes from 'prop-types'

import percentageValueWithinLimits from '../../helpers/percentage-value-within-limits'

const PercentageInputField = ({ name, onChange, defaultValue, testKey, inputRef }) => {
  const sanitisePercentageInput = value => {
    const sanitisedValue = percentageValueWithinLimits(value)

    if (sanitisedValue > sanitisedValue.toFixed(2)) {
      inputRef.current.value = sanitisedValue.toFixed(2)
    }

    if (inputRef.current.value < 0 || inputRef.current.value > 100) {
      inputRef.current.value = sanitisedValue
    }

    return sanitisedValue.toFixed(2)
  }

  return (
    <span className='input--percentage'>
      <input
        type='number'
        name={name}
        onChange={event => onChange(sanitisePercentageInput(event.target.value))}
        className='frm__input frm__input--tiny'
        defaultValue={defaultValue}
        step='0.01'
        ref={inputRef}
        data-test={`${testKey}-percentage-input`}
      />
    </span>
  )
}

PercentageInputField.defaultProps = {
  defaultValue: 0.00
}

PercentageInputField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  testKey: PropTypes.string.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  defaultValue: PropTypes.string
}

export default PercentageInputField
