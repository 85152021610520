import { all, fork } from 'redux-saga/effects'

import Sentry from './sentry'
import MaterialItems from './material-items'
import LabourItems from './labour-items'
import Items from './items'
import Paperwork from './paperwork'

export default function * rootSaga () {
  yield all([
    fork(Sentry),
    fork(MaterialItems),
    fork(LabourItems),
    fork(Items),
    fork(Paperwork)
  ])
}
