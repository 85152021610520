export const estimateValuesUnduplicatable = obj => {
  const {
    reference,
    date,
    validUntil,
    jobId,
    overwriteLocal,
    url,
    dueDate,
    jobAddress,
    jobPostcode,
    customerName,
    customerAddress,
    customerPostcode,
    introduction,
    ...rest
  } = obj

  return rest
}

const estimateValues = obj => {
  const {
    documentTitle,
    date,
    validUntil,
    customerName,
    customerAddress,
    customerPostcode,
    jobAddress,
    jobPostcode,
    reference,
    discount
  } = obj

  return {
    documentTitle,
    date,
    validUntil,
    customerName,
    customerAddress,
    customerPostcode,
    jobAddress,
    jobPostcode,
    reference,
    discount
  }
}

export default estimateValues
