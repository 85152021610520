import React, { Component } from 'react'
import Announcement from '../announcement'

class ReactivatedMessage extends Component {
  render () {
    return (
      <Announcement>
        Email address confirmed. Thank you.
      </Announcement>
    )
  }
}

export default ReactivatedMessage
