import React from 'react'
import PropTypes from 'prop-types'

import WorkDateSvg from '@/components/svg/work-date'
import AppointmentSvg from '@/components/svg/appointment'
import GeneralEventSvg from '@/components/svg/general-event'
import AutomationSvg from '@/components/svg/automation'

import {
  WORK_DATE,
  GENERAL_EVENT,
  APPOINTMENT,
  AUTOMATION_TASK,
  GROUPED_AUTOMATION_TASKS
} from './event_types'

const EventIcon = ({ type, className }) => {
  switch (type) {
    case GENERAL_EVENT: return <GeneralEventSvg className={className} />
    case WORK_DATE: return <WorkDateSvg className={className} />
    case APPOINTMENT: return <AppointmentSvg className={className} />
    case AUTOMATION_TASK: return <AutomationSvg className={className} />
    case GROUPED_AUTOMATION_TASKS: return <AutomationSvg className={className} />
    default: return ''
  }
}

EventIcon.defaultProps = {
  className: ''
}

EventIcon.propTypes = {
  type: PropTypes.oneOf([
    GENERAL_EVENT,
    APPOINTMENT,
    WORK_DATE,
    AUTOMATION_TASK,
    GROUPED_AUTOMATION_TASKS
  ]).isRequired,
  className: PropTypes.string
}

export default EventIcon
