import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import localforage from 'localforage'
import moment from 'moment'

import { createLogger } from 'redux-logger'
import reducer from './reducers'
import saga from './sagas'

const keyPrefix = 'paperwork-v2-'

function createStore (configuration) {
  const persistKey = `${keyPrefix}${configuration.paperworkUUID}`

  // remove legacy global paperwork store
  localforage.removeItem('persist:paperwork-v2').catch(() => {
    // legacy store no longer exists
  })

  // update the last update timestamp for the current piece of paperwork
  localforage.getItem(`${keyPrefix}last-updated`).then(updatedAts => {
    const newObject = { ...updatedAts } || {}
    newObject[configuration.paperworkUUID] = new Date()
    localforage.setItem(`${keyPrefix}last-updated`, newObject).then(() => { })
  })

  // clear paperwork stores that haven't been updated in the past 30 days
  localforage.getItem(`${keyPrefix}last-updated`).then(updatedAts => {
    if (!updatedAts) { return }

    Object.keys(updatedAts).forEach(paperworkUUID => {
      const updatedAt = updatedAts[paperworkUUID]
      if (moment(updatedAt).isBefore(moment().subtract(30, 'days'))) {
        localforage.removeItem(`persist:${keyPrefix}${paperworkUUID}`)
        const newUpdatedAts = { ...updatedAts }
        delete newUpdatedAts[paperworkUUID]
        localforage.setItem(`${keyPrefix}last-updated`, newUpdatedAts).then(() => { })
      }
    })
  })

  const persistedReducer = persistReducer({
    key: persistKey,
    storage: localforage,
    blacklist: ['configuration'],
    debug: configuration.debug
  }, reducer)

  const sagaMiddleware = createSagaMiddleware({
    onError: err => {
      if (!window.Sentry) return
      if (err && err.message) {
        Sentry.captureException(err)
      } else if (err) {
        Sentry.captureMessage(`${err}`)
      } else {
        Sentry.captureMessage('Unknown saga error')
      }
    }
  })

  const logger = createLogger({
    diff: true,
    collapsed: true
  })

  const middleware = getDefaultMiddleware => {
    const list = getDefaultMiddleware({
      serializableCheck: false
    })

    list.push(sagaMiddleware)
    if (configuration.debug === true) { list.push(logger) }

    return list
  }

  const store = configureStore({
    reducer: persistedReducer,
    middleware
  })

  sagaMiddleware.run(saga)

  const persistor = persistStore(store)

  return { store, persistor }
}

export default createStore
