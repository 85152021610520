import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getErrors } from '../selectors'

const Errors = ({ uuid }) => {
  const errors = useSelector(state => getErrors(state, uuid))

  if (!errors) { return null }
  if (Object.entries(errors).length === 0) { return null }

  return (
    <ul className='pwrk-frm__errors' data-test='errors'>
      {
        Object.entries(errors).map(error => (
          <li className='pwrk-frm__error' key={error[0]}>
            {`${error[1]}`}
          </li>
        ))
      }
    </ul>
  )
}

Errors.propTypes = {
  uuid: PropTypes.string.isRequired
}

export default Errors
