import { Controller } from 'stimulus'
import axios from '@ytb/axios'

export default class SendToSelfController extends Controller {
  static targets = ['sendButton', 'form']

  static values = { email: String }

  send (event) {
    event.preventDefault()
    event.target.innerHTML = 'Sending...'

    const formData = new FormData(this.formTarget)
    formData.set('message_form[sent_to_self]', true)
    formData.set('send_to_self', true)

    axios.post(this.formTarget.action, formData).then(() => {
      event.target.innerHTML = 'Sample emailed to you – send again'
    })
  }
}
