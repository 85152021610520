import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { Field as FinalFormField } from '../modules/react-final-form'

const localeDateFormat = window.App.locale === 'en-us' ? 'M j, Y' : 'j M Y'

const DateField = ({ name, label, testKey, validateFields, className, ...props }) => (
  <FinalFormField {...props} name={name} validateFields={validateFields}>
    {({ input, meta }) => (
      <>
        <label htmlFor={name} className='pwrk-frm__label'>{label}</label>
        <Flatpickr
          value={input.value}
          onChange={selected => { input.onChange(moment(selected[0]).format('YYYY-MM-DD')) }}
          name={input.name}
          id={input.name}
          options={{
            disableMobile: true,
            dateFormat: localeDateFormat,
            parseDate: date => new Date(date),
            locale: {
              firstDayOfWeek: 1 // start week on Monday
            }
          }}
          data-test={`${testKey}-field`}
          className={`pwrk-frm__input ${className}`}
        />
        {meta.error && meta.touched && <span className='pwrk-frm__error-msg' data-test={`${testKey}-field-errors`}>{meta.error}</span>}
      </>
    )}
  </FinalFormField>
)

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  testKey: PropTypes.string.isRequired,
  validateFields: PropTypes.array
}

DateField.defaultProps = {
  className: '',
  validateFields: []
}

export default DateField
