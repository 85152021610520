import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'

import errorClass from '../utils/error-class'
import ErrorMessage from './error-message'

const Name = ({ handleNameChange }) => {
  const { errors, register } = useFormContext()

  const object = 'user'
  const field = 'name'
  const name = `${object}[${field}]`

  return (
    <div className='frm__block'>
      <label className='frm__label' htmlFor='user_name'>Team member name</label>
      <input
        autoComplete='off'
        placeholder='Enter their full name'
        className={`frm__input${errorClass({ errors, object, field })}`}
        type='text'
        name={name}
        id='user_name'
        ref={register({ required: true })}
        onChange={handleNameChange}
        data-test='name'
      />
      <ErrorMessage object={object} field={field} />
    </div>
  )
}

Name.propTypes = {
  handleNameChange: PropTypes.func.isRequired
}

export default Name
