import { Controller } from 'stimulus'

export default class PercentFieldController extends Controller {
  static targets = ['selectInput', 'textInput']

  static values = {
    defaultSelected: String
  }

  connect () {
    this.selectInputTarget.value = this.defaultSelectedValue
  }

  switchInputMode (event) {
    const { value } = event.target

    if (value === 'selectOtherInput') {
      this.selectInputTarget.classList.add('hidden')
      this.textInputTarget.classList.remove('hidden')
      this.textInputTarget.firstElementChild.disabled = false
      this.textInputTarget.firstElementChild.focus()
    }
  }
}
