import React from 'react'
import PropTypes from 'prop-types'

import Money from './money'

const TaxTotal = ({ name, total, label }) => (
  <div>
    <span className='pwrk-frm__totals-label'>
      {label}
      {' '}
      @
      {' '}
      {name}
      %
    </span>
    <span className='pwrk-frm__totals-value'>
      <Money amount={total} />
    </span>
  </div>
)

TaxTotal.propTypes = {
  name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
}

export default TaxTotal
