import React from 'react'
import { useFormContext } from 'react-hook-form'

const EmailInvite = () => {
  const { register } = useFormContext()

  return (
    <fieldset>
      <div className='frm__block u-mrgn-btm--x2'>
        <input
          type='checkbox'
          name='email_invite'
          id='email_invite'
          value='yes'
          className='frm__checkbox'
          defaultChecked
          ref={register}
        />
        <label className='frm__label frm__label--inline' htmlFor='email_invite'>Email an invite to this team member</label>
        <p className='t--sml'>
          An invite allows this team member to choose a password and
          sign in to your account.
          <br />
          You can also send (or re-send) invites later.
        </p>
      </div>
    </fieldset>
  )
}

export default EmailInvite
