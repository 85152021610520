import { Controller } from 'stimulus'

export default class BillingFrequencyController extends Controller {
  static targets = ['form', 'frequency', 'monthly', 'annual', 'title']

  connect () {
    const params = new URLSearchParams(window.location.search)
    const frequency = params.get('billing_frequency')

    if (frequency === 'monthly') {
      this.annualTarget.classList.add('hidden')
    } else {
      this.monthlyTarget.classList.add('hidden')
    }
  }

  switch (event) {
    const { title } = event.currentTarget.dataset
    event.preventDefault()

    this.titleTarget.textContent = this.titleTarget.textContent.replace(/Monthly|Annual/, title)

    this.formTarget.action = this.formTarget.action.replace(/monthly|annual/, title.toLowerCase())

    this.monthlyTarget.classList.toggle('hidden')
    this.annualTarget.classList.toggle('hidden')

    this.frequencyTarget.value = title.toLowerCase()

    const newurl = `${window.location.origin}${window.location.pathname}?billing_frequency=${title.toLowerCase()}`
    window.history.replaceState({ path: newurl }, '', newurl)
  }
}
