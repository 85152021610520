import { Controller } from 'stimulus'

export default class HelpscoutController extends Controller {
  static get shouldLoad () {
    return window.App.helpscoutApiKey !== ''
  }

  open () {
    this.Beacon('open')
  }

  modal ({ params: { id } }) {
    this.Beacon('article', id, { type: 'modal' })
  }

  getSuggestion () {
    this.Beacon('identify', {
      name: window.App.currentUser.name,
      email: window.App.currentUser.email
    })
    this.Beacon('show-message', '6ccb8c34-32b0-4f07-a1ac-26ad9d7da312', { delay: 500, force: true })
  }

  // Private

  Beacon (method, options, data) {
    this.loadBeacon()
    window.Beacon(method, options, data)
  }

  loadBeacon () {
    if (document.getElementById('helpscout-beacon-script')) return

    window.Beacon = (method, options, data) => {
      window.Beacon.readyQueue.push({ method, options, data })
    }
    window.Beacon.readyQueue = []
    window.Beacon('init', window.App.helpscoutApiKey)

    const script = document.createElement('script')
    script.id = 'helpscout-beacon-script'
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://beacon-v2.helpscout.net'
    document.body.appendChild(script)
  }
}
