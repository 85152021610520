import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { getConfiguration } from '../selectors'
import { reset, paperworkFetch } from '../actions'

const FetchGate = ({ children }) => {
  const dispatch = useDispatch()
  const { paperworkFetched, paperworkFetchFailed } = useSelector(getConfiguration)

  if (paperworkFetchFailed) {
    return (
      <div>
        <h3 className='t--xxl t--bold u-mrgn-btm--x2'>Sorry - something went wrong</h3>
        <p className='u-mrgn-btm--x4'>
          We&apos;ve been alerted and will be investigating this very soon...
        </p>
        <p className='u-mrgn-btm--x4'>
          In the meantime, please
          {' '}
          <a
            className='link'
            onClick={() => dispatch(paperworkFetch())}
          >
            try loading this document again
          </a>
          .
        </p>
        <p>
          If the issue persists
          {' '}
          <a className='link' onClick={() => dispatch(reset())}>try starting over</a>
          .
        </p>
      </div>
    )
  }

  if (!paperworkFetched) {
    return (
      <div className='pwrk-skeleton'>
        <div className='pwrk-skeleton__title' />
        <div className='pwrk-skeleton__ref' />
        <div className='pwrk-skeleton__date' />
        <div className='pwrk-skeleton__to' />
        <div className='pwrk-skeleton__item-1' />
      </div>
    )
  }

  return <>{children}</>
}

FetchGate.propTypes = {
  children: PropTypes.node.isRequired
}

export default FetchGate
