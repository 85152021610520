/* eslint no-alert:0 */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { PaperworkContext } from '../contexts'
import { paperworkSubmit, paperworkDiscard } from '../actions'
import { getConfiguration } from '../selectors'

const SaveBar = ({
  saveText = 'Save',
  submittingText = 'Saving...',
  discardText = 'Discard',
  changed = true
}) => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)

  const handleDiscard = () => {
    if (changed && !window.confirm('Are you sure you want to discard your changes?')) {
      return
    }

    dispatch(paperworkDiscard(paperworkUUID))
  }

  const { paperworkSubmitting } = useSelector(state => getConfiguration(state))

  return (
    <div className='pwrk-frm__save'>
      <button
        disabled={paperworkSubmitting}
        type='button'
        data-test='save-button'
        data-controller='disable-during-upload'
        data-disable-during-upload-text-value='Uploading files...'
        className='pwrk-frm__save-submit'
        onClick={() => dispatch(paperworkSubmit(paperworkUUID))}
      >
        {paperworkSubmitting ? submittingText : saveText}
      </button>
      <button
        data-test='discard-button'
        className='pwrk-frm__save-discard'
        type='button'
        onClick={handleDiscard}
      >
        {discardText}
      </button>
    </div>
  )
}

SaveBar.defaultProps = {
  saveText: 'Save',
  submittingText: 'Saving...',
  discardText: 'Discard',
  changed: true
}

SaveBar.propTypes = {
  saveText: PropTypes.string,
  submittingText: PropTypes.string,
  discardText: PropTypes.string,
  changed: PropTypes.bool
}

export default SaveBar
