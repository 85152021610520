import React from 'react'

const DuplicateSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M4.5,24c-1.241,0-2.25-1.009-2.25-2.25V8.25C2.25,7.009,3.259,6,4.5,6h2.25V2.25C6.75,1.009,7.759,0,9,0h7.629 c0.601,0,1.165,0.234,1.59,0.658l2.872,2.872c0.425,0.425,0.659,0.99,0.659,1.59V15.75c0,1.241-1.009,2.25-2.25,2.25h-2.25v3.75 c0,1.241-1.009,2.25-2.25,2.25H4.5z M4.5,7.5c-0.414,0-0.75,0.336-0.75,0.75v13.5c0,0.414,0.336,0.75,0.75,0.75H15 c0.414,0,0.75-0.336,0.75-0.75V11.121c0-0.197-0.08-0.39-0.219-0.53l-2.872-2.872c-0.142-0.141-0.33-0.219-0.53-0.219H4.5z M19.5,16.5c0.414,0,0.75-0.336,0.75-0.75V5.121c0-0.197-0.08-0.39-0.219-0.53l-2.872-2.872c-0.142-0.141-0.33-0.219-0.53-0.219H9 c-0.414,0-0.75,0.336-0.75,0.75V6h3.879c0.6,0,1.165,0.234,1.59,0.658l2.872,2.872c0.425,0.425,0.659,0.99,0.659,1.59V16.5H19.5z' />
    </svg>
  </span>
)

export default DuplicateSvg
