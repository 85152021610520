import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'

export default class PinToBottomController extends Controller {
  initialize () {
    this.updateElement = debounce(this.updateElement, 100).bind(this)
  }

  connect () {
    window.visualViewport.addEventListener('resize', this.updateElement)
  }

  disconnect () {
    window.visualViewport.removeEventListener('resize', this.updateElement)
  }

  // private

  updateElement () {
    const offset = window.innerHeight - window.visualViewport.height
    this.element.style.bottom = `${offset}px`
  }
}
