import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { init } from './actions'
import Store from './components/store'

const Init = ({ id, fetchUrl }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(init(id, fetchUrl))
  }, [id])

  return null
}

Init.propTypes = {
  id: PropTypes.number.isRequired,
  fetchUrl: PropTypes.string.isRequired
}

export default props => (
  <Store>
    <Init {...props} />
  </Store>
)
