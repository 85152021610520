import React from 'react'
import Field from '../final-form-field'

const QuantityField = () => (
  <Field
    name='quantity'
    testKey='quantity'
    className='pwrk-frm__input--number pwrk-frm__input--qty'
    inputMode='numeric'
    maxLength='5'
    selectOnFocus
  />
)

export default QuantityField
