import { Controller } from 'stimulus'

export default class FlashMessageController extends Controller {
  static classes = ['closed']

  static values = {
    closeDelay: { type: Number, default: 5000 },
    cssTransitionDuration: { type: Number, default: 1000 },
    persistent: { type: Boolean, default: false }
  }

  connect () {
    this.element.classList.remove(this.closedClass)
    if (!this.persistentValue) {
      this.closeAfterDelay()
    }
  }

  close (event) {
    event.preventDefault()
    this.element.remove()
  }

  // Private

  closeAfterDelay () {
    setTimeout(() => {
      this.element.classList.add(this.closedClass)
      setTimeout(() => {
        this.element.remove()
      }, this.cssTransitionDurationValue)
    }, this.closeDelayValue)
  }
}
