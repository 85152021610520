import React from 'react'
import PropTypes from 'prop-types'

import TrashSvg from '../../svg/trash'
import ViewSvg from '../../svg/view'
import truncatedFileName from '../helpers/truncated-file-name'
import Dropdown from '../../dropdown'
import DropdownItem from '../../dropdown/item'

const SelectableFileListItem = ({ file, onDelete, inputName }) => (
  <li className='email-attachments__file'>
    <input type='hidden' name={`all_${inputName}[]`} />
    <input
      type='checkbox'
      name={`${inputName}[]`}
      id={`file_${file.id}`}
      className='frm__checkbox frm__checkbox--sml'
      value={file.id}
      defaultChecked={file.selected}
    />
    <label htmlFor={`file_${file.id}`}>
      {truncatedFileName(file.name)}
      <span className='email-attachments__file-meta'>
        {file.createdAtPretty}
        &nbsp;·&nbsp;
        {file.sizePretty}
        {file.thumbnailUrl &&
          (
            <span className='email-attachments__file-thumb'>
              <img alt={file.name} src={file.thumbnailUrl} srcSet={`${file.thumbnailUrl} 1x`} />
            </span>
          )}
      </span>
    </label>

    <Dropdown
      type='ellipsis'
      buttonClassName='btn btn--no-border btn--sml'
    >
      <DropdownItem>
        <a href={file.url} target='_blank' rel='noreferrer'>
          <span className='icon'><ViewSvg /></span>
          View this file
        </a>
      </DropdownItem>
      {file.deletable &&
        <DropdownItem className='menu__item--delete'>
          <a href='#' className='c--red' onClick={e => { if (window.confirm('Are you sure you want to delete this file? Cannot be undone!')) { e.preventDefault(); onDelete(file) } }}>
            <span className='icon'><TrashSvg /></span>
            Delete file
          </a>
        </DropdownItem>}
    </Dropdown>
  </li>
)

SelectableFileListItem.propTypes = {
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired
}

export default SelectableFileListItem
