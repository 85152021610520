import { schema } from 'normalizr'
import significantDigits from '../helpers/significant-digits'
import richTextTag from '../../rich-text/helpers/richTextTag'

export const materialItem = new schema.Entity(
  'materialItems',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: (entity, parent) => (
      {
        ...entity,
        quantity: significantDigits(entity.quantity),
        markupPercentage: significantDigits(entity.markupPercentage),
        paperworkUUID: parent.paperworkUUID,
        itemUUID: parent.uuid,
        changed: true,
        overwriteLocal: parent.overwriteLocal
      }
    )
  }
)

export const labourItem = new schema.Entity(
  'labourItems',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: (entity, parent) => (
      {
        ...entity,
        units: significantDigits(entity.units),
        markupPercentage: significantDigits(entity.markupPercentage),
        paperworkUUID: parent.paperworkUUID,
        itemUUID: parent.uuid,
        changed: true,
        overwriteLocal: parent.overwriteLocal
      }
    )
  }
)

export const item = new schema.Entity(
  'items',
  {
    materialItems: [materialItem],
    labourItems: [labourItem]
  },
  {
    idAttribute: 'uuid',
    processStrategy: (entity, parent) => (
      {
        ...entity,
        paperworkUUID: parent.uuid,
        changed: true,
        overwriteLocal: parent.overwriteLocal,
        quantity: significantDigits(entity.quantity),
        description: richTextTag(entity.description)
      }
    )
  }
)

export const section = new schema.Entity(
  'sections',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: (entity, parent) => (
      {
        ...entity,
        paperworkUUID: parent.uuid,
        changed: true
      }
    )
  }
)

export const paperwork = new schema.Entity(
  'paperwork',
  {
    items: [item],
    sections: [section]
  },
  {
    idAttribute: 'uuid',
    processStrategy: entity => (
      {
        ...entity,
        changed: false,
        intro: richTextTag(entity.description),
        terms: richTextTag(entity.terms),
        notes: richTextTag(entity.notes)
      }
    )
  }
)

export default paperwork
