import flatpickr from 'flatpickr'
import moment from 'moment'
import 'flatpickr/dist/flatpickr.css'

let dateFormat = 'j F Y'

if (window.App.locale === 'en-us') {
  dateFormat = 'M j, Y'
}

function initDatePicker ({
  selector = '.dateselect',
  showMonths = 2,
  defaultDate = moment(selector.value),
  disableMobile = false,
  onChange
} = {}) {
  return flatpickr(selector, {
    dateFormat,
    showMonths,
    onChange,
    defaultDate,
    disableMobile,
    locale: {
      firstDayOfWeek: 1 // start week on Monday
    }
  })
}

export default initDatePicker
