import React from 'react'

const WorkDateSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M7.159 12.6a1 1 0 1 0-1.414 1.414l.353.353a.5.5 0 0 1 0 .707L.587 20.587A2 2 0 0 0 2 24a1.984 1.984 0 0 0 1.415-.587l5.51-5.51a.5.5 0 0 1 .707 0l.353.353a1 1 0 1 0 1.415-1.414zM20.434 6.19h.125a1.72 1.72 0 0 0 1.253-.69l1.968-2.633a1.115 1.115 0 0 0-.1-1.448L22.582.325A1.109 1.109 0 0 0 21.165.2l-2.7 1.875a1.713 1.713 0 0 0-.731 1.259 1.664 1.664 0 0 0 .085.685.5.5 0 0 1-.12.515l-7.609 7.6a.75.75 0 0 0 0 1.06l.708.708a.75.75 0 0 0 1.061 0l7.65-7.642A.5.5 0 0 1 20 6.13a1.653 1.653 0 0 0 .434.06zM18.21 15.153a.5.5 0 0 1-.484-.13l-2.24-2.24a.516.516 0 0 0-.707 0L12.658 14.9a.5.5 0 0 0 0 .707l2.24 2.241a.5.5 0 0 1 .129.484 4.506 4.506 0 0 0 5.512 5.512.5.5 0 0 0 .225-.837l-1.5-1.494a.507.507 0 0 1-.143-.3l-.177-1.591a.5.5 0 0 1 .553-.552l1.59.177a.5.5 0 0 1 .3.143l1.5 1.5a.5.5 0 0 0 .836-.225 4.5 4.5 0 0 0-5.512-5.512zM5.666 8.974a.5.5 0 0 1 .484.13l2.24 2.24a.5.5 0 0 0 .707 0l2.121-2.121a.5.5 0 0 0 0-.707l-2.24-2.241a.5.5 0 0 1-.129-.484A4.5 4.5 0 0 0 3.336.279a.5.5 0 0 0-.225.836l1.495 1.5a.5.5 0 0 1 .144.3L4.926 4.5a.5.5 0 0 1-.552.552l-1.591-.177a.5.5 0 0 1-.3-.143L.99 3.237a.5.5 0 0 0-.836.225 4.5 4.5 0 0 0 5.512 5.512z' />
    </svg>
  </span>
)

export default WorkDateSvg
