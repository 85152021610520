document.addEventListener('app-connect', () => {
  if (document.getElementById('new_account_cancel_form')) {
    const form = document.getElementById('new_account_cancel_form')
    const confirmationInput = form.querySelector('input[type="checkbox"]')
    const verificationTextInput = form.querySelector('input[type="text"]')
    const submitButton = form.querySelector('button')

    const disableButton = button => {
      button.setAttribute('disabled', 'disabled')
      button.classList.remove('btn--red')
    }

    const enableButton = button => {
      button.removeAttribute('disabled')
      button.classList.add('btn--red')
    }

    const handleChangeEvent = (button, confirmation, verify) => {
      if (confirmation.checked && verify.value === 'DELETE') {
        enableButton(button)
      } else {
        disableButton(button)
      }
    }

    // Disable button on load
    disableButton(submitButton)

    // Event listeners
    confirmationInput.addEventListener('change', () => { handleChangeEvent(submitButton, confirmationInput, verificationTextInput) })
    verificationTextInput.addEventListener('input', () => { handleChangeEvent(submitButton, confirmationInput, verificationTextInput) })
  }
})
