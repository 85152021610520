import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ItemContext } from '../../contexts'
import { itemSave } from '../../actions'
import { getItem } from '../../selectors'

const SaveButton = () => {
  const dispatch = useDispatch()
  const { itemUUID } = useContext(ItemContext)
  const { saved = false } = useSelector(state => getItem(state, itemUUID))

  if (saved) {
    return (
      <span className='btn btn--sml u-mrgn-r--x2 active saved' data-test='item-saved'>
        Saved
      </span>
    )
  }

  return (
    <button
      onClick={() => dispatch(itemSave(itemUUID))}
      className='btn btn--sml u-mrgn-r--x2'
      type='button'
      data-test='item-save-button'
    >
      Save to price list
    </button>
  )
}

export default SaveButton
