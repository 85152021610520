import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import LabourSearch from './index'
import Modal from '../../../modal/index'

const Button = ({ children, onResultClick }) => {
  const [showSearch, setShowSearch] = useState(false)

  return (
    <>
      <button
        onClick={() => { setShowSearch(true) }}
        data-test='add-item-from-labour-rates'
        className='btn__add'
        type='button'
      >
        {children}
      </button>
      {
        showSearch && (
          <Modal closeText='Close' onClose={() => setShowSearch(false)}>
            <LabourSearch
              onResultClick={labourRate => {
                onResultClick(labourRate)
                setShowSearch(false)
              }}
            />
          </Modal>
        )
      }
    </>
  )
}

Button.propTypes = {
  onResultClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Button
