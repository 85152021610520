import React, { useContext, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'

import { PaperworkContext } from '../contexts'
import { paperworkFormChanged, paperworkFieldChanged } from '../actions'
import { getConfiguration, getPaperwork } from '../selectors'
import estimateValues from '../schemas/estimate-values'
import Form from './form'
import Sections from './sections'
import Errors from './errors'
import SubTotal from './sub-total'
import TaxTotals from './tax-totals'
import GrandTotal from './grand-total'
import DocumentTitleSelect from './document-title-select'
import SaveBar from './save-bar'
import Field from './final-form-field'
import ReferenceField from './reference-field'
import DateField from './date-field'
import TextArea from './textarea'
import RichText from '../../rich-text'
import Discount from './discount/index'

const Estimate = () => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)
  const paperwork = useSelector(state => getPaperwork(state, paperworkUUID))
  const { paperworkType } = useSelector(getConfiguration)

  const introductionSelector = useCallback(state => getPaperwork(state, paperworkUUID).introduction, [paperworkUUID])
  const notesSelector = useCallback(state => getPaperwork(state, paperworkUUID).notes, [paperworkUUID])
  const termsSelector = useCallback(state => getPaperwork(state, paperworkUUID).terms, [paperworkUUID])

  const onFieldChange = useCallback(debounce(event => {
    dispatch(
      paperworkFieldChanged(
        paperworkUUID,
        event.target.name,
        event.target.value
      )
    )
  }, 400), [paperworkUUID])

  const onIntroductionChange = useCallback(text => {
    onFieldChange({ target: { name: 'introduction', value: text } })
  }, [onFieldChange])

  const onNotesChange = useCallback(text => {
    onFieldChange({ target: { name: 'notes', value: text } })
  }, [onFieldChange])

  const onTermsChange = useCallback(text => {
    onFieldChange({ target: { name: 'terms', value: text } })
  }, [onFieldChange])

  const showAddressPanel = paperworkType === 'estimate'

  return (
    <Form
      initialValues={estimateValues(paperwork)}
      onChange={(values, modifiedFields) => {
        dispatch(paperworkFormChanged(paperworkUUID, values, modifiedFields))
      }}
    >
      <Errors uuid={paperworkUUID} />
      <section className='pwrk-frm__section pwrk-frm__header'>
        <div className='pwrk-frm__block pwrk-frm__title'>
          <DocumentTitleSelect name='documentTitle' testKey='document-title' />
        </div>

        {showAddressPanel && (
          <>
            <div className='pwrk-frm__header-col pwrk-frm__header-col--wide'>
              <div className='pwrk-frm__block'>
                <ReferenceField
                  defaultPrefix='Reference'
                  className='pwrk-frm__input'
                  type='string'
                  placeholder='Any reference (optional)'
                  maxLength='35'
                />
              </div>
              <div className='pwrk-frm__header-col--2'>
                <div className='pwrk-frm__block'>
                  <DateField
                    label='Date'
                    name='date'
                    testKey='date'
                    className='pwrk-frm__input--short'
                  />
                </div>
                <div className='pwrk-frm__block'>
                  <DateField
                    label='Valid until'
                    name='validUntil'
                    testKey='valid-until'
                    className='pwrk-frm__input--short'
                  />
                </div>
              </div>
            </div>

            <div className='pwrk-frm__header-col'>
              <div className='pwrk-frm__block'>
                <label htmlFor='customerName' className='pwrk-frm__label'>
                  To
                </label>
                <ul className='pwrk-frm__input-list'>
                  <li>
                    <Field
                      name='customerName'
                      testKey='customer-name'
                      placeholder='Customer name'
                    />
                  </li>
                  <li>
                    <TextArea
                      name='customerAddress'
                      testKey='customer-address'
                      placeholder='Customer address'
                      minRows='1'
                      maxRows='4'
                    />
                  </li>
                  <li>
                    <Field
                      name='customerPostcode'
                      testKey='customer-postcode'
                      placeholder='Customer postcode'
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className='pwrk-frm__header-col'>
              <div className='pwrk-frm__block'>
                <label htmlFor='jobAddress' className='pwrk-frm__label'>
                  For
                </label>
                <ul className='pwrk-frm__input-list'>
                  <li>
                    <TextArea
                      name='jobAddress'
                      testKey='job-address'
                      placeholder='Site address'
                      minRows='1'
                      maxRows='4'
                    />
                  </li>
                  <li>
                    <Field
                      name='jobPostcode'
                      testKey='job-postcode'
                      placeholder='Site postcode'
                    />
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}

      </section>

      <div className='pwrk-frm__section'>
        <div className='pwrk-frm__block'>
          <RichText
            label='Introduction'
            name='introduction'
            id='introduction'
            autoFocus={false}
            valueSelector={introductionSelector}
            testKey='introduction'
            placeholder='Enter any introduction for your customer'
            onChange={onIntroductionChange}
          />
        </div>
      </div>

      <Sections
        descriptionPlaceholder="Describe what you'll be doing…"
      />

      <div className='pwrk-frm__totals'>
        <div className='pwrk-frm__block'>
          <SubTotal />
        </div>
        <div className='pwrk-frm__block'>
          <Discount />
        </div>
        <div className='pwrk-frm__block'>
          <TaxTotals />
        </div>
        <div className='pwrk-frm__block'>
          <GrandTotal />
        </div>
      </div>

      <div className='pwrk-frm__section pwrk-frm__footer'>
        <div className='pwrk-frm__block'>
          <RichText
            label='Notes'
            name='notes'
            id='notes'
            valueSelector={notesSelector}
            testKey='notes'
            autoFocus={false}
            placeholder='Enter any extra notes for your customer'
            onChange={onNotesChange}
          />
        </div>
      </div>

      <div className='pwrk-frm__section'>
        <div className='pwrk-frm__block'>
          <RichText
            label='Terms'
            name='terms'
            autoFocus={false}
            id='terms'
            valueSelector={termsSelector}
            testKey='terms'
            placeholder='Enter any terms and conditions for your customer'
            onChange={onTermsChange}
          />
        </div>
      </div>

      <SaveBar
        saveText={paperworkType === 'estimate' ? 'Save quote' : 'Save quote template'}
        submittingText={paperworkType === 'estimate' ? 'Saving quote...' : 'Saving quote template..'}
      />
    </Form>
  )
}

export default Estimate
