import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import localforage from 'localforage'

import reducer from '../reducers'
import saga from '../sagas'

const persistedReducer = persistReducer({
  key: 'message-form-v2',
  storage: localforage,
  blacklist: ['emailTemplates']
}, reducer)

const sagaMiddleware = createSagaMiddleware()

const middleware = getDefaultMiddleware => {
  const list = getDefaultMiddleware({
    serializableCheck: false
  })

  list.push(sagaMiddleware)

  return list
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware
})

sagaMiddleware.run(saga)

export const persistor = persistStore(store)

const Store = ({ children }) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
)

Store.propTypes = {
  children: PropTypes.node.isRequired
}

export default Store
