import React from 'react'
import PropTypes from 'prop-types'
import TimeAgo from 'react-timeago'

const notificationClassName = readAt => {
  if (!readAt) {
    return 'notifications-menu__item notifications-menu__item--unread'
  }
  return 'notifications-menu__item'
}

const Notification = ({
  id,
  title,
  receivedAt,
  readAt,
  url
}) => (
  <a id={`notification_${id}`} href={url} data-test='notification-item'>
    <div className={notificationClassName(readAt)}>
      <p>
        <span className='notifications-menu__item-notification'>
          {title}
        </span>

        <span className='notifications-menu__item-meta'>
          <TimeAgo date={receivedAt} live={false} />
        </span>
      </p>
    </div>
  </a>
)

Notification.defaultProps = {
  id: 1,
  title: '',
  receivedAt: '',
  readAt: '',
  url: '/'
}

Notification.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  receivedAt: PropTypes.string,
  readAt: PropTypes.string,
  url: PropTypes.string
}

export default Notification
