import parseMoney from '../helpers/parse-money'
import { createSelector } from './shared'

export const getAllMaterialItems = state => state.materialItems
export const getMaterialItem = (state, materialItemUUID) => (
  getAllMaterialItems(state)[materialItemUUID]
)

export const calculateMaterialItemTotal = materialItem => {
  const quantity = parseMoney(materialItem.quantity)
  const itemPrice = parseMoney(materialItem.itemPrice)
  const markupPercentage = parseMoney(materialItem.markupPercentage)
  const itemPriceWithMarkup = parseMoney(itemPrice * (1 + markupPercentage / 100))
  return parseMoney(quantity * itemPriceWithMarkup)
}

export const calculateMaterialItemSubTotal = materialItem => {
  const quantity = parseMoney(materialItem.quantity)
  const itemPrice = parseMoney(materialItem.itemPrice)
  return parseMoney(quantity * itemPrice)
}

export const getMaterialItemTotal = createSelector(
  getMaterialItem,
  calculateMaterialItemTotal
)

export const isMaterialItemEmpty = (state, materialItemUUID) => {
  const { description, itemPrice } = getMaterialItem(state, materialItemUUID)

  return (description === '' && (parseMoney(itemPrice) === 0.00))
}

export const materialItemTotals = createSelector(
  getMaterialItemTotal,
  total => ({ total })
)
