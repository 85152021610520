import moment from 'moment'
import initDatePicker from './date-picker'

function getDateTime (occasion) {
  const date = document.getElementById(`event_event_${occasion}`).value
  const hour = document.getElementById(`event_event_${occasion}_time_4i`).value
  const minute = document.getElementById(`event_event_${occasion}_time_5i`).value

  return moment(`${date} ${hour}:${minute}`)
}

function diffDates (startDate, endDate) {
  const start = moment(startDate)
  const end = moment(endDate)

  return end.diff(start)
}

function setDuration () {
  const startDate = getDateTime('start')
  const endDate = getDateTime('end')
  const duration = diffDates(startDate, endDate)

  document.querySelector('[data-event-form]').setAttribute('data-event-duration', duration)
}

function getDuration () {
  return document.querySelector('[data-event-form]').getAttribute('data-event-duration')
}

function updateEventEnd (newEndDate) {
  let dateFormat = 'DD MMM YYYY'

  if (window.App.locale === 'en-us') {
    dateFormat = 'MMM DD, YYYY'
  }

  const date = newEndDate.format(dateFormat)
  const hour = newEndDate.format('HH')
  const minute = newEndDate.format('mm')

  document.getElementById('event_event_end').value = date
  document.getElementById('event_event_end_time_4i').value = hour
  document.getElementById('event_event_end_time_5i').value = minute
}

function displayError () {
  const endDate = document.getElementById('event_event_end')

  if (document.getElementsByClassName('end-date-warning').length === 0) {
    const error = document.createElement('div')

    error.innerHTML = 'Event cannot end before it starts.'
    error.classList.add('frm__error-msg', 'end-date-warning')
    endDate.parentNode.appendChild(error)
  }
}

function defaultDuration () {
  const startDateTime = getDateTime('start')
  const defaultEndDateTime = startDateTime.add(1, 'h')

  setDuration()
  updateEventEnd(defaultEndDateTime)
  displayError()
}

function clearError () {
  const errors = document.getElementsByClassName('end-date-warning')

  if (errors.length > 0) {
    Array.from(errors).forEach(error => error.remove())
  }
}

document.addEventListener('app-connect', () => {
  document.querySelectorAll('.event-dateselect').forEach(input => {
    initDatePicker({
      selector: input,
      showMonths: 1,
      defaultDate: moment(input.value),
      disableMobile: true
    })
  })

  const newEventForm = document.querySelector('[data-event-form]')

  if (newEventForm) {
    // set the default duration when page loads
    setDuration()

    // update the event duration data attribute when the end time is changed
    const endDate = document.querySelector('[data-event-end]')
    const endTime = document.querySelectorAll('[data-event-end-time]')

    Array.from(endTime).concat(endDate).forEach(element => {
      element.addEventListener('change', () => {
        const startDateTime = getDateTime('start')
        const endDateTime = getDateTime('end')

        if (diffDates(startDateTime, endDateTime) >= 0) {
          clearError()
          setDuration()
        } else {
          defaultDuration()
        }
      })
    })

    // Update the end date/time when start date/time changes
    const startDate = document.querySelector('[data-event-start]')
    const startTime = document.querySelectorAll('[data-event-start-time]')

    Array.from(startTime).concat(startDate).forEach(element => {
      element.addEventListener('change', () => {
        const startDateTime = getDateTime('start')
        const duration = getDuration()
        const newEndDate = startDateTime.add(duration)

        updateEventEnd(newEndDate)
      })
    })
  }
})
