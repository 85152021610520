import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { setNotificationCallback } from '@/channels/notifications_channel'
import { add } from './actions'
import reducer from './reducers'
import sagas from './sagas'

function createStore () {
  const sagaMiddleware = createSagaMiddleware({})

  const middleware = getDefaultMiddleware => {
    const list = getDefaultMiddleware()

    list.push(sagaMiddleware)

    return list
  }

  const store = configureStore({ reducer, middleware })

  sagaMiddleware.run(sagas)

  setNotificationCallback(payload => {
    store.dispatch(add(payload))
  })

  return store
}

export default createStore
