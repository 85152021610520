import { Turbo } from '@hotwired/turbo-rails'

Turbo.session.drive = false

Turbo.StreamActions.change_style = function changeStyle () {
  const style = this.getAttribute('style')
  const targets = this.getAttribute('targets')
  const elements = document.querySelectorAll(targets)

  elements.forEach((element) => {
    element.style.cssText = style
  })
}
