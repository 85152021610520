import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from '@ytb/axios'

import ShareToggleButton from './share-toggle-button'
import TrashSvg from '../../../svg/trash'
import Dropdown from '../../../dropdown'
import DropdownItem from '../../../dropdown/item'

const Options = ({ isShared, endpoint, fileId, companyHasFieldStaff, onDelete }) => {
  const [shared, setShared] = useState(isShared)

  const handleShareFile = event => {
    event.preventDefault()

    axios.put(
      `${endpoint}/${fileId}`,
      { shared: !shared }
    ).then(() => {
      setShared(!shared)
    })
  }

  return (
    <div className='file-thumb__options'>
      {companyHasFieldStaff &&
        <ShareToggleButton onToggle={handleShareFile} shared={shared} />}

      <Dropdown
        type='ellipsis'
        containerClassName='u-float-right u-mrgn-up'
        buttonClassName='btn--no-border btn--sml btn--ellipsis-only u-mrgn-up--x2'
        buttonTestKey='file-menu-btn'
      >
        {companyHasFieldStaff && (
          <DropdownItem>
            <a href='#' onClick={e => handleShareFile(e)}>
              <ShareToggleButton
                onToggle={handleShareFile}
                shared={!shared}
              />
              {shared ? 'Unshare with field team' : 'Share with field team'}
            </a>
          </DropdownItem>
        )}

        <DropdownItem className='menu__item--delete'>
          <a href='#' className='c--red' onClick={e => { if (window.confirm('Are you sure you want to delete this file? Cannot be undone!')) { e.preventDefault(); onDelete(fileId) } }} data-test='delete-file'>
            <span className='icon icon--red'><TrashSvg /></span>
            Delete file
          </a>
        </DropdownItem>
      </Dropdown>
    </div>
  )
}

Options.defaultProps = {
  companyHasFieldStaff: false
}

Options.propTypes = {
  isShared: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
  fileId: PropTypes.number.isRequired,
  companyHasFieldStaff: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
}

export default Options
