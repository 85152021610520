import {
  createReducer,
  updateEntity,
  deepSetKeys,
  removeEntitiesByProperty,
  removeStaleEntities,
  updateEntities
} from './util'

import {
  INIT,
  RESET,
  MATERIAL_AUTOCOMPLETE_CHOOSE,
  MATERIAL_SEARCH_CHOOSE,
  MATERIAL_ITEM_ADD,
  MATERIAL_ITEM_REMOVE,
  MATERIAL_ITEM_FORM_CHANGED,
  MATERIAL_ITEM_SAVE_SUCCESS,
  MATERIAL_ITEM_SAVE_FAILURE,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_SEARCH_CHOOSE,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_DUPLICATE_SUCCESS,
  PAPERWORK_REMOVE,
  ENTITIES_ADD,
  MATERIAL_ITEM_DUPLICATE
} from '../types'
const init = state => {
  const newState = deepSetKeys(state, { focussed: false })

  return {
    ...newState,
    template: {
      description: '',
      quantity: '1',
      itemPrice: '0.00',
      saved: false,
      fromAutocomplete: false,
      fromSearch: false
    }
  }
}

const reset = () => ({})

const entitiesAdd = (state, { payload: { entities: { materialItems: entities } } }) => (
  updateEntities(state, entities)
)

const materialAutocompleteChoose = (state, action) => {
  const { materialItemUUID, item } = action.payload
  const existingQuantity = state[materialItemUUID].quantity

  return updateEntity(
    state,
    materialItemUUID,
    { ...item, quantity: existingQuantity, fromAutocomplete: true }
  )
}

const materialSearchChoose = (state, action) => {
  const { itemUUID, data: { entities: { materialItems: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { itemUUID })
  const uuid = Object.keys(newEntities)[0]
  const entity = newEntities[uuid]

  return {
    ...state,
    [uuid]: {
      ...state.template,
      ...entity,
      fromSearch: true
    }
  }
}

const materialItemAdd = (
  state,
  { payload: { materialItemUUID, paperworkUUID, itemUUID, byUser } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  materialItemUUID,
  {
    ...state.template,
    paperworkUUID,
    itemUUID,
    focussed: byUser
  }
)

const materialItemDuplicate = (
  state,
  { payload: { materialItemUUID, newUUID } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  newUUID,
  {
    ...state[materialItemUUID],
    uuid: newUUID,
    focussed: true
  }
)

const materialItemFormChanged = (
  state,
  { payload: { materialItemUUID, values } }
) => updateEntity(
  state,
  materialItemUUID,
  { ...values, changed: true }
)

const materialItemSaveSuccess = (
  state,
  { payload: { materialItemUUID } }
) => updateEntity(
  state,
  materialItemUUID,
  { saved: true }
)

const materialItemSaveFailure = (
  state,
  { payload: { materialItemUUID } }
) => updateEntity(
  state,
  materialItemUUID,
  { saved: false }
)

const itemAutocompleteChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { materialItems: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  return {
    ...state,
    ...newEntities
  }
}

const itemSearchChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { materialItems: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  return {
    ...state,
    ...newEntities
  }
}

const paperworkFetchSuccess = (state, action) => {
  const { materialItems: entities } = action.payload.data.entities

  return {
    ...state,
    ...removeStaleEntities(state, entities)
  }
}

const paperworkDuplicateSuccess = (state, action) => {
  const { materialItems: entities } = action.payload.data.entities

  return {
    ...state,
    ...entities
  }
}

const paperworkRemove = (state, { payload: { paperworkUUID } }) => (
  removeEntitiesByProperty(state, 'paperworkUUID', paperworkUUID)
)

const materialItemRemove = (state, { payload: { materialItemUUID } }) => (
  removeEntitiesByProperty(state, 'uuid', materialItemUUID)
)

export default createReducer({}, {
  [INIT]: init,
  [RESET]: reset,
  [ENTITIES_ADD]: entitiesAdd,
  [MATERIAL_AUTOCOMPLETE_CHOOSE]: materialAutocompleteChoose,
  [ITEM_AUTOCOMPLETE_CHOOSE]: itemAutocompleteChoose,
  [ITEM_SEARCH_CHOOSE]: itemSearchChoose,
  [MATERIAL_ITEM_ADD]: materialItemAdd,
  [MATERIAL_ITEM_REMOVE]: materialItemRemove,
  [MATERIAL_ITEM_DUPLICATE]: materialItemDuplicate,
  [MATERIAL_ITEM_FORM_CHANGED]: materialItemFormChanged,
  [MATERIAL_ITEM_SAVE_SUCCESS]: materialItemSaveSuccess,
  [MATERIAL_ITEM_SAVE_FAILURE]: materialItemSaveFailure,
  [MATERIAL_SEARCH_CHOOSE]: materialSearchChoose,
  [PAPERWORK_REMOVE]: paperworkRemove,
  [PAPERWORK_DUPLICATE_SUCCESS]: paperworkDuplicateSuccess,
  [PAPERWORK_FETCH_SUCCESS]: paperworkFetchSuccess
})
