import React from 'react'

const EmptyPriceList = () => (
  <section className='blank-state'>

    <h2 className='blank-state__title'>
      Your price list is empty
    </h2>
    <p className='blank-state__desc'>
      Save new items and manage your price list via
      {' '}
      <strong>Settings &gt; Price lists</strong>
    </p>

  </section>
)

export default EmptyPriceList
