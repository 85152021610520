import App from './app'

const {
  currentCompany,
  currentUser,
  revision,
  assetHost,
  publicHost,
  railsEnv
} = App

let devOptions = {}

if (railsEnv !== 'production') {
  devOptions = {
    environment: railsEnv,
    release: railsEnv,
    dsn: 'https://acacaeaccacacacabcaacdacdacadaca@sentry.io/000001' // dummy DSN
  }
}

window.Sentry = window.Sentry || {}
window.Sentry && Sentry.onLoad && Sentry.onLoad(() => {
  Sentry.init({
    environment: publicHost,
    release: revision,
    allowUrls: [assetHost, publicHost],
    sendDefaultPii: true, // required for headers and cookies
    ...devOptions
  })

  if (currentUser.loggedIn) {
    Sentry.setUser({
      id: currentUser.id,
      name: currentUser.name,
      email: currentUser.email,
      company_id: currentCompany.id,
      company_name: currentCompany.name,
      company_plan: currentCompany.plan
    })
  }

  Sentry.configureScope(scope => {
    scope.addEventProcessor(event => ({
      ...event,
      extra: {
        ...event.extra
      }
    }))
  })
})
