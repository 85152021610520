import {
  INIT,
  RESET,
  SHOW_MESSAGE_TEMPLATE_PANEL,
  HIDE_MESSAGE_TEMPLATE_PANEL,
  TEMPLATE_CHOSEN,
  SUBJECT_CHANGED,
  BODY_CHANGED,
  FETCH_CONTENT_SUCCESS
} from '../types'

const init = (state, action) => ({
  ...state,
  current: action.payload.id
})

const reset = (state, action) => {
  const newState = { ...state }
  delete newState[action.payload.id]

  return newState
}

const showTemplatePanel = state => ({
  ...state,
  [state.current]: {
    ...state[state.current],
    focussed: false
  }
})

const hideTemplatePanel = state => ({
  ...state,
  [state.current]: {
    ...state[state.current],
    focussed: true
  }
})

const handleTemplateChosen = (state, action) => {
  const { uuid, emailTemplate: { subject, body, attachments } } = action.payload

  return {
    ...state,
    [state.current]: {
      uuid,
      subject,
      body,
      attachments,
      subjectEdited: false,
      bodyEdited: false,
      templateChosen: true
    }
  }
}

const fetchContentSuccess = (state, { payload }) => {
  if (state[state.current]) {
    const { subjectEdited, bodyEdited, templateChosen } = state[state.current]
    if (subjectEdited || bodyEdited || templateChosen) { return state }
  }

  return ({
    ...state,
    [state.current]: {
      ...payload.json,
      uuid: payload.uuid,
      subjectEdited: false,
      bodyEdited: false,
      templateChosen: false
    }
  })
}

const subjectChanged = (state, action) => {
  const { text } = action.payload

  return {
    ...state,
    [state.current]: {
      ...state[state.current],
      subject: text,
      subjectEdited: text !== ''
    }
  }
}

const bodyChanged = (state, action) => {
  const { text } = action.payload

  return {
    ...state,
    [state.current]: {
      ...state[state.current],
      body: text,
      bodyEdited: text !== ''
    }
  }
}

const content = (state = {}, action) => {
  switch (action.type) {
    case INIT:
      return init(state, action)
    case RESET:
      return reset(state, action)
    case FETCH_CONTENT_SUCCESS:
      return fetchContentSuccess(state, action)
    case SHOW_MESSAGE_TEMPLATE_PANEL:
      return showTemplatePanel(state, action)
    case HIDE_MESSAGE_TEMPLATE_PANEL:
      return hideTemplatePanel(state, action)
    case TEMPLATE_CHOSEN:
      return handleTemplateChosen(state, action)
    case SUBJECT_CHANGED:
      return subjectChanged(state, action)
    case BODY_CHANGED:
      return bodyChanged(state, action)
    default:
      return state
  }
}

export default content
