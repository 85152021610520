import { Controller } from 'stimulus'

export default class DisableDuringUploadController extends Controller {
  static values = {
    text: String
  }

  connect () {
    document.addEventListener('direct-upload:start', this.directUploadStart.bind(this))
    document.addEventListener('direct-upload:error', this.directUploadError.bind(this))
    document.addEventListener('direct-upload:end', this.directUploadEnd.bind(this))
  }

  disconnect () {
    document.removeEventListener('direct-upload:start', this.directUploadStart.bind(this))
    document.removeEventListener('direct-upload:error', this.directUploadError.bind(this))
    document.removeEventListener('direct-upload:end', this.directUploadEnd.bind(this))
  }

  directUploadStart (event) {
    this.disable()
  }

  directUploadError (event) {
    this.enable()
  }

  directUploadEnd (event) {
    this.enable()
  }

  disable () {
    this.previousText = this.element.textContent
    this.element.innerHTML = this.textValue
    this.element.disabled = true
  }

  enable () {
    this.element.innerHTML = this.previousText
    this.element.disabled = false
  }
}
