import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'

import messageForm from './message-form/reducers'
import schedule from './schedule/reducers'

import messageFormSaga from './message-form/sagas'
import eventsSaga from './schedule/sagas'

const App = window.App || {}
const initialState = App.initialState || {}

const reducer = combineReducers({
  messageForm,
  schedule
})

const sagaMiddleware = createSagaMiddleware({
  onError: err => {
    if (!window.Sentry) return
    if (err && err.message) {
      Sentry.captureException(err)
    } else if (err) {
      Sentry.captureMessage(`${err}`)
    } else {
      Sentry.captureMessage('Unknown saga error')
    }
  }
})

const runSagaMiddleware = () => {
  sagaMiddleware.run(messageFormSaga)
  sagaMiddleware.run(eventsSaga)
}

const middleware = [sagaMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
)

runSagaMiddleware()

export default store
