import React from 'react'
import PaperworkTotal from './totals/paperwork'

const SubTotal = () => (
  <div>
    <span className='pwrk-frm__totals-label'>Sub Total</span>
    <span className='pwrk-frm__totals-value' data-test='sub-total'>
      <PaperworkTotal name='subTotal' />
    </span>
  </div>
)

export default SubTotal
