import React from 'react'
import PropTypes from 'prop-types'

import TrashSvg from '../../../svg/trash'

const DeleteLink = ({ onDelete, id }) => (
  <a
    onClick={onDelete}
    role='link'
    tabIndex={id}
    className='link delete-file c--red'
  >
    <span className='email-attachments__icon email-attachments__icon--red'>
      <TrashSvg />
    </span>
  </a>
)

DeleteLink.propTypes = {
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
}

export default DeleteLink
