import React from 'react'
import PropTypes from 'prop-types'
import Notification from './notification'

const Unread = ({ notifications, onClearAllClick }) => {
  if (notifications.length === 0) { return null }
  return (
    <div className='notifications-menu--unread' data-test='notifications-menu-items-unread'>
      <div className='notifications-menu__header'>
        <a className='notifications-menu__header__clear' onClick={onClearAllClick} data-test='notifications-clear-btn'>Mark all as read</a>
        <h4>Unread</h4>
      </div>
      {
        notifications.map(
          notification => <Notification key={notification.id} {...notification} />
        )
      }
    </div>
  )
}

Unread.defaultProps = {
  onClearAllClick: () => {},
  notifications: []
}

Unread.propTypes = {
  onClearAllClick: PropTypes.func,
  notifications: PropTypes.array
}

export default Unread
