import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import PermissionCategory from './permission-category'

const PermissionsList = ({ permissionCategories }) => {
  const { register, setValue } = useFormContext()

  useEffect(() => {
    const enabledPermissions = permissionCategories.map(permissionCategory => (
      permissionCategory.enabledPermissions
    )).flat()

    register('company_user[selected_permissions]')
    setValue('company_user[selected_permissions]', enabledPermissions)
  }, [])

  return (
    <>
      {permissionCategories.map(permissionCategory => (
        <PermissionCategory
          key={permissionCategory.title}
          category={permissionCategory.title}
          permissions={permissionCategory.permissions}
          permissionIds={permissionCategory.permissionIds}
          enabledPermissions={permissionCategory.enabledPermissions}
        />
      ))}
    </>
  )
}

PermissionsList.defaultProps = {
  permissionCategories: []
}

PermissionsList.propTypes = {
  permissionCategories: PropTypes.array
}

export default PermissionsList
