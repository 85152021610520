const NAMESPACE = 'SCHEDULE_'
export const INIT = `${NAMESPACE}INIT`
export const ROUTE_CHANGED = `${NAMESPACE}ROUTE_CHANGED`
export const FETCH_EVENTS_SUCCESS = `${NAMESPACE}FETCH_EVENTS_SUCCESS`
export const FETCH_EVENTS_FAILURE = `${NAMESPACE}FETCH_EVENTS_FAILURE`
export const DELETE_EVENT = `${NAMESPACE}DELETE_EVENT`
export const DELETE_EVENT_SUCCESS = `${NAMESPACE}DELETE_EVENT_SUCCESS`
export const DELETE_EVENT_FAILURE = `${NAMESPACE}DELETE_EVENT_FAILURE`
export const FETCH_COMPANY_USERS_SUCCESS = `${NAMESPACE}FETCH_COMPANY_USERS_SUCCESS`
export const FETCH_COMPANY_USERS_FAILURE = `${NAMESPACE}FETCH_COMPANY_USERS_FAILURE`
export const CHANGE_EVENT_COLOR = `${NAMESPACE}CHANGE_EVENT_COLOR`
export const CHANGE_EVENT_COLOR_SUCCESS = `${NAMESPACE}CHANGE_EVENT_COLOR_SUCCESS`
export const CHANGE_EVENT_COLOR_FAILURE = `${NAMESPACE}CHANGE_EVENT_COLOR_FAILURE`
