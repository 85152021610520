import { combineReducers } from 'redux'

import configuration from './configuration'
import errors from './errors'
import items from './items'
import labourItems from './labour-items'
import materialItems from './material-items'
import paperwork from './paperwork'
import sections from './sections'
import ui from './ui'

const appReducer = combineReducers({
  configuration,
  ui,
  errors,
  items,
  labourItems,
  materialItems,
  paperwork,
  sections
})

export default appReducer
