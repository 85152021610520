import { put, takeLatest, all } from 'redux-saga/effects'
import axios from '@ytb/axios'

import { INIT } from '../types'
import { fetchContentSuccess } from '../actions'

function * fetchContent (action) {
  const json = yield axios.get(action.payload.fetchUrl).then(response => response.data)
  yield put(fetchContentSuccess(json))
}

export default function * emailTemplatesSaga () {
  yield all([
    takeLatest(INIT, fetchContent)
  ])
}
