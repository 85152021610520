import _objectWithoutPropertiesLoose from '@babel/runtime/helpers/esm/objectWithoutPropertiesLoose'
import * as React from 'react'
import React__default from 'react'
import { formSubscriptionItems, createForm, version as version$1, fieldSubscriptionItems } from './final-form'

const _extends = function (target) {
  for (let i = 1; i < arguments.length; i++) {
    const source = arguments[i]
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key]
      }
    }
  }
  return target
}

const _excluded$3 = ['render', 'children', 'component']
// shared logic between components that use either render prop,
// children render function, or component prop
function renderComponent (props, lazyProps, name) {
  const render = props.render
  const children = props.children
  const component = props.component
  const rest = _objectWithoutPropertiesLoose(props, _excluded$3)

  if (component) {
    return /* #__PURE__ */React.createElement(component, Object.assign(lazyProps, rest, {
      children,
      render
    }))
  }

  if (render) {
    return render(children === undefined ? Object.assign(lazyProps, rest) // inject children back in
      : Object.assign(lazyProps, rest, {
        children
      }))
  }

  if (typeof children !== 'function') {
    throw new Error('Must specify either a render prop, a render function as children, or a component prop to ' + name)
  }

  return children(Object.assign(lazyProps, rest))
}

function useWhenValueChanges (value, callback, isEqual) {
  if (isEqual === void 0) {
    isEqual = function isEqual (a, b) {
      return a === b
    }
  }

  const previous = React__default.useRef(value)
  React__default.useEffect(function () {
    if (!isEqual(value, previous.current)) {
      callback()
      previous.current = value
    }
  })
}

/**
 * A simple hook to create a constant value that lives for
 * the lifetime of the component.
 *
 * Plagiarized from https://github.com/Andarist/use-constant
 *
 * Do NOT reuse this code unless you know what you're doing.
 * Use Andarist's hook; it's more fault tolerant to things like
 * falsy values.
 *
 * @param {Function} init - A function to generate the value
 */

function useConstant (init) {
  const ref = React__default.useRef()

  if (!ref.current) {
    ref.current = init()
  }

  return ref.current
}

const shallowEqual = function shallowEqual (a, b) {
  if (a === b) {
    return true
  }

  if (typeof a !== 'object' || !a || typeof b !== 'object' || !b) {
    return false
  }

  const keysA = Object.keys(a)
  const keysB = Object.keys(b)

  if (keysA.length !== keysB.length) {
    return false
  }

  const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(b)

  for (let idx = 0; idx < keysA.length; idx++) {
    const key = keysA[idx]

    if (!bHasOwnProperty(key) || a[key] !== b[key]) {
      return false
    }
  }

  return true
}

const isSyntheticEvent = function isSyntheticEvent (candidate) {
  return !!(candidate && typeof candidate.stopPropagation === 'function')
}

const ReactFinalFormContext = /* #__PURE__ */React.createContext()

function useLatest (value) {
  const ref = React__default.useRef(value)
  React__default.useEffect(function () {
    ref.current = value
  })
  return ref
}

const version = '6.5.8'

const addLazyState = function addLazyState (dest, state, keys) {
  keys.forEach(function (key) {
    Object.defineProperty(dest, key, {
      get: function get () {
        return state[key]
      },
      enumerable: true
    })
  })
}

const addLazyFormState = function addLazyFormState (dest, state) {
  return addLazyState(dest, state, ['active', 'dirty', 'dirtyFields', 'dirtySinceLastSubmit', 'dirtyFieldsSinceLastSubmit', 'error', 'errors', 'hasSubmitErrors', 'hasValidationErrors', 'initialValues', 'invalid', 'modified', 'modifiedSinceLastSubmit', 'pristine', 'submitError', 'submitErrors', 'submitFailed', 'submitSucceeded', 'submitting', 'touched', 'valid', 'validating', 'values', 'visited'])
}
const addLazyFieldMetaState = function addLazyFieldMetaState (dest, state) {
  return addLazyState(dest, state, ['active', 'data', 'dirty', 'dirtySinceLastSubmit', 'error', 'initial', 'invalid', 'length', 'modified', 'modifiedSinceLastSubmit', 'pristine', 'submitError', 'submitFailed', 'submitSucceeded', 'submitting', 'touched', 'valid', 'validating', 'visited'])
}

const _excluded$2 = ['debug', 'decorators', 'destroyOnUnregister', 'form', 'initialValues', 'initialValuesEqual', 'keepDirtyOnReinitialize', 'mutators', 'onSubmit', 'subscription', 'validate', 'validateOnBlur']
const versions = {
  'final-form': version$1,
  'react-final-form': version
}
const all$1 = formSubscriptionItems.reduce(function (result, key) {
  result[key] = true
  return result
}, {})

function ReactFinalForm (_ref) {
  const debug = _ref.debug
  const _ref$decorators = _ref.decorators
  const decorators = _ref$decorators === void 0 ? [] : _ref$decorators
  const destroyOnUnregister = _ref.destroyOnUnregister
  const alternateFormApi = _ref.form
  const initialValues = _ref.initialValues
  const initialValuesEqual = _ref.initialValuesEqual
  const keepDirtyOnReinitialize = _ref.keepDirtyOnReinitialize
  const mutators = _ref.mutators
  const onSubmit = _ref.onSubmit
  const _ref$subscription = _ref.subscription
  const subscription = _ref$subscription === void 0 ? all$1 : _ref$subscription
  const validate = _ref.validate
  const validateOnBlur = _ref.validateOnBlur
  const rest = _objectWithoutPropertiesLoose(_ref, _excluded$2)

  const config = {
    debug,
    destroyOnUnregister,
    initialValues,
    keepDirtyOnReinitialize,
    mutators,
    onSubmit,
    validate,
    validateOnBlur
  }
  const form = useConstant(function () {
    const f = alternateFormApi || createForm(config) // pause validation until children register all fields on first render (unpaused in useEffect() below)

    f.pauseValidation()
    return f
  }) // synchronously register and unregister to query form state for our subscription on first render

  const _React$useState = React.useState(function () {
    let initialState = {}
    form.subscribe(function (state) {
      initialState = state
    }, subscription)()
    return initialState
  })
  const state = _React$useState[0]
  const setState = _React$useState[1] // save a copy of state that can break through the closure
  // on the shallowEqual() line below.

  const stateRef = useLatest(state)
  React.useEffect(function () {
    // We have rendered, so all fields are now registered, so we can unpause validation
    form.isValidationPaused() && form.resumeValidation()
    const unsubscriptions = [form.subscribe(function (s) {
      if (!shallowEqual(s, stateRef.current)) {
        setState(s)
      }
    }, subscription)].concat(decorators ? decorators.map(function (decorator) {
      return (// this noop ternary is to appease the flow gods
        // istanbul ignore next
        decorator(form)
      )
    }) : [])
    return function () {
      form.pauseValidation() // pause validation so we don't revalidate on every field deregistration

      unsubscriptions.reverse().forEach(function (unsubscribe) {
        return unsubscribe()
      }) // don't need to resume validation here; either unmounting, or will re-run this hook with new deps
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, decorators) // warn about decorator changes
  // istanbul ignore next

  if (process.env.NODE_ENV !== 'production') {
    // You're never supposed to use hooks inside a conditional, but in this
    // case we can be certain that you're not going to be changing your
    // NODE_ENV between renders, so this is safe.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useWhenValueChanges(decorators, function () {
      console.error('Form decorators should not change from one render to the next as new values will be ignored')
    }, shallowEqual)
  } // allow updatable config

  useWhenValueChanges(debug, function () {
    form.setConfig('debug', debug)
  })
  useWhenValueChanges(destroyOnUnregister, function () {
    form.destroyOnUnregister = !!destroyOnUnregister
  })
  useWhenValueChanges(keepDirtyOnReinitialize, function () {
    form.setConfig('keepDirtyOnReinitialize', keepDirtyOnReinitialize)
  })
  useWhenValueChanges(initialValues, function () {
    form.setConfig('initialValues', initialValues)
  }, initialValuesEqual || shallowEqual)
  useWhenValueChanges(mutators, function () {
    form.setConfig('mutators', mutators)
  })
  useWhenValueChanges(onSubmit, function () {
    form.setConfig('onSubmit', onSubmit)
  })
  useWhenValueChanges(validate, function () {
    form.setConfig('validate', validate)
  })
  useWhenValueChanges(validateOnBlur, function () {
    form.setConfig('validateOnBlur', validateOnBlur)
  })

  const handleSubmit = function handleSubmit (event) {
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault()
      }

      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation()
      }
    }

    return form.submit()
  }

  const renderProps = {
    form: _extends({}, form, {
      reset: function reset (eventOrValues) {
        if (isSyntheticEvent(eventOrValues)) {
          // it's a React SyntheticEvent, call reset with no arguments
          form.reset()
        } else {
          form.reset(eventOrValues)
        }
      }
    }),
    handleSubmit
  }
  addLazyFormState(renderProps, state)
  return /* #__PURE__ */React.createElement(ReactFinalFormContext.Provider, {
    value: form
  }, renderComponent(_extends({}, rest, {
    __versions: versions
  }), renderProps, 'ReactFinalForm'))
}

function useForm (componentName) {
  const form = React.useContext(ReactFinalFormContext)

  if (!form) {
    throw new Error((componentName || 'useForm') + ' must be used inside of a <Form> component')
  }

  return form
}

function useFormState (_temp) {
  const _ref = _temp === void 0 ? {} : _temp
  const onChange = _ref.onChange
  const _ref$subscription = _ref.subscription
  const subscription = _ref$subscription === void 0 ? all$1 : _ref$subscription

  const form = useForm('useFormState')
  const firstRender = React.useRef(true)
  const onChangeRef = React.useRef(onChange)
  onChangeRef.current = onChange // synchronously register and unregister to query field state for our subscription on first render

  const _React$useState = React.useState(function () {
    let initialState = {}
    form.subscribe(function (state) {
      initialState = state
    }, subscription)()

    if (onChange) {
      onChange(initialState)
    }

    return initialState
  })
  const state = _React$useState[0]
  const setState = _React$useState[1]

  React.useEffect(function () {
    return form.subscribe(function (newState) {
      if (firstRender.current) {
        firstRender.current = false
      } else {
        setState(newState)

        if (onChangeRef.current) {
          onChangeRef.current(newState)
        }
      }
    }, subscription)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [])
  const lazyState = {}
  addLazyFormState(lazyState, state)
  return lazyState
}

const _excluded$1 = ['onChange', 'subscription']

function FormSpy (_ref) {
  const onChange = _ref.onChange
  const subscription = _ref.subscription
  const rest = _objectWithoutPropertiesLoose(_ref, _excluded$1)

  const reactFinalForm = useForm('FormSpy')
  const state = useFormState({
    onChange,
    subscription
  })

  if (onChange) {
    return null
  }

  const renderProps = {
    form: _extends({}, reactFinalForm, {
      reset: function reset (eventOrValues) {
        if (isSyntheticEvent(eventOrValues)) {
          // it's a React SyntheticEvent, call reset with no arguments
          reactFinalForm.reset()
        } else {
          reactFinalForm.reset(eventOrValues)
        }
      }
    })
  }
  return renderComponent(_extends({}, rest, renderProps), state, 'FormSpy')
}

const isReactNative = typeof window !== 'undefined' && window.navigator && window.navigator.product && window.navigator.product === 'ReactNative'

const getSelectedValues = function getSelectedValues (options) {
  const result = []

  if (options) {
    for (let index = 0; index < options.length; index++) {
      const option = options[index]

      if (option.selected) {
        result.push(option.value)
      }
    }
  }

  return result
}

const getValue = function getValue (event, currentValue, valueProp, isReactNative) {
  if (!isReactNative && event.nativeEvent && event.nativeEvent.text !== undefined) {
    return event.nativeEvent.text
  }

  if (isReactNative && event.nativeEvent) {
    return event.nativeEvent.text
  }

  const detypedEvent = event
  const _detypedEvent$target = detypedEvent.target
  const type = _detypedEvent$target.type
  const value = _detypedEvent$target.value
  const checked = _detypedEvent$target.checked

  switch (type) {
    case 'checkbox':
      if (valueProp !== undefined) {
        // we are maintaining an array, not just a boolean
        if (checked) {
          // add value to current array value
          return Array.isArray(currentValue) ? currentValue.concat(valueProp) : [valueProp]
        } else {
          // remove value from current array value
          if (!Array.isArray(currentValue)) {
            return currentValue
          }

          const index = currentValue.indexOf(valueProp)

          if (index < 0) {
            return currentValue
          } else {
            return currentValue.slice(0, index).concat(currentValue.slice(index + 1))
          }
        }
      } else {
        // it's just a boolean
        return !!checked
      }

    case 'select-multiple':
      return getSelectedValues(event.target.options)

    default:
      return value
  }
}

/**
 * Creates a callback, even with closures, that will be
 * instance === for the lifetime of the component, always
 * calling the most recent version of the function and its
 * closures.
 */

function useConstantCallback (callback) {
  const ref = React.useRef(callback)
  React.useEffect(function () {
    ref.current = callback
  })
  return React.useCallback(function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key]
    }

    return ref.current.apply(null, args)
  }, [])
}

const all = fieldSubscriptionItems.reduce(function (result, key) {
  result[key] = true
  return result
}, {})

const defaultFormat = function defaultFormat (value, name) {
  return value === undefined ? '' : value
}

const defaultParse = function defaultParse (value, name) {
  return value === '' ? undefined : value
}

const defaultIsEqual = function defaultIsEqual (a, b) {
  return a === b
}

function useField (name, config) {
  if (config === void 0) {
    config = {}
  }

  const _config = config
  const afterSubmit = _config.afterSubmit
  const allowNull = _config.allowNull
  const component = _config.component
  const data = _config.data
  const defaultValue = _config.defaultValue
  const _config$format = _config.format
  const format = _config$format === void 0 ? defaultFormat : _config$format
  const formatOnBlur = _config.formatOnBlur
  const initialValue = _config.initialValue
  const multiple = _config.multiple
  const _config$parse = _config.parse
  const parse = _config$parse === void 0 ? defaultParse : _config$parse
  const _config$subscription = _config.subscription
  const subscription = _config$subscription === void 0 ? all : _config$subscription
  const type = _config.type
  const validateFields = _config.validateFields
  const _value = _config.value
  const form = useForm('useField')
  const configRef = useLatest(config)

  const register = function register (callback, silent) {
    return (// avoid using `state` const in any closures created inside `register`
      // because they would refer `state` from current execution context
      // whereas actual `state` would defined in the subsequent `useField` hook
      // execution
      // (that would be caused by `setState` call performed in `register` callback)
      form.registerField(name, callback, subscription, {
        afterSubmit,
        beforeSubmit: function beforeSubmit () {
          const _configRef$current = configRef.current
          const beforeSubmit = _configRef$current.beforeSubmit
          const formatOnBlur = _configRef$current.formatOnBlur
          const _configRef$current$fo = _configRef$current.format
          const format = _configRef$current$fo === void 0 ? defaultFormat : _configRef$current$fo

          if (formatOnBlur) {
            const _ref = form.getFieldState(name)
            const value = _ref.value

            const formatted = format(value, name)

            if (formatted !== value) {
              form.change(name, formatted)
            }
          }

          return beforeSubmit && beforeSubmit()
        },
        data,
        defaultValue,
        getValidator: function getValidator () {
          return configRef.current.validate
        },
        initialValue,
        isEqual: function isEqual (a, b) {
          return (configRef.current.isEqual || defaultIsEqual)(a, b)
        },
        silent,
        validateFields
      })
    )
  }

  const firstRender = React.useRef(true) // synchronously register and unregister to query field state for our subscription on first render

  const _React$useState = React.useState(function () {
    let initialState = {} // temporarily disable destroyOnUnregister

    // temporarily disable destroyOnUnregister
    const destroyOnUnregister = form.destroyOnUnregister
    form.destroyOnUnregister = false
    register(function (state) {
      initialState = state
    }, true)() // return destroyOnUnregister to its original value

    // return destroyOnUnregister to its original value
    form.destroyOnUnregister = destroyOnUnregister
    return initialState
  })
  const state = _React$useState[0]
  const setState = _React$useState[1]

  React.useEffect(function () {
    return register(function (state) {
      if (firstRender.current) {
        firstRender.current = false
      } else {
        setState(state)
      }
    }, false)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [name, data, defaultValue, // If we want to allow inline fat-arrow field-level validation functions, we
    // cannot reregister field every time validate function !==.
    // validate,
    initialValue // The validateFields array is often passed as validateFields={[]}, creating
    // a !== new array every time. If it needs to be changed, a rerender/reregister
    // can be forced by changing the key prop
    // validateFields
  ])
  const meta = {}
  addLazyFieldMetaState(meta, state)
  const input = {
    name,

    get value () {
      let value = state.value

      if (formatOnBlur) {
        if (component === 'input') {
          value = defaultFormat(value)
        }
      } else {
        value = format(value, name)
      }

      if (value === null && !allowNull) {
        value = ''
      }

      if (type === 'checkbox' || type === 'radio') {
        return _value
      } else if (component === 'select' && multiple) {
        return value || []
      }

      return value
    },

    get checked () {
      let value = state.value

      if (type === 'checkbox') {
        value = format(value, name)

        if (_value === undefined) {
          return !!value
        } else {
          return !!(Array.isArray(value) && ~value.indexOf(_value))
        }
      } else if (type === 'radio') {
        return format(value, name) === _value
      }

      return undefined
    },

    onBlur: useConstantCallback(function (event) {
      state.blur()

      if (formatOnBlur) {
        /**
         * Here we must fetch the value directly from Final Form because we cannot
         * trust that our `state` closure has the most recent value. This is a problem
         * if-and-only-if the library consumer has called `onChange()` immediately
         * before calling `onBlur()`, but before the field has had a chance to receive
         * the value update from Final Form.
         */
        const fieldState = form.getFieldState(state.name)
        state.change(format(fieldState.value, state.name))
      }
    }),
    onChange: useConstantCallback(function (event) {
      // istanbul ignore next
      if (process.env.NODE_ENV !== 'production' && event && event.target) {
        const targetType = event.target.type
        const unknown = ~['checkbox', 'radio', 'select-multiple'].indexOf(targetType) && !type && component !== 'select'

        const _value2 = targetType === 'select-multiple' ? state.value : _value

        if (unknown) {
          console.error('You must pass `type="' + (targetType === 'select-multiple' ? 'select' : targetType) + '"` prop to your Field(' + name + ') component.\n' + ("Without it we don't know how to unpack your `value` prop - " + (Array.isArray(_value2) ? '[' + _value2 + ']' : '"' + _value2 + '"') + '.'))
        }
      }

      const value = event && event.target ? getValue(event, state.value, _value, isReactNative) : event
      state.change(parse(value, name))
    }),
    onFocus: useConstantCallback(function (event) {
      return state.focus()
    })
  }

  if (multiple) {
    input.multiple = multiple
  }

  if (type !== undefined) {
    input.type = type
  }

  const renderProps = {
    input,
    meta
  } // assign to force Flow check

  return renderProps
}

const _excluded = ['afterSubmit', 'allowNull', 'beforeSubmit', 'children', 'component', 'data', 'defaultValue', 'format', 'formatOnBlur', 'initialValue', 'isEqual', 'multiple', 'name', 'parse', 'subscription', 'type', 'validate', 'validateFields', 'value']
const Field = /* #__PURE__ */React.forwardRef(function Field (_ref, ref) {
  const afterSubmit = _ref.afterSubmit
  const allowNull = _ref.allowNull
  const beforeSubmit = _ref.beforeSubmit
  const children = _ref.children
  const component = _ref.component
  const data = _ref.data
  const defaultValue = _ref.defaultValue
  const format = _ref.format
  const formatOnBlur = _ref.formatOnBlur
  const initialValue = _ref.initialValue
  const isEqual = _ref.isEqual
  const multiple = _ref.multiple
  const name = _ref.name
  const parse = _ref.parse
  const subscription = _ref.subscription
  const type = _ref.type
  const validate = _ref.validate
  const validateFields = _ref.validateFields
  const value = _ref.value
  const rest = _objectWithoutPropertiesLoose(_ref, _excluded)

  const field = useField(name, {
    afterSubmit,
    allowNull,
    beforeSubmit,
    children,
    component,
    data,
    defaultValue,
    format,
    formatOnBlur,
    initialValue,
    isEqual,
    multiple,
    parse,
    subscription,
    type,
    validate,
    validateFields,
    value
  })

  if (typeof children === 'function') {
    return children(_extends({}, field, rest))
  }

  if (typeof component === 'string') {
    // ignore meta, combine input with any other props
    return /* #__PURE__ */React.createElement(component, _extends({}, field.input, {
      children,
      ref
    }, rest))
  }

  if (!name) {
    throw new Error('prop name cannot be undefined in <Field> component')
  }

  return renderComponent(_extends({
    children,
    component,
    ref
  }, rest), field, 'Field(' + name + ')')
})

function withTypes () {
  return {
    Form: ReactFinalForm,
    FormSpy
  }
}

export { Field, ReactFinalForm as Form, FormSpy, useField, useForm, useFormState, version, withTypes }
