import React from 'react'
import PropTypes from 'prop-types'

import FileListItem from './file-list-item'
import SelectableFileListItem from './selectable-file-list-item'

const FileList = ({ files, onDelete, currentUserId, isSelectable, showFileSize, inputName }) => (
  <ul>
    {files.map(file => (
      isSelectable
        ? (
          <SelectableFileListItem
            file={file}
            onDelete={onDelete}
            key={file.id}
            inputName={inputName}
          />
          )
        : (
          <FileListItem
            file={file}
            onDelete={onDelete}
            currentUserId={currentUserId}
            key={file.id}
            showFileSize={showFileSize}
          />
          )
    ))}
  </ul>
)

FileList.defaultProps = {
  files: [],
  isSelectable: false,
  showFileSize: false,
  currentUserId: null,
  inputName: 'attachments'
}

FileList.propTypes = {
  files: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  isSelectable: PropTypes.bool,
  showFileSize: PropTypes.bool,
  inputName: PropTypes.string
}

export default FileList
