/* eslint-disable no-useless-call */
import { createChannel } from './consumer'

let callback

const channel = createChannel('NotificationsChannel', {
  received (data) {
    if (callback) callback.call(null, data)
  }
})

const setNotificationCallback = fn => {
  callback = fn
}

export { channel, setNotificationCallback }
