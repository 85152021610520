import React from 'react'
import PropTypes from 'prop-types'

import TrashSvg from '@/components/svg/trash'
import DropdownItem from '../../dropdown/item'

const DeleteButton = ({ onDelete }) => (
  <DropdownItem
    className='menu__item--delete'
  >
    <a
      href='/events'
      data-confirm='Are you sure you want to delete this event? Cannot undo!'
      data-event-menu-delete-button
      onClick={e => { onDelete(); e.preventDefault() }}
    >
      <TrashSvg className='icon icon--red' />
      Delete
    </a>
  </DropdownItem>
)

DeleteButton.defaultProps = {
  onDelete: () => {}
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func
}

export default DeleteButton
