import {
  format,
  unformat,
  toFixed
} from 'accounting-js'

const formatMoney = (value, currencyUnit = '') => format(
  toFixed(unformat(value), 2),
  {
    symbol: currencyUnit,
    format: {
      pos: '%s%v', // for positive values, eg. "$1.00" (required)
      neg: '(%s-%v)', // for negative values, eg. "($1.00)" [optional]
      zero: '%s%v' // for zero values, eg. "$0.00" [optional]
    }
  }
)

export default formatMoney
