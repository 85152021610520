import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ColorSelector = ({ colors, selectedColor, onChange }) => {
  const [activeColor, setActiveColor] = useState(selectedColor)

  const handleSelect = color => {
    setActiveColor(color)
    onChange(color)
  }

  return (
    colors.map((color, i) => (
      <span
        onClick={() => handleSelect(color)}
        role='menuitem'
        tabIndex={i}
        aria-label={`Color selector ${color}`}
        key={color}
        className={`color-selector__item color-selector__item--sml color-selector__item--${color} ${activeColor === color ? 'color-selector__item--selected' : ''} u-mrgn-r`}
      />
    ))
  )
}

ColorSelector.defaultProps = {
  colors: [],
  selectedColor: ''
}

ColorSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  selectedColor: PropTypes.string
}

export default ColorSelector
