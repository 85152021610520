import React from 'react'

import App from './app'
import TemplateButton from './components/template-button'

export default props => (
  <App>
    <TemplateButton {...props} />
  </App>
)
