import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { normalize, denormalize } from 'normalizr'
import { decamelizeKeys } from 'humps'

import setNewUuids, { onlyUuids, setNewUuidsWithMap } from '../helpers/set-new-uuids'
import { itemSave } from '../services'
import {
  ITEM_ADD,
  ITEM_SAVE,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_DUPLICATE
} from '../types'
import {
  entitiesAdd,
  materialItemAdd,
  labourItemAdd,
  sectionRemove,
  itemSaveSuccess,
  itemSaveFailure
} from '../actions'
import * as schemas from '../schemas'
import { getPaperwork } from '../selectors/paperwork'
import { getItem } from '../selectors/items'

export function * itemAddSaga (action) {
  const { paperworkUUID, itemUUID } = action.payload

  yield put(materialItemAdd(paperworkUUID, itemUUID, false))
  yield put(materialItemAdd(paperworkUUID, itemUUID, false))
  yield put(labourItemAdd(paperworkUUID, itemUUID, false))
  yield put(labourItemAdd(paperworkUUID, itemUUID, false))
}

export function * itemDuplicateSaga ({ payload: { itemUUID } }) {
  const allState = yield (select(state => state))

  // fetch the current item entity
  const item = yield (select(state => getItem(state, itemUUID)))

  // fetch the related paperwork entity
  const paperwork = yield (select(state => getPaperwork(state, item.paperworkUUID)))

  // scope the paperwork to only the item (and section) we want to duplicate
  const paperworkWithOneItem = { ...paperwork, items: [itemUUID], sections: [itemUUID] }

  // denormalize the paperwork to get all entities (items, sections, materialItems, and labourItems from allState
  const denormalized = yield call(denormalize, paperworkWithOneItem, schemas.paperwork, allState)

  // change all the uuids to brand new uuids (which ultimately creates new items, sections, materialItems, and labourItems)
  const [newUuids, uuids] = yield call(setNewUuidsWithMap, denormalized, [paperwork.uuid])

  // filter the entities to only the ones we want to add to the store
  const withOnlyUuids = yield call(onlyUuids, newUuids, uuids)

  // convert them back to a normalized structure ready to be added to the store
  const normalizedNewUuids = yield call(normalize, withOnlyUuids, schemas.paperwork, allState)

  // focus the new section that has been created
  // TODO: this probably isn't the best place to do this setting it in the section reducer would be a better option
  const newSection = normalizedNewUuids.entities.sections[uuids[itemUUID]]
  newSection.focussed = true

  // As we want to add this to an existing piece of paperwork, we merge the existing item and section uuids back into the new entities
  const newPaperwork = normalizedNewUuids.entities.paperwork[paperwork.uuid]
  // TODO: add the new items and sections after the ones we are duplicating instead of at the end
  newPaperwork.items = paperwork.items.toSpliced(paperwork.items.indexOf(itemUUID), 0, ...newPaperwork.items)
  newPaperwork.sections = paperwork.sections.toSpliced(paperwork.sections.indexOf(itemUUID), 0, ...newPaperwork.sections)

  // we now dispatch an action to allow the reducers for each slice of state to add the new entities to the store
  yield put(entitiesAdd(normalizedNewUuids.entities))
}

export function * itemAutocompleteChooseSaga (action) {
  const { paperworkUUID, itemUUID } = action.payload

  yield put(sectionRemove(paperworkUUID, itemUUID))
}

export function * itemSaveSaga (action) {
  const { itemUUID } = action.payload
  const allState = yield (select(state => state))
  const { paperworkUUID } = yield (select(state => getItem(state, itemUUID)))
  const paperwork = yield (select(state => getPaperwork(state, paperworkUUID)))
  const paperworkWithOneItem = { ...paperwork, items: [itemUUID] }
  const denormalized = yield call(denormalize, paperworkWithOneItem, schemas.paperwork, allState)
  const decamelized = yield call(decamelizeKeys, denormalized)
  const withNewUuids = yield call(setNewUuids, decamelized)

  const { response, error } = yield call(itemSave, withNewUuids)

  if (response) {
    yield put(itemSaveSuccess(itemUUID))
  } else {
    yield put(itemSaveFailure(itemUUID, error))
  }
}

export default function * itemsSaga () {
  yield all([
    takeLatest(ITEM_ADD, itemAddSaga),
    takeLatest(ITEM_DUPLICATE, itemDuplicateSaga),
    takeLatest(ITEM_SAVE, itemSaveSaga),
    takeLatest(ITEM_AUTOCOMPLETE_CHOOSE, itemAutocompleteChooseSaga)
  ])
}
