import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

const messages = {
  user: {
    name: {
      required: 'Please enter a name for this team member'
    },
    email: {
      required: 'Please enter an email address for this team member'
    }
  },
  company_user: {
    initials: {
      required: 'Please enter the initials for this team member'
    }
  }
}

const ErrorMessage = ({ object, field }) => {
  const { errors } = useFormContext()
  if (!errors[object] || !errors[object][field]) return null
  const { message, type } = errors[object][field]
  if (type === 'server') {
    return <span className='frm__error-msg'>{message}</span>
  }
  return <span className='frm__error-msg'>{messages[object][field][type]}</span>
}

ErrorMessage.propTypes = {
  object: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired
}

export default ErrorMessage
