import React from 'react'

const SaveSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M2.25 24A2.252 2.252 0 0 1 0 21.75V7.243a3.72 3.72 0 0 1 1.099-2.651L4.59 1.099A3.727 3.727 0 0 1 7.243 0H21.75A2.252 2.252 0 0 1 24 2.25v19.5A2.252 2.252 0 0 1 21.75 24H2.25zm19.5-1.5a.75.75 0 0 0 .75-.75V2.25a.75.75 0 0 0-.75-.75H19.5v5.25A2.252 2.252 0 0 1 17.25 9h-7.5A2.252 2.252 0 0 1 7.5 6.75V1.5h-.257c-.601 0-1.167.234-1.592.659L2.16 5.652c-.426.425-.66.99-.66 1.591V21.75c0 .414.336.75.75.75H4.5v-8.25A2.252 2.252 0 0 1 6.75 12h10.5a2.252 2.252 0 0 1 2.25 2.25v8.25h2.25zm-3.75 0v-8.25a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v8.25h12zM9 6.75c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75V1.5H9v5.25z' />
      <path d='M15.75 6a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-.75.75zM8.25 16.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5h-4.5zM8.25 19.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 0 1 0 1.5h-7.5z' />
    </svg>
  </span>
)

export default SaveSvg
