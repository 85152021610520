import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import NotificationBellEmptySvg from '@/components/svg/notification-bell--empty'
import NotificationBellSvg from '@/components/svg/notification-bell'

const Bell = ({ onBellLoad, onBellClick, unreadCount }) => {
  useEffect(onBellLoad, [])

  return (
    <a
      onClick={onBellClick}
      className={unreadCount > 0 ? 'notification-bell notification-bell--unread' : 'notification-bell'}
      href='/notifications'
      data-test='notification-bell'
    >
      {unreadCount > 0 ? <NotificationBellSvg /> : <NotificationBellEmptySvg />}
    </a>
  )
}

Bell.defaultProps = {
  onBellLoad: () => {},
  onBellClick: () => {},
  unreadCount: 0
}

Bell.propTypes = {
  onBellLoad: PropTypes.func,
  onBellClick: PropTypes.func,
  unreadCount: PropTypes.number
}

export default Bell
