import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import CheckCircleSvg from '@/components/svg/check-circle'
import CloseSvg from '@/components/svg/close'
import PermissionsList from './permissions-list'

const Role = ({
  adminPrice,
  permissions,
  canManageAdmins,
  canManageFieldStaff,
  proRataCredit,
  initialRole
}) => {
  const { getValues, register } = useFormContext()

  const [activeRole, setActiveRole] = useState('admin')

  useEffect(() => {
    const data = getValues()
    setActiveRole(data['company_user[role]'])
  }, [getValues])

  const handleAdminClick = () => {
    setActiveRole('admin')
  }

  const handleFieldClick = () => {
    setActiveRole('field')
  }

  const isCreditable = () => { return initialRole === 'admin' && proRataCredit.length > 0 }

  return (
    <div className='u-mrgn-btm--x6'>
      <label className='frm__label'>What&apos;s their role?</label>
      <div className='frm__radio-block'>
        <ul className={`flex-grid ${canManageAdmins && canManageFieldStaff ? 'flex-grid--w-gutters' : ''} u-mrgn-l--x0`}>
          {canManageAdmins && (
            <li className={`${canManageFieldStaff ? 'flex-grid__cell--1of2' : 'flex-grid__cell u-mrgn-l--x0'}`}>
              <label
                onClick={(handleAdminClick)}
                data-test='admin-label'
                className={`frm__label--boxed u-cursor-pointer ${activeRole === 'admin' ? 'active' : ''}`}
              >
                <input
                  className='u-float-left u-mrgn-top'
                  type='radio'
                  data-action='click->toggle#toggleNoPrevent'
                  value='admin'
                  name='company_user[role]'
                  ref={register}
                />
                <span className='u-inline-block'>
                  <h5>
                    <strong>Admin</strong>
                  </h5>
                  <p className='t--sml'>
                    {adminPrice}
                    &nbsp;per month
                  </p>
                </span>
              </label>
            </li>
          )}

          {canManageFieldStaff && (
            <li className='flex-grid__cell'>
              <label
                onClick={handleFieldClick}
                data-test='field-label'
                className={`frm__label--boxed u-cursor-pointer ${activeRole === 'field' ? 'active' : ''}`}
              >
                <input
                  className='u-float-left u-mrgn-top'
                  type='radio'
                  value='field'
                  data-action='click->toggle#toggleNoPrevent'
                  name='company_user[role]'
                  ref={register}
                />
                <span className='u-inline-block'>
                  <h5>
                    <strong>Field team</strong>
                  </h5>
                  <p className='t--sml'>
                    FREE
                  </p>
                  {isCreditable() && (
                    <p className='t--sml'>
                      We'll credit you {proRataCredit} for unused days
                    </p>
                  )}
                </span>
              </label>
            </li>
          )}
        </ul>
      </div>

      {activeRole === 'admin' && (
        <>
          <h3 className='company-user__permissions-title c--green'>
            Can…
          </h3>
          <div className='company-user__permissions company-user__permissions--green'>
            <ul className='company-user__permissions-section'>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CheckCircleSvg className='icon icon--sml icon--green' />
                  View, create and manage all customers, jobs and scheduling
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CheckCircleSvg className='icon icon--sml icon--green' />
                  Assign work to field team members
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CheckCircleSvg className='icon icon--sml icon--green' />
                  Receive notifications (choose which via <strong>Settings &gt; Notifications</strong>)
                </span>
              </li>
            </ul>
            <PermissionsList
              permissionCategories={permissions.admin}
            />
          </div>
          <h3 className='company-user__permissions-title c--red'>
            Can&apos;t…
          </h3>
          <div className='company-user__permissions company-user__permissions--red'>
            <ul className='company-user__permissions-section'>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CloseSvg className='icon icon--sml icon--red' />
                  Remove account owner
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CloseSvg className='icon icon--sml icon--red' />
                  Manage billing
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CloseSvg className='icon icon--sml icon--red' />
                  Cancel account
                </span>
              </li>
            </ul>
          </div>
        </>
      )}

      {activeRole === 'field' && (
        <>
          <h3 className='company-user__permissions-title c--green'>
            Can…
          </h3>
          <div className='company-user__permissions company-user__permissions--green'>
            <ul className='company-user__permissions-section'>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CheckCircleSvg className='icon icon--sml icon--green' />
                  Only view job sheets, events and tasks they&apos;ve been assigned
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CheckCircleSvg className='icon icon--sml icon--green' />
                  Add notes and upload images to jobs they&apos;re assigned to
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CheckCircleSvg className='icon icon--sml icon--green' />
                  Receive daily schedule emails
                </span>
              </li>
            </ul>
          </div>
          <h3 className='company-user__permissions-title c--red'>
            Can&apos;t…
          </h3>
          <div className='company-user__permissions company-user__permissions--red'>
            <ul className='company-user__permissions-section'>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CloseSvg className='icon icon--sml icon--red' />
                  View any job costs or totals
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CloseSvg className='icon icon--sml icon--red' />
                  View or manage any quotes or invoices
                </span>
              </li>
              <li className='company-user__permissions-item'>
                <span className='company-user__permissions-item-label'>
                  <CloseSvg className='icon icon--sml icon--red' />
                  Create or update any jobs, customers or paperwork
                </span>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

Role.propTypes = {
  adminPrice: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  canManageAdmins: PropTypes.bool.isRequired,
  canManageFieldStaff: PropTypes.bool.isRequired,
  proRataCredit: PropTypes.string
}

export default Role
