import React from 'react'
import PropTypes from 'prop-types'
import Notification from './notification'

const NotificationsList = ({ label, notifications }) => (
  <section className='notifications-list' data-test={`notifications-items-${label.toLowerCase()}`}>
    <div className='section-heading'>
      <h4 className='section-heading__title'>{label}</h4>
    </div>
    {
      notifications.map(
        notification => <Notification key={notification.id} {...notification} />
      )
    }
  </section>
)

NotificationsList.defaultProps = {
  label: '',
  notifications: []
}

NotificationsList.propTypes = {
  label: PropTypes.string,
  notifications: PropTypes.array
}

export default NotificationsList
