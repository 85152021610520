import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Announcement extends Component {
  render () {
    const { children, type } = this.props
    const classes = `announcement announcement--${type}`
    return (
      <div className={classes}>
        <div className='t--white u-align-center'>
          {children}
        </div>
      </div>
    )
  }
}

Announcement.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node
}

Announcement.defaultProps = {
  type: '',
  children: null
}

export default Announcement
