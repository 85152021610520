import React from 'react'

const EmptyLabourRates = () => (
  <section className='blank-state'>

    <h2 className='blank-state__title'>
      No labour rates in your list
    </h2>
    <p className='blank-state__desc'>
      Visit
      {' '}
      <a href='/labour_rates' target='_blank' className='link'>
        <strong>Settings &gt; Labour rates</strong>
      </a>
      {' '}
      to add labour rates to your list, then easily select those rates when costing work 👍
    </p>

  </section>
)

export default EmptyLabourRates
