import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { denormalize } from 'normalizr'

import { getPaperwork, getState } from '../selectors'
import * as schemas from '../schemas'
import { PaperworkContext } from '../contexts'

const Debug = () => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const allState = useSelector(getState)
  const paperwork = useSelector(state => getPaperwork(state, paperworkUUID))

  const defaultFileName = `paperwork_${paperworkUUID}`

  const downloadPaperworkJson = name => {
    const denormalizedPaperwork = denormalize(paperwork, schemas.paperwork, allState)
    const element = document.createElement('a')
    const blob = new Blob([JSON.stringify(denormalizedPaperwork, null, 2)], { type: 'text/json' })
    element.href = URL.createObjectURL(blob)
    element.download = `${name}.json`
    document.body.appendChild(element)
    element.click()
  }

  return (
    <>
      <button onClick={() => downloadPaperworkJson(defaultFileName)} type='button'>JSON</button>
    </>
  )
}
export default Debug
