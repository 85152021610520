import dialogPolyfill from 'dialog-polyfill'

document.addEventListener('app-connect', () => {
  Array.from(document.querySelectorAll('dialog')).forEach(dialog => {
    dialogPolyfill.registerDialog(dialog)
  })

  Array.from(document.querySelectorAll('[data-dialog]')).forEach(dialogTrigger => {
    const dialog = document.getElementById(dialogTrigger.dataset.dialog)

    dialogTrigger.addEventListener('click', e => {
      e.preventDefault()
      dialog.showModal()
      document.dispatchEvent(new Event('modal-open'))
    })

    dialog.querySelectorAll('[data-dialog-close]').forEach(close => {
      close.addEventListener('click', e => {
        e.preventDefault()
        dialog.close()
        document.dispatchEvent(new Event('modal-close'))
      })
    })
  })
})
