/* eslint consistent-return: 0 */
/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/default-props-match-prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const SavingConfirmation = createReactClass({
  displayName: 'SavingConfirmation',
  propTypes: {
    saving: PropTypes.bool
  },
  getDefaultProps () {
    return {
      saving: false
    }
  },
  confirmation () {
    const { saving } = this.props
    if (saving) {
      return React.createElement('span', {
        key: 'saving-confirmation',
        className: 'icon'
      },
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
          <path d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'>
            <animateTransform
              attributeType='xml'
              attributeName='transform'
              type='rotate'
              from='0 25 25'
              to='360 25 25'
              dur='0.8s'
              repeatCount='indefinite'
            />
          </path>
        </svg>)
    }
  },
  render () {
    return React.createElement(ReactCSSTransitionGroup, {
      transitionName: 'saving-confirmation',
      transitionEnter: false,
      transitionLeave: false
    }, this.confirmation())
  }
})

export default SavingConfirmation
