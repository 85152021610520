import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function dayButtonStyle (currentMode) {
  if (currentMode === 'day') { return 'schedule__day-button pillbox__btn active' }

  return 'schedule__day-button pillbox__btn'
}

function weekButtonStyle (currentMode) {
  if (currentMode === 'week') { return 'schedule__week-button pillbox__btn active' }

  return 'schedule__week-button pillbox__btn'
}

const DateModeSelector = ({ dayUrl, weekUrl, dateMode }) => (
  <ul className='pillbox pillbox--sml u-visible-lrg-scrn-only u-mrgn-l--x3'>
    <li>
      <Link to={dayUrl}>
        <button type='button' className={dayButtonStyle(dateMode)}>
          Day
        </button>
      </Link>
    </li>
    <li>
      <Link to={weekUrl}>
        <button type='button' className={weekButtonStyle(dateMode)}>
          Week
        </button>
      </Link>
    </li>
  </ul>
)

DateModeSelector.defaultProps = {
  dateMode: 'week',
  dayUrl: '',
  weekUrl: ''
}

DateModeSelector.propTypes = {
  dateMode: PropTypes.string,
  dayUrl: PropTypes.string,
  weekUrl: PropTypes.string
}

export default DateModeSelector
