// Enable the message form submit button once the file-uploader react component
// has mounted. This is a temporary fix to stop the form being submitted before
// the react component has mounted which submits a form without the attachments
// param.
document.addEventListener('react.mounted', event => {
  if (event.detail === 'file-uploader') {
    const btn = document.querySelector('[data-message-form-submit]')
    if (btn) {
      btn.disabled = false
    }
  }
}, false)
