import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { WORK_DATE, GENERAL_EVENT, APPOINTMENT, GROUPED_AUTOMATION_TASKS } from './event_types'

import Event from './event'
import GroupedAutomationTasks from './grouped-automation-tasks'

const EventComponents = {
  [WORK_DATE]: Event,
  [GENERAL_EVENT]: Event,
  [APPOINTMENT]: Event,
  [GROUPED_AUTOMATION_TASKS]: GroupedAutomationTasks
}

const Events = ({
  events,
  currentDay,
  onEventDelete,
  onEventColorChange,
  canManageEvent,
  canViewContact,
  eventColors
}) => (
  <>
    {
      events.map(
        event => {
          const Component = EventComponents[event.type]

          return (
            <Component
              key={event.id}
              currentDay={currentDay}
              onDelete={onEventDelete}
              onColorChange={onEventColorChange}
              canManageEvent={canManageEvent}
              canViewContact={canViewContact}
              eventColors={eventColors}
              {...event}
            />
          )
        }
      )
    }
  </>
)

Events.defaultProps = {
  canViewContact: false,
  canManageEvent: false,
  onEventDelete: () => {},
  onEventColorChange: () => {},
  eventColors: []
}

Events.propTypes = {
  events: PropTypes.array.isRequired,
  currentDay: PropTypes.string.isRequired,
  canViewContact: PropTypes.bool,
  onEventDelete: PropTypes.func,
  onEventColorChange: PropTypes.func,
  canManageEvent: PropTypes.bool,
  eventColors: PropTypes.arrayOf(PropTypes.string)
}

export default Events
