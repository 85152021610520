/* eslint-disable n/no-callback-literal */
import cash from 'cash-dom'
import axios from '@ytb/axios'

/**
 * $.load
 *
 * A simple version of $.load that uses axios to fetch the HTML
 * and then replaces the contents of the element with the response.
 *
 * Note: this is a very simple version of $.load, and does not include
 * error handling as the api is quite different
 *
 * @param {string} url
 * @param {object} params
 * @param {function} callback
 *
 * @return {object} this
 *
 * @example
 * $('#elm').load('url', {param: 'value'}, (elm, response) => {
 *  // elm is the element that was loaded
 *  // response is the response from the server
 * });
  * */
cash.fn.load = function load (url, params, callback = () => {}) {
  if (typeof params === 'function') {
    callback = params // eslint-disable-line no-param-reassign
    params = undefined // eslint-disable-line no-param-reassign
  }
  if (typeof params === 'string') {
    params = params.split('&').map(param => { // eslint-disable-line no-param-reassign
      const [key, value] = param.split('=')
      return { [key]: value }
    })
  }

  axios({ url, params, headers: { 'X-Requested-With': 'XMLHttpRequest' } }).then(response => {
    this.html(response.data)
    callback(this, response)
  }).catch(error => {
    if (!window.Sentry) { return }
    if (error && error.message) {
      Sentry.captureException(error)
    } else if (error) {
      Sentry.captureMessage(`Cash-dom load error: ${error}`)
    } else {
      Sentry.captureMessage('Unknown cash-dom load error')
    }
  })

  return this
}

export default cash
