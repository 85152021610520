import { v4 as uuidv4 } from 'uuid'
import cloneDeepWith from 'lodash/cloneDeepWith'

export const setNewUuidsWithMap = (object, except = []) => {
  const uuidMap = {}
  except.forEach(uuid => { uuidMap[uuid] = uuid })

  const withNewUuids = cloneDeepWith(object, (value, key) => {
    if (key === 'uuid') {
      if (uuidMap[value]) { return uuidMap[value] }
      const newUuid = uuidv4()
      uuidMap[value] = newUuid
      return newUuid
    }
    return undefined
  })

  const withAssociationPositions = cloneDeepWith(withNewUuids, value => {
    if (Array.isArray(value)) {
      return value.map(position => uuidMap[position] || position)
    }
    return undefined
  })

  return [withAssociationPositions, uuidMap]
}

export function onlyUuids (object, uuidMap) {
  return cloneDeepWith(object, (value, key) => {
    if (key === 'uuid') {
      if (Object.values(uuidMap).includes(value)) { return value }
      return undefined
    }
    return undefined
  })
}

function setNewUuids (object, except = []) {
  const [withAssociationPositions] = setNewUuidsWithMap(object, except)
  return withAssociationPositions
}

export default setNewUuids
