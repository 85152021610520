import React, { Component } from 'react'
import PropTypes from 'prop-types'

class InactiveMessage extends Component {
  constructor (props) {
    super(props)
    this.handleReactivate = this.handleReactivate.bind(this)
  }

  handleReactivate (event) {
    const { onReactivate } = this.props
    event.preventDefault()
    onReactivate()
  }

  render () {
    const { email } = this.props
    return (
      <span className='frm__error-msg'>
        We cannot send email to
        {' '}
        {email}
        <span className='t--no-bold'>
          - previous messages have been undeliverable.
          If you think this is wrong,&nbsp;
          <a onClick={this.handleReactivate} className='link' role='link' tabIndex={0}>
            re-activate this email
          </a>
        </span>
      </span>
    )
  }
}

InactiveMessage.propTypes = {
  email: PropTypes.string.isRequired,
  onReactivate: PropTypes.func.isRequired
}

export default InactiveMessage
