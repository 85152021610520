import {
  TOGGLE_PANEL,
  HIDE_PANEL,
  ADD,
  CLEAR_ALL,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE
} from '../types'

export const togglePanel = () => ({
  type: TOGGLE_PANEL
})

export const hidePanel = () => ({
  type: HIDE_PANEL
})

export const add = ({
  id,
  title,
  readAt,
  receivedAt,
  url
}) => ({
  type: ADD,
  id,
  title,
  readAt,
  receivedAt,
  url
})

export const clearAll = () => ({
  type: CLEAR_ALL
})

export const fetch = () => ({
  type: FETCH
})

export const fetchSuccess = data => ({
  type: FETCH_SUCCESS,
  payload: data
})

export const fetchFailure = errors => ({
  type: FETCH_FAILURE,
  payload: errors
})
