import React from 'react'

import App from './app'
import Body from './components/body'

export default props => (
  <App>
    <Body {...props} />
  </App>
)
