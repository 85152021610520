function closeAnyOpenMenus (event) {
  if (!event.target.classList.contains('js-nav-menu') && !event.target.parentNode.classList.contains('js-nav-menu')) {
    document.querySelector('.nav-settings__menu').classList.remove('nav-settings__menu--open')
    document.body.removeEventListener('click', closeAnyOpenMenus, false)
  }
}

document.addEventListener('app-connect', () => {
  Array.from(document.querySelectorAll('.nav .js-nav-menu')).forEach(element => {
    element.addEventListener('click', e => {
      const target = e.target.closest('.js-nav-menu')
      target.parentNode.querySelector('.nav-settings__menu').classList.toggle('nav-settings__menu--open')
      document.body.addEventListener('click', closeAnyOpenMenus, false)
      e.preventDefault()
    }, false)
  })

  Array.from(document.querySelectorAll('.nav .js-mobile-nav-menu')).forEach(element => {
    element.addEventListener('click', e => {
      e.target.closest('.mobile-item').querySelector('.nav-settings__menu').classList.toggle('nav-settings__menu--open')
      e.preventDefault()
    }, false)
  })
})
