const materialItemValues = obj => {
  const {
    description,
    quantity,
    unitType,
    itemPrice,
    markupPercentage,
    saved,
    fromAutocomplete,
    fromSearch
  } = obj

  return {
    description,
    quantity,
    unitType,
    itemPrice,
    markupPercentage,
    saved,
    fromAutocomplete,
    fromSearch
  }
}

export default materialItemValues
