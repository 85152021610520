import { INIT } from '../types'

function handleInit (payload) {
  const {
    viewJobPathTemplate,
    viewJobSheetPathTemplate,
    printJobSheetPathTemplate,
    sendConfirmationEmailPathTemplate,
    editJobAppointmentPathTemplate,
    editJobWorkDatePathTemplate,
    editJobGeneralEventPathTemplate,
    editGeneralEventPathTemplate,
    isFieldStaff
  } = payload

  return {
    viewJobPathTemplate,
    viewJobSheetPathTemplate,
    printJobSheetPathTemplate,
    sendConfirmationEmailPathTemplate,
    editJobAppointmentPathTemplate,
    editJobWorkDatePathTemplate,
    editJobGeneralEventPathTemplate,
    editGeneralEventPathTemplate,
    isFieldStaff
  }
}

const display = (state = {}, action) => {
  switch (action.type) {
    case INIT: {
      return handleInit(action.payload)
    }
    default: {
      return state
    }
  }
}

export default display
