import { Controller } from 'stimulus'

export default class ClearInputController extends Controller {
  static targets = ['input', 'trigger']

  connect () {
    this.setClearVisibility()
  }

  update () {
    this.setClearVisibility()
  }

  clear () {
    this.inputTarget.value = ''
    this.setClearVisibility()
  }

  // private

  setClearVisibility () {
    if (this.inputTarget.value.length > 0) {
      this.triggerTarget.classList.remove(this.hiddenClass)
    } else {
      this.triggerTarget.classList.add(this.hiddenClass)
    }
  }

  get hiddenClass () {
    return this.data.get('hidden-class')
  }
}
