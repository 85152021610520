/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
/* eslint react/no-unused-state: 0 */
/* eslint prefer-destructuring: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import SaveStatusConfirmation from './save-status-confirmation'

const slice = [].slice

const CheckboxInput = createReactClass({
  displayName: 'CheckboxInput',
  propTypes: {
    componentId: PropTypes.number,
    inputId: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    model: PropTypes.string.isRequired,
    attribute: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    class: PropTypes.string,
    noCreate: PropTypes.bool,
    noRemove: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string
  },
  getDefaultProps () {
    return {
      initialValue: false,
      noCreate: false
    }
  },
  getInitialState () {
    const { initialValue } = this.props
    return {
      errors: [],
      value: initialValue,
      saving: false,
      saved: false
    }
  },
  titlecase (word) {
    return `${word[0].toUpperCase()}${word.slice(1, +(word.length - 1) + 1 || 9e9)}`
  },
  model () {
    const { model } = this.props
    let word
    return slice.call((() => {
      let j
      let len
      const ref = model.split('_')
      const results = []
      for (j = 0, len = ref.length; j < len; j += 1) {
        word = ref[j]
        results.push(this.titlecase(word))
      }
      return results
    }).call(this)).join(' ')
  },
  label () {
    const { attribute } = this.props
    const { value } = this.state
    return `${this.model()} #${(value.id || 'X')}${attribute}`
  },
  checked () {
    const { attribute } = this.props
    const { value } = this.state
    return value[attribute]
  },
  render () {
    const { class: className, inputId, label, name, onEdit, status } = this.props

    return React.createElement('label', {
      key: `${name}-checkbox`
    }, React.createElement('input', {
      className,
      id: inputId,
      type: 'checkbox',
      onChange: onEdit,
      defaultChecked: this.checked()
    }),
    React.createElement('label', { for: inputId }),
    label,
    React.createElement(SaveStatusConfirmation, {
      inline: true,
      status
    }))
  }
})

export default CheckboxInput
