import React from 'react'
import PropTypes from 'prop-types'

import CloseSvg from '@/components/svg/close'

const TemplatePanelCloseButton = ({ onCloseClick }) => (
  <button
    type='button'
    onClick={e => { onCloseClick(e) }}
    id='close_template_modal'
    className='choose-template__close btn btn--no-border btn--clear'
  >
    <span className='icon icon--med'>
      <CloseSvg />
    </span>
  </button>
)

TemplatePanelCloseButton.defaultProps = {
  onCloseClick: () => {}
}

TemplatePanelCloseButton.propTypes = {
  onCloseClick: PropTypes.func
}

export default TemplatePanelCloseButton
