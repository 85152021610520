import Popper from 'popper.js'

const poppers = []

const closePopup = (obj, i) => {
  obj.popper.destroy()
  obj.elm.remove()
  poppers.splice(i, 1)
}

const closePopups = () => {
  poppers.forEach(closePopup)
}

const clickOutsideHandler = event => {
  poppers.forEach((obj, i) => {
    if (!obj.elm.contains(event.target)) {
      closePopup(obj, i)
    }
  })
}

const initCloseButton = popupElm => {
  const elm = popupElm.querySelector('[data-popup-close]')
  elm.addEventListener('click', () => { closePopups() })
}

const createElement = htmlString => {
  const div = document.createElement('div')
  div.innerHTML = htmlString
  return div.firstChild
}

// html: string for popup
// target: element to attach popup to
// location: element in the markup to insert the popup html before
const createPopup = (html, target, location) => {
  const popupElem = createElement(html)
  location.insertBefore(popupElem, null)
  const popper = new Popper(target, popupElem, {
    placement: 'right'
  })
  initCloseButton(popupElem)
  poppers.push({ popper, elm: popupElem })
}

export { createPopup, clickOutsideHandler, closePopups }
