import axios from '@ytb/axios'

import createFlashMessage from '@/application/flashes'

const userAttributes = (newResource, oldResource, event) => {
  if (!oldResource || !newResource) {
    return {}
  }
  const attributes = {
    0: {
      assigned: '1',
      user_id: newResource.id
    }
  }

  if (oldResource.id !== 'unassigned') {
    // destroy the old event_users record if there is one
    attributes[1] = {
      id: event.extendedProps.eventUserIds[oldResource.id].id,
      _destroy: 'true'
    }
  }
  return attributes
}

export default function updateEvent (event, newResource, oldResource, revert) {
  const params = {
    event: {
      event_start: event.start,
      event_end: event.end,
      event_users_attributes: userAttributes(newResource, oldResource, event)
    }
  }
  axios.put(
    `${event.extendedProps.updateUrl}.json`,
    params
  ).then(result => {
    if (newResource && newResource.id !== 'unassigned') {
      const user = result.data.event_users.find(eu => eu.user_id === parseInt(newResource.id))
      const eventUserIds = {
        [newResource.id]: {
          id: user.id,
          user_id: user.user_id
        }
      }

      event.setExtendedProp('eventUserIds', eventUserIds)
    }
    createFlashMessage('Event updated')
  }).catch(() => {
    createFlashMessage('Sorry the event could not be saved', 'alert')
    revert()
  })
}
