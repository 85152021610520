import React from 'react'
import PropTypes from 'prop-types'
import Menu from './menu'
import Unread from './unread'
import Previous from './previous'
import ClickOutsideProvider from '../containers/click-outside-provider'

const List = ({
  show,
  unreadNotifications,
  previousNotifications,
  onClearAllClick
}) => {
  if (!show) { return null }
  return (
    <ClickOutsideProvider>
      <Menu>
        <div className='notifications-menu__items' data-test='notifications-menu-items'>
          <Unread notifications={unreadNotifications} onClearAllClick={onClearAllClick} />
          <Previous notifications={previousNotifications} />
        </div>
        <div className='notifications-menu__footer'>
          <p>
            <a href='/notifications' className='notifications-menu__see_all'>See all notifications</a>
          </p>
        </div>
      </Menu>
    </ClickOutsideProvider>
  )
}

List.defaultProps = {
  show: false,
  unreadNotifications: [],
  previousNotifications: [],
  onClearAllClick: () => {}
}

List.propTypes = {
  show: PropTypes.bool,
  unreadNotifications: PropTypes.array,
  previousNotifications: PropTypes.array,
  onClearAllClick: PropTypes.func
}

export default List
