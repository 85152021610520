import ImageEditor from '@uppy/image-editor'
import uploader from './uploader'

const EditableImageUploader = options => {
  const ytbLogoAspectRatio = 2.3333333333333335
  const uploaderInstance = uploader({
    ...options,
    autoProceed: false,
    allowMultipleUploads: false
  })

  uploaderInstance.use(ImageEditor, {
    id: 'ImageEditor',
    quality: 1.0,
    actions: {
      revert: true,
      zoomIn: true,
      zoomOut: true,
      rotate: false,
      flip: false,
      cropSquare: false,
      cropWidescreen: false,
      cropWidescreenVertical: false
    },
    cropperOptions: {
      aspectRatio: ytbLogoAspectRatio,
      viewMode: 0,
      croppedCanvasOptions: {
        fillColor: '#fff'
      }
    }
  })

  uploaderInstance.on('file-editor:complete', () => {
    options.onEditComplete()
    uploaderInstance.upload().then(() => uploaderInstance.cancelAll())
  })

  return uploaderInstance
}

export default EditableImageUploader
