import { Controller } from 'stimulus'
import axios from '@ytb/axios'

export default class LoaderController extends Controller {
  static targets = ['loading', 'content']

  static values = { url: String }

  connect () {
    this.load()

    this.loadOnEvent = this.load.bind(this)

    document.addEventListener('loader-refresh', this.loadOnEvent)
  }

  disconnect () {
    document.removeEventListener('loader-refresh', this.loadOnEvent)
  }

  load () {
    this.contentTarget.classList.add('invisible')
    this.loadingTarget.classList.remove('hidden')
    axios.get(this.urlValue).then(response => {
      this.loadingTarget.classList.add('hidden')
      this.contentTarget.innerHTML = response.data
      this.contentTarget.classList.remove('invisible')
    })
  }
}
