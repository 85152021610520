import React, { useState } from 'react'

import Modal from './index'

const Example = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <button
        className='btn'
        type='button'
        onClick={e => { e.preventDefault(); setShowModal(true) }}
      >
        Show modal
      </button>
      {
        showModal && (
          <Modal closeText='Cancel' onClose={() => setShowModal(false)}>
            <h1 className='t--lrg'><strong>Stuff</strong></h1>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <p>
              It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. The point of using Lorem Ipsum
              is that it has a more-or-less normal distribution of letters, as opposed to
              using Content here, content here, making it look like readable English.
              Many desktop publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for lorem ipsum will uncover many web
              sites still in their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <p>
              It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. The point of using Lorem Ipsum
              is that it has a more-or-less normal distribution of letters, as opposed to
              using Content here, content here, making it look like readable English.
              Many desktop publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for lorem ipsum will uncover many web
              sites still in their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <p>
              It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. The point of using Lorem Ipsum
              is that it has a more-or-less normal distribution of letters, as opposed to
              using Content here, content here, making it look like readable English.
              Many desktop publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for lorem ipsum will uncover many web
              sites still in their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <div className='u-mrgn-btn--x25'>&nbsp;</div>
            <p>
              It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. The point of using Lorem Ipsum
              is that it has a more-or-less normal distribution of letters, as opposed to
              using Content here, content here, making it look like readable English.
              Many desktop publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for lorem ipsum will uncover many web
              sites still in their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
          </Modal>
        )
      }
    </div>
  )
}

export default Example
