import React from 'react'

const ArrowLeftSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M16.25 24a.743.743 0 0 1-.53-.22L5 13.06a1.488 1.488 0 0 1-.439-1.059c0-.4.155-.777.438-1.06L15.72.22c.141-.142.33-.22.53-.22s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53L6.06 12l10.72 10.72a.744.744 0 0 1 0 1.06.749.749 0 0 1-.53.22z' />
    </svg>
  </span>
)

export default ArrowLeftSvg
