import { Controller } from 'stimulus'
import A11yDialog from 'a11y-dialog'

export default class DialogController extends Controller {
  static values = {
    visible: { type: Boolean, default: false }
  }

  connect () {
    this.element[this.identifier] = this
    this.dialog = new A11yDialog(this.element)

    if (this.visibleValue) {
      this.show()
    }
  }

  show () {
    this.dialog.show()
    this.dispatch('show')
  }

  hide () {
    this.dispatch('hide')
  }

  disconnect () {
    this.dialog.destroy()
  }
}
