import React from 'react'
import { Link } from 'react-router-dom'

import RotateBackSvg from '@/components/svg/rotate-back'

const BackToToday = () => (
  <span className='t--sml u-no-print'>
    <Link to='/' className='link link--no-uline'>
      <RotateBackSvg className='icon icon--tiny icon--blue' />
      {' Today'}
    </Link>
  </span>
)

export default BackToToday
