import Analytics from 'analytics'
import intercomPlugin from '@analytics/intercom'
import mixpanelPlugin from '@analytics/mixpanel'
import googleAnalyticsPlugin from '@analytics/google-analytics'

import Cookies from 'js-cookie'
import App from './app'
import { removeNulls } from './utils/object'

function loadAnalytics (plugins) {
  if (App.debug) { console.log('Loading analytics with plugins:', plugins) }

  const analytics = Analytics({
    app: 'YourTradebase',
    plugins,
    debug: App.debug
  })

  return analytics
}

let plugins = []

const intercomEnabled = App.intercomAppId !== '' && App.railsEnv !== 'test'
const mixpanelEnabled = App.mixpanelProjectToken !== '' && App.railsEnv !== 'test'
const googleAnalyticsEnabled = App.googleAnalyticsId !== '' && App.railsEnv !== 'test'

if (mixpanelEnabled) {
  plugins = [...plugins, ...[mixpanelPlugin({ token: App.mixpanelProjectToken })]]
}

if (intercomEnabled) {
  plugins = [...plugins, ...[intercomPlugin({ appId: App.intercomAppId })]]
}

if (googleAnalyticsEnabled) {
  plugins = [
    ...plugins,
    ...[googleAnalyticsPlugin(
      { measurementIds: [App.googleAnalyticsId, App.googleAnalyticsIdSite] }
    )]
  ]
}

const analyticsEnabled = plugins.length > 0

const analytics = loadAnalytics(plugins)

document.addEventListener('app-connect', () => {
  if (App.debug) { console.log('App connected, sending analytics') }
  const {
    intercomUserHash,
    analyticsTrackedEvents,
    currentCompany,
    currentUser
  } = window.App

  if (App.debug) { console.log(App) }

  const marketingSiteTraits = removeNulls({
    utm_campaign: Cookies.get('utm_campaign'),
    utm_source: Cookies.get('utm_source'),
    utm_medium: Cookies.get('utm_medium')
  })

  if (currentUser.loggedIn) {
    const traits = {
      trial: currentCompany.isTrial,
      plan: currentCompany.planId,
      state: currentCompany.state,
      ...marketingSiteTraits
    }

    analytics.identify(
      currentUser.id.toString(),
      traits,
      {
        plugins: {
          intercom: false
        }
      }
    )

    if (intercomEnabled) {
      analytics.identify(
        currentUser.id.toString(),
        {
          ...traits,
          ...{
            user_hash: intercomUserHash,
            hide_default_launcher: true,
            custom_launcher_selector: '#launch-intercom-button'
          }
        },
        {
          plugins: {
            all: false,
            intercom: true
          }
        }
      )
    }

    if (analyticsEnabled) {
      analyticsTrackedEvents.forEach(event => {
        if (App.debug) { console.log('Sending analytics event:', event) }
        if (event.name === 'Register page visited') {
          analytics.track(event.name, { ...event.properties, ...marketingSiteTraits })
        } else {
          analytics.track(event.name, event.properties)
        }
      })
    }
  }

  analytics.page()
})

export default analytics
