import { connect } from 'react-redux'
import moment from 'moment'

import { deleteEvent } from '../actions'
import { createDays, addEventsToDays } from '../helpers/days'
import View from '../components/view'

export const mapStateToProps = (
  { schedule: { display, events } },
  { showHeadings, canManageEvent, canViewContact, noEventsMessage, order }
) => {
  const { start, end } = display
  let days = []

  if (moment(start).isValid() && moment(end).isValid()) {
    days = addEventsToDays(events, createDays(start, end, order))
  }

  days = days.filter(day => day.events.length > 0)

  return {
    days,
    showHeadings,
    canManageEvent,
    canViewContact,
    noEventsMessage
  }
}

const mapDispatchToProps = dispatch => ({
  onEventDelete (id) {
    dispatch(deleteEvent(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
