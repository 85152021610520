export const invoiceValuesUnduplicatable = obj => {
  const {
    reference,
    jobId,
    overwriteLocal,
    url,
    date,
    dueDate,
    quoteRef,
    jobAddress,
    jobPostcode,
    customerName,
    customerAddress,
    customerPostcode,
    ...rest
  } = obj

  return rest
}

const invoiceValues = obj => {
  const {
    date,
    dueDate,
    documentTitle,
    prefix,
    reference,
    quoteRef,
    jobAddress,
    jobPostcode,
    customerName,
    customerAddress,
    customerPostcode,
    notes,
    terms,
    cisRate,
    vatReverseCharge
  } = obj

  return {
    date,
    dueDate,
    documentTitle,
    prefix,
    reference,
    quoteRef,
    jobAddress,
    jobPostcode,
    customerName,
    customerAddress,
    customerPostcode,
    notes,
    terms,
    cisRate,
    vatReverseCharge
  }
}

export default invoiceValues
