import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getTranslation } from '../selectors'
import { PaperworkContext } from '../contexts'
import TaxTotal from './tax-total'
import { paperworkTotals as totals } from '../selectors/paperwork'

const TaxTotals = () => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const { taxes } = useSelector(state => totals(state, paperworkUUID))
  const label = useSelector(state => getTranslation(state, 'taxLabel'))

  return (
    <div data-test='tax-totals'>
      {
        taxes.map(tax => (
          <TaxTotal key={tax.name} name={tax.name} total={tax.total} label={label} />
        ))
      }
    </div>
  )
}

export default TaxTotals
