import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormContext, useForm } from 'react-hook-form'
import axios from '@ytb/axios'

import createFlashMessage from '@/application/flashes'
import Name from './components/name'
import Email from './components/email'
import Initials from './components/initials'
import Role from './components/role'
import EmailInvite from './components/email-invite'

const Form = ({ adminPrice, url, formData, canManageAdmins, canManageFieldStaff, proRataCredit, initialRole }) => {
  const methods = useForm({ defaultValues: { ...formData } })

  const [manualInitials, setManualInitials] = useState(false)

  const setErrors = errors => {
    Object.entries(errors).forEach(([field, messages]) => {
      messages.forEach(message => {
        methods.setError(`user[${field}]`, 'server', message)
      })
    })
  }

  const onSubmit = data => {
    axios.post(url, data).then(response => {
      // on success redirect to url provided
      window.location.href = response.data.redirect_to
    }).catch(error => {
      if (error.response) {
        setErrors(error.response.data.errors)
      } else if (error.request) {
        createFlashMessage('A network error prevented the form from being submitted. Please try again.', 'alert')
      } else {
        createFlashMessage('A unknown error prevented the form from being submitted. Please try again.', 'alert')
      }
    })
  }

  const handleNameChange = event => {
    if (manualInitials) return
    const generatedInitials = event.target.value
      .split(' ')
      .map(n => n[0])
      .join('')
      .substring(0, 3)
      .toUpperCase()
    const revalidate = generatedInitials.length > 0
    methods.setValue('company_user[initials]', generatedInitials, revalidate)
  }

  const handleInitialsChange = () => {
    setManualInitials(true)
  }

  return (
    <FormContext {...methods}>
      <form id='company-user' onSubmit={methods.handleSubmit(onSubmit)} className='frm'>
        <fieldset className='u-mrgn-btm--x2'>
          <Name handleNameChange={handleNameChange} />
          <Email />
          <Initials handleInitialsChange={handleInitialsChange} />
        </fieldset>
        <Role
          adminPrice={adminPrice}
          permissions={formData.company_user.permissions}
          canManageAdmins={canManageAdmins}
          canManageFieldStaff={canManageFieldStaff}
          proRataCredit={proRataCredit}
          initialRole={initialRole}
        />
        <EmailInvite />
      </form>
    </FormContext>
  )
}

Form.propTypes = {
  adminPrice: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  formData: PropTypes.string,
  canManageAdmins: PropTypes.bool.isRequired,
  canManageFieldStaff: PropTypes.bool.isRequired,
  initialRole: PropTypes.string.isRequired,
  proRataCredit: PropTypes.string
}

Form.defaultProps = {
  formData: null,
  proRataCredit: ''
}

export default Form
