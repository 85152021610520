import moment from 'moment'

window.App = window.App || {}

const localeTimeFormat = window.App.useTwelveHourFormat ? 'h:mma' : 'H:mm'
const localeTimeFormatNoMinutes = window.App.useTwelveHourFormat ? 'ha' : 'H:mm'

const formatTimeMinutes = time => {
  if (moment(time).minutes() > 0) {
    return moment(time).format(localeTimeFormat)
  }
  return moment(time).format(localeTimeFormatNoMinutes)
}

const eventTime = (start, end, current) => {
  if (moment(start).isSame(current, 'day') && !moment(end).isValid()) {
    return formatTimeMinutes(start)
  }
  if (moment(start).isBefore(current, 'day') && !moment(end).isValid()) {
    return 'All day'
  }
  if (moment(start).isSame(current, 'day') && moment(end).isAfter(current, 'day')) {
    return `${formatTimeMinutes(start)} onward`
  }
  if (moment(start).isBefore(current, 'day') && moment(end).isAfter(current, 'day')) {
    return 'All day'
  }
  if (moment(start).isBefore(current, 'day') && moment(end).isSame(current, 'day')) {
    return `Until ${formatTimeMinutes(end)}`
  }
  if (moment(start).isSame(current, 'day') && moment(end).isSame(current, 'day')) {
    if (moment(start).isSame(end, 'minute')) {
      return formatTimeMinutes(start)
    }

    return `${formatTimeMinutes(start)} to ${formatTimeMinutes(end)}`
  }

  return ''
}

export default eventTime
