const labourItemValues = obj => {
  const {
    description,
    units,
    unitType,
    rate,
    markupPercentage,
    saved,
    fromAutocomplete,
    fromSearch
  } = obj

  return {
    description,
    units,
    unitType,
    rate,
    markupPercentage,
    saved,
    fromAutocomplete,
    fromSearch
  }
}

export default labourItemValues
