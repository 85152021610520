import { Controller } from 'stimulus'

export default class FilterListController extends Controller {
  static targets = ['results', 'empty', 'facet', 'text', 'viewAll', 'showingResultsText']

  connect () {
    this.handleEmptyTarget()
    this.hideResultsOverDisplayLimit()
  }

  hideResultsOverDisplayLimit () {
    const max = this.data.get('max') || 10

    this.resultsTargets.forEach((target, index) => {
      if (index > max - 1) {
        target.classList.add('hidden')
      }
    })

    if (this.resultsTargets.length > max) {
      this.viewAllTarget.classList.remove('hidden')
      this.showingResultsTextTarget.textContent = `Showing ${max} of ${this.resultsTargets.length} - `
    }
  }

  filter () {
    const textTerm = this.textTarget.value.toLowerCase()
    const facets = this.facetTargets
      .filter(target => target.value.length > 0)
      .map(target => ({ name: target.name.replace('-facet', ''), value: target.value.toLowerCase() }))

    this.hideResults()
    this.viewAllTarget.classList.toggle('hidden', textTerm)

    this.resultsTargets.forEach(target => {
      const matchesText = target.textContent.toLowerCase().includes(textTerm)
      const matchesAllFacets = (this.resultsThatMatchFacets(facets, target) === facets.length)

      if (matchesText && matchesAllFacets) {
        target.classList.remove('hidden')
      }
    })

    if (textTerm.length === 0 && facets.length === 0) {
      this.hideResultsOverDisplayLimit()
    }

    this.handleEmptyTarget()
  }

  clear () {
    this.textTarget.value = ''
    this.facetTargets.forEach(target => {
      target.value = ''
    })

    this.showAllResults()
    this.hideResultsOverDisplayLimit()
    this.handleEmptyTarget()
  }

  hideResults () {
    this.resultsTargets.forEach(target => { target.classList.add('hidden') })
  }

  showAllResults () {
    this.resultsTargets.forEach(target => { target.classList.remove('hidden') })
  }

  viewAll (event) {
    event.preventDefault()

    this.showAllResults()
    this.viewAllTarget.classList.add('hidden')
  }

  resultsThatMatchFacets (facets, result) {
    let matchesFacets = 0
    facets.forEach(facet => {
      if (result.dataset[facet.name].toLowerCase() === facet.value) {
        matchesFacets += 1
      }
    })
    return matchesFacets
  }

  handleEmptyTarget () {
    const showEmpty = this.resultsTargets.length === this.resultsTargets.filter(target => target.classList.contains('hidden')).length
    this.emptyTarget.classList.toggle('hidden', !showEmpty)
  }

  stopSubmit (event) {
    // This is separate to filter due to filter being triggered
    // by the `input` event, which doesn't send keycode.
    const ENTER = 13
    if (event.keyCode === ENTER) {
      event.preventDefault()
    }
  }
}
