import $ from '@ytb/cash-dom'

document.addEventListener('app-connect', () => {
  $(document).on('ajax:success', '#email-form', () => window.location.reload())

  $(document).on('ajax:error', '#email-form', (e, data) => {
    $('#email-form .js-email-form-fields').html(
      $('.js-email-form-fields', $(data.responseText))
    )
  })
})
