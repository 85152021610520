import React from 'react'
import { useSelector } from 'react-redux'

import { getConfiguration } from '../selectors'
import Field from './final-form-field'

const MoneyField = props => {
  const { currencyUnit } = useSelector(getConfiguration)

  return (
    <span
      className='input--currency'
      data-currency-unit={currencyUnit}
    >
      <Field
        {...props}
        parse={value => value.replace(/[^0-9.-]/g, '')}
        placeholder='0.00'
        inputMode='decimal'
        pattern='[0-9]*'
        maxLength='12'
        type='text'
      />
    </span>
  )
}

export default MoneyField
