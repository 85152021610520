import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm, FormSpy as FinalFormSpy } from '../modules/react-final-form'
import { shallowEqualObjects } from 'shallow-equal'

import resetObjectValues from '../helpers/reset-object-values'

class Form extends React.Component {
  constructor (props) {
    super(props)
    this.state = props.initialValues
    this.handleFormChange = this.handleFormChange.bind(this)
  }

  allValues (formState) {
    return {
      ...resetObjectValues(formState.touched),
      ...formState.values
    }
  }

  handleFormChange (formState) {
    const { onChange } = this.props
    const allValues = this.allValues(formState)
    const lastValues = this.state

    if (!shallowEqualObjects(allValues, lastValues)) {
      onChange(allValues, formState.modified)
      this.setState(allValues)
    }
  }

  render () {
    const { initialValues, children, decorators } = this.props

    return (
      <FinalForm
        initialValues={initialValues}
        onSubmit={() => {}}
        subscribe={{ values: true }}
        decorators={decorators}
      >
        {() => (
          <>
            <FinalFormSpy onChange={this.handleFormChange} />
            {children}
          </>
        )}
      </FinalForm>
    )
  }
}

Form.defaultProps = {
  initialValues: {},
  children: null,
  decorators: [],
  onChange: () => {}
}

Form.propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.node,
  decorators: PropTypes.array,
  onChange: PropTypes.func
}

export default Form
