import groupBy from 'lodash/groupBy'

const renderPaperworkGroupHeadings = () => {
  const results = document.querySelectorAll('[data-test="grouped-result"]')
  const groupedResults = groupBy(Array.from(results), 'dataset.status')

  Object.values(groupedResults).forEach(group => {
    const firstItemInGroup = group[0]
    const { status } = firstItemInGroup.dataset
    const heading = document.querySelector(`[data-group-heading="${status}"]`)

    if (!heading) { return }

    if (!firstItemInGroup.previousSibling.isEqualNode(heading)) {
      firstItemInGroup.parentNode.insertBefore(heading, firstItemInGroup)
    }
  })
}

export default renderPaperworkGroupHeadings
