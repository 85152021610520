import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getConfiguration } from '../selectors'
import formatMoney from '../helpers/format-money'

const Money = ({ amount }) => {
  const { currencyUnit } = useSelector(getConfiguration)

  return (
    <>
      {formatMoney(amount, currencyUnit)}
    </>
  )
}

Money.propTypes = {
  amount: PropTypes.number.isRequired
}

export default Money
