import axios from '@ytb/axios'

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export const paperworkSubmit = (path, data) => {
  try {
    return axios
      .post(path, data, { headers: jsonHeaders })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } catch (error) {
    return { error }
  }
}

export const paperworkFetch = path => {
  try {
    return axios
      .get(path, { headers: jsonHeaders })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } catch (error) {
    return { error }
  }
}

export const materialSave = data => {
  try {
    return axios
      .post('/materials?v2=true', data, { headers: jsonHeaders })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } catch (error) {
    return { error }
  }
}

export const labourSave = data => {
  try {
    return axios
      .post('/labour_rates?v2=true', data, { headers: jsonHeaders })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } catch (error) {
    return { error }
  }
}

export const itemSave = data => {
  try {
    return axios
      .post('/api/price_lists', data, { headers: jsonHeaders })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } catch (error) {
    return { error }
  }
}

export const priceListAutocomplete = term => axios.get(`/api/price_lists/autocomplete.json?term=${encodeURI(term)}`)
export const priceListSearch = (term, page = 1, perPage = 10) => axios.get(`/api/price_lists.json?term=${encodeURI(term)}&page=${page}&per_page=${perPage}`)
export const materialAutocomplete = term => axios.get(`/api/materials/autocomplete.json?term=${encodeURI(term)}`)
export const materialSearch = (term, page = 1, perPage = 10, category = '', brand = '') => axios.get(`/api/materials.json?term=${encodeURI(term)}&page=${page}&per_page=${perPage}&category=${category}&brand=${brand}`)
export const materialCategories = () => axios.get('/material_categories.json')
export const materialBrands = () => axios.get('/material_brands.json')
export const labourAutocomplete = term => axios.get(`/api/labour_rates/autocomplete.json?term=${encodeURI(term)}`)
export const labourSearch = (term, page = 1, perPage = 10) => axios.get(`/api/labour_rates.json?term=${encodeURI(term)}&page=${page}&per_page=${perPage}`)
export const numberAvailable = (paperworkUUID, value) => axios.get(`/invoices/numbers/${encodeURI(value)}?uuid=${paperworkUUID}`)
