import React from 'react'
import PropTypes from 'prop-types'
import range from 'lodash/range'

const PercentageSelectField = ({
  name,
  label,
  onChange,
  defaultValue,
  testKey
}) => (
  <span className='u-inline-block'>
    {label && (<label className='frm__label' htmlFor={name}>{label}</label>)}
    <select
      defaultValue={Number(defaultValue).toFixed(2)}
      name={name}
      className='pwrk-frm__input pwrk-frm__input--strong pwrk-frm__input--t--sml'
      onChange={event => onChange(event.target.value)}
      data-test={`${testKey}-percentage-select`}
    >
      {range(0, 105, 5).map(num => (
        <option key={num} value={parseFloat(num).toFixed(2)}>
          {num}
          %
        </option>
      ))}
      <option disabled>––––</option>
      <option value='selectOtherInput'>Other…</option>
    </select>
  </span>
)

PercentageSelectField.defaultProps = {
  label: '',
  defaultValue: 0.00
}

PercentageSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  testKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string
}

export default PercentageSelectField
