import parseMoney from '../helpers/parse-money'
import { reduce, multiply, createSelector } from './shared'
import { calculateLabourItemSubTotal, calculateLabourItemTotal, getAllLabourItems, getLabourItem } from './labour-items'
import { calculateMaterialItemSubTotal, calculateMaterialItemTotal, getAllMaterialItems, getMaterialItem } from './material-items'

export const getAllItems = state => state.items
export const getItem = (state, itemUUID) => getAllItems(state)[itemUUID]
const getItemQuantity = (state, itemUUID) => parseMoney(getItem(state, itemUUID).quantity)

export const getItemMaterialItems = createSelector(
  getItem,
  getAllMaterialItems,
  (item, materialItems) => Object.values(
    materialItems
  ).filter(materialItem => (item.materialItems || []).includes(materialItem.uuid))
)

const getItemMaterialItemsTotal = createSelector(
  getItemMaterialItems,
  reduce(calculateMaterialItemTotal)
)

const getItemMaterialItemsSubTotal = createSelector(
  getItemMaterialItems,
  reduce(calculateMaterialItemSubTotal)
)

export const getItemLabourItems = createSelector(
  getItem,
  getAllLabourItems,
  (item, labourItems) => Object.values(
    labourItems
  ).filter(labourItem => (item.labourItems || []).includes(labourItem.uuid))
)

const getItemLabourItemsTotal = createSelector(
  getItemLabourItems,
  reduce(calculateLabourItemTotal)
)

const getItemLabourItemsSubTotal = createSelector(
  getItemLabourItems,
  reduce(calculateLabourItemSubTotal)
)

const getItemSubTotal = createSelector(
  getItem,
  getItemMaterialItemsTotal,
  getItemLabourItemsTotal,
  (item, materialItemsTotal, labourItemsTotal) => parseMoney(parseMoney(item.price) + materialItemsTotal + labourItemsTotal)
)

const getItemMaterialItemTotal = createSelector(
  (state, itemUUID) => getItem(state, itemUUID),
  (state, _itemUUID, materialItemUUID) => getMaterialItem(state, materialItemUUID),
  (item, materialItem) => calculateMaterialItemTotal(materialItem)
)

const getItemLabourItemTotal = createSelector(
  (state, _itemUUID, labourItemUUID) => getLabourItem(state, labourItemUUID),
  labourItem => calculateLabourItemTotal(labourItem)
)

// For materials/labour: We have four different levels of totals: single, line, item and paperwork.
//   - Single is used for calculating the line totals
//   - Line is used to show the total and for calculating the item totals
//   - Item is used for calculating the paperwork totals.
//
// For items: We have three different levels of totals: single, line and paperwork. Single is used before we show quantity, line is used after we show quantity.
//
// For paperwork: We have one level of totals derived from the child paperwork totals

const getItemDiscountTotal = createSelector(
  getItem,
  getItemSubTotal,
  (item, subTotal) => (subTotal * (parseMoney(item.discount) / 100.00)) * -1
)

const getItemTax = createSelector(
  getItem,
  getItemDiscountTotal,
  getItemSubTotal,
  (item, discount, subTotal) => parseMoney((subTotal + discount) * (parseMoney(item.taxRate) / 100.00))
)

const getItemTotal = createSelector(
  getItemSubTotal,
  getItemTax,
  getItemDiscountTotal,
  (subTotal, tax, discount) => (subTotal + discount + tax)
)

const getItemDiscountLineTotal = createSelector(
  getItemQuantity,
  getItemDiscountTotal,
  multiply
)

const getItemLabourItemLineTotal = createSelector(
  getItemLabourItemTotal,
  getItemQuantity,
  multiply
)

const getItemMaterialItemLineTotal = createSelector(
  getItemMaterialItemTotal,
  getItemQuantity,
  multiply
)

const getItemMaterialItemsLineSubTotal = createSelector(
  getItemMaterialItemsSubTotal,
  getItemQuantity,
  multiply
)

const getItemMaterialItemsLineTotal = createSelector(
  getItemMaterialItemsTotal,
  getItemQuantity,
  multiply
)

const getItemLabourItemsLineSubTotal = createSelector(
  getItemLabourItemsSubTotal,
  getItemQuantity,
  multiply
)

const getItemLabourItemsLineTotal = createSelector(
  getItemLabourItemsTotal,
  getItemQuantity,
  multiply
)

const getItemLineSubTotal = createSelector(
  getItemSubTotal,
  getItemQuantity,
  multiply
)

const getItemLineTax = createSelector(
  getItemTax,
  getItemQuantity,
  multiply
)

const getItemLineTotal = createSelector(
  getItemTotal,
  getItemQuantity,
  multiply
)

export const itemLabourItemTotals = createSelector(
  getItemLabourItemLineTotal,
  total => ({
    total
  })
)

export const itemMaterialItemTotals = createSelector(
  getItemMaterialItemLineTotal,
  total => ({
    total
  })
)

export const itemTotals = createSelector(
  getItemMaterialItemsSubTotal,
  getItemMaterialItemsTotal,
  getItemLabourItemsSubTotal,
  getItemLabourItemsTotal,
  getItemSubTotal,
  getItemLineSubTotal,
  getItemDiscountLineTotal,
  getItemLineTax,
  getItemLineTotal,
  getItemMaterialItemsLineSubTotal,
  getItemMaterialItemsLineTotal,
  getItemLabourItemsLineSubTotal,
  getItemLabourItemsLineTotal,
  (
    materialItemsSubTotal,
    materialItemsTotal,
    labourItemsSubTotal,
    labourItemsTotal,
    subTotal,
    // quantity is applied to totals after this point
    lineSubTotal,
    discount,
    lineTax,
    total,
    materialItemsLineSubTotal,
    materialItemsLineTotal,
    labourItemsLineSubTotal,
    labourItemsLineTotal
  ) => ({
    materialItemsSubTotal,
    materialItemsTotal,
    labourItemsSubTotal,
    labourItemsTotal,
    subTotal,
    lineSubTotal,
    discount,
    lineTax,
    total,
    materialItemsLineSubTotal,
    materialItemsLineTotal,
    labourItemsLineSubTotal,
    labourItemsLineTotal
  })
)
