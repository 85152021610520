import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Schedule from './containers/schedule'

const { store } = window.App

class ScheduleApp extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router basename='/schedule'>
          <Route
            path='/:routerStartDate?/:routerDateMode?/:routerType?/:routerAssignee?'
            render={() => <Schedule {...this.props} />}
          />
        </Router>
      </Provider>
    )
  }
}

ScheduleApp.defaultProps = {
  showEmptyDays: true,
  showHeadings: true,
  includeAllGeneralEvents: false,
  startDate: '',
  eventsUrl: '/schedule/events.json',
  canViewContact: false,
  canManageEvent: false,
  dateMode: 'week',
  type: 'all',
  assignee: 'all',
  eventColors: []
}

ScheduleApp.propTypes = {
  showEmptyDays: PropTypes.bool,
  showHeadings: PropTypes.bool,
  includeAllGeneralEvents: PropTypes.bool,
  startDate: PropTypes.string,
  eventsUrl: PropTypes.string,
  canViewContact: PropTypes.bool,
  canManageEvent: PropTypes.bool,
  dateMode: PropTypes.string,
  type: PropTypes.string,
  assignee: PropTypes.string,
  eventColors: PropTypes.arrayOf(PropTypes.string)
}

export default ScheduleApp
