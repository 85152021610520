import React from 'react'

const MenuMoreSolidSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <circle cx='3.25' cy='12' r='3.25' />
      <circle cx='12' cy='12' r='3.25' />
      <circle cx='20.75' cy='12' r='3.25' />
    </svg>
  </span>
)

export default MenuMoreSolidSvg
