import React from 'react'
import PropTypes from 'prop-types'

import CloseSvg from '@/components/svg/close'

const ClearSearchButton = ({ onClick }) => (
  <button
    type='button'
    onClick={onClick}
    className='srch-tlbr__btn srch-tlbr__clear'
  >
    <CloseSvg className='icon' />
  </button>
)

ClearSearchButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default ClearSearchButton
