import { Controller } from 'stimulus'

import { renderComponent } from './renderer'

export default class ReactController extends Controller {
  static values = {
    path: String,
    props: Object
  }

  connect () {
    const { element, pathValue, propsValue } = this
    this.root = renderComponent(element, pathValue, propsValue)
  }

  disconnect () {
    if (!this.root) return
    this.root.unmount()
  }
}

window.Stimulus.register('react', ReactController)
