import React from 'react'

import App from './app'
import EmailAttachment from './components/email-attachment'

export default props => (
  <App>
    <EmailAttachment {...props} />
  </App>
)
