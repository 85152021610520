import React, { Fragment, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { camelizeKeys } from 'humps'
import { normalize } from 'normalizr'
import OutsideClickHandler from 'react-outside-click-handler'

import { ItemContext } from '../../contexts'
import setNewUuids from '../../helpers/set-new-uuids'
import { priceListAutocomplete } from '../../services'
import { item as itemSchema } from '../../schemas'
import Field from '../final-form-field'
import SearchResults from './search-results'

const TitleField = ({ onResultClick, autoFocus }) => {
  const { itemUUID } = useContext(ItemContext)
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)

  const search = value => {
    if (value.length < 1) { setResults([]); return }
    priceListAutocomplete(value)
      .then(response => {
        setResults(camelizeKeys(response.data.items))
        setShowResults(true)
      })
  }

  const debouncedSearch = useCallback(debounce(search, 300), [itemUUID])

  return (
    <>
      <Field
        name='title'
        testKey='title'
        placeholder='Give this work a title'
        className='pwrk-frm__input--title'
        autoFocus={autoFocus}
        onChange={event => debouncedSearch(event.target.value)}
      />
      <OutsideClickHandler onOutsideClick={() => setShowResults(false)}>
        {showResults &&
          (
            <SearchResults
              items={results}
              onResultClick={item => {
                onResultClick(normalize(setNewUuids(item), itemSchema))
                setShowResults(false)
              }}
            />
          )}
      </OutsideClickHandler>
    </>
  )
}

TitleField.defaultProps = {
  autoFocus: false
}

TitleField.propTypes = {
  autoFocus: PropTypes.bool,
  onResultClick: PropTypes.func.isRequired
}

export default TitleField
