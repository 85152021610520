import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import Color from './color'

const ColorSelector = () => {
  const { getValues, register, setValue, watch } = useFormContext()

  const colors = [
    'faint-metal',
    'light-metal',
    'green-5',
    'teal-5',
    'purple-5',
    'red-5',
    'orange-5',
    'yellow-5'
  ]

  const watchInitials = watch('company_user[initials]')
  const [activeColor, setActiveColor] = useState(colors[0])

  const setColor = color => {
    setActiveColor(color)
    setValue('company_user[color]', color)
  }

  useEffect(() => {
    const data = getValues()
    setActiveColor(data['company_user[color]'])
  }, [getValues])

  return (
    <span className='company-user__selector'>
      <input
        ref={register}
        type='hidden'
        name='company_user[color]'
      />
      {colors.map((value, i) => (
        <Color
          key={value}
          arrayIndex={i}
          initials={watchInitials}
          color={value}
          activeColor={activeColor}
          setColor={setColor}
        />
      ))}
    </span>
  )
}

export default ColorSelector
