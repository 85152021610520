/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'

const SaveButton = createReactClass({
  displayName: 'SaveButton',
  propTypes: {
    status: PropTypes.oneOf(['inert', 'save', 'saving', 'saved']).isRequired,
    onClick: PropTypes.func.isRequired
  },
  button () {
    const { onClick, status } = this.props
    switch (status) {
      case 'save':
        return (
          <button type='button' onClick={onClick} className='btn btn--green'>
            Save changes
          </button>
        )
      case 'saving':
        return (
          <button type='button' disabled className='btn btn--green'>
            Saving...
          </button>
        )
      case 'saved':
        return (
          <button
            type='button'
            disabled
            className='btn btn--clear frm__success-msg'
          >
            <strong>Saved</strong>
          </button>
        )
      default:
        return null
    }
  },
  render () {
    return (
      <div className='u-block frm__save_button'>
        {this.button()}
      </div>
    )
  }
})

export default SaveButton
