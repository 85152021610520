import { channel } from '@/channels/notifications_channel'
import {
  ADD,
  CLEAR_ALL,
  FETCH_SUCCESS
} from '../types'

const notification = (state, action) => {
  switch (action.type) {
    case ADD:
      return {
        id: action.id,
        title: action.title,
        readAt: action.readAt,
        receivedAt: action.receivedAt,
        url: action.url
      }
    default:
      return state
  }
}

const addUniqueNotification = (state, action) => {
  const newNotification = notification(undefined, action)
  return [
    ...state.filter(item => item.id !== newNotification.id),
    newNotification
  ]
}

const sortNotificationsMostRecentFirst = notifications => [...notifications].sort((a, b) => {
  if (a.receivedAt < b.receivedAt) { return 1 }
  if (a.receivedAt > b.receivedAt) { return -1 }
  return 0
})

const notifications = (state = [], action) => {
  switch (action.type) {
    case ADD:
      return sortNotificationsMostRecentFirst(
        addUniqueNotification(state, action)
      )
    case FETCH_SUCCESS: {
      return action.payload
    }
    case CLEAR_ALL: {
      const unreadNotifications = state.filter(n => !n.readAt)
      const unreadNotificationIds = unreadNotifications.map(n => n.id)
      let newState = [...state]
      unreadNotifications.forEach(n => {
        newState = notifications(
          newState,
          {
            type: ADD,
            ...n,
            readAt: new Date().toISOString()
          }
        )
      })
      channel.perform('clear_all_notifications', { ids: unreadNotificationIds })
      return newState
    }
    default:
      return state
  }
}

export default notifications
