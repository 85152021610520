// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the
// `rails generate channel` command.

import { createConsumer } from '@rails/actioncable'

const consumer = createConsumer()

class NullChannel {
  perform () {

  }
}

export const createChannel = (...args) => {
  try {
    window.App = window.App || {}
    if (!window.App.cable) {
      window.App.cable = consumer
    }
    return consumer.subscriptions.create(...args)
  } catch (e) {
    if (e.name !== 'SecurityError') {
      throw e
    }
    return new NullChannel()
  }
}

export default consumer
