/* eslint func-names: 0, react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
/* eslint prefer-destructuring: 0 */
/* eslint react/default-props-match-prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import Input from './settings/input'

const slice = [].slice

const Setting = createReactClass({
  displayName: 'Setting',
  propTypes: {
    autosave: PropTypes.bool,
    autofocus: PropTypes.bool,
    for: PropTypes.string.isRequired,
    setting: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['string', 'text', 'richtext', 'bool', 'int', 'float', '[string]', '[text]', '[bool]', '[int]', '[float]']).isRequired,
    path: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object)
    ]),
    placeholder: PropTypes.string,
    options: PropTypes.array,
    shallowRoutes: PropTypes.bool,
    noCreate: PropTypes.bool,
    addLabel: PropTypes.string,
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf(['before', 'after']),
    tooltip: PropTypes.string,
    surround: PropTypes.string,
    helpers: PropTypes.arrayOf(PropTypes.string),
    class: PropTypes.string,
    labelClass: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  },
  getDefaultProps () {
    return {
      type: 'text',
      initialValue: '',
      label: undefined,
      labelPosition: 'before',
      shallowRoutes: false,
      noCreate: false,
      class: '',
      errors: []
    }
  },
  childProps () {
    const newProps = { ...this.props }
    delete newProps.setting
    delete newProps.for
    return newProps
  },
  name () {
    const { for: forName, setting } = this.props
    return `${forName}[${setting}]`
  },
  inputId () {
    let attr
    return slice.call(function () {
      let i
      let len
      const ref = this.name().split('[')
      const results = []
      for (i = 0, len = ref.length; i < len; i += 1) {
        attr = ref[i]
        results.push(attr.replace(/]$/, ''))
      }
      return results
    }.call(this)).join('_')
  },
  render () {
    return React.createElement(Input, {
      ...this.childProps(),
      for: 'setting.value',
      name: this.name(),
      inputId: this.inputId()
    })
  }
})

export default Setting
