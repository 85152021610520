import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ExpandingTextArea = ({ value, onChange, minRows, maxRows, testKey, className, ...props }) => {
  const textareaRef = useRef()
  const [rows, setRows] = useState(minRows)

  const formatScrollHeight = element => {
    const textareaLineHeight = 24
    const previousRows = element.rows

    element.rows = minRows

    const currentRows = ~~(element.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      element.rows = currentRows
    }

    if (currentRows >= maxRows) {
      element.rows = maxRows
    }

    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  useEffect(() => {
    formatScrollHeight(textareaRef.current)
  }, [])

  return (
    <textarea
      {...props}
      rows={rows}
      ref={textareaRef}
      value={value}
      data-test={`${testKey}-field`}
      className={`pwrk-frm__input ${className}`}
      onChange={e => {
        onChange(e)
        formatScrollHeight(e.target)
      }}
    />
  )
}

ExpandingTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  testKey: PropTypes.string.isRequired,
  minRows: PropTypes.string,
  maxRows: PropTypes.string,
  className: PropTypes.string
}

ExpandingTextArea.defaultProps = {
  minRows: '3',
  maxRows: '10',
  className: ''
}

export default ExpandingTextArea
