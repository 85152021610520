import React from 'react'
import BellWithoutPanel from './containers/bell-without-panel'
import WithStore from './components/with-store'

const NotificationsBell = () => (
  <WithStore>
    <BellWithoutPanel />
  </WithStore>
)

export default NotificationsBell
