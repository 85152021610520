import { combineReducers } from 'redux'

import events from './events'
import display from './display'
import eventApiFilters from './event-api-filters'
import companyUsers from './company-users'
import urlFormats from './url-formats'

export default combineReducers({
  events,
  display,
  eventApiFilters,
  companyUsers,
  urlFormats
})
