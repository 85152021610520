/* eslint react/no-did-mount-set-state: 0 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { subjectChanged } from '../actions'
import ExpandingTextArea from './expanding-text-area'

const Subject = () => {
  const dispatch = useDispatch()
  const {
    subject,
    focussed
  } = useSelector(state => state.content[state.content.current])

  return (
    <ExpandingTextArea
      focussed={focussed}
      onChange={event => { dispatch(subjectChanged(event.target.value)) }}
      placeholder='Subject…'
      rows='1'
      value={subject}
      autoFocus={subject === ''}
      className='frm__input textarea--single textarea--static'
      name='message_form[subject]'
      id='message_form_subject'
      data-test='subject-field'
    />
  )
}

export default Subject
