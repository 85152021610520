import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import AddSvg from '@/components/svg/add'
import Money from '../money'

const Result = ({ item, onResultClick }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (item.selected) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [item])

  return (
    <li key={item.id} ref={ref} className={item.selected ? 'c-card price-list-search__card price-list-search__card--selected' : 'c-card price-list-search__card'}>
      <a
        onClick={() => { onResultClick(item) }}
        role='button'
        tabIndex={0}
        data-test='material-search-results-link'
      >
        <div className='flex-grid'>
          <div className='flex-grid__cell flex-grid__cell--v-center'>
            <h4 className='t--reg'>
              <strong>{item.description}</strong>
            </h4>
            <span className='t--sml c--dark-metal'>
              {item.productCode}
              {item.brand && item.productCode ? ' · ' : ''}
              {item.brand}
            </span>
          </div>

          <div className='flex-grid__cell flex-grid__cell--1of8 flex-grid__cell--v-top'>
            <p className='t--sml u-pad-top u-align-right'>
              <strong><Money amount={item.itemPrice} /></strong>
              <div className='t--sml c--dark-metal'>
                {item.unitType && (
                  <span>
                    per
                    {item.unitType}
                  </span>
                )}
                {item.markupPercentage > 0 && (
                  <span>
                    &nbsp;
                    (+
                    {item.markupPercentage}
                    %)
                  </span>
                )}
              </div>
            </p>
          </div>

          <div className='flex-grid__cell flex-grid__cell--1of4 flex-grid__cell--v-top price-list-search__card-add'>
            <span className='btn__add'>
              <AddSvg className='icon icon--add u-mrgn-r' />
              Add this material
            </span>
          </div>
        </div>
      </a>
    </li>
  )
}

Result.propTypes = {
  item: PropTypes.object.isRequired,
  onResultClick: PropTypes.func.isRequired
}

export default Result
