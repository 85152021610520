import React, { useState } from 'react'
import PropTypes from 'prop-types'

import EventIcon from './event_icon'
import ChevronDownSvg from '../../svg/chevron-down'

import { WORK_DATE, GENERAL_EVENT, APPOINTMENT, GROUPED_AUTOMATION_TASKS } from './event_types'
import AutomationTask from './automation-task'

const GroupedAutomationTasks = ({
  type,
  currentDay,
  automationTasks,
  canViewContact
}) => {
  const [expanded, setExpanded] = useState(false)

  const clickHandler = React.useCallback(() => setExpanded(!expanded), [expanded])

  if (automationTasks.length === 1) {
    const task = automationTasks[0]

    return (
      <AutomationTask
        key={task.id}
        currentDay={currentDay}
        canViewContact={canViewContact}
        {...task}
      />
    )
  }

  return (
    <span>
      <li className='c-card' onClick={clickHandler}>
        <div className='auto-task auto-task--event auto-task--event-group'>
          <div className='auto-task__icon'>
            <EventIcon type={type} className='icon icon--sml icon--automation' />
          </div>
          <div className='auto-task__desc'>
            {`${automationTasks.length} auto-messages to be sent`}
            <ChevronDownSvg className='icon icon--micro icon--purple-1 u-mrgn-l' />
          </div>
        </div>
      </li>
      <ul className={`grouped-automations ${expanded ? 'grouped-automations--expanded' : ''}`}>
        {
          automationTasks.map(task => (
            <AutomationTask
              key={task.id}
              currentDay={currentDay}
              canViewContact={canViewContact}
              {...task}
            />
          ))
      }
      </ul>

    </span>
  )
}

GroupedAutomationTasks.defaultProps = {
  canViewContact: false
}

GroupedAutomationTasks.propTypes = {
  type: PropTypes.oneOf([
    GENERAL_EVENT,
    APPOINTMENT,
    WORK_DATE,
    GROUPED_AUTOMATION_TASKS
  ]).isRequired,
  currentDay: PropTypes.string.isRequired,
  automationTasks: PropTypes.arrayOf({}).isRequired,
  canViewContact: PropTypes.bool
}

export default GroupedAutomationTasks
