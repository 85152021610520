import { Controller } from 'stimulus'

export default class RevealMoreListController extends Controller {
  static targets = ['item', 'toggle']

  static values = {
    enabled: Boolean
  }

  toggle (event) {
    event.preventDefault()

    this.itemTargets.slice(1).forEach(item => { item.classList.toggle('js-hidden') })
    this.enabledValue = !this.enabledValue
  }

  enabledValueChanged (value) {
    if (!this.hasToggleTarget) { return }

    if (value) {
      this.toggleTarget.innerText = `- hide ${this.itemTargets.slice(1).length} auto-messages`
    } else {
      this.toggleTarget.innerText = `+ ${this.itemTargets.slice(1).length} more auto-messages…`
    }
  }
}
