import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import PercentageInputField from './input'
import PercentageSelectField from './select'

const PercentageField = ({
  name,
  label,
  onChange,
  defaultValue,
  testKey
}) => {
  const [showInput, setShowInput] = useState(defaultValue % 5 !== 0)
  const didMountRef = useRef(false)
  const inputRef = useRef(null)

  const handleOnChange = value => {
    if (value === 'selectOtherInput') {
      setShowInput(true)
      return
    }

    onChange(value)
  }

  useEffect(() => {
    if (didMountRef.current && showInput) {
      inputRef.current.focus()
    }

    didMountRef.current = true
  }, [showInput])

  if (showInput) {
    return (
      <PercentageInputField
        name={name}
        onChange={handleOnChange}
        defaultValue={defaultValue}
        testKey={testKey}
        inputRef={inputRef}
      />
    )
  }
  return (
    <PercentageSelectField
      name={name}
      onChange={handleOnChange}
      defaultValue={defaultValue}
      label={label}
      testKey={testKey}
    />
  )
}

PercentageField.defaultProps = {
  label: '',
  defaultValue: 0.00
}

PercentageField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  testKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string
}

export default PercentageField
