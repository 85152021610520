export const WORK_DATE = 'WorkDate'
export const GENERAL_EVENT = 'GeneralEvent'
export const APPOINTMENT = 'Appointment'
export const AUTOMATION_TASK = 'AutomationTask'
export const GROUPED_AUTOMATION_TASKS = 'GroupedAutomationTasks'

const EventTypes = {}

EventTypes[WORK_DATE] = { colour: 'teal-1', pretty: 'Work date' }
EventTypes[GENERAL_EVENT] = { colour: 'purple-1', pretty: 'Event' }
EventTypes[APPOINTMENT] = { colour: 'orange-1', pretty: 'Appointment' }
EventTypes[AUTOMATION_TASK] = { colour: 'purple-1', pretty: 'Auto-message' }
EventTypes[GROUPED_AUTOMATION_TASKS] = { colour: 'purple-1', pretty: 'Auto-messages' }

export default EventTypes
