import arrayMove from 'array-move'

import {
  createReducer,
  updateEntity,
  updateEntities,
  removeStaleEntities,
  deepSetKeys,
  removeObjectKey,
  removeEntitiesByProperty,
  updateEntitiesByProperty
} from './util'

import {
  INIT,
  RESET,
  ENTITIES_ADD,
  ITEM_ADD,
  ITEM_TOGGLE_OPTIONAL,
  ITEM_FORM_CHANGED,
  ITEM_FIELD_CHANGED,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_SEARCH_CHOOSE,
  LABOUR_SEARCH_CHOOSE,
  LABOUR_ITEM_ADD,
  LABOUR_ITEM_REMOVE,
  LABOUR_ITEM_POSITION_CHANGE,
  LABOUR_ITEM_FORM_CHANGED,
  MATERIAL_SEARCH_CHOOSE,
  MATERIAL_ITEM_ADD,
  MATERIAL_ITEM_REMOVE,
  MATERIAL_ITEM_POSITION_CHANGE,
  MATERIAL_ITEM_FORM_CHANGED,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_DUPLICATE_SUCCESS,
  PAPERWORK_DISCOUNT_CHANGED,
  PAPERWORK_DISCOUNT_REMOVE,
  ITEM_SAVE_SUCCESS,
  ITEM_SAVE_FAILURE,
  PAPERWORK_REMOVE,
  SECTION_REMOVE,
  ITEM_QUANTITY_DECREMENT,
  ITEM_QUANTITY_INCREMENT,
  MATERIAL_ITEM_DUPLICATE,
  LABOUR_ITEM_DUPLICATE
} from '../types'

const reset = () => ({})

const init = (
  state,
  { payload: { configuration } }
) => {
  const newState = deepSetKeys(state, { focussed: false })

  return {
    ...newState,
    template: {
      taxRate: configuration.defaultTaxRate,
      title: '',
      description: '',
      price: '0.00',
      discount: '0.00',
      quantity: '1',
      labourItems: [],
      materialItems: [],
      optional: false,
      saved: false
    }
  }
}

const itemAdd = (
  state,
  { payload: { itemUUID, paperworkUUID, byUser } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  itemUUID,
  {
    ...state.template,
    paperworkUUID,
    focussed: byUser
  }
)

const entitiesAdd = (state, { payload: { entities: { items: entities } } }) => (
  updateEntities(state, entities)
)

const sectionRemove = (state, { payload: { sectionUUID } }) => (
  removeObjectKey(state, sectionUUID)
)

const itemToggleOptional = (state, { payload: { itemUUID } }) => updateEntity(
  state,
  itemUUID,
  { optional: !state[itemUUID].optional }
)

const itemQuantityIncrement = (state, { payload: { itemUUID } }) => updateEntity(
  state,
  itemUUID,
  { quantity: Number(state[itemUUID].quantity) + 1 }
)

function itemQuantityDecrement (state, { payload: { itemUUID } }) {
  if (state[itemUUID].quantity === 1) { return state }
  return updateEntity(
    state,
    itemUUID,
    { quantity: Number(state[itemUUID].quantity) - 1 }
  )
}

const itemFormChanged = (
  state,
  { payload: { itemUUID, values } }
) => updateEntity(
  state,
  itemUUID,
  {
    ...values,
    saved: false,
    changed: true
  }
)

const itemFieldChanged = (
  state,
  { payload: { itemUUID, name, value } }
) => {
  let newState = { ...state }
  if (name === 'taxRate') {
    newState = updateEntity(newState, 'template', { taxRate: value })
  }
  return updateEntity(
    newState,
    itemUUID,
    {
      [name]: value,
      saved: false,
      changed: true
    }
  )
}

const itemSaveSuccess = (state, { payload: { itemUUID } }) => updateEntity(
  state,
  itemUUID,
  { saved: true }
)

const itemSaveFailure = (state, { payload: { itemUUID } }) => updateEntity(
  state,
  itemUUID,
  { saved: false }
)

const itemAutocompleteChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { items: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  const uuid = Object.keys(newEntities)[0]
  const entity = newEntities[uuid]

  return {
    ...state,
    [uuid]: {
      ...state.template,
      ...entity,
      discount: state.template.discount
    }
  }
}

const itemSearchChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { items: entities } } } = action.payload

  const newEntities = deepSetKeys(entities, { paperworkUUID })

  const uuid = Object.keys(newEntities)[0]
  const entity = newEntities[uuid]

  return {
    ...state,
    [uuid]: {
      ...state.template,
      ...entity,
      discount: state.template.discount
    }
  }
}

const labourItemAdd = (
  state,
  { payload: { itemUUID, labourItemUUID } }
) => updateEntity(
  state,
  itemUUID,
  {
    labourItems: (state[itemUUID].labourItems || []).concat(labourItemUUID)
  }
)

const labourSearchChoose = (state, action) => {
  const { itemUUID, data: { entities: { labourItems } } } = action.payload
  const item = state[itemUUID]

  return updateEntity(
    state,
    itemUUID,
    {
      labourItems: item.labourItems.concat(Object.keys(labourItems))
    }
  )
}

const labourItemRemove = (
  state,
  { payload: { itemUUID, labourItemUUID } }
) => updateEntity(
  state,
  itemUUID,
  {
    labourItems: (state[itemUUID].labourItems || []).filter(
      uuid => uuid !== labourItemUUID
    )
  }
)

const labourItemDuplicate = (
  state,
  { payload: { itemUUID, labourItemUUID, newUUID } }
) => {
  const labourItems = state[itemUUID].labourItems || []
  const currentIndex = labourItems.indexOf(labourItemUUID)
  const newlabourItems = labourItems.toSpliced(currentIndex, 0, newUUID)

  return updateEntity(
    state,
    itemUUID,
    {
      labourItems: newlabourItems
    }
  )
}

const labourItemPositionChange = (
  state,
  { payload: { itemUUID, oldIndex, newIndex } }
) => updateEntity(
  state,
  itemUUID,
  {
    labourItems: arrayMove(state[itemUUID].labourItems, oldIndex, newIndex)
  }
)

const labourItemFormChanged = (
  state,
  { payload: { itemUUID } }
) => updateEntity(
  state,
  itemUUID,
  { changed: true }
)

const materialItemAdd = (
  state,
  { payload: { itemUUID, materialItemUUID } }
) => updateEntity(
  state,
  itemUUID,
  {
    materialItems: (state[itemUUID].materialItems || []).concat(materialItemUUID)
  }
)

const materialItemDuplicate = (
  state,
  { payload: { itemUUID, materialItemUUID, newUUID } }
) => {
  const materialItems = state[itemUUID].materialItems || []
  const currentIndex = materialItems.indexOf(materialItemUUID)
  const newMaterialItems = materialItems.toSpliced(currentIndex, 0, newUUID)

  return updateEntity(
    state,
    itemUUID,
    {
      materialItems: newMaterialItems
    }
  )
}

const materialSearchChoose = (state, action) => {
  const { itemUUID, data: { entities: { materialItems } } } = action.payload
  const item = state[itemUUID]

  return updateEntity(
    state,
    itemUUID,
    {
      materialItems: item.materialItems.concat(Object.keys(materialItems))
    }
  )
}

const materialItemRemove = (
  state,
  { payload: { itemUUID, materialItemUUID } }
) => updateEntity(
  state,
  itemUUID,
  {
    materialItems: (state[itemUUID].materialItems || []).filter(
      uuid => uuid !== materialItemUUID
    )
  }
)

const materialItemPositionChange = (
  state,
  { payload: { itemUUID, oldIndex, newIndex } }
) => updateEntity(
  state,
  itemUUID,
  {
    materialItems: arrayMove(state[itemUUID].materialItems, oldIndex, newIndex)
  }
)

const materialItemFormChanged = (
  state,
  { payload: { itemUUID } }
) => updateEntity(
  state,
  itemUUID,
  { changed: true }
)

const paperworkFetchSuccess = (state, action) => {
  const { items: entities } = action.payload.data.entities

  return {
    ...state,
    ...removeStaleEntities(state, entities)
  }
}

const paperworkDuplicateSuccess = (state, action) => {
  const { items: entities } = action.payload.data.entities

  return {
    ...state,
    ...entities
  }
}

const paperworkRemove = (state, { payload: { paperworkUUID } }) => (
  removeEntitiesByProperty(state, 'paperworkUUID', paperworkUUID)
)

const paperworkDiscountChanged = (state, { payload: { paperworkUUID, value } }) => (
  updateEntitiesByProperty(
    updateEntity(state, 'template', { discount: value }),
    'paperworkUUID',
    paperworkUUID,
    { discount: value }
  )
)

const paperworkDiscountRemove = (state, { payload: { paperworkUUID } }) => (
  updateEntitiesByProperty(
    updateEntity(state, 'template', { discount: '0.00' }),
    'paperworkUUID',
    paperworkUUID,
    { discount: '0.00' }
  )
)

export default createReducer({}, {
  [INIT]: init,
  [RESET]: reset,
  [ENTITIES_ADD]: entitiesAdd,
  [ITEM_ADD]: itemAdd,
  [ITEM_AUTOCOMPLETE_CHOOSE]: itemAutocompleteChoose,
  [ITEM_FORM_CHANGED]: itemFormChanged,
  [ITEM_FIELD_CHANGED]: itemFieldChanged,
  [ITEM_SAVE_FAILURE]: itemSaveFailure,
  [ITEM_SAVE_SUCCESS]: itemSaveSuccess,
  [ITEM_SEARCH_CHOOSE]: itemSearchChoose,
  [ITEM_TOGGLE_OPTIONAL]: itemToggleOptional,
  [ITEM_QUANTITY_INCREMENT]: itemQuantityIncrement,
  [ITEM_QUANTITY_DECREMENT]: itemQuantityDecrement,
  [LABOUR_ITEM_ADD]: labourItemAdd,
  [LABOUR_ITEM_FORM_CHANGED]: labourItemFormChanged,
  [LABOUR_ITEM_POSITION_CHANGE]: labourItemPositionChange,
  [LABOUR_ITEM_REMOVE]: labourItemRemove,
  [LABOUR_SEARCH_CHOOSE]: labourSearchChoose,
  [MATERIAL_ITEM_ADD]: materialItemAdd,
  [MATERIAL_ITEM_FORM_CHANGED]: materialItemFormChanged,
  [MATERIAL_ITEM_POSITION_CHANGE]: materialItemPositionChange,
  [MATERIAL_ITEM_REMOVE]: materialItemRemove,
  [MATERIAL_ITEM_DUPLICATE]: materialItemDuplicate,
  [LABOUR_ITEM_DUPLICATE]: labourItemDuplicate,
  [MATERIAL_SEARCH_CHOOSE]: materialSearchChoose,
  [PAPERWORK_REMOVE]: paperworkRemove,
  [PAPERWORK_DUPLICATE_SUCCESS]: paperworkDuplicateSuccess,
  [PAPERWORK_FETCH_SUCCESS]: paperworkFetchSuccess,
  [PAPERWORK_DISCOUNT_CHANGED]: paperworkDiscountChanged,
  [PAPERWORK_DISCOUNT_REMOVE]: paperworkDiscountRemove,
  [SECTION_REMOVE]: sectionRemove
})
