/* eslint-disable no-restricted-syntax */
import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { denormalize } from 'normalizr'
import { decamelizeKeys } from 'humps'

import schema from '../schemas'
import { materialSave } from '../services'
import {
  PAPERWORK_FETCH_SUCCESS,
  MATERIAL_ITEM_SAVE,
  MATERIAL_ITEM_FORM_CHANGED,
  MATERIAL_SEARCH_CHOOSE
} from '../types'
import {
  materialItemAdd,
  materialItemSaveSuccess,
  materialItemSaveFailure,
  materialItemRemove
} from '../actions'
import { getConfiguration } from '../selectors'
import { getAllMaterialItems, isMaterialItemEmpty } from '../selectors/material-items'
import { getPaperwork } from '../selectors/paperwork'
import { getItemMaterialItems } from '../selectors/items'

export function * materialItemSaveSaga (action) {
  const { materialItemUUID } = action.payload

  const allState = yield (select(state => state))
  const materialItem = yield (select(state => getAllMaterialItems(state)[materialItemUUID]))
  const denormalized = yield call(denormalize, materialItem, schema, allState)
  const decamelized = yield call(decamelizeKeys, denormalized)

  const { response, error } = yield call(materialSave, decamelized)

  if (response) {
    yield put(materialItemSaveSuccess(materialItemUUID))
  } else {
    yield put(materialItemSaveFailure(materialItemUUID, error))
  }
}

export function * ensureEmptyItem (itemUUID) {
  const { paperworkUUID } = yield select(getConfiguration)
  const materialItems = yield (select(state => getItemMaterialItems(state, itemUUID)))
  const changedMaterialItems = materialItems.filter(materialItem => materialItem.changed)

  if (materialItems.length === changedMaterialItems.length) {
    yield put(materialItemAdd(paperworkUUID, itemUUID, false))
  }
}

export function * materialItemFormChangedSaga (action) {
  const { itemUUID } = action.payload
  yield ensureEmptyItem(itemUUID)
}

export function * paperworkFetchSuccessSaga () {
  const { paperworkUUID } = yield select(getConfiguration)
  const { items } = yield select(getPaperwork, paperworkUUID)

  yield all(items.map(ensureEmptyItem))
}

export function * materialSearchChooseSaga (action) {
  const { itemUUID } = action.payload
  const materialItems = yield (select(state => getItemMaterialItems(state, itemUUID)))
  const emptyMaterialItems = yield (select(state => materialItems.filter(materialItem => isMaterialItemEmpty(state, materialItem.uuid))))

  if (emptyMaterialItems.length > 0) {
    const emptyMaterialItemUUID = emptyMaterialItems[emptyMaterialItems.length - 1].uuid

    yield put(materialItemRemove(itemUUID, emptyMaterialItemUUID))
  }
}

export default function * materialItemSaga () {
  yield all([
    takeLatest(PAPERWORK_FETCH_SUCCESS, paperworkFetchSuccessSaga),
    takeLatest(MATERIAL_ITEM_SAVE, materialItemSaveSaga),
    takeLatest(MATERIAL_ITEM_FORM_CHANGED, materialItemFormChangedSaga),
    takeLatest(MATERIAL_SEARCH_CHOOSE, materialSearchChooseSaga)
  ])
}
