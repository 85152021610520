import React from 'react'

const GeneralEventSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M12 0a9.5 9.5 0 0 0-4 18.119 2.5 2.5 0 0 1 1.218 1.222l1.873 4.076a1 1 0 0 0 1.817 0l1.876-4.08A2.5 2.5 0 0 1 16 18.118 9.5 9.5 0 0 0 12 0zm0 15.75a6.25 6.25 0 1 1 6.25-6.25A6.257 6.257 0 0 1 12 15.75z' />
      <circle cx='12' cy='9.5' r='4.25' />
    </svg>
  </span>
)

export default GeneralEventSvg
