/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
/* eslint react/no-unused-state: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'

const NumberInput = createReactClass({
  displayName: 'NumberInput',
  propTypes: {
    componentId: PropTypes.number,
    inputId: PropTypes.string,
    initialValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    attribute: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    type: PropTypes.string,
    class: PropTypes.string,
    surround: PropTypes.string,
    helpers: PropTypes.arrayOf(PropTypes.element),
    noCreate: PropTypes.bool,
    noRemove: PropTypes.bool,
    fieldWrapperClass: PropTypes.string
  },
  getDefaultProps () {
    return {
      initialValue: '',
      noCreate: false
    }
  },
  getInitialState () {
    const { initialValue } = this.props
    return {
      errors: [],
      value: initialValue,
      saving: false,
      saved: false
    }
  },
  value () {
    const { attribute } = this.props
    const { value } = this.state
    return value[attribute]
  },
  render () {
    const { class: className, inputId, onEdit } = this.props
    return React.createElement('input', {
      className,
      id: inputId,
      type: 'number',
      onChange: onEdit,
      defaultValue: this.value()
    })
  }
})

export default NumberInput
