import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { init } from '../actions'
import { getConfiguration } from '../selectors'

const InitGate = ({
  children,
  configuration
}) => {
  const dispatch = useDispatch()
  const { loaded } = useSelector(getConfiguration)

  useEffect(() => {
    dispatch(init(configuration))
  }, [])

  return (
    <>
      {loaded && children}
    </>
  )
}

InitGate.defaultProps = {
  children: null,
  configuration: {}
}

InitGate.propTypes = {
  children: PropTypes.node,
  configuration: PropTypes.object
}

export default InitGate
