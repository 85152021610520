import React from 'react'

import PaperworkTotal from './totals/paperwork'

const GrandTotal = () => (
  <div className='pwrk-frm__grand-total'>
    <span className='pwrk-frm__totals-label'>Total</span>
    <span className='pwrk-frm__totals-value' data-test='total'>
      <PaperworkTotal name='total' />
    </span>
  </div>
)

export default GrandTotal
