import { Controller } from 'stimulus'

export default class ToggleVatNumberController extends Controller {
  static targets = ['country', 'vatNumber']

  connect () {
    this.toggle()
  }

  toggle () {
    const euCountries = this.data.get('valid-countries')

    if (euCountries.includes(this.countryTarget.value.toLowerCase())) {
      this.vatNumberTarget.classList.remove('hidden')
    } else {
      this.vatNumberTarget.classList.add('hidden')
    }
  }
}
