import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { MaterialItemContext } from '../../../contexts'
import Money from '../../money'
import { materialItemTotals as totals } from '../../../selectors'

const MaterialItemTotal = ({ name }) => {
  const { materialItemUUID } = useContext(MaterialItemContext)
  const amount = useSelector(state => totals(state, materialItemUUID))[name]

  return (
    <Money amount={amount} />
  )
}

MaterialItemTotal.propTypes = {
  name: PropTypes.string.isRequired
}

export default MaterialItemTotal
