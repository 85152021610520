import React from 'react'
import PropTypes from 'prop-types'

import Day from './day'
import ActionBar from './action-bar'

const Schedule = props => {
  const {
    days
  } = props

  return (
    <div>
      <ActionBar {...props} />
      <div className='days u-mrgn-btm--x18' data-test='schedule-days'>
        {
          days.map(
            day => (
              <Day
                key={day.date}
                {...day}
                {...props}
              />
            )
          )
        }
      </div>

    </div>
  )
}

Schedule.propTypes = {
  days: PropTypes.array.isRequired
}

export default Schedule
