import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import axios from '@ytb/axios'

import createFlashMessage from '@/application/flashes'
import createEventParams from '../helpers/create-event-params'
import {
  INIT,
  ROUTE_CHANGED,
  DELETE_EVENT,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  FETCH_EVENTS_FAILURE,
  CHANGE_EVENT_COLOR,
  CHANGE_EVENT_COLOR_FAILURE,
  CHANGE_EVENT_COLOR_SUCCESS
} from '../types'

import {
  fetchEventsSuccess,
  fetchEventsFailure,
  deleteEventSuccess,
  deleteEventFailure,
  changeEventColorSuccess,
  changeEventColorFailure
} from '../actions'

export function * fetchEventsSaga () {
  const eventApiFilters = yield select(state => state.schedule.eventApiFilters)
  try {
    const json = yield axios.get(
      eventApiFilters.eventsUrl,
      { params: createEventParams(eventApiFilters) }
    ).then(response => response.data)
    yield put(fetchEventsSuccess(json))
  } catch (e) {
    yield put(fetchEventsFailure())
  }
}

export function * deleteEventSaga (action) {
  const { payload: { id } } = action

  try {
    yield axios.delete(
      `/events/${id}`,
      {
        headers: {
          Accept: 'application/json'
        }
      }
    )
    yield put(deleteEventSuccess(id))
  } catch (e) {
    yield put(deleteEventFailure(id))
  }
}

export function * changeEventColorSaga (action) {
  const { payload: { id, color } } = action

  try {
    yield axios.put(
      `/events/${id}/color`,
      { color }
    )

    yield put(changeEventColorSuccess(id, color))
  } catch (e) {
    yield put(changeEventColorFailure(id))
  }
}

export function * changeEventColorSuccessSaga () {
  yield call(createFlashMessage, 'Event has color been changed')
}

export function * changeEventColorFailureSaga () {
  yield call(createFlashMessage, 'Unable to change the event color', 'alert')
}

export function * fetchEventsFailureSaga () {
  yield call(createFlashMessage, 'Unable to fetch events, please refresh the page', 'alert')
}

export function * deleteEventSuccessSaga () {
  yield call(createFlashMessage, 'Event has been deleted')
}

export function * deleteEventFailureSaga () {
  yield call(createFlashMessage, 'Unable to delete the event', 'alert')
}

export default function * eventsSaga () {
  yield all([
    takeLatest(INIT, fetchEventsSaga),
    takeLatest(ROUTE_CHANGED, fetchEventsSaga),
    takeLatest(FETCH_EVENTS_FAILURE, fetchEventsFailureSaga),
    takeLatest(DELETE_EVENT, deleteEventSaga),
    takeLatest(DELETE_EVENT_FAILURE, deleteEventFailureSaga),
    takeLatest(DELETE_EVENT_SUCCESS, deleteEventSuccessSaga),
    takeLatest(CHANGE_EVENT_COLOR, changeEventColorSaga),
    takeLatest(CHANGE_EVENT_COLOR_FAILURE, changeEventColorFailureSaga),
    takeLatest(CHANGE_EVENT_COLOR_SUCCESS, changeEventColorSuccessSaga)
  ])
}
