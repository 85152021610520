import { Controller } from 'stimulus'

export default class FocusController extends Controller {
  static targets = ['focusable']

  static values = {
    focused: { type: Boolean, default: false }
  }

  connect () {
    if (!this.hasFocusableTarget && this.focusedValue) {
      this.element.focus()
    }
  }

  trigger () {
    setTimeout(() => {
      this.focusableTarget.focus()
    }, 100)
  }
}
