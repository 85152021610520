import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class MobileMenuController extends Controller {
  static targets = ['menu', 'nav']

  connect () {
    useClickOutside(this)
  }

  clickOutside (event) {
    if (this.body.classList.contains('m-nav--open')) {
      event.preventDefault()
      this.toggle()
    }
  }

  toggle () {
    this.body.classList.toggle('m-nav--open')
  }

  hideNav () {
    this.navTargets.forEach((nav) => {
      nav.classList.add('hidden')
    })
  }

  showNav () {
    this.navTargets.forEach((nav) => {
      nav.classList.remove('hidden')
    })
  }

  get body () {
    return document.getElementsByTagName('body')[0]
  }
}
