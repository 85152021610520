import React from 'react'

const Loading = () => (
  <div className='skeleton skeleton--price-list-item'>
    <span className='skeleton__bones--one' />
    <span className='skeleton__bones--two' />
  </div>
)

export default Loading
