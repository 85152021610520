const NAMESPACE = 'MESSAGE_FORM_'

export const SHOW_MESSAGE_TEMPLATE_PANEL = `${NAMESPACE}SHOW_MESSAGE_TEMPLATE_PANEL`
export const HIDE_MESSAGE_TEMPLATE_PANEL = `${NAMESPACE}HIDE_MESSAGE_TEMPLATE_PANEL`
export const FETCH_EMAIL_TEMPLATES_SUCCESS = `${NAMESPACE}FETCH_EMAIL_TEMPLATES_SUCCESS`
export const TEMPLATE_CHOSEN = `${NAMESPACE}TEMPLATE_CHOSEN`
export const SUBJECT_CHANGED = `${NAMESPACE}SUBJECT_CHANGED`
export const BODY_CHANGED = `${NAMESPACE}BODY_CHANGED`
export const INIT = `${NAMESPACE}INIT`
export const RESET = `${NAMESPACE}RESET`
export const FETCH_CONTENT_SUCCESS = `${NAMESPACE}FETCH_CONTENT_SUCCESS`
