import {
  createReducer,
  updateEntity,
  removeStaleEntities,
  deepSetKeys,
  removeObjectKey,
  removeEntitiesByProperty,
  updateEntities
} from './util'

import {
  INIT,
  RESET,
  ITEM_ADD,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_SEARCH_CHOOSE,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_DUPLICATE_SUCCESS,
  PAPERWORK_REMOVE,
  HEADING_ADD,
  SECTION_FORM_CHANGED,
  SECTION_FIELD_CHANGED,
  SECTION_REMOVE,
  ENTITIES_ADD
} from '../types'

const reset = () => ({})

const init = state => {
  const newState = deepSetKeys(state, { focussed: false })

  return {
    ...newState,
    template: {
      focussed: false,
      data: {}
    }
  }
}

const itemAdd = (
  state,
  { payload: { itemUUID, paperworkUUID, byUser } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  itemUUID,
  {
    ...state.template,
    paperworkUUID,
    type: 'Item',
    focussed: byUser
  }
)

const itemAutocompleteChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { items: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  const uuid = Object.keys(newEntities)[0]

  return {
    ...state,
    [uuid]: {
      ...state.template,
      uuid,
      paperworkUUID,
      type: 'Item'
    }
  }
}

const itemSearchChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { items: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  const uuid = Object.keys(newEntities)[0]

  return {
    ...state,
    [uuid]: {
      ...state.template,
      uuid,
      paperworkUUID,
      type: 'Item',
      focussed: true
    }
  }
}

const sectionRemove = (state, { payload: { sectionUUID } }) => (
  removeObjectKey(state, sectionUUID)
)

const paperworkFetchSuccess = (state, action) => {
  const { sections: entities } = action.payload.data.entities

  return {
    ...state,
    ...removeStaleEntities(state, entities)
  }
}

const paperworkDuplicateSuccess = (state, action) => {
  const { sections: entities } = action.payload.data.entities

  return {
    ...state,
    ...entities
  }
}

const paperworkRemove = (state, { payload: { paperworkUUID } }) => (
  removeEntitiesByProperty(state, 'paperworkUUID', paperworkUUID)
)

const headingAdd = (
  state,
  { payload: { sectionUUID, paperworkUUID, byUser } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  sectionUUID,
  {
    ...state.template,
    paperworkUUID,
    type: 'Heading',
    data: {
      title: '',
      description: ''
    },
    focussed: byUser
  }
)

const sectionFormChanged = (
  state,
  { payload: { sectionUUID, values } }
) => updateEntity(
  state,
  sectionUUID,
  {
    data: {
      ...state[sectionUUID].data,
      ...values
    },
    changed: true
  }
)

const entitiesAdd = (state, { payload: { entities: { sections: entities } } }) => (
  updateEntities(state, entities)
)

const sectionFieldChanged = (
  state,
  { payload: { sectionUUID, name, value } }
) => updateEntity(
  state,
  sectionUUID,
  {
    data: { ...state[sectionUUID].data, [name]: value },
    [name]: value,
    changed: true
  }
)

export default createReducer({}, {
  [INIT]: init,
  [RESET]: reset,
  [ITEM_ADD]: itemAdd,
  [ENTITIES_ADD]: entitiesAdd,
  [ITEM_AUTOCOMPLETE_CHOOSE]: itemAutocompleteChoose,
  [ITEM_SEARCH_CHOOSE]: itemSearchChoose,
  [SECTION_REMOVE]: sectionRemove,
  [PAPERWORK_REMOVE]: paperworkRemove,
  [PAPERWORK_DUPLICATE_SUCCESS]: paperworkDuplicateSuccess,
  [PAPERWORK_FETCH_SUCCESS]: paperworkFetchSuccess,
  [HEADING_ADD]: headingAdd,
  [SECTION_FORM_CHANGED]: sectionFormChanged,
  [SECTION_FIELD_CHANGED]: sectionFieldChanged
})
