import { Controller } from 'stimulus'

export default class ToggleSaveButtonController extends Controller {
  static targets = ['saveButton']

  toggle (event) {
    const { defaultValue: initialValue, value: newValue } = event.target

    if (newValue !== initialValue) {
      this.saveButtonTarget.classList.remove('hidden')
    } else {
      this.saveButtonTarget.classList.add('hidden')
    }
  }
}
