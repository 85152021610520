/* eslint consistent-return: 0, react/no-array-index-key: 0, default-case: 0 */
/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import SaveButton from './save-button'

import CheckboxInput from './checkbox-input'
import NumberInput from './number-input' // Not currently being used
import RadioInput from './radio-input'
import SelectInput from './select-input'
import TextInput from './text-input'

import RemoveInputButton from './remove-input-button'
import SaveStatusConfirmation from './save-status-confirmation'
import ErrorMessages from './error-messages'

const Field = createReactClass({
  displayName: 'Field',
  propTypes: {
    autosave: PropTypes.bool,
    autofocus: PropTypes.bool,
    componentId: PropTypes.number,
    inputId: PropTypes.string,
    initialValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    placeholder: PropTypes.string,
    attribute: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    classes: PropTypes.string,
    helpers: PropTypes.arrayOf(PropTypes.string),
    noCreate: PropTypes.bool,
    noRemove: PropTypes.bool,
    fieldWrapperClass: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any),
    field: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  },
  getDefaultProps () {
    return {
      initialValue: '',
      noCreate: false,
      errors: []
    }
  },
  getInitialState () {
    const { errors, initialValue } = this.props
    return {
      errors,
      value: initialValue,
      status: 'inert'
    }
  },
  fieldWrapperClasses () {
    const { errors, fieldWrapperClass } = this.props
    let classes = (fieldWrapperClass || '').split(' ')
    if (errors.length > 0) {
      classes = classes.concat('field_with_errors')
    }
    return classes.join(' ').replace(/^ *| *$/g, '')
  },
  classes () {
    let { classes } = this.props
    classes = (classes || '').split(' ')
    return classes.join(' ').replace(/^ *| *$/g, '')
  },
  style () {
    return {}
  },
  field () {
    const { field, onEdit, options, type } = this.props
    const { status, value } = this.state
    if (options != null) {
      if (field === 'radio') {
        return React.createElement(RadioInput, {
          ...this.props,
          onEdit: onEdit(this),
          value,
          status
        })
      }
      return React.createElement(SelectInput, { ...this.props, onEdit: onEdit(this) })
    }
    switch (type) {
      case 'string':
      case 'richtext':
      case 'text':
        return React.createElement(TextInput, { ...this.props, onEdit: onEdit(this) })
      case 'bool':
        return React.createElement(CheckboxInput, {
          ...this.props,
          onEdit: onEdit(this),
          status
        })
      case 'int':
      case 'float':
        return React.createElement(NumberInput, { ...this.props, onEdit: onEdit(this) })
    }
  },
  wrappedField () {
    return React.createElement('span', {
      className: this.fieldWrapperClasses()
    }, this.field())
  },
  errorMessages () {
    const { errors } = this.state
    return React.createElement(ErrorMessages, {
      errors
    })
  },
  saveStatus () {
    const { autosave } = this.props
    if (autosave) {
      return this.saveStatusConfirmation()
    }
    return this.saveButton()
  },
  saveStatusInline () {
    const { autosave, field, options } = this.props
    if (field === 'radio') {
      return true
    }
    return autosave && (options == null)
  },
  saveButton () {
    const { saver, status } = this.state
    if (saver) {
      return React.createElement(SaveButton, {
        status,
        onClick: saver
      })
    }
  },
  saveStatusConfirmation () {
    const { field, type } = this.props
    const { status } = this.state
    if (!(field === 'radio' || type === 'bool')) {
      return React.createElement(SaveStatusConfirmation, {
        inline: this.saveStatusInline(),
        status
      })
    }
  },
  removeInputButton () {
    const { onRemove, noCreate, noRemove } = this.props
    if (onRemove && !noCreate && !noRemove) {
      return React.createElement(RemoveInputButton, {
        remove: onRemove(this)
      })
    }
  },
  helpers () {
    const { helpers } = this.props
    return (
      helpers.map((helper, i) => (
        <span className='u-block frm__input-helper' key={`helper-${i}`}>
          {helper}
        </span>
      ))
    )
  },
  render () {
    return (
      <div style={this.style()} className={this.classes()}>
        {this.wrappedField()}
        {this.removeInputButton()}
        {this.saveStatus()}
        {this.errorMessages()}
        {this.helpers()}
      </div>
    )
  }
})

export default Field
