import moment from 'moment'
import cloneDeepWith from 'lodash/cloneDeepWith'

export function createReducer (initialState, handlers) {
  return function reducer (state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    }
    return state
  }
}

export function updateEntity (state, uuid, object) {
  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      ...object,
      uuid,
      updatedAt: moment.utc().toISOString()
    }
  }
}

export function updateEntities (state, entities) {
  if (!entities) { return state }
  return Object.values(entities).reduce((newEntities, entity) => (
    updateEntity(newEntities, entity.uuid, entity)
  ), { ...state })
}

export function removeObjectKey (object, key) {
  const newObject = { ...object }
  delete newObject[key]
  return newObject
}

export function removeStaleEntities (localEntities, remoteEntities) {
  const freshEntities = { ...remoteEntities }

  Object.keys(freshEntities).forEach(uuid => {
    const remoteItem = freshEntities[uuid]
    const localItem = localEntities[uuid]
    if (!localItem) { return }
    if (remoteItem.overwriteLocal) { return }

    const { updatedAt: localUpdatedAt } = localItem
    const { updatedAt: remoteUpdatedAt } = remoteItem
    // remove entity if our local one has been updated more recently
    if (remoteUpdatedAt === null || moment(remoteUpdatedAt).isBefore(moment(localUpdatedAt))) {
      delete freshEntities[uuid]
    }
  })

  return freshEntities
}

export function removeEntitiesByProperty (items, property, value) {
  const newItems = { ...items }

  Object.keys(newItems).forEach(uuid => {
    if (newItems[uuid][property] === value) {
      delete newItems[uuid]
    }
  })

  return newItems
}

export function updateEntitiesByProperty (items, property, value, properties) {
  const newItems = { ...items }

  Object.keys(newItems).forEach(uuid => {
    if (newItems[uuid][property] === value) {
      newItems[uuid] = { ...items[uuid], ...properties }
    }
  })

  return newItems
}

export function deepSetKeys (object, newKeyValues) {
  return cloneDeepWith(object, (_, key) => {
    let result
    Object.entries(newKeyValues).forEach(([newKey, newValue]) => {
      if (key === newKey) { result = newValue }
    })
    return result
  })
}
