import React from 'react'
import PropTypes from 'prop-types'

import Preview from './preview'
import UploadDetails from './upload-details'
import Options from './options'

const Thumbnail = ({ file, endpoint, companyHasFieldStaff, onDelete }) => (
  <li className='file-thumb'>
    <Preview
      name={file.name}
      url={file.url}
      thumbnailUrl={file.thumbnailUrl}
      previewType={file.type}
      iconClass={file.iconClass}
    />
    <UploadDetails
      name={file.createdBy}
      date={file.createdAtPretty}
      size={file.sizePretty}
      companyHasFieldStaff={companyHasFieldStaff}
    />
    <Options
      isShared={file.shared}
      fileId={file.id}
      endpoint={endpoint}
      companyHasFieldStaff={companyHasFieldStaff}
      onDelete={onDelete}
    />
  </li>
)

Thumbnail.defaultProps = {
  companyHasFieldStaff: false
}

Thumbnail.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    createdBy: PropTypes.string.isRequired,
    createdAtPretty: PropTypes.string.isRequired,
    shared: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired,
    sizePretty: PropTypes.string.isRequired

  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  companyHasFieldStaff: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
}

export default Thumbnail
