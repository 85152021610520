import React, { useCallback, useState, useContext, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import AddSvg from '@/components/svg/add'

import { PaperworkContext } from '../../contexts'
import { paperworkDiscountChanged, paperworkDiscountRemove } from '../../actions'
import DiscountInput from './input'
import { getPaperworkDiscountPercentages } from '../../selectors/paperwork'

const Discount = () => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)
  const itemDiscounts = useSelector(state => getPaperworkDiscountPercentages(state, paperworkUUID))
  const anyItemsHaveDiscount = itemDiscounts.filter(discount => discount > 0).length > 0
  const [showInput, setShowInput] = useState(anyItemsHaveDiscount)

  const onFieldChange = useCallback(debounce(value => {
    dispatch(
      paperworkDiscountChanged(
        paperworkUUID,
        value
      )
    )
  }, 100), [paperworkUUID])

  const onRemove = useCallback(() => {
    dispatch(paperworkDiscountRemove(paperworkUUID))
    setShowInput(false)
  }, [paperworkUUID])

  return (
    <>
      {showInput
        ? (
          <DiscountInput onChange={onFieldChange} onRemove={onRemove} />
          )
        : (
          <a
            role='button'
            onClick={() => setShowInput(true)}
            className='btn__add'
            data-test='add-discount'
            tabIndex='0'
          >
            <AddSvg className='icon--add icon--sml u-mrgn-r' />
            Add discount
          </a>
          )}
    </>
  )
}

export default Discount
