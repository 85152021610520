import React from 'react'
import { createRoot } from 'react-dom/client'

import apps from './apps'

export function renderComponent (container, path, props) {
  const App = apps[`${path}`]

  if (!App) {
    throw new Error(`React component at ${path} could not be found.`)
  }

  const root = createRoot(container)

  root.render(<App {...props} />)

  return root
}
