import React from 'react'
import PropTypes from 'prop-types'

import SearchResult from './search-result'
import KeyboardSelectableItems from '../keyboard-selectable-items'

const SearchResults = ({ items, onResultClick }) => {
  if (items.length === 0) { return null }

  return (
    <div
      data-test='item-search-results'
      className='pwrk-frm__item-search'
    >
      <KeyboardSelectableItems
        key={items.map(item => item.uuid).join('')}
        items={items}
        onChoose={onResultClick}
        itemComponent={SearchResult}
      />
    </div>
  )
}

SearchResults.propTypes = {
  items: PropTypes.array.isRequired,
  onResultClick: PropTypes.func.isRequired
}

export default SearchResults
