import React, { Fragment, useContext } from 'react'

import { PaperworkContext } from '../contexts'
import Errors from './errors'
import Sections from './sections'
import TaxTotals from './tax-totals'
import SubTotal from './sub-total'
import GrandTotal from './grand-total'
import SaveBar from './save-bar'

export default function Job () {
  const { paperworkUUID } = useContext(PaperworkContext)
  return (
    <>
      <Errors uuid={paperworkUUID} />
      <Sections
        allowAddSections={false}
      />
      <div className='pwrk-frm__totals'>
        <div className='pwrk-frm__block'>
          <SubTotal />
        </div>
        <div className='pwrk-frm__block'>
          <TaxTotals />
        </div>
        <div className='pwrk-frm__block'>
          <GrandTotal />
        </div>
      </div>
      <SaveBar
        saveText='Save to work scope'
        submittingText='Saving to work scope...'
      />
    </>
  )
}
