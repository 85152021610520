const select = assignee => {
  assignee.classList.add('active')
  assignee.querySelector('[data-checkbox]').checked = true
  assignee.querySelector('[data-destroy]').value = false
}

const deselect = assignee => {
  assignee.classList.remove('active')
  assignee.querySelector('[data-checkbox]').checked = false
  assignee.querySelector('[data-destroy]').value = true
}

const toggleAssignee = event => {
  event.preventDefault()
  const assignee = event.target.closest('[data-assignee]')
  if (assignee.classList.contains('active')) {
    deselect(assignee)
  } else {
    select(assignee)
  }
}

const toggleAllAssignees = event => {
  event.preventDefault()
  const toggle = event.target
  if (toggle.getAttribute('data-toggle-assignees') === 'select-all') {
    Array.from(document.querySelectorAll('[data-assignee]')).forEach(assignee => {
      select(assignee)
    })
    toggle.setAttribute('data-toggle-assignees', 'deselect-all')
    toggle.innerHTML = 'De-select all'
  } else {
    Array.from(document.querySelectorAll('[data-assignee]')).forEach(assignee => {
      deselect(assignee)
    })
    toggle.setAttribute('data-toggle-assignees', 'select-all')
    toggle.innerHTML = 'Select all'
  }
}

document.addEventListener('app-connect', () => {
  // Toggle assignee
  Array.from(document.querySelectorAll('[data-assignee]')).forEach(link => {
    link.addEventListener('click', toggleAssignee, false)
  })
  // select/de-select all
  document.querySelector('[data-toggle-assignees]')?.addEventListener('click', toggleAllAssignees, false)
})
