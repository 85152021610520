import { Controller } from 'stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'

class TooltipController extends Controller {
  static values = {
    content: String,
    delay: { type: Array, default: [50, 0] }, // [show, hide]
    placement: { type: String, default: 'bottom' }
  }

  connect () {
    this.tippy = tippy(this.element, {
      content: this.contentValue,
      delay: [this.showDelayValue, this.hideDelayValue],
      placement: this.placementValue,
      theme: 'ytb',
      maxWidth: 400
    })
  }

  disconnect () {
    this.tippy.destroy()
  }
}

function addToDataTooltipElement (element) {
  const controllers = (element.dataset.controller || '').split(' ')
  element.dataset.controller = compact(uniq([...controllers, 'tooltip'])).join(' ')
  element.dataset.tooltipContentValue = element.dataset.tooltip
  element.dataset.tooltipInStimulus = true
  delete element.dataset.tooltip
}

function removeFromDataTooltipElement (element) {
  const controllers = (element.dataset.controller || '').split(' ')
  element.dataset.controller = controllers.filter(controller => controller !== 'tooltip').join(' ')
  delete element.dataset.tooltipContentValue
  delete element.dataset.tooltipInStimulus
}

function addToDataTooltip () {
  document.querySelectorAll('[data-tooltip]').forEach(addToDataTooltipElement)
}

function removeFromDataTooltip () {
  document.removeEventListener('app-connect', addToDataTooltip)
  document.querySelectorAll('[data-tooltip-in-stimulus]').forEach(removeFromDataTooltipElement)
}

document.addEventListener('app-connect', addToDataTooltip)
document.addEventListener('app-disconnect', removeFromDataTooltip)

export default TooltipController
