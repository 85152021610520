import { all, fork } from 'redux-saga/effects'

import EmailTemplates from './email-templates'
import Content from './content'

export default function * rootSaga () {
  yield all([
    fork(EmailTemplates),
    fork(Content)
  ])
}
