const animateFlashMessage = () => {
  const flashMessage = document.querySelector('.action_message')

  if (flashMessage) {
    flashMessage.classList.remove('action_message--closed')
    setTimeout(() => flashMessage.classList.add('action_message--closed'), 5000)
  }
}

function hideFlashMessage (message) {
  const flashMessage = message || document.querySelector('.action_message')

  if (flashMessage) {
    flashMessage.remove()
  }
}

function initializeCloseButton () {
  const flashCloseBtn = document.querySelector('#action-msg .close')

  if (flashCloseBtn) {
    flashCloseBtn.addEventListener('click', event => {
      event.preventDefault()
      const flashMessage = document.querySelector('.action_message')
      hideFlashMessage(flashMessage)
    })
  }
}

export default function createFlashMessage (message, cssClass) {
  const alertClass = cssClass || 'notice'
  const flashMessage = document.createElement('div')

  flashMessage.classList.add('action_message', 'action_message--closed', `${alertClass}`)
  flashMessage.id = 'action-msg'
  flashMessage.setAttribute('data-test', `flash-${alertClass}`)
  flashMessage.innerHTML = `<a href="#" class="close">OK</a><p>${message}</p>`

  hideFlashMessage()
  document.querySelector('.nav').insertAdjacentElement('beforebegin', flashMessage)
  initializeCloseButton()
  setTimeout(() => animateFlashMessage(), 1)
}

window.addEventListener('app-connect', () => {
  initializeCloseButton()
  animateFlashMessage()
})
