import React from 'react'
import KeyboardDownSvg from '@/components/svg/keyboard-down'
import KeyboardUpSvg from '@/components/svg/keyboard-up'
import KeyboardReturnSvg from '@/components/svg/keyboard-return'

const KeyboardHints = () => (
  <div className='u-visible-lrg-scrn-only t--sml c--metal'>
    Use
    <KeyboardDownSvg className='icon icon--tiny icon--metal u-mrgn-l' />
    <KeyboardUpSvg className='icon icon--tiny icon--metal u-mrgn-l' />
    <KeyboardReturnSvg className='icon icon--tiny icon--metal u-mrgn-lr' />
    to select via keyboard
  </div>
)

export default KeyboardHints
