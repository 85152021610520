import { Controller } from 'stimulus'

export default class RadioSelectController extends Controller {
  static targets = ['radio', 'item']

  connect () {
    document.addEventListener('radio-selection-toggle', this.handleSelection.bind(this))
  }

  select (event) {
    const selectionEvent = new CustomEvent('radio-selection-toggle', {
      detail: { name: event.target.name, value: event.target.value }
    })
    document.dispatchEvent(selectionEvent)

    this.radioTarget.checked = true
  }

  selectAllItems () {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(item => { item.checked = true })
    }
  }

  handleSelection () {
    if (this.radioTarget.checked === false) {
      this.deselectAllItems()
    }
  }

  deselectAllItems () {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(item => { item.checked = false })
    }
  }
}
