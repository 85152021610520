import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'

export default class AutoSubmitController extends Controller {
  static targets = ['form', 'formElement', 'loadingIndicator']

  initialize () {
    this.submit = debounce(this.submit, 500).bind(this)
  }

  submit () {
    const form = this.hasFormTarget ? this.formTarget : this.element

    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.remove('hidden')
    }

    form.requestSubmit()
    this.formElementTargets.forEach(element => { element.disabled = true })
  }
}
