import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import ColorSelector from './color-selector'
import errorClass from '../../utils/error-class'
import ErrorMessage from '../error-message'

const Initials = ({ handleInitialsChange }) => {
  const { errors, register } = useFormContext()

  const object = 'company_user'
  const field = 'initials'
  const name = `${object}[${field}]`

  return (
    <div className='frm__block'>
      <label className='frm__label' htmlFor='user_initials'>Initials</label>
      <div className='frm__input-primer'>When assigned to jobs or events, we&apos;ll identify this team member with these initials</div>
      <input
        maxLength='3'
        autoComplete='off'
        placeholder='AA'
        className={`frm__input frm__input--inline${errorClass({ errors, object, field })}`}
        style={{ width: '65px', maxWidth: '65px' }}
        size='3'
        type='text'
        name={name}
        id='user_initials'
        onChange={handleInitialsChange}
        ref={register({ required: true })}
        data-test='initials'
      />
      <ColorSelector />
      <ErrorMessage object={object} field={field} />
    </div>
  )
}

Initials.propTypes = {
  handleInitialsChange: PropTypes.func
}

Initials.defaultProps = {
  handleInitialsChange: () => {}
}

export default Initials
