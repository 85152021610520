import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { reset } from './actions'
import Store from './components/store'

const Reset = ({ id }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(reset(id))
  }, [])

  return null
}

Reset.propTypes = {
  id: PropTypes.number.isRequired
}

export default props => (
  <Store>
    <Reset {...props} />
  </Store>
)
