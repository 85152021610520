import { connect } from 'react-redux'

import EventActions from '../components/event_actions'

export const mapStateToProps = (
  { schedule: { urlFormats } },
  { jobId, eventId, type }
) => {
  const {
    isFieldStaff,
    viewJobPathTemplate,
    viewJobSheetPathTemplate,
    printJobSheetPathTemplate,
    sendConfirmationEmailPathTemplate,
    editJobAppointmentPathTemplate,
    editJobWorkDatePathTemplate,
    editJobGeneralEventPathTemplate,
    editGeneralEventPathTemplate
  } = urlFormats

  const isLinkedToJob = !!jobId
  const viewJobPath = ((isFieldStaff) ? viewJobSheetPathTemplate : viewJobPathTemplate).replace(':job_id', jobId)
  const viewJobSheetPath = viewJobSheetPathTemplate.replace(':job_id', jobId)
  const printJobSheetPath = printJobSheetPathTemplate.replace(':job_id', jobId)
  const sendConfirmationPath = sendConfirmationEmailPathTemplate.replace(':event_id', eventId)
  const canSendConfirmationEmail = isLinkedToJob && !isFieldStaff

  let editEventPath = {
    GeneralEvent: editJobGeneralEventPathTemplate,
    Appointment: editJobAppointmentPathTemplate,
    WorkDate: editJobWorkDatePathTemplate
  }

  editEventPath = editEventPath[type]

  if (!isLinkedToJob && type === 'GeneralEvent') {
    editEventPath = editGeneralEventPathTemplate
  }

  editEventPath = editEventPath.replace(':job_id', jobId).replace(':event_id', eventId)

  return {
    isLinkedToJob,
    isFieldStaff,
    viewJobPath,
    viewJobSheetPath,
    printJobSheetPath,
    sendConfirmationPath,
    editEventPath,
    canSendConfirmationEmail
  }
}

export default connect(mapStateToProps)(EventActions)
