import App from '@/application/app'
import analytics from '@/application/analytics'
import { Controller } from 'stimulus'

export default class IntercomController extends Controller {
  disconnect () {
    analytics.plugins.intercom.hide()
  }

  open (event) {
    if (App.intercomAppId === '') {
      // fall back to link if intercom is not enabled
      return
    }

    event.preventDefault()
    analytics.plugins.intercom.show()
  }
}
