import React from 'react'

const AutomationSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M24 19.5a4.5 4.5 0 0 0-4.5-4.5 4.28 4.28 0 0 0-.875.1.249.249 0 0 1-.275-.133L14.934 8.13a.249.249 0 0 1 .056-.3 4.5 4.5 0 1 0-5.98 0 .252.252 0 0 1 .057.3l-3.418 6.834a.248.248 0 0 1-.275.132A4.263 4.263 0 0 0 4.5 15a4.5 4.5 0 1 0 4.333 5.684.249.249 0 0 1 .241-.184h5.852a.25.25 0 0 1 .241.184A4.5 4.5 0 0 0 24 19.5zm-8.833-1.184a.249.249 0 0 1-.241.184H9.074a.25.25 0 0 1-.241-.184 4.482 4.482 0 0 0-1.343-2.15.249.249 0 0 1-.056-.3l3.417-6.834a.249.249 0 0 1 .275-.133 3.951 3.951 0 0 0 1.749 0 .252.252 0 0 1 .275.133l3.416 6.834a.249.249 0 0 1-.056.3 4.482 4.482 0 0 0-1.343 2.149z' />
    </svg>
  </span>
)

export default AutomationSvg
