import { Uppy } from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import axios from '@ytb/axios'

const uploader = ({
  fileType,
  onUploadEnd,
  endpoint,
  params = {},
  restrictions = {},
  handleError = () => {},
  method = 'post',
  autoProceed = true,
  allowMultipleUploads = true,
  onUploadStart = () => {}
}) => {
  const uppy = new Uppy({
    autoProceed,
    allowMultipleUploadBatches: allowMultipleUploads,
    debug: false,
    restrictions,
    locale: {
      strings: {
        youCanOnlyUploadFileTypes: 'You can only upload images.'
      }
    }
  })

  uppy.use(AwsS3Multipart, {
    companionUrl: '/'
  })

  uppy.on('upload', () => {
    onUploadStart()
  })
  uppy.on('upload-success', file => {
    const fileData = JSON.stringify({
      id: file.s3Multipart.key.match(/^uploads\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type
      }
    })

    axios({
      method,
      url: endpoint,
      data: {
        [fileType]: fileData,
        ...params
      }
    }).then(response => onUploadEnd(response.data.file))
  })

  uppy.on('restriction-failed', (file, error) => {
    handleError(error.message)
  })

  return uppy
}

export default uploader
