import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Announcement from '../announcement'
import AnnouncementButtons from '../announcement/buttons'

class FailedToReactivateMessage extends Component {
  handleReactivate (event) {
    event.preventDefault()
  }

  render () {
    const { email, updatePath } = this.props
    return (
      <Announcement type='red'>
        We are having problems sending emails to
        {' '}
        <strong>{email}</strong>

        <AnnouncementButtons>
          <a
            href={updatePath}
            className='btn btn--sml btn--clear c--white'
            style={{ borderColor: 'rgba(255,255,255,0.6)' }}
          >
            Update your email
          </a>
        </AnnouncementButtons>
      </Announcement>
    )
  }
}

FailedToReactivateMessage.propTypes = {
  email: PropTypes.string.isRequired,
  updatePath: PropTypes.string.isRequired
}

export default FailedToReactivateMessage
