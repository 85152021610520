import { FETCH_EVENTS_SUCCESS, DELETE_EVENT_SUCCESS, CHANGE_EVENT_COLOR_SUCCESS } from '../types'

const events = (state = [], action) => {
  switch (action.type) {
    case FETCH_EVENTS_SUCCESS:
      return [...action.payload.data]
    case DELETE_EVENT_SUCCESS:
      return state.filter(event => event.id !== action.payload.id)
    case CHANGE_EVENT_COLOR_SUCCESS:
      return state.map(event => {
        if (event.id === action.payload.id) {
          return { ...event, selectedColor: action.payload.color }
        }
        return event
      })
    default:
      return state
  }
}

export default events
