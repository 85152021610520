/* globals Element */

import React, { Fragment, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { camelizeKeys } from 'humps'
import OutsideClickHandler from 'react-outside-click-handler'

import { LabourItemContext } from '../../contexts'
import { labourAutocomplete } from '../../services'
import Field from '../final-form-field'
import SearchResults from './search-results'

const DescriptionField = ({ onResultClick, t, autoFocus, domReference }) => {
  const { labourItemUUID } = useContext(LabourItemContext)
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)

  const search = value => {
    if (value.length < 1) { setResults([]); return }
    labourAutocomplete(value)
      .then(response => {
        setResults(camelizeKeys(response.data.items))
        setShowResults(true)
      })
  }

  const debouncedSearch = useCallback(debounce(search, 300), [labourItemUUID])

  return (
    <>
      <Field
        name='description'
        testKey='description'
        placeholder={`Enter ${t('labour')} description`}
        className='m-table__input m-table__input--title'
        onChange={event => debouncedSearch(event.target.value)}
        autoFocus={autoFocus}
        domReference={domReference}
      />
      <OutsideClickHandler onOutsideClick={() => setShowResults(false)}>
        {showResults &&
          (
            <SearchResults
              items={results}
              onResultClick={item => { onResultClick(item); setShowResults(false) }}
            />
          )}
      </OutsideClickHandler>
    </>
  )
}

DescriptionField.defaultProps = {
  autoFocus: false,
  domReference: () => {}
}

DescriptionField.propTypes = {
  onResultClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  domReference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

export default DescriptionField
