import $ from '@ytb/cash-dom'

document.addEventListener('app-connect', () => {
  $(document).on('change', 'form#bulk_materials input, form#bulk_materials select', event => {
    $(event.target).closest('tr').find("input[name*='update']").prop('checked', true)
  })

  $(document).on('change', 'form#bulk_materials input, form#bulk_materials select', event => {
    $(event.target).closest('tr').find("input[name*='update']").prop('checked', true)
  })

  $(document).on('click', 'form#bulk_materials #select_all', event => {
    if (event.target.checked) {
      $('tr.filters .options').removeClass('hidden')
      $("input[name*='bulk_edit']").each((i, elm) => {
        $(elm).prop('checked', true)
      })
    } else {
      $('tr.filters .options').addClass('hidden')
      $("input[name*='bulk_edit']").each((i, elm) => {
        $(elm).prop('checked', false)
      })
    }
  })

  $(document).on('click', 'form#bulk_materials input[name*="bulk_edit"]', event => {
    if (event.target.checked) {
      $('tr.filters .options').removeClass('hidden')
    } else if ($('form#bulk_materials input:checked[name*="bulk_edit"]').length === 0) {
      // is this the last unchecked checkbox?
      $('tr.filters .options').addClass('hidden')
    }
  })

  $(document).on('change', 'form#bulk_materials select#options-select', event => {
    const option = $(event.target).val()
    if (option === 'markup') {
      $('tr.filters .markup-input').show()
      $('tr.filters .percent-input').hide()
      $('tr.filters .price-input').hide()
    } else if (option === 'price') {
      $('tr.filters .price-input').show()
      $('tr.filters .percent-input').hide()
      $('tr.filters .markup-input').hide()
    } else if (option === 'percent') {
      $('tr.filters .percent-input').show()
      $('tr.filters .price-input').hide()
      $('tr.filters .markup-input').hide()
    } else if (option === 'delete') {
      if (window.confirm('Are you sure you want to delete these items?')) { // eslint-disable-line no-restricted-globals
        window.alert("Materials that you have checked will be deleted when you click 'Save changes'")
        $('tr.filters .markup-input').hide()
        $('tr.filters .price-input').hide()
      } else {
        $(event.target).val('')
        $('tr.filters .markup-input').hide()
        $('tr.filters .price-input').hide()
        $('tr.filters .percent-input').hide()
      }
    } else {
      $('tr.filters .markup-input').hide()
      $('tr.filters .price-input').hide()
      $('tr.filters .percent-input').hide()
    }
  })
})
