const toggleRadioActive = event => {
  const container = event.target.closest('.frm__radio-block')
  Array.from(container.querySelectorAll('[data-toggle-radio-button]')).forEach(element => {
    element.classList.remove('active')
  })
  event.target.parentNode.classList.add('active')
  event.target.closest('[data-toggle-radio-button]').classList.add('active')
}

document.addEventListener('app-connect', () => {
  Array.from(document.querySelectorAll('[data-toggle-radio-button]')).forEach(element => {
    element.addEventListener('click', toggleRadioActive, false)
  })
})
