import { Controller } from 'stimulus'

// Compliments turbo-frame lazy loading by only showing
// the frame after a delay. This is useful for progress bars etc.
export default class RevealController extends Controller {
  static values = { delay: { type: Number, default: 500 } }

  static classes = ['hidden']

  connect () {
    setTimeout(() => this.toggle(), this.delayValue)
  }

  toggle () {
    this.element.classList.toggle(this.hiddenClass)
  }
}
