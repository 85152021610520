import React from 'react'
import PropTypes from 'prop-types'

import DayWithEvents from './day-with-events'
import DayWithoutEvents from './day-without-events'

const Day = props => {
  const { events } = props

  if (events.length > 0) {
    return (<DayWithEvents {...props} />)
  }
  return (<DayWithoutEvents {...props} />)
}

Day.defaultProps = {
  events: []
}

Day.propTypes = {
  date: PropTypes.string.isRequired,
  events: PropTypes.array
}

export default Day
