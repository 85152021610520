import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { init, routeChanged, deleteEvent, changeEventColor } from '../actions'
import { buildUrls } from '../helpers/url-builders'
import buildTypes from '../helpers/build-types'
import buildAssignees from '../helpers/build-assignees'
import { createDays, addEventsToDays } from '../helpers/days'
import { formatDateRange } from '../helpers/date-range'
import Schedule from '../components/schedule'

class ScheduleContainer extends Component {
  componentDidMount () {
    const { onInit, match: { params }, ...props } = this.props
    onInit({ ...props, ...params })
  }

  componentDidUpdate (prevProps) {
    const { match: { params }, onRouteChange } = this.props
    const { match: { params: oldParams } } = prevProps

    if (params === oldParams) { return }

    onRouteChange({ ...this.props, ...params })
  }

  render () {
    return (
      <Schedule {...this.props} />
    )
  }
}

ScheduleContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      routerStartDate: PropTypes.string,
      routerDateMode: PropTypes.string,
      routerType: PropTypes.string,
      routerAssignee: PropTypes.string
    })
  }).isRequired,
  onRouteChange: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired
}

const todayWithinDays = days => {
  if (days.length > 0) {
    if (days.length > 1) {
      return moment().isBetween(days[0].date, days[days.length - 1].date, '[]')
    }
    return moment().isSame(days[0].date, 'day')
  }

  return false
}

export const mapStateToProps = (
  { schedule: { display, events, companyUsers } },
  {
    showEmptyDays,
    dateMode: initialDateMode,
    type: initialType,
    assignee: initialAssignee,
    ...otherProps
  }
) => {
  const {
    start,
    end,
    dateMode: displayDateMode,
    type: displayType,
    assignee: displayAssignee
  } = display
  const dateMode = (displayDateMode || initialDateMode)
  const type = (displayType || initialType)
  const assignee = (displayAssignee || initialAssignee)
  const urlParts = { dateMode, start, type, assignee }
  const types = buildTypes(urlParts)
  const assignees = buildAssignees(companyUsers, urlParts)

  let days = []
  let isToday = false
  let title
  let urls

  if (moment(start).isValid() && moment(end).isValid()) {
    days = addEventsToDays(events, createDays(start, end))
    urls = buildUrls(urlParts)
    isToday = todayWithinDays(days)

    if (!showEmptyDays) {
      days = days.filter(day => day.events.length > 0)
    }

    if (days.length > 0) {
      title = formatDateRange(days[0].date, days[days.length - 1].date)
    }
  }

  return {
    days,
    isToday,
    title,
    dateMode,
    types,
    assignees,
    companyUsers,
    ...urls,
    ...otherProps
  }
}

const mapDispatchToProps = dispatch => ({
  onEventDelete (id) {
    dispatch(deleteEvent(id))
  },
  onEventColorChange (id, color) {
    dispatch(changeEventColor(id, color))
  },
  onRouteChange (props) {
    dispatch(routeChanged(props))
  },
  onInit (props) {
    dispatch(init(props))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer))
