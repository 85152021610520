import React from 'react'
import PropTypes from 'prop-types'

import truncatedFileName from '../../helpers/truncated-file-name'

const Preview = ({ url, name, thumbnailUrl, previewType, iconClass, handleOnClick }) => (
  <a href={url} download className='link'>
    <div className='preview' onClick={handleOnClick} role='button' tabIndex={0}>
      {previewType === 'icon'
        ? <span className={`file-thumb__icon file-thumb__icon-${iconClass}`} />
        : <img alt={name} src={thumbnailUrl} srcSet={`${thumbnailUrl} 1x`} />}
    </div>
    {url &&
      (
        <p className='file-thumb__name'>
          {url && truncatedFileName(name)}
        </p>
      )}
  </a>
)

Preview.defaultProps = {
  url: null,
  thumbnailUrl: '',
  iconClass: 'image',
  previewType: 'image',
  handleOnClick: () => {}
}

Preview.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
  previewType: PropTypes.string,
  iconClass: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  handleOnClick: PropTypes.func
}

export default Preview
