import React from 'react'
import PropTypes from 'prop-types'

import allowedFileTypes from '../helpers/allowed-file-types-list'

const FileInputButton = ({ buttonClass, handleUpload, disabled, children }) => (
  <div>
    <input
      disabled={disabled}
      type='file'
      multiple
      onChange={handleUpload}
      id='file'
      className='btn__add upload-btn'
      data-test='file-uploader'
      accept={allowedFileTypes}
    />
    <label htmlFor='file' className={buttonClass} data-test='upload-btn'>
      {children}
    </label>
  </div>
)

FileInputButton.defaultProps = {
  handleUpload: () => {},
  disabled: false
}

FileInputButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleUpload: PropTypes.func,
  disabled: PropTypes.bool
}

export default FileInputButton
