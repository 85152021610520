import React from 'react'
import PropTypes from 'prop-types'

import KeyboardSelectableItems from '../keyboard-selectable-items'
import Result from './result'

const Results = ({ onResultClick, items, ...otherProps }) => (
  <ul className='c-card__list price-list-search'>
    <KeyboardSelectableItems
      key={items.map(item => item.uuid).join('')}
      items={items}
      onChoose={onResultClick}
      itemComponent={Result}
      {...otherProps}
    />
  </ul>
)

Results.propTypes = {
  onResultClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}

export default Results
