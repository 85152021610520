import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SubmittingMessage extends Component {
  render () {
    const { email } = this.props
    return (
      <span className='frm__error-msg'>
        We cannot send email to
        {' '}
        {email}
        {' '}
        <span className='t--no-bold'>
          - previous messages have been undeliverable.
        </span>
        &nbsp; re-activating...
      </span>
    )
  }
}

SubmittingMessage.propTypes = {
  email: PropTypes.string.isRequired
}

export default SubmittingMessage
