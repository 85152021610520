/* eslint-disable react/jsx-handler-names */

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from '../modules/react-final-form'

const Checkbox = ({ name, label, validateFields, testKey, ...props }) => (
  <Field
    {...props}
    name={name}
    validateFields={validateFields}
    type='checkbox'
  >
    {({ input }) => (
      <span data-test={`${testKey}-field`}>
        <input
          {...props}
          type='checkbox'
          id={name}
          checked={input.checked}
          onChange={input.onChange}
          className='frm__checkbox'
        />
        <label htmlFor={name} className='pwrk-frm__label'>{label}</label>
      </span>
    )}
  </Field>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  testKey: PropTypes.string.isRequired,
  validateFields: PropTypes.array
}

Checkbox.defaultProps = {
  label: '',
  validateFields: []
}

export default Checkbox
