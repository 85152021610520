import { Controller } from 'stimulus'
import axios from '@ytb/axios'

import createFlashMessage from '@/application/flashes'

export default class TasksController extends Controller {
  complete (event) {
    this.updateTaskState(event, 'complete', event.target.dataset.completeUrl)
  }

  undo (event) {
    this.updateTaskState(event, 'undo', event.target.dataset.undoUrl)
  }

  // Private

  updateTaskState (event, status, url) {
    const trigger = event.target

    trigger.disabled = true

    axios.put(url).then(() => {
      createFlashMessage((status === 'undo') ? 'Task marked as incomplete' : 'Task marked as complete')
      trigger.closest('.task').classList.add('hidden')
    }).catch(() => {
      createFlashMessage('An error has occurred, please refresh and try again.', 'alert')
      trigger.disabled = false

      if (status === 'complete') {
        trigger.checked = false
      }
    })
  }
}
