import React from 'react'

const EmptyMaterials = () => (
  <section className='blank-state'>

    <h2 className='blank-state__title'>
      No materials in your list
    </h2>
    <p className='blank-state__desc'>
      Visit
      {' '}
      <a href='/materials' target='_blank' className='link'>
        <strong>Settings &gt; Materials</strong>
      </a>
      {' '}
      to add materials to your material list, then easily select those materials when costing work 👍
    </p>

  </section>
)

export default EmptyMaterials
