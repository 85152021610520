import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import stripHtml from 'string-strip-html'
import truncate from 'lodash/truncate'

import Money from '../money'

const SearchResult = ({ item, onResultClick }) => {
  const ref = useRef(null)
  useEffect(() => {
    if (item.selected) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [item])

  return (
    <div ref={ref} className='ui-menu-item' style={{ listStyle: 'none' }}>
      <a
        data-test='item-search-results-link'
        onClick={onResultClick}
        tabIndex={0}
        role='button'
        className={item.selected ? 'ui-menu-item-wrapper autocomplete--highlighted' : 'ui-menu-item-wrapper'}
      >
        <span className='autocomplete__price'>
          <Money amount={item.total} />
        </span>
        <span className='autocomplete__title'>
          <span className='autocomplete__highlight'>
            {item.title}
          </span>
        </span>

        <span className='autocomplete__description'>
          {
            truncate(
              stripHtml(item.description).result,
              { length: 200, separator: '...' }
            )
          }
        </span>
      </a>
    </div>
  )
}

SearchResult.propTypes = {
  item: PropTypes.object.isRequired,
  onResultClick: PropTypes.func.isRequired
}

export default SearchResult
