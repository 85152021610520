import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import View from './containers/view'
import { init } from './actions'

const { store } = window.App

class ViewApp extends Component {
  componentDidMount () {
    store.dispatch(init(this.props))
  }

  render () {
    return (
      <Provider store={store}>
        <View {...this.props} />
      </Provider>
    )
  }
}

ViewApp.defaultProps = {
  showHeadings: true,
  includeAllGeneralEvents: false,
  startDate: '',
  endDate: '',
  eventsUrl: '/events.json',
  canManageEvent: false,
  canViewContact: false,
  noEventsMessage: null,
  order: 'asc'
}

ViewApp.propTypes = {
  showHeadings: PropTypes.bool,
  includeAllGeneralEvents: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  eventsUrl: PropTypes.string,
  canManageEvent: PropTypes.bool,
  canViewContact: PropTypes.bool,
  noEventsMessage: PropTypes.string,
  order: PropTypes.string
}

export default ViewApp
