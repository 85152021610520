import moment from 'moment'
import sortBy from 'lodash/sortBy'

export const createDays = (startDate, endDate, order) => {
  const allDaysInRange = Math.abs(moment(startDate).startOf('day').diff(moment(endDate).startOf('day'), 'days')) + 1
  const days = [...Array(allDaysInRange)].map((_, currentDay) => ({ date: moment(startDate).add(currentDay, 'day').toISOString() }))

  if (order === 'desc') {
    return days.reverse()
  }

  return days
}

export const addEventsToDays = (events, days) => {
  const newDays = [...days]
  newDays.forEach((day, index) => {
    newDays[index].events = sortBy(events.filter(event => (
      moment(day.date).isBetween(event.start, event.end, 'day', '[]')
    )), ['start'])
  })
  return newDays
}
