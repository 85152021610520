import React from 'react'

const EnvelopeSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M3,20.5c-1.241,0-2.25-1.009-2.25-2.25v-12c0-0.504,0.164-0.981,0.476-1.38c0.007-0.011,0.015-0.022,0.022-0.031 c0.006-0.008,0.015-0.018,0.023-0.027C1.696,4.298,2.327,4,3,4h18c0.671,0,1.301,0.297,1.731,0.814 c0.01,0.01,0.019,0.021,0.026,0.031c0.006,0.007,0.012,0.017,0.018,0.026C23.086,5.27,23.25,5.746,23.25,6.25v12 c0,1.241-1.009,2.25-2.25,2.25H3z M2.25,18.25C2.25,18.664,2.586,19,3,19h18c0.414,0,0.75-0.336,0.75-0.75V6.562l-7.276,5.596 C13.77,12.7,12.891,12.999,12,12.999c-0.891,0-1.77-0.299-2.474-0.841L2.25,6.562V18.25z M10.44,10.969 c0.444,0.342,0.998,0.53,1.56,0.53s1.115-0.188,1.559-0.53l7.111-5.47H3.329L10.44,10.969z' />
    </svg>
  </span>
)

export default EnvelopeSvg
