import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import * as types from '../types'
import { createSelector } from '../selectors'

const nullUiState = { id: 'null', lastAction: '', lastField: '' }

const entityAdapter = createEntityAdapter()

export const slice = createSlice({
  name: 'ui',
  initialState: entityAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(types.ITEM_FIELD_CHANGED, (state, { payload: { itemUUID, name } }) => {
        entityAdapter.upsertOne(state, { id: itemUUID, lastAction: 'input', lastField: name })
      })
      .addCase(types.ITEM_QUANTITY_INCREMENT, (state, { payload: { itemUUID } }) => {
        entityAdapter.upsertOne(state, { id: itemUUID, lastAction: 'button', lastField: 'quantity' })
      })
      .addCase(types.ITEM_QUANTITY_DECREMENT, (state, { payload: { itemUUID } }) => {
        entityAdapter.upsertOne(state, { id: itemUUID, lastAction: 'button', lastField: 'quantity' })
      })
  }
})

// Action creators are generated for each case reducer function
// export const { } = slice.actions;

const { selectById } = entityAdapter.getSelectors(state => state.ui)
export const selectUiById = createSelector(selectById, ui => ui || nullUiState)
export default slice.reducer
