import $ from '@ytb/cash-dom'

document.addEventListener('app-connect', () => {
  const $newJobFields = () => $('#new_job_fields')
  const $jobSelectFields = () => $('#job_select_fields')
  const focusFirstVisibleInput = element => element.find('input[type=text]:enabled').first().trigger('focus')
  const $newJobButton = () => $('#show_new_job')
  const $newJobHeader = () => $('#show_new_job_header')
  const $submitFormButton = () => $('button[form]')
  const $selectJobHeader = () => $('#show_select_job_header')

  // Job select page, shows add job form
  $(document).on('click', '#show_new_job', e => {
    $newJobButton().hide()
    $jobSelectFields().hide()
    $newJobFields().show()
    $newJobHeader().show()
    $selectJobHeader().hide()
    $submitFormButton().removeClass('hidden')
    focusFirstVisibleInput($newJobFields())
    e.preventDefault()
  })

  // Job select page shows existing jobs list
  $(document).on('click', '#select_existing_job', e => {
    $newJobButton().show()
    $jobSelectFields().show()
    $newJobFields().hide()
    $newJobHeader().hide()
    $selectJobHeader().show()
    $submitFormButton().addClass('hidden')
    e.preventDefault()
  })
})
