import React from 'react'

const FileSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M3 24a2.252 2.252 0 0 1-2.25-2.25V2.25A2.252 2.252 0 0 1 3 0h15.045c.592 0 1.149.227 1.572.639l2.954 2.883c.431.421.679 1.008.679 1.611V21.75A2.252 2.252 0 0 1 21 24H3zM3 1.5a.75.75 0 0 0-.75.75v19.5c0 .414.336.75.75.75h18a.75.75 0 0 0 .75-.75V5.133a.754.754 0 0 0-.227-.537l-2.954-2.883a.746.746 0 0 0-.523-.213H3z' />
      <path d='M6.045 9a.75.75 0 0 1 0-1.5h12a.75.75 0 0 1 0 1.5h-12zm0 4.5a.75.75 0 0 1 0-1.5h12a.75.75 0 0 1 0 1.5h-12zm0 4.5a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5h-6z' />
    </svg>
  </span>
)

export default FileSvg
