import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AddInputButton extends Component {
  render () {
    const { add, label } = this.props
    return React.createElement('a', {
      className: 'btn btn--sml btn--white btn--icon-green c--green u-mrgn-top',
      onClick: add,
      style: {
        cursor: 'pointer'
      }
    }, React.createElement('i', {
      className: 'fa fa-plus'
    }), label)
  }
}

AddInputButton.propTypes = {
  add: PropTypes.func.isRequired,
  label: PropTypes.string
}

AddInputButton.defaultProps = {
  label: ''
}

export default AddInputButton
