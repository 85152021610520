import { Controller } from 'stimulus'

export default class PaperworkController extends Controller {
  static targets = ['materialsModal', 'labourModal', 'activityModal']

  openMaterials (event) {
    this.materialsModalTarget.modal.open()
    event.preventDefault()
  }

  openLabour (event) {
    this.labourModalTarget.modal.open()
    event.preventDefault()
  }

  openActivity (event) {
    this.activityModalTarget.modal.open()
    event.preventDefault()
  }
}
