import { Controller } from 'stimulus'

export default class AutoVisitController extends Controller {
  static values = {
    url: String
  }

  connect () {
    const a = document.createElement('a')
    a.href = this.urlValue
    a.click()
  }
}
