import React from 'react'

const KeyboardUpSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M3.75 24A3.754 3.754 0 0 1 0 20.25V3.75A3.754 3.754 0 0 1 3.75 0h16.5A3.754 3.754 0 0 1 24 3.75v16.5A3.754 3.754 0 0 1 20.25 24H3.75zm0-22.5A2.252 2.252 0 0 0 1.5 3.75v16.5a2.252 2.252 0 0 0 2.25 2.25h16.5a2.252 2.252 0 0 0 2.25-2.25V3.75a2.252 2.252 0 0 0-2.25-2.25H3.75z' />
      <path d='M10.5 19.5c-.827 0-1.5-.673-1.5-1.5v-5.689l-1.94 1.94c-.283.282-.66.438-1.06.438a1.49 1.49 0 0 1-1.061-.44 1.493 1.493 0 0 1-.439-1.061V11.12c0-.601.234-1.165.658-1.59l5.782-5.78c.283-.284.66-.44 1.06-.44.401 0 .778.156 1.061.44l5.781 5.781c.425.425.659.99.659 1.59v2.068c0 .401-.156.777-.438 1.06-.283.284-.66.44-1.061.44-.401 0-.778-.156-1.061-.438L15 12.311V18c0 .827-.673 1.5-1.5 1.5h-3zm-.75-9.75a.747.747 0 0 1 .75.75V18h3v-7.5a.747.747 0 0 1 .75-.75c.2 0 .389.078.53.22L18 13.19v-2.069c0-.197-.08-.39-.219-.53L12 4.81l-5.781 5.781a.748.748 0 0 0-.219.53v2.068L9.22 9.97a.743.743 0 0 1 .53-.22z' />
    </svg>
  </span>
)

export default KeyboardUpSvg
