import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { unformat, toFixed } from 'accounting-js'

import { getConfiguration } from '../selectors'

const PseudoMoneyField = ({ amount }) => {
  const { currencyUnit } = useSelector(getConfiguration)

  return (
    <span
      className='input--currency'
      data-currency-unit={currencyUnit}
    >
      <div className='pwrk-frm__input pwrk-frm__input--pseudo'>
        {toFixed(unformat(amount), 2)}
      </div>
    </span>
  )
}

PseudoMoneyField.propTypes = {
  amount: PropTypes.number.isRequired
}

export default PseudoMoneyField
