import React from 'react'
import PropTypes from 'prop-types'

const NoResults = ({ resource, query }) => (
  <ul className='c-card__list'>
    <li className='c-card c--dark-metal'>
      No
      {' '}
      {resource}
      {' '}
      matching
      {' '}
      <strong>{query}</strong>
      .
      {' '}
      Try searching again…
    </li>
  </ul>
)

NoResults.propTypes = {
  resource: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired
}

export default NoResults
