import { Controller } from 'stimulus'

export default class BulkItemController extends Controller {
  static classes = ['selected', 'unselected']

  static values = {
    id: String,
    enabled: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  }

  toggle (event) {
    if (!this.enabledValue) return

    this.dispatch('toggle', { detail: { id: this.idValue } })
    event.preventDefault()
  }

  enable () {
    this.enabledValue = true
  }

  disable () {
    this.selectedValue = false
    this.enabledValue = false
  }

  select () {
    this.selectedValue = true
  }

  deselect () {
    this.selectedValue = false
  }

  // Value observers
  enabledValueChanged () {
    this.setSelectedClasses()
  }

  selectedValueChanged () {
    this.setSelectedClasses()
  }

  // Private

  setSelectedClasses () {
    if (!this.enabledValue) {
      this.element.classList.remove(this.selectedClass)
      this.element.classList.remove(this.unselectedClass)
      return
    }
    if (this.selectedValue) {
      this.element.classList.add(this.selectedClass)
      this.element.classList.remove(this.unselectedClass)
    } else {
      this.element.classList.remove(this.selectedClass)
      this.element.classList.add(this.unselectedClass)
    }
  }
}
