import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const NullItemWrapper = ({ children }) => (
  <>
    {children}
  </>
)

NullItemWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default NullItemWrapper
