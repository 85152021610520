import {
  createReducer,
  removeObjectKey
} from './util'

import {
  INIT,
  RESET,
  PAPERWORK_SAVE_FAILURE,
  PAPERWORK_SAVE_SUCCESS,
  MATERIAL_ITEM_SAVE_FAILURE,
  LABOUR_ITEM_SAVE_FAILURE,
  PAPERWORK_REFERENCE_UNAVAILABLE
} from '../types'

const init = () => ({})
const reset = () => ({})

const paperworkSaveFailure = (state, { payload: { paperworkUUID, error } }) => {
  if (error.response) {
    const { data } = error.response
    switch (data.error_type) {
      case 'schema_invalid': {
        let errors
        if (data.errors.items) {
          errors = data.errors.items[0]; // eslint-disable-line
        } else {
          errors = data.errors
        }
        return {
          ...state,
          [paperworkUUID]: {
            ...errors
          }
        }
      }
      case 'paperwork_save_failed': {
        return {
          ...state,
          [paperworkUUID]: {
            ...data.errors
          }
        }
      }
      default: {
        return state
      }
    }
  } else {
    return state
  }
}

const paperworkReferenceUnavailable = (state, { payload: { paperworkUUID, nextNumber } }) => ({
  ...state,
  [paperworkUUID]: {
    ...state[paperworkUUID],
    reference: `That number is already taken, so we've changed it to the next available number: ${nextNumber}`
  }
})

const paperworkSaveSuccess = (state, { payload: { paperworkUUID } }) => (
  removeObjectKey(state, paperworkUUID)
)

const materialItemSaveFailure = (state, { payload: { materialItemUUID, error } }) => {
  const { data } = error.response

  return {
    ...state,
    [materialItemUUID]: {
      ...data.errors
    }
  }
}

const labourItemSaveFailure = (state, { payload: { labourItemUUID, error } }) => {
  const { data } = error.response

  return {
    ...state,
    [labourItemUUID]: {
      ...data.errors
    }
  }
}

export default createReducer({}, {
  [INIT]: init,
  [RESET]: reset,
  [PAPERWORK_SAVE_FAILURE]: paperworkSaveFailure,
  [PAPERWORK_SAVE_SUCCESS]: paperworkSaveSuccess,
  [PAPERWORK_REFERENCE_UNAVAILABLE]: paperworkReferenceUnavailable,
  [MATERIAL_ITEM_SAVE_FAILURE]: materialItemSaveFailure,
  [LABOUR_ITEM_SAVE_FAILURE]: labourItemSaveFailure
})
