import React from 'react'
import PropTypes from 'prop-types'

import EventIcon from './event_icon'
import eventTime from '../helpers/event-time'

import EventTypes, { WORK_DATE, GENERAL_EVENT, APPOINTMENT, AUTOMATION_TASK } from './event_types'

const generateTitle = title => {
  if (title === '') { return '' }
  return ` ${String.fromCharCode(183)} ${title}`
}

const AutomationTask = ({
  title,
  start,
  end,
  url,
  currentDay,
  type,
  contact,
  automatableTitle
}) => (
  <li className='c-card auto-task auto-task--event'>
    <div className='flex-grid c-event'>
      <div className='flex-grid__cell u-mrgn-l--x0 u-mrgn-r--x2 flex-grid__cell--1of5'>
        <p className='t--sml'>
          <strong>
            {eventTime(start, end, currentDay)}
          </strong>
        </p>
      </div>

      <div className='flex-grid__cell u-mrgn-l--x0'>
        <h5>
          <a href={url}>
            <span className={`c-event__type  c--${EventTypes[type].colour}`}>
              <EventIcon type={type} className='icon icon--automation' />
              {' '}
              <strong>{EventTypes[type].pretty}</strong>
            </span>
            <strong>{generateTitle(title)}</strong>
          </a>
        </h5>

        <div className='c-event__address'>
          For&nbsp;
          {contact.display_name}
          {generateTitle(automatableTitle)}
        </div>

      </div>

    </div>
  </li>
)

AutomationTask.defaultProps = {
  contact: null
}

AutomationTask.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([GENERAL_EVENT, APPOINTMENT, WORK_DATE, AUTOMATION_TASK]).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  currentDay: PropTypes.string.isRequired,
  automatableTitle: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    display_name: PropTypes.string
  })
}

export default AutomationTask
