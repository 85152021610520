import React, { useEffect, useCallback, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import richTextTag from './helpers/richTextTag'
import { useSelector } from 'react-redux'

const RichText = ({
  onChange,
  autoFocus,
  label,
  name,
  id,
  value,
  valueSelector,
  testKey,
  placeholder
}) => {
  const trixValue = useRef('')
  const uniqueId = uuidv4()
  const trixInput = useRef()

  let initialValue
  if (valueSelector) {
    initialValue = useSelector(valueSelector, (a, b) => {
      return a === b || a === trixValue.current
    })
  } else {
    initialValue = value
  }

  const handleTrixChange = useCallback(event => {
    const editorHtml = richTextTag(event.target.value)

    if (trixValue.current === editorHtml && trixValue.current !== '') return

    onChange(editorHtml)
    trixValue.current = editorHtml
  }, [])

  useEffect(() => {
    if (!trixInput.current) return

    trixInput.current.addEventListener('trix-change', handleTrixChange)

    trixInput.current.addEventListener('trix-file-accept', event => {
      if (!event.file.type.match(/image.*/)) {
        event.preventDefault()
      }
    })
  }, [onChange])

  useEffect(() => {
    if (!trixInput.current) return

    if (autoFocus === true) {
      trixInput.current.focus()
    }
  }, [autoFocus])

  return (
    <>
      {label !== '' && (
        <label
          onClick={() => { trixInput.current.focus() }}
          className='pwrk-frm__label'
        >
          {label}
        </label>
      )}
      <div className='trix__wrapper'>
        <input type='hidden' id={uniqueId} name={name} value={initialValue} />
        <trix-editor
          class='trix-content'
          id={`${id}_trix`}
          input={uniqueId}
          ref={trixInput}
          data-test={`${testKey}-field`}
          placeholder={placeholder}
          data-direct-upload-url='/rails/active_storage/direct_uploads'
          data-blob-url-template='/rails/active_storage/blobs/:signed_id/*filename'
        />
      </div>
    </>
  )
}

RichText.defaultProps = {
  name: 'trix',
  id: 'trix',
  autoFocus: true,
  onChange: () => {},
  value: '',
  testKey: '',
  label: '',
  placeholder: ''
}

RichText.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  valueSelector: PropTypes.func,
  testKey: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string
}

const MemoRichText = memo(RichText)
export default MemoRichText
