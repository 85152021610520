import React, { Fragment, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import AddSvg from '@/components/svg/add'
import { normalize } from 'normalizr'
import { itemAdd, headingAdd, sectionPositionChange, itemSearchChoose } from '../../actions'
import { getPaperworkSections } from '../../selectors'
import { PaperworkContext } from '../../contexts'
import List from './list'
import PriceListSearchButton from '../price-list-search/button'
import setNewUuids from '../../helpers/set-new-uuids'

import { item as itemSchema } from '../../schemas'

const Sections = ({
  descriptionPlaceholder,
  allowAddItems,
  allowAddSections
}) => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)
  const sections = useSelector(state => getPaperworkSections(state, paperworkUUID))

  return (
    <>
      <div data-test='sections'>
        <List
          sections={sections}
          useDragHandle
          helperClass='pwrk-frm__item--dragging'
          lockAxis='y'
          useWindowAsScrollContainer
          onSortEnd={({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) { return }
            dispatch(sectionPositionChange(paperworkUUID, oldIndex, newIndex))
          }}
          descriptionPlaceholder={descriptionPlaceholder}
        />
        <div className='pwrk-frm__add-section'>
          {allowAddItems && (
            <>
              <button
                data-test='add-item-button'
                className='btn__add'
                type='button'
                onClick={() => {
                  dispatch(itemAdd(paperworkUUID))
                }}
              >
                <AddSvg className='icon icon--add' />
                Add blank item
              </button>
              <PriceListSearchButton
                onResultClick={item => {
                  dispatch(itemSearchChoose(paperworkUUID, normalize(setNewUuids(item), itemSchema)))
                }}
              >
                <AddSvg className='icon icon--add' />
                Add from price list
              </PriceListSearchButton>
            </>
          )}

          {allowAddSections && (
            <button
              data-test='add-heading-button'
              className='btn__add'
              type='button'
              onClick={() => { dispatch(headingAdd(paperworkUUID)) }}
            >
              <AddSvg className='icon icon--add' />
              Add heading
            </button>
          )}
        </div>
      </div>
    </>
  )
}

Sections.defaultProps = {
  descriptionPlaceholder: '',
  allowAddItems: true,
  allowAddSections: true
}

Sections.propTypes = {
  descriptionPlaceholder: PropTypes.string,
  allowAddItems: PropTypes.bool,
  allowAddSections: PropTypes.bool
}

export default Sections
