import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PaperworkContext } from '../../../contexts'
import Money from '../../money'
import { paperworkTotals as totals } from '../../../selectors'

const PaperworkTotal = ({ name }) => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const amount = useSelector(state => totals(state, paperworkUUID))[name]

  return (
    <Money amount={amount} />
  )
}

PaperworkTotal.propTypes = {
  name: PropTypes.string.isRequired
}

export default PaperworkTotal
