import SignaturePad from 'signature_pad'

document.addEventListener('app-connect', () => {
  const signaturePadWrapper = document.querySelector('[data-test-signature]')

  if (signaturePadWrapper) {
    const canvas = document.querySelector('canvas')
    const signaturePad = new SignaturePad(canvas)
    const clearBtn = document.querySelector('[data-test-signature-clear-btn]')
    const undoBtn = document.querySelector('[data-test-signature-undo-btn]')
    const form = document.querySelector('[data-test=accept-form]')
    const submitBtn = form.querySelector('[data-test="accept-btn"]')

    clearBtn.addEventListener('click', event => {
      event.preventDefault()
      signaturePad.clear()
    })

    undoBtn.addEventListener('click', event => {
      const data = signaturePad.toData()
      event.preventDefault()

      if (data) {
        data.pop()
        signaturePad.fromData(data)
      }
    })

    const handleSuccess = () => {
      const signatureElement = document.createElement('input')

      signatureElement.setAttribute('type', 'hidden')
      signatureElement.setAttribute('name', 'clients_estimates_accept_form[signature]')
      signatureElement.setAttribute('value', signaturePad.toDataURL())

      form.appendChild(signatureElement)
      form.submit()
      submitBtn.disabled = false
    }

    const handleFailure = () => {
      const errorWrapper = document.createElement('div')

      errorWrapper.classList.add('field_with_errors')
      signaturePadWrapper.parentNode.insertBefore(errorWrapper, signaturePadWrapper)
      errorWrapper.appendChild(signaturePadWrapper)

      const errorMessage = document.createElement('span')
      errorMessage.classList.add('frm__error-msg')
      errorMessage.innerHTML = 'Please press, hold and sign above to provide your signature'
      errorWrapper.parentNode.insertBefore(errorMessage, errorWrapper.nextSibling)

      submitBtn.disabled = false
    }

    form.addEventListener('submit', event => {
      event.preventDefault()

      submitBtn.disabled = true

      if (signaturePad.isEmpty()) {
        handleFailure()
      } else {
        handleSuccess()
      }
    })
  }

  Array.from(document.querySelectorAll('[data-optional-item]')).forEach(optionalItem => {
    optionalItem.addEventListener('click', event => {
      const item = event.target
      const totalElement = document.querySelector('[data-quote-total]')
      const total = Number(totalElement.innerText)
      const itemPrice = Number(item.dataset.price)

      if (item.checked) {
        totalElement.innerText = Number((total + itemPrice).toFixed(2))
      } else {
        totalElement.innerText = Number((total - itemPrice).toFixed(2))
      }
    })
  })
})
