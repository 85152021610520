import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ContactPopup from '../../contact-popup/contact-popup'
import EventActions from '../containers/event_actions'
import EventIcon from './event_icon'
import UserInitials from './user_initials'
import eventTime from '../helpers/event-time'

import EventTypes, { WORK_DATE, GENERAL_EVENT, APPOINTMENT, GROUPED_AUTOMATION_TASKS } from './event_types'

const generateTitle = title => {
  if (title === '') { return '' }
  return ` ${String.fromCharCode(183)} ${title}`
}

const Event = ({
  id,
  type,
  title,
  start,
  end,
  url,
  location,
  locationUrl,
  selectedColor,
  defaultColor,
  users,
  currentDay,
  onDelete,
  onColorChange,
  canManageEvent,
  contact,
  job,
  jobId,
  canViewContact,
  eventColors
}) => (
  <li className='c-card'>

    <div className='flex-grid c-event'>
      <div className='flex-grid__cell u-mrgn-l--x0 u-mrgn-r--x2 flex-grid__cell--1of5'>
        <p className='t--sml'>
          <strong>
            {eventTime(start, end, currentDay)}
          </strong>
        </p>

        <p className='c-event__initials'>
          {
            users.map(
              user => <UserInitials key={user.id} {...user} />
            )
          }
        </p>
      </div>

      <div className='flex-grid__cell u-mrgn-l--x0'>
        <h5>
          <a href={url}>
            <strong>
              <span className={`c-event__type c--${selectedColor}`}>
                <EventIcon type={type} className={`icon icon--tiny icon--${selectedColor}`} />
                {' '}
                {EventTypes[type].pretty}
              </span>
              {generateTitle(title)}
            </strong>
          </a>
        </h5>

        <div className='c-event__address'>
          {
            job && (
              <>
                {'For '}
                {
                  contact &&
                  (
                    <ContactPopup
                      displayName={contact.display_name}
                      contactName={contact.name}
                      customerSince={contact.created_at}
                      address={contact.address}
                      contactDetails={contact.contact_details}
                      canViewContact={canViewContact}
                      url={contact.url}
                    />
                  )
                }
                {generateTitle(job.title)}
              </>
            )
          }
        </div>

        {location !== '' && (
          <p className='c-event__address'>
            <a href={locationUrl} className='link link--no-uline c--dark-metal' target='new'>
              {location}
            </a>
          </p>
        )}
      </div>

      <div className='c-event__actions u-no-print'>
        <EventActions
          eventId={id}
          jobId={jobId}
          type={type}
          onDelete={onDelete}
          onColorChange={onColorChange}
          canDelete={canManageEvent}
          selectedColor={selectedColor}
          colors={[defaultColor, ...eventColors]}
        />
      </div>

    </div>
  </li>
)

Event.defaultProps = {
  title: '',
  location: '',
  locationUrl: '',
  users: [],
  canViewContact: false,
  contact: null,
  job: null,
  jobId: null,
  onDelete: () => {},
  onColorChange: () => {},
  canManageEvent: false,
  eventColors: []
}

Event.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    GENERAL_EVENT,
    APPOINTMENT,
    WORK_DATE,
    GROUPED_AUTOMATION_TASKS
  ]).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  location: PropTypes.string,
  locationUrl: PropTypes.string,
  selectedColor: PropTypes.string.isRequired,
  defaultColor: PropTypes.string.isRequired,
  users: PropTypes.array,
  currentDay: PropTypes.string.isRequired,
  canViewContact: PropTypes.bool,
  contact: PropTypes.shape({
    display_name: PropTypes.string,
    name: PropTypes.string,
    created_at: PropTypes.string,
    address: PropTypes.string,
    contact_details: PropTypes.array,
    url: PropTypes.string
  }),
  job: PropTypes.shape({
    title: PropTypes.string
  }),
  jobId: PropTypes.number,
  onDelete: PropTypes.func,
  onColorChange: PropTypes.func,
  canManageEvent: PropTypes.bool,
  eventColors: PropTypes.arrayOf(PropTypes.string)
}

export default Event
