import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import useKeyPress from '../helpers/use-key-press'

const KeyboardSelectableItems = ({ items: initialItems, selectFirst, onChoose, itemComponent }) => {
  if (initialItems.length === 0) { return null }

  const enterPressed = useKeyPress('Enter')
  const downPressed = useKeyPress('ArrowDown')
  const upPressed = useKeyPress('ArrowUp')
  const [items, setItems] = useState(initialItems)

  useEffect(() => {
    const newItems = [...items]
    if (selectFirst) { newItems[0].selected = true }
    setItems(newItems)
  }, [])

  useEffect(() => {
    if (enterPressed) {
      onChoose(items.find(item => item.selected) || items[0])
    }
  }, [enterPressed])

  useEffect(() => {
    if (downPressed) {
      const nextIndex = items.findIndex(item => item.selected) + 1
      const newItems = items.map(item => ({ ...item, selected: false }))
      const nextItem = newItems[nextIndex]
      if (nextItem) {
        nextItem.selected = true
      } else {
        newItems[0].selected = true
      }
      setItems(newItems)
    }
  }, [downPressed])

  useEffect(() => {
    if (upPressed) {
      const previousIndex = items.findIndex(item => item.selected) - 1
      const newItems = items.map(item => ({ ...item, selected: false }))
      const previousItem = newItems[previousIndex]
      if (previousItem) {
        previousItem.selected = true
      } else {
        newItems[newItems.length - 1].selected = true
      }
      setItems(newItems)
    }
  }, [upPressed])

  return (
    <>
      {
        items.map(item => (
          <Fragment key={item.uuid || item.id}>
            {itemComponent({ item, onResultClick: () => onChoose(item) })}
          </Fragment>
        ))
      }
    </>
  )
}

KeyboardSelectableItems.defaultProps = {
  selectFirst: false
}

KeyboardSelectableItems.propTypes = {
  selectFirst: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onChoose: PropTypes.func.isRequired,
  itemComponent: PropTypes.func.isRequired
}

export default KeyboardSelectableItems
