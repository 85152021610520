import React, { Fragment, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'

import { PaperworkContext } from '../contexts'
import { paperworkFormChanged, paperworkFieldChanged } from '../actions'
import { getConfiguration, getPaperwork, getTranslation } from '../selectors'
import invoiceValues from '../schemas/invoice-values'
import { composeValidators, required, mustBeNumber } from '../validations'
import Form from './form'
import SubTotal from './sub-total'
import Discount from './discount/index'
import TaxTotals from './tax-totals'
import GrandTotal from './grand-total'
import Sections from './sections'
import Errors from './errors'
import DocumentTitleSelect from './document-title-select'
import Select from './select'
import Checkbox from './checkbox'
import SaveBar from './save-bar'
import Field from './final-form-field'
import DateField from './date-field'
import TextArea from './textarea'
import ReferenceField from './reference-field'
import RichText from '../../rich-text'
import PaperworkTotal from './totals/paperwork'

const Invoice = () => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)
  const { deductsCis, vatReverseCharge: showVatReverseCharge } = useSelector(getConfiguration)
  const paperwork = useSelector(state => getPaperwork(state, paperworkUUID))
  const t = key => useSelector(state => getTranslation(state, key))

  const notesSelector = useCallback(state => getPaperwork(state, paperworkUUID).notes, [paperworkUUID])
  const termsSelector = useCallback(state => getPaperwork(state, paperworkUUID).terms, [paperworkUUID])

  const showCis = deductsCis || paperwork.cisRate > 0
  const { vatReverseCharge } = paperwork

  const onFieldChange = useCallback(debounce(event => {
    dispatch(
      paperworkFieldChanged(
        paperworkUUID,
        event.target.name,
        event.target.value
      )
    )
  }, 400), [paperworkUUID])

  const onNotesChange = useCallback(text => {
    onFieldChange({ target: { name: 'notes', value: text } })
  }, [onFieldChange])

  const onTermsChange = useCallback(text => {
    onFieldChange({ target: { name: 'terms', value: text } })
  }, [onFieldChange])

  return (
    <Form
      initialValues={invoiceValues(paperwork)}
      onChange={(values, modifiedFields) => {
        dispatch(paperworkFormChanged(paperworkUUID, values, modifiedFields))
      }}
    >
      <Errors uuid={paperworkUUID} />
      <section className='pwrk-frm__section pwrk-frm__header'>

        <div className='pwrk-frm__block pwrk-frm__title'>
          <DocumentTitleSelect name='documentTitle' testKey='document-title' />
        </div>

        <div className='pwrk-frm__header-col pwrk-frm__header-col--wide'>
          <div className='pwrk-frm__block'>
            <ReferenceField
              defaultPrefix='Number'
              placeholder='Invoice no.'
              className='pwrk-frm__input--short'
              validate={composeValidators(required, mustBeNumber)}
            />
          </div>
          <div className='pwrk-frm__block'>
            <Field
              label='Reference'
              name='quoteRef'
              testKey='quote-ref'
              placeholder='Any reference (optional)'
              maxLength='35'
            />
          </div>
          <div className='pwrk-frm__header-col--2'>
            <div className='pwrk-frm__block'>
              <DateField
                label='Date'
                name='date'
                testKey='date'
              />
            </div>
            <div className='pwrk-frm__block'>
              <DateField
                label='Due date'
                name='dueDate'
                testKey='due_date'
              />
            </div>
          </div>
        </div>

        <div className='pwrk-frm__header-col'>
          <div className='pwrk-frm__block'>
            <label htmlFor='customerName' className='pwrk-frm__label'>
              To
            </label>
            <ul className='pwrk-frm__input-list'>
              <li>
                <Field
                  name='customerName'
                  testKey='customer-name'
                  placeholder='Customer name'
                />
              </li>
              <li>
                <TextArea
                  name='customerAddress'
                  testKey='customer-address'
                  placeholder='Customer address'
                  minRows='1'
                  maxRows='4'
                />
              </li>
              <li>
                <Field
                  name='customerPostcode'
                  testKey='customer-postcode'
                  placeholder='Customer postcode'
                />
              </li>
            </ul>
          </div>
        </div>

        <div className='pwrk-frm__header-col'>
          <div className='pwrk-frm__block'>
            <label htmlFor='jobAddress' className='pwrk-frm__label'>
              For
            </label>
            <ul className='pwrk-frm__input-list'>
              <li>
                <TextArea
                  name='jobAddress'
                  testKey='job-address'
                  placeholder='Site address'
                  minRows='1'
                  maxRows='4'
                />
              </li>
              <li>
                <Field
                  name='jobPostcode'
                  testKey='job-postcode'
                  placeholder='Site postcode'
                />
              </li>
            </ul>
          </div>
        </div>

      </section>

      <Sections
        descriptionPlaceholder='Describe what you did…'
      />

      <div className='pwrk-frm__totals'>
        <div className='pwrk-frm__block'>
          <SubTotal />
        </div>
        <div className='pwrk-frm__block'>
          <Discount />
        </div>
        {!vatReverseCharge && (
          <div className='pwrk-frm__block'>
            <TaxTotals />
          </div>
        )}
        <div className='pwrk-frm__block'>
          <GrandTotal />
        </div>

        {showCis && (
          <>
            <div className='pwrk-frm__block'>
              <span className='pwrk-frm__totals-label pwrk-frm__totals-label--cis'>
                {'CIS @ '}
                <Select
                  name='cisRate'
                  options={[
                    { label: '0%', value: '0.00' },
                    { label: '20%', value: '20.00' },
                    { label: '30%', value: '30.00' }
                  ]}
                  testKey='cis-rate'
                  className='pwrk-frm__input pwrk-frm__input--strong pwrk-frm__input--t--sml'
                />
                {`of ${t('labour')}`}
              </span>
              <span className='pwrk-frm__totals-value' data-test='cis-total'>
                <PaperworkTotal name='cis' />
              </span>
            </div>

            <div className='pwrk-frm__block'>
              <span className='pwrk-frm__grand-total'>
                <span className='pwrk-frm__totals-label'>
                  Total Payable
                </span>
                <span className='pwrk-frm__totals-value' data-test='cis-total-payable'>
                  <PaperworkTotal name='cisPayable' />
                </span>
              </span>
            </div>
          </>
        )}

        {(showVatReverseCharge || vatReverseCharge) && (
          <div className='pwrk-frm__block pwrk-frm__reverse-charge-checkbox'>
            <Checkbox name='vatReverseCharge' label='Apply VAT reverse charge' testKey='vat-reverse-charge' />
          </div>
        )}

        {vatReverseCharge && (
          <div className='pwrk-frm__block'>
            <div>Customer to pay VAT to HMRC:</div>
            <TaxTotals />
          </div>
        )}

      </div>

      <div className='pwrk-frm__section pwrk-frm__footer'>
        <div className='pwrk-frm__block'>
          <RichText
            label='Notes'
            name='notes'
            id='notes'
            valueSelector={notesSelector}
            testKey='notes'
            autoFocus={false}
            placeholder='Enter any extra notes for your customer'
            onChange={onNotesChange}
          />
        </div>
      </div>

      <div className='pwrk-frm__section'>
        <div className='pwrk-frm__block'>
          <RichText
            label='Terms'
            name='terms'
            id='terms'
            valueSelector={termsSelector}
            autoFocus={false}
            testKey='terms'
            placeholder='Enter any terms and conditions for your customer'
            onChange={onTermsChange}
          />
        </div>
      </div>

      <SaveBar
        saveText='Save invoice'
        submittingText='Saving invoice...'
      />
    </Form>
  )
}

export default Invoice
