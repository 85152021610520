import React from 'react'
import PropTypes from 'prop-types'

import Day from './day'
import NoEventsMessage from './no-events-message'

const View = ({
  days,
  showHeadings,
  canViewContact,
  onEventDelete,
  canManageEvent,
  noEventsMessage
}) => {
  if (days.length === 0) {
    return (<NoEventsMessage message={noEventsMessage} />)
  }

  return (
    <div className='days'>
      {
        days.map(
          day => (
            <Day
              key={day.date}
              showHeadings={showHeadings}
              canViewContact={canViewContact}
              onEventDelete={onEventDelete}
              canManageEvent={canManageEvent}
              {...day}
            />
          )
        )
      }
    </div>
  )
}

View.defaultProps = {
  showHeadings: true,
  canManageEvent: false,
  canViewContact: false,
  noEventsMessage: null
}

View.propTypes = {
  days: PropTypes.array.isRequired,
  showHeadings: PropTypes.bool,
  onEventDelete: PropTypes.func.isRequired,
  canManageEvent: PropTypes.bool,
  canViewContact: PropTypes.bool,
  noEventsMessage: PropTypes.string
}

export default View
