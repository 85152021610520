import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import SignaturePad from 'signature_pad'

export default class SignatureFieldController extends Controller {
  static targets = ['canvas', 'input']

  static values = {
    dataUri: String
  }

  connect () {
    this.setSignature = debounce(this.setSignature, 400).bind(this)

    this.signaturePad = new SignaturePad(this.canvasTarget)

    if (this.dataUriValue) {
      this.signaturePad.fromDataURL(this.dataUriValue, { ratio: 1, width: 600 })
    }

    this.signaturePad.addEventListener('endStroke', () => {
      this.setSignature(this.signaturePad.toDataURL())
    })
  }

  disconnect () {
    this.signaturePad.off()
  }

  clear (event) {
    event.preventDefault()

    this.signaturePad.clear()
    this.setSignature('')
  }

  undo (event) {
    const data = this.signaturePad.toData()
    event.preventDefault()

    if (data) {
      data.pop()
      this.signaturePad.fromData(data)
      this.setSignature(this.signaturePad.toDataURL())
    }
  }

  setSignature (data) {
    this.dataUriValue = data
  }

  dataUriValueChanged (value) {
    if (value) {
      this.inputTarget.value = value
    }
  }
}
