import $ from '@ytb/cash-dom'
import axios from '@ytb/axios'

function buildOption (category) {
  return $('<option></option>').attr('value', category.id).text(category.category_name)
}

function categoryCreated (category) {
  $('form select#material_category_id').append(buildOption(category)).val(category.id)

  const materialCategoryList = $('[data-material-categories]')
  if (materialCategoryList.length > 0) {
    materialCategoryList.load('/material_categories.html')
  }
}

function categoryCreatedBulk (category, selectedElm) {
  $('form#bulk_materials select.select_material_category').each((i, elm) => (
    $(elm).append(buildOption(category))
  ))
  $(selectedElm).val(category.id)
}

function categoryDeleted (category) {
  $('ul.material-categories-list').find(`li#category-${category.id}`).fadeOut('slow')
}

function categoryUpdated (category) {
  $('ul.material-categories-list').find(`li#category-${category.id} span.category-name`).html(category.category_name)
}

function createCategory (callback, callbackVar) {
  const category = window.prompt('Please enter a new category name:', '')
  if (category !== null) {
    axios.post(
      '/material_categories.json', {
        material_category: {
          category_name: category
        }
      }
    ).then(response => categoryCreated(response.data, callbackVar))
  }
}

function deleteCategory (url) {
  axios.delete(`${url}.json`).then(response => categoryDeleted(response.data))
}

function renameCategory (existingName, url) {
  const newName = window.prompt('Please enter a new category name:', existingName)
  if (newName !== null) {
    axios.patch(
      `${url}.json`, {
        material_category: {
          category_name: newName
        }
      }
    ).then(response => categoryUpdated(response.data))
  }
}

document.addEventListener('app-connect', () => {
  $(document).on('change', 'form#bulk_materials select.select_material_category', e => {
    if ($(e.target).val() === 'new') {
      createCategory(categoryCreatedBulk, e.target)
    }
  })

  $(document).on('change', 'form#material_form select#material_category_id, form.edit_material select#material_category_id', e => {
    if ($(e.target).val() === 'new') {
      createCategory(categoryCreated)
    }
  })

  $(document).on('click', 'div.material-categories a.add_new_material', e => {
    e.preventDefault()
    createCategory(categoryCreated)
  })

  $(document).on('click', 'div.material-categories a.delete-category', e => {
    e.preventDefault()
    deleteCategory($(e.target).closest('a').attr('data-url'))
  })

  $(document).on('click', 'div.material-categories a.rename-category', e => {
    e.preventDefault()
    const $target = $(e.target).closest('a')
    renameCategory($target.attr('data-name'), $target.attr('data-url'))
  })

  $(document).on('click', '.js-edit-material', e => {
    e.preventDefault()
    $('#material_list').load($(e.target).closest('a').data('url'), () => ($('#material_form input#material_description').focus()))
  })
})
