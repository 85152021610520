import { Converter } from 'showdown'

const fromMarkdown = value => {
  const converter = new Converter({
    simpleLineBreaks: true,
    literalMidWordUnderscores: true
  })
  return converter.makeHtml(value).replace(/(?:\r\n|\r|\n)/g, '')
}

export default fromMarkdown
