import moment from 'moment'

export const fetchAssignee = payload => {
  let { assignee } = payload
  const { routerAssignee } = payload

  if (routerAssignee && routerAssignee !== '') {
    assignee = routerAssignee
  }

  return assignee
}

export const fetchDateMode = payload => {
  let { dateMode } = payload
  const { routerDateMode } = payload

  if (routerDateMode && routerDateMode !== '') {
    dateMode = routerDateMode
  }

  return dateMode
}

export const fetchType = payload => {
  let { type } = payload
  const { routerType } = payload

  if (routerType && routerType !== '') {
    type = routerType
  }

  return type
}

export const fetchStartDate = payload => {
  let { startDate } = payload
  const { routerStartDate } = payload

  if (routerStartDate && moment(routerStartDate).isValid()) {
    startDate = routerStartDate
  }

  if (!moment(startDate).isValid()) {
    return null
  }

  return startDate
}

export const formatDateRange = (startDate, endDate) => {
  if (endDate && moment(startDate).isSame(moment(endDate), 'day')) {
    return null
  }

  if (moment(startDate).isSame(moment(), 'week')) { return 'This week' }

  if (moment(startDate).isSame(moment().add('1', 'week'), 'week')) { return 'Next week' }

  // Same month, different day
  if (moment(startDate).isSame(moment(endDate), 'month')) {
    if (moment(startDate).isSame(moment(), 'year')) {
      return `${moment(startDate).format('D')} - ${moment(endDate).format('D MMMM')}`
    }
    return `${moment(startDate).format('D')} - ${moment(endDate).format('D MMMM YYYY')}`
  }

  // Same year, different month
  if (moment(startDate).isSame(moment(endDate), 'year')) {
    if (moment(startDate).isSame(moment(), 'year')) {
      return `${moment(startDate).format('D MMMM')} - ${moment(endDate).format('D MMMM')}`
    }

    return `${moment(startDate).format('D MMMM YYYY')} - ${moment(endDate).format('D MMMM YYYY')}`
  }

  // Different year
  return `${moment(startDate).format('D MMMM YYYY')} - ${moment(endDate).format('D MMMM YYYY')}`
}
