import React from 'react'

const TrashSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M6.631 23.25a2.263 2.263 0 0 1-2.242-2.064L3.06 5.25H1.5a.75.75 0 0 1 0-1.5h6V3A2.252 2.252 0 0 1 9.75.75h4.5A2.252 2.252 0 0 1 16.5 3v.75h6a.75.75 0 0 1 0 1.5h-1.56l-1.328 15.937a2.262 2.262 0 0 1-2.242 2.063H6.631zm-.748-2.188c.032.386.36.688.748.688H17.37a.753.753 0 0 0 .747-.688L19.435 5.25H4.565l1.318 15.812zM15 3.75V3a.75.75 0 0 0-.75-.75h-4.5A.75.75 0 0 0 9 3v.75h6z' />
      <path d='M9.75 18a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 0 1 1.5 0v7.5a.75.75 0 0 1-.75.75zm4.5 0a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 0 1 1.5 0v7.5a.75.75 0 0 1-.75.75z' />
    </svg>
  </span>
)

export default TrashSvg
