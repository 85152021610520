import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'

import axios from '@ytb/axios'

import Modal from '../modal'
import AccreditationUploader from '../file-uploader/accreditation-uploader'
import TradeAccreditation from './trade-accreditation'

const Accreditation = ({
  destroyUrl,
  fromServer,
  id,
  name,
  position,
  thumbnailUrl,
  largeUrl,
  type,
  tradeAccreditations
}) => {
  const initialFile = fromServer
    ? {
        fromServer,
        id,
        destroyUrl,
        name,
        thumbnailUrl,
        largeUrl,
        type
      }
    : null

  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState(initialFile)

  const uploader = useCallback(
    className => (
      <AccreditationUploader
        maxFileSize={2097152}
        endpoint='/accreditations'
        containerClassName={className}
        initialFile={file}
        onFileUploaded={uploadedFile => { setFile(uploadedFile); setShowModal(false) }}
        onFileDeleted={() => { setFile(null) }}
        createParams={{ position }}
      />
    ),
    [file, position]
  )

  const uploadTradeAccreditationImage = useCallback(
    image => (
      axios.post('/accreditations',
        {
          image_remote_url: image,
          position
        }).then(response => {
        setFile({ ...response.data.file })
        setShowModal(false)
      })
    ),
    [position]
  )

  const srcset = file && (`${file.thumbnailUrl} 1x, ${file.largeUrl || ''} 2x`)

  const thumbnail = file && file.fromServer !== undefined && file.fromServer
    ? (
      <div className='accreditation-upldr__thumbnail'>
        <img alt={file.name} src={file.thumbnailUrl} srcSet={srcset} />
      </div>
      )
    : (
      <div className='accreditation-upldr__placeholder'>
        Upload badge #
        {position + 1}
      </div>
      )

  const modal = tradeAccreditations.length === 0
    ? (uploader('accreditation-settings-upldr'))
    : (
      <>
        <div className='accreditation-upldr'>
          <button type='button' onClick={e => { e.preventDefault(); setShowModal(true) }}>
            {thumbnail}
          </button>
        </div>
        {
        showModal && (
          <Modal closeText='Close' onClose={() => setShowModal(false)}>
            <h1 className='t--xl u-mrgn-top--x6 u-mrgn-btm--x6'>
              <strong>Add an accreditation badge</strong>
            </h1>

            <div className='flex-grid'>
              <div className='flex-grid__cell flex-grid__cell--1of4'>

                <h2 className='u-mrgn-btm--x6'>Upload your own…</h2>
                {uploader('accreditation-settings-upldr--lrg')}

                <p className='t--sml c--dark-metal u-mrgn-top--x5 u-align-center u-mrgn-r--x5 u-pad-lr--x4'>
                  <span className='u-visible-lrg-scrn-only'>Drag and drop a logo above, or click to upload a logo from your computer</span>
                  <span className='u-visible-sml-scrn-only'>Click to choose an image for your logo</span>
                </p>
              </div>
              <div className='flex-grid__cell'>
                <h2 className='u-mrgn-btm--x4'> … or select one of these:</h2>
                <div className='flex-grid flex-grid--wrap'>
                  {
                    tradeAccreditations.map(item => (
                      <TradeAccreditation
                        url={item.url}
                        largeUrl={item.large_url}
                        srcset={item.srcset}
                        name={item.name}
                        key={item.id}
                        handleClick={imageUrl => { uploadTradeAccreditationImage(imageUrl) }}
                      />
                    ))
                  }
                </div>
              </div>
            </div>
          </Modal>
        )
      }
      </>
      )

  return modal
}

Accreditation.propTypes = {
  destroyUrl: PropTypes.string,
  fromServer: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  position: PropTypes.number.isRequired,
  thumbnailUrl: PropTypes.string,
  type: PropTypes.string,
  tradeAccreditations: PropTypes.array
}

Accreditation.defaultProps = {
  destroyUrl: '',
  fromServer: false,
  id: null,
  name: null,
  thumbnailUrl: '',
  type: '',
  tradeAccreditations: []
}

export default Accreditation
