import React from 'react'
import PropTypes from 'prop-types'

const SampleItemWrapper = ({ children }) => (
  <div className='pwrk-frm__section--sample'>

    {children}

  </div>
)

SampleItemWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default SampleItemWrapper
