import React from 'react'

const ViewOffSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M2.783 21.75a.756.756 0 0 1-.541-.23.745.745 0 0 1-.209-.535.745.745 0 0 1 .231-.526l18.75-18a.745.745 0 0 1 1.06.022.748.748 0 0 1-.021 1.061l-18.75 18a.753.753 0 0 1-.52.208zM11.988 20.25l-.11.001c-1.048 0-2.103-.163-3.137-.483a.751.751 0 0 1 .223-1.466.73.73 0 0 1 .222.034c.888.276 1.8.416 2.709.416l.249.001c3.853 0 7.756-2.786 10.125-5.393a.91.91 0 0 0-.002-1.22 19.786 19.786 0 0 0-2.959-2.653.744.744 0 0 1-.299-.49.752.752 0 0 1 1.183-.722 21.35 21.35 0 0 1 3.187 2.858c.832.923.832 2.312.003 3.233-2.586 2.846-6.895 5.886-11.237 5.886l-.157-.002zM3.778 17.011a.751.751 0 0 1-.46-.158 21.623 21.623 0 0 1-2.693-2.486 2.41 2.41 0 0 1-.003-3.234c2.586-2.845 6.896-5.884 11.236-5.884l.237.001c.846 0 1.687.102 2.5.305a.749.749 0 1 1-.363 1.456 8.807 8.807 0 0 0-2.129-.26h-.229c-3.863 0-7.771 2.785-10.14 5.391a.91.91 0 0 0 .002 1.22 20.146 20.146 0 0 0 2.503 2.31.746.746 0 0 1 .132 1.052.752.752 0 0 1-.593.287z' />
      <path d='M8.25 13.5a.75.75 0 0 1-.75-.75c0-1.2.468-2.329 1.318-3.18a4.465 4.465 0 0 1 3.179-1.32c.207 0 .374.167.375.374l.002.75c0 .1-.039.195-.109.266s-.165.11-.265.11a2.98 2.98 0 0 0-2.121.88A2.979 2.979 0 0 0 9 12.75a.748.748 0 0 1-.75.75zM12.001 17.25a.374.374 0 0 1-.375-.375l-.001-.75c0-.099.039-.195.11-.265a.372.372 0 0 1 .265-.11 2.98 2.98 0 0 0 2.122-.879A2.985 2.985 0 0 0 15 12.749a.75.75 0 0 1 1.5 0 4.47 4.47 0 0 1-1.317 3.182 4.471 4.471 0 0 1-3.182 1.319z' />
    </svg>
  </span>
)

export default ViewOffSvg
