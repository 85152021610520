import React from 'react'
import { useFormContext } from 'react-hook-form'

import errorClass from '../utils/error-class'
import ErrorMessage from './error-message'

const Email = () => {
  const { errors, register } = useFormContext()

  const object = 'user'
  const field = 'email'
  const name = `${object}[${field}]`

  return (
    <div className='frm__block'>
      <label className='frm__label' htmlFor='user_email'>Email address</label>
      <div className='frm__input-primer'>They&apos;ll use this email address to sign in to YourTradebase</div>
      <input
        autoComplete='off'
        placeholder='Enter their email address'
        className={`frm__input${errorClass({ errors, object, field })}`}
        type='text'
        name={name}
        id='user_email'
        ref={register({ required: true })}
        data-test='email'
      />
      <ErrorMessage object={object} field={field} />
    </div>
  )
}

export default Email
