import { Controller } from 'stimulus'

export default class JobAddressController extends Controller {
  static targets = ['addressInput', 'postcodeInput', 'blank', 'blankForm', 'newAddress']

  initialize () {
    const { address, postcode } = this.getSelectedAddress()

    this.addressInputTarget.value = address
    this.postcodeInputTarget.value = postcode
  }

  selectAddress (event) {
    const { currentTarget } = event
    const { address, postcode } = currentTarget.dataset

    this.addressInputTarget.value = address
    this.postcodeInputTarget.value = postcode

    if (currentTarget !== this.blankTarget) {
      this.blankFormTarget.classList.add('hidden')
      this.newAddressTarget.classList.remove('hidden')
    } else {
      this.blankFormTarget.classList.remove('hidden')
      this.newAddressTarget.classList.add('hidden')

      this.addressInputTarget.focus()
    }
  }

  getSelectedAddress () {
    return Array.from(
      document.querySelectorAll('[name=address_selector]')
    ).filter(address => address.checked)[0].dataset
  }

  updateState () {
    const { blankTarget } = this

    blankTarget.dataset.address = this.addressInputTarget.value
    blankTarget.dataset.postcode = this.postcodeInputTarget.value
  }
}
