import moment from 'moment'

const ISO_DATE_FORMAT = 'YYYY-MM-DD'

export const buildUrl = ({ start, dateMode, type, assignee }) => (
  `/${moment(start).format(ISO_DATE_FORMAT)}/${dateMode}/${type}/${assignee}`
)

export const buildNextUrl = ({ start: currentStart, dateMode, ...urlParts }) => {
  let start

  switch (dateMode) {
    case 'week': {
      start = moment(currentStart).startOf('isoweek').add(1, 'week')
      break
    }
    case 'day': {
      start = moment(currentStart).startOf('day').add(1, 'day')
      break
    }
    default: {
      throw new Error(`Unknown dateMode: ${dateMode}`)
    }
  }

  return buildUrl({ ...urlParts, dateMode, start })
}

export const buildPreviousUrl = ({ start: currentStart, dateMode, ...urlParts }) => {
  let start
  switch (dateMode) {
    case 'week': {
      const startOfWeek = moment(currentStart).startOf('isoweek')
      if (!startOfWeek.isSame(currentStart, 'day')) {
        start = startOfWeek
      } else {
        start = startOfWeek.subtract(1, 'week')
      }
      break
    }
    case 'day': {
      start = moment(currentStart).startOf('day').subtract(1, 'day')
      break
    }
    default: {
      throw new Error(`Unknown dateMode: ${dateMode}`)
    }
  }

  return buildUrl({ ...urlParts, dateMode, start })
}

export const buildUrls = urlParts => ({
  nextUrl: buildNextUrl(urlParts),
  previousUrl: buildPreviousUrl(urlParts),
  dayUrl: buildUrl({ ...urlParts, dateMode: 'day' }),
  weekUrl: buildUrl({ ...urlParts, dateMode: 'week' })
})
