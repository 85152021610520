/* eslint consistent-return: 0 */
/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/default-props-match-prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const SavedConfirmation = createReactClass({
  displayName: 'SavedConfirmation',
  propTypes: {
    saved: PropTypes.bool
  },
  getDefaultProps () {
    return {
      saved: false
    }
  },
  confirmation () {
    const { saved } = this.props
    if (saved) {
      return React.createElement('span', {
        key: 'saved-confirmation',
        className: 'frm__success-msg'
      }, React.createElement('i', null), ' Saved')
    }
  },
  render () {
    return React.createElement(ReactCSSTransitionGroup, {
      transitionName: 'saved-confirmation',
      transitionEnter: false,
      transitionLeaveTimeout: 250
    }, this.confirmation())
  }
})

export default SavedConfirmation
