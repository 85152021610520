import { all, fork } from 'redux-saga/effects'

import CompanyUsers from './company-users'
import Events from './events'

export default function * rootSaga () {
  yield all([
    fork(CompanyUsers),
    fork(Events)
  ])
}
