/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import SavingConfirmation from './saving-confirmation'
import SavedConfirmation from './saved-confirmation'

const SaveStatusConfirmation = createReactClass({
  displayName: 'SaveStatusConfirmation',
  propTypes: {
    inline: PropTypes.bool,
    status: PropTypes.oneOf(['inert', 'saving', 'saved']).isRequired
  },
  classes () {
    const { inline } = this.props
    let classes = ['frm__state']
    if (!inline) {
      classes = classes.concat('u-block')
    }
    return classes.join(' ')
  },
  render () {
    const { status } = this.props
    return React.createElement('div', {
      className: this.classes()
    }, React.createElement(SavingConfirmation, {
      saving: status === 'saving'
    }), React.createElement(SavedConfirmation, {
      saved: status === 'saved',
      key: 'saved-confirmation'
    }))
  }
})

export default SaveStatusConfirmation
