import React, { useContext, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import differenceWith from 'lodash/differenceWith'

import {
  itemFormChanged,
  itemFieldChanged,
  sectionRemove,
  itemAutocompleteChoose
} from '../../actions'
import { PaperworkContext, ItemContext } from '../../contexts'
import { getItem, getConfiguration, getTranslation, getPaperworkItemsWithMultipleQuantity } from '../../selectors'
import { selectUiById } from '../../reducers/ui'
import itemValues from '../../schemas/item-values'
import RichText from '../../../rich-text'
import Form from '../form'
import LabourItemsButton from '../labour-items/button'
import MaterialItemsButton from '../material-items/button'
import LabourItemsTotalField from '../labour-items/total-field'
import MaterialItemsTotalField from '../material-items/total-field'
import MoneyField from '../money-field'
import TaxRateSelect from '../tax-rate-select'
import DuplicateButton from './duplicate-button'
import TitleField from './title-field'
import HelpPrompt from '../help-prompt'
import DragHandle from '../sections/drag-handle'
import RemoveButton from '../sections/remove-button'
import NullItemWrapper from './null-item-wrapper'
import SampleItemWrapper from './sample-item-wrapper'
import significantDigits from '../../helpers/significant-digits'
import OptionalButton from './optional-button'
import SaveButton from './save-button'
import ItemTotal from '../totals/items'
import QuantityDecrementButton from './quantity-decrement-button'
import QuantityIncrementButton from './quantity-increment-button'
import QuantityField from './quantity-field'

const Item = ({
  itemUUID,
  sortIndex,
  descriptionPlaceholder
}) => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const dispatch = useDispatch()

  const {
    allowOptionalItems,
    allowTaxRate,
    allowItemQuantity,
    allowItemDuplicate: allowDuplicate,
    allowItemSave: allowSave,
    allowItemReorder: allowReorder,
    allowItemRemove: allowRemove
  } = useSelector(state => getConfiguration(state))

  const item = useSelector(state => getItem(state, itemUUID))
  const ui = useSelector(state => selectUiById(state, itemUUID))

  const { changed, sample, focussed, discount } = item
  const formValues = itemValues(item)
  const t = key => useSelector(state => getTranslation(state, key))

  const paperworkWithItemsThatHaveMultipleQuantity = useSelector(state => getPaperworkItemsWithMultipleQuantity(state, paperworkUUID))
  const showQuantity = (
    item.quantity > 1 ||
    (ui && ui.lastAction === 'input' && ui.lastField === 'quantity') ||
    paperworkWithItemsThatHaveMultipleQuantity.length > 0
  )
  const showQuantityButton = (allowItemQuantity || showQuantity)

  const onFieldChange = useCallback(debounce(event => {
    dispatch(
      itemFieldChanged(
        paperworkUUID,
        itemUUID,
        event.target.name,
        event.target.value
      )
    )
  }, 400), [paperworkUUID, itemUUID])

  const onDescriptionChange = useCallback(text => {
    onFieldChange({ target: { name: 'description', value: text } })
  }, [onFieldChange])

  const descriptionSelector = useCallback((state) =>
    getItem(state, itemUUID).description
  , [itemUUID])

  const handleRemove = () => {
    if (changed && !window.confirm('Are you sure you want to remove this work item? It cannot be undone.')) {
      return
    }
    dispatch(sectionRemove(paperworkUUID, itemUUID))
  }

  const ItemWrapper = sample ? SampleItemWrapper : NullItemWrapper

  return (
    <ItemContext.Provider value={{ itemUUID }}>
      <ItemWrapper>
        <Form
          initialValues={formValues}
          onChange={values => {
            if (shallowEqual(values, formValues)) return

            const changedValues = Object.fromEntries(
              differenceWith(
                Object.entries(values),
                Object.entries(formValues),
                (a, b) => a[1] === b[1]
              )
            )

            dispatch(itemFormChanged(paperworkUUID, itemUUID, changedValues))
          }}
        >
          <div className='pwrk-frm__item'>
            <div className='pwrk-frm__item-desc'>
              <div className='pwrk-frm__input-list'>
                <TitleField
                  onResultClick={
                    result => dispatch(itemAutocompleteChoose(paperworkUUID, itemUUID, result))
                  }
                  autoFocus={focussed}
                />
                <div className='pwrk-frm__block'>
                  <RichText
                    name='description'
                    id='description'
                    valueSelector={descriptionSelector}
                    testKey='description'
                    placeholder={descriptionPlaceholder}
                    autoFocus={false}
                    onChange={onDescriptionChange}
                  />
                </div>
              </div>
              <div className='pwrk-frm__item-options'>
                {allowOptionalItems &&
                  <OptionalButton />}
                {allowSave &&
                  <SaveButton />}
                {showQuantityButton && (
                  <span className='btn__group'>
                    <span className='btn__group-item'>
                      <QuantityDecrementButton />
                    </span>
                    <span className='btn__group-item u-mrgn-r'>
                      <QuantityIncrementButton />
                    </span>
                  </span>
                )}
                {allowReorder && (
                  <span className='u-visible-lrg-scrn-only'>
                    <DragHandle />
                  </span>
                )}
                {allowDuplicate &&
                  <DuplicateButton />}
                {allowRemove &&
                  <RemoveButton onClick={handleRemove} />}
              </div>
            </div>

            <div className='pwrk-frm__item-amounts'>

              <div className='pwrk-frm__item-prices'>

                <MaterialItemsButton>
                  <div className='pwrk-frm__item-price' data-test='item-materials-total'>
                    <label>
                      Materials
                    </label>
                    <span className='pwrk-frm__item-price-input'>
                      <MaterialItemsTotalField />
                    </span>
                  </div>
                </MaterialItemsButton>

                <LabourItemsButton>
                  <div className='pwrk-frm__item-price' data-test='item-labour-total'>
                    <label>
                      {capitalize(t('labour'))}
                    </label>
                    <span className='pwrk-frm__item-price-input'>
                      <LabourItemsTotalField />
                    </span>
                  </div>
                </LabourItemsButton>

                <div className='pwrk-frm__item-price'>
                  <label htmlFor='price'>
                    Price
                  </label>
                  <span className='pwrk-frm__item-price-input'>
                    <MoneyField name='price' testKey='price' />
                  </span>
                </div>

              </div>

              <div className='pwrk-frm__item-totals'>

                {(allowTaxRate || discount > 0) &&
                  (
                    <div className='pwrk-frm__item-total'>
                      <label>
                        {showQuantity ? 'Sub Total / item' : 'Sub Total'}
                      </label>
                      <span className='pwrk-frm__item-total-value' data-test='item-sub-total'>
                        <ItemTotal name='subTotal' />
                      </span>
                    </div>
                  )}

                {showQuantity &&
                  (
                    <div className='pwrk-frm__item-total'>
                      <label>
                        <span className='pwrk-frm__item-total-label'>
                          Qty
                          &nbsp;&times;
                        </span>
                        <Form
                          initialValues={{ quantity: item.quantity }}
                          onChange={values => {
                            dispatch(itemFieldChanged(paperworkUUID, itemUUID, 'quantity', values.quantity))
                          }}
                        >
                          <QuantityField />
                        </Form>
                      </label>
                      <span className='pwrk-frm__item-total-value'>
                        <div>
                          <ItemTotal name='lineSubTotal' />
                        </div>
                      </span>
                    </div>
                  )}

                {discount > 0 && (
                  <div className='pwrk-frm__item-total'>
                    <label htmlFor='discount' data-test='item-discount-label'>
                      Discount
                      &#64;&nbsp;
                      {`${significantDigits(discount.toString())}%`}
                    </label>
                    <span className='pwrk-frm__item-total-value' data-test='item-discount-total'>
                      <ItemTotal name='discount' />
                    </span>
                  </div>
                )}

                {allowTaxRate &&
                  (
                    <div className='pwrk-frm__item-total'>
                      <label htmlFor='taxRate'>
                        <span className='pwrk-frm__item-total-label'>
                          {t('taxLabel')}
                        &nbsp;&#64;
                        </span>
                        <Form
                          initialValues={{ taxRate: item.taxRate }}
                          onChange={values => {
                            dispatch(itemFieldChanged(paperworkUUID, itemUUID, 'taxRate', values.taxRate))
                          }}
                        >
                          <TaxRateSelect name='taxRate' testKey='tax-rate' className='pwrk-frm__input--qty' />
                        </Form>
                      </label>
                      <span className='pwrk-frm__item-total-value'>
                        <ItemTotal name='lineTax' />
                      </span>
                    </div>
                  )}

                <div className='pwrk-frm__item-total pwrk-frm__item-total--bold'>
                  <label>
                    Total
                  </label>
                  <span className='pwrk-frm__item-total-value pwrk-frm__item-total--bold' data-test='item-total'>
                    <ItemTotal name='total' />
                  </span>
                </div>

              </div>

            </div>

          </div>

          {sortIndex === 2 &&
            <HelpPrompt name='saving_price_list' />}

        </Form>
      </ItemWrapper>
    </ItemContext.Provider>
  )
}

Item.defaultProps = {
  sortIndex: 0,
  descriptionPlaceholder: 'Describe what you did…'
}

Item.propTypes = {
  itemUUID: PropTypes.string.isRequired,
  descriptionPlaceholder: PropTypes.string,
  sortIndex: PropTypes.number
}

export default Item
