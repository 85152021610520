import { Controller } from 'stimulus'

export default class ToggleDisabledController extends Controller {
  static targets = ['toggleable']

  toggle () {
    this.toggleableTargets.forEach(target => {
      target.disabled = !target.disabled
    })
  }
}
