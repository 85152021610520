import { toFixed } from 'accounting-js'
/**
 * Returns the input value with trailing zeros removed, up to the first non-zero digit after the decimal point.
 * @param {string|number} stringOrNumber - The input value to format.
 * @returns {string} The formatted value.
 * @example
 * significantDigits('3.1400'); // '3.14'
 * significantDigits('22.'); // '22.'
 * significantDigits('.22'); // '.22'
 */
const significantDigits = stringOrNumber => {
  const string = typeof stringOrNumber === 'number' ? toFixed(stringOrNumber) : stringOrNumber

  if (stringOrNumber === undefined) { return stringOrNumber }
  // requires a decimal place
  if (!string.match(/\./)) { return string }
  // only format if the number is complete
  // (not '22.' or '.22')
  if (string.match(/\.$/)) { return string }
  if (string.match(/^\./)) { return string }

  return string
    .replace(/0+$/, '')
    .replace(/\.$/, '')
}

export default significantDigits
