import React from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'

import createStore from '../store'

const WithStore = ({ children }) => {
  const store = createStore()

  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

WithStore.propTypes = {
  children: PropTypes.node.isRequired
}

export default WithStore
