import React from 'react'
import { useSelector } from 'react-redux'

import { getConfiguration } from '../selectors'

import Select from './select'

const DocumentTitleSelect = props => {
  const { documentTitleOptions } = useSelector(getConfiguration)

  return (<Select options={documentTitleOptions} {...props} />)
}

export default DocumentTitleSelect
