import { connect } from 'react-redux'

import Bell from '../components/bell'
import { fetch } from '../actions'

const mapStateToProps = state => ({
  unreadCount: state.notifications.filter(n => !n.readAt).length
})

const mapDispatchToProps = dispatch => ({
  onBellLoad () {
    dispatch(fetch())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Bell)
