import React from 'react'

const ViewSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M11.842 19.499c-4.333 0-8.636-3.04-11.219-5.884a2.409 2.409 0 0 1-.002-3.232C3.205 7.538 7.513 4.5 11.855 4.5l.151.001.153-.001c4.328 0 8.634 3.038 11.219 5.881.831.923.832 2.312.002 3.233-2.586 2.846-6.897 5.886-11.245 5.886l-.149-.001h-.144zm.011-13.5c-3.851 0-7.753 2.784-10.12 5.39a.91.91 0 0 0 .002 1.22c2.366 2.605 6.267 5.39 10.12 5.39l.131-.001.136.001c3.867 0 7.776-2.785 10.145-5.392a.91.91 0 0 0-.002-1.22C19.897 8.783 15.993 6 12.142 6l-.156.001-.133-.002z' />
      <path d='M11.998 16.498a4.471 4.471 0 0 1-3.182-1.319 4.465 4.465 0 0 1-1.317-3.182 4.505 4.505 0 0 1 4.5-4.499c1.202 0 2.332.468 3.182 1.317a4.468 4.468 0 0 1 1.318 3.181 4.471 4.471 0 0 1-1.319 3.185 4.471 4.471 0 0 1-3.181 1.317h-.001zm.002-7.5c-1.654 0-3 1.345-3 2.999 0 .801.312 1.555.878 2.121a2.98 2.98 0 0 0 2.121.879c.802 0 1.555-.312 2.121-.878s.879-1.32.879-2.121c0-.804-.312-1.557-.879-2.123A2.982 2.982 0 0 0 12 8.998z' />
    </svg>
  </span>
)

export default ViewSvg
