document.addEventListener('app-connect', () => {
  const toggles = [...document.querySelectorAll('[data-toggle]')]

  toggles.forEach(toggle => {
    toggle.addEventListener('click', e => {
      e.preventDefault()
      const target = document.querySelector(toggle.dataset.toggle)
      target.classList.toggle('hidden')
    })
  })
})
