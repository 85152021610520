import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '../../../modal/index'
import MaterialItems from './index'

const Button = ({ children, ...props }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <a
        role='button'
        className='link link--modal'
        tabIndex={0}
        onClick={() => { setShowModal(true) }}
        data-test='materials-button'
      >
        {children}
      </a>
      {
        showModal && (
          <Modal closeText='Done' onClose={() => setShowModal(false)}>
            <MaterialItems {...props} />
          </Modal>
        )
      }
    </>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired
}

export default Button
