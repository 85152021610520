/* eslint react/no-array-index-key: 0 */
/* eslint react/prefer-es6-class: 0 , react/no-unused-prop-types: 0 */
/* eslint react/require-default-props: 0 */
/* eslint react/no-unused-state: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'

const SelectInput = createReactClass({
  displayName: 'SelectInput',
  propTypes: {
    componentId: PropTypes.number,
    inputId: PropTypes.string,
    initialValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    attribute: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    type: PropTypes.string,
    class: PropTypes.string,
    surround: PropTypes.string,
    helpers: PropTypes.arrayOf(PropTypes.element),
    noCreate: PropTypes.bool,
    noRemove: PropTypes.bool,
    for: PropTypes.string
  },
  getDefaultProps () {
    return {
      initialValue: '',
      noCreate: false
    }
  },
  getInitialState () {
    const { initialValue } = this.props
    return {
      errors: [],
      value: initialValue,
      saving: false,
      saved: false
    }
  },
  float () {
    const { type } = this.props
    if (type === 'float') {
      return 1
    }
    return 0
  },
  selectedValue () {
    const { attribute } = this.props
    const { value } = this.state
    return this.typedOption(value[attribute])
  },
  typedOption (option) {
    if (typeof option === 'number') {
      return option.toFixed(this.float())
    }
    return option
  },
  optionValue (option) {
    if (option instanceof Array) {
      return this.typedOption(option[1])
    }
    return this.typedOption(option)
  },
  optionLabel (option) {
    if (option instanceof Array) {
      return this.typedOption(option[0])
    }
    return this.typedOption(option)
  },
  options () {
    const { for: forName, options } = this.props
    return (
      options.map((option, i) => (
        <option
          key={`${forName}-select-option-${i}`}
          value={this.optionValue(option)}
        >
          {this.optionLabel(option[0])}
        </option>
      ))
    )
  },
  render () {
    const { class: className, inputId, onEdit } = this.props
    return (
      <select
        className={className}
        id={inputId}
        type='text'
        onChange={onEdit}
        defaultValue={this.selectedValue()}
      >
        {this.options()}
      </select>
    )
  }
})

export default SelectInput
