import React, { useContext } from 'react'

import { useSelector } from 'react-redux'
import { itemTotals } from '../../selectors'
import { ItemContext } from '../../contexts'
import PseudoMoneyField from '../pseudo-money-field'

const TotalField = () => {
  const { itemUUID } = useContext(ItemContext)
  const amount = useSelector(state => itemTotals(state, itemUUID)).materialItemsTotal

  return (
    <PseudoMoneyField amount={amount} />
  )
}

export default TotalField
