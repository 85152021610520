import React from 'react'

const EditSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M0.748,24c-0.198,0-0.391-0.08-0.531-0.22c-0.186-0.186-0.261-0.461-0.196-0.716l1.77-6.905 c0.006-0.022,0.021-0.063,0.03-0.084c0.001-0.004,0.007-0.02,0.015-0.037c0.041-0.09,0.09-0.163,0.151-0.223L16.513,1.289 C17.338,0.46,18.438,0.003,19.611,0c0,0,0,0,0,0c1.178,0,2.277,0.454,3.106,1.279l0.029,0.029c0.821,0.841,1.264,1.948,1.251,3.121 c-0.014,1.173-0.482,2.269-1.32,3.087L8.183,22.01c-0.062,0.062-0.137,0.113-0.231,0.154c-0.048,0.021-0.078,0.033-0.111,0.042 l-6.908,1.772C0.872,23.993,0.81,24,0.748,24z M1.789,22.209l4.41-1.131l-3.281-3.275L1.789,22.209z M7.657,20.414l13.02-13.02 L16.603,3.32L3.58,16.344L7.657,20.414z M21.736,6.332c1.031-1.125,1.015-2.872-0.059-3.972l-0.02-0.02 C21.113,1.799,20.389,1.5,19.62,1.5V1.125L19.619,1.5c-0.734,0.002-1.421,0.271-1.954,0.762L21.736,6.332z' />
    </svg>
  </span>
)

export default EditSvg
