import { Controller } from 'stimulus'
import creditCardType from 'credit-card-type'

export default class CardHighlightController extends Controller {
  static targets = ['number', 'mastercard', 'visa', 'active', 'inactive', 'brand']

  connect () {
    this.trigger()
  }

  trigger () {
    const cardNumber = this.numberTarget.value
    this.hideActive()
    this.hideInactive()

    if (cardNumber.length < 4) {
      this.reset()
      return true
    }

    const cardType = creditCardType(cardNumber)[0]
    if (cardType) {
      switch (cardType.type) {
        case 'mastercard':
          this.showMastercard()
          break
        case 'visa':
          this.showVisa()
          break
        default:
          this.handleUnsupportedCard()
          break
      }
    } else {
      this.handleUnsupportedCard()
    }

    return true
  }

  hideActive () {
    this.activeTargets.forEach(target => {
      target.closest('span').classList.add('hidden')
    })
  }

  hideInactive () {
    this.inactiveTargets.forEach(target => {
      target.closest('span').classList.add('hidden')
    })
  }

  showActive () {
    this.activeTargets.forEach(target => {
      target.closest('span').classList.remove('hidden')
    })
  }

  showInactive () {
    this.inactiveTargets.forEach(target => {
      target.closest('span').classList.remove('hidden')
    })
  }

  showMastercard () {
    this.mastercardTarget.closest('span').classList.remove('hidden')
    this.visaTargets.forEach(t => {
      if (t.dataset.cardHighlightTarget.includes('inactive')) {
        t.closest('span').classList.remove('hidden')
      }
    })
    this.brandTarget.value = 'master'
  }

  showVisa () {
    this.visaTarget.closest('span').classList.remove('hidden')
    this.mastercardTargets.forEach(t => {
      if (t.dataset.cardHighlightTarget.includes('inactive')) {
        t.closest('span').classList.remove('hidden')
      }
    })
    this.brandTarget.value = 'visa'
  }

  handleUnsupportedCard () {
    this.numberTarget.classList.add('frm__input--error')
    this.showInactive()
  }

  reset () {
    this.showActive()
    this.hideInactive()
    this.numberTarget.classList.remove('frm__input--error')
  }
}
