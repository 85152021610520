import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { LabourItemContext } from '../../../contexts'
import Money from '../../money'
import { labourItemTotals as totals } from '../../../selectors'

const LabourItemTotal = ({ name }) => {
  const { labourItemUUID } = useContext(LabourItemContext)
  const amount = useSelector(state => totals(state, labourItemUUID))[name]

  return (
    <Money amount={amount} />
  )
}

LabourItemTotal.propTypes = {
  name: PropTypes.string.isRequired
}

export default LabourItemTotal
