import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'

const ExpandingTextArea = ({ autoFocus, ...props }) => {
  const textareaRef = useRef(null)

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        textareaRef.current.focus()
      }, 1)
    }
  }, [autoFocus])

  return (
    <TextareaAutosize
      {...props}
      ref={textareaRef}
    />
  )
}

ExpandingTextArea.defaultProps = {
  value: '',
  autoFocus: false,
  focussed: true
}

ExpandingTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  focussed: PropTypes.bool
}

export default ExpandingTextArea
