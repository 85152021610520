import { useEffect } from 'react'

const useBeforeUnload = uploading => {
  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    if (uploading) {
      window.addEventListener('beforeunload', alertUser)
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [uploading])
}

export default useBeforeUnload
