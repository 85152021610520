import React from 'react'

const ArrowRightSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M5.499 24a.752.752 0 0 1-.53-1.281L15.689 12 4.969 1.28c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l10.72 10.72a1.5 1.5 0 0 1 .001 2.119L6.029 23.78a.743.743 0 0 1-.53.22z' />
    </svg>
  </span>
)

export default ArrowRightSvg
