import $ from '@ytb/cash-dom'

document.addEventListener('app-connect', () => {
  $(document).on('click', '#add_labour_rate_button', e => {
    e.preventDefault()
    const $elm = $(e.target).closest('a')
    $elm.prop('disabled', true)
    $('#add_labour_rate_button').hide()
    $('#new_labour_rate_form').load($elm.data('url'), () => {
      $('#new_labour_rate_form').show()
      $('#new_labour_rate_form #labour_rate_description').trigger('focus')
    })
  })

  $(document).on('click', '#new_labour_rate_form .cancel', e => {
    e.preventDefault()
    $('#new_labour_rate_form').empty()
    $('#add_labour_rate_button').prop('disabled', false)
    $('#add_labour_rate_button').show()
  })

  $(document).on('click', '#js-edit-labour-rate', e => {
    e.preventDefault()
    $('#labour_list').load($(e.target).closest('a').data('url'), () => {
      $('#labour_rate_form input#labour_rate_description').trigger('focus')
    })
  })
})
