import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getSection } from '../../selectors'
import { SectionContext } from '../../contexts'
import Heading from './heading'
import Item from './item'

const sectionComponents = {
  Item,
  Heading
}

const Section = ({ sectionUUID, ...otherProps }) => {
  const scrollRef = useRef(null)
  const { type, focussed } = useSelector(state => getSection(state, sectionUUID))
  const SectionType = sectionComponents[type]

  useEffect(() => {
    if (focussed) {
      window.scroll({ top: (scrollRef.current.offsetTop + 80), left: 0, behavior: 'smooth' })
    }
  }, [])

  return (
    <SectionContext.Provider value={{ sectionUUID }}>
      <div ref={scrollRef} className='pwrk-frm__section' data-test='section'>
        <SectionType sectionUUID={sectionUUID} {...otherProps} />
      </div>
    </SectionContext.Provider>
  )
}

Section.propTypes = {
  sectionUUID: PropTypes.string.isRequired
}

export default Section
