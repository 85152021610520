import { Controller } from 'stimulus'

export default class AutoSavedFieldController extends Controller {
  static targets = ['status']

  static values = {
    saved: Boolean
  }

  connect () {
    if (this.savedValue) {
      setTimeout(() => this.statusTarget.classList.add('js-hidden'), 1500)
    }
  }
}
