import React, { useRef, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { SortableHandle } from 'react-sortable-hoc'

import TrashSvg from '@/components/svg/trash'
import MoveUpDownSvg from '@/components/svg/move-up-down'
import {
  labourItemFormChanged,
  labourItemRemove,
  labourAutocompleteChoose,
  labourItemDuplicate
} from '../../actions'
import { getLabourItem, getConfiguration, getTranslation, isLabourItemEmpty, getItem } from '../../selectors'
import labourItemValues from '../../schemas/labour-item-values'
import { ItemContext, LabourItemContext } from '../../contexts'
import Field from '../final-form-field'
import Form from '../form'
import DescriptionField from './description-field'
import MoneyField from '../money-field'
import SaveButton from './save-button'
import UnitTypeSelect from './unit-type-select'
import significantDigits from '../../helpers/significant-digits'
import LabourItemTotal from '../totals/labour-items'
import ItemLabourItemTotal from '../totals/items/labour-item'
import DuplicateSvg from '@/components/svg/duplicate'

const DragHandle = SortableHandle(() => <a className='m-table__row-drag-handle'><MoveUpDownSvg className='icon icon--tiny' /></a>)

const Item = ({ labourItemUUID, isFirstItem }) => {
  const dispatch = useDispatch()
  const { itemUUID } = useContext(ItemContext)
  const item = useSelector(state => getItem(state, itemUUID))
  const labourItem = useSelector(state => getLabourItem(state, labourItemUUID))
  const formValues = labourItemValues(labourItem)
  const { changed, focussed, fromSearch } = labourItem
  const { currencyUnit, defaultLabourMarkupPercentage } = useSelector(getConfiguration)
  const isEmpty = useSelector(state => isLabourItemEmpty(state, labourItemUUID))
  const isFocussed = focussed || (isFirstItem && isEmpty)
  const isMultipliedByItemQuantity = item.quantity > 1
  const t = key => useSelector(state => getTranslation(state, key))

  const unitInput = useRef(null)
  const scrollRef = useRef(null)
  const descriptionFieldRef = useRef(null)

  useEffect(() => {
    if (isFocussed) { scrollRef.current.scrollIntoView() }
  }, [])

  useEffect(() => {
    if (isFocussed) {
      setTimeout(() => {
        descriptionFieldRef.current.focus()
      }, 1)
    }
  }, [])

  const handleRemove = () => {
    if (changed && !window.confirm('Are you sure you want to remove this labour item? It cannot be undone')) {
      return
    }

    dispatch(labourItemRemove(itemUUID, labourItemUUID))
  }
  const handleDuplicate = () => {
    dispatch(labourItemDuplicate(itemUUID, labourItemUUID))
  }
  return (
    <LabourItemContext.Provider value={{ labourItemUUID }}>
      <Form
        initialValues={formValues}
        onChange={values => dispatch(labourItemFormChanged(labourItemUUID, itemUUID, values))}
      >
        <ul ref={scrollRef} className={isEmpty ? 'm-table__row m-table__row--empty' : 'm-table__row'} data-test='labour-item'>
          <li data-label='Labour' className='m-table__cell m-table__cell--title'>
            <DragHandle />
            <DescriptionField
              onResultClick={result => {
                dispatch(labourAutocompleteChoose(labourItemUUID, result))
                unitInput.current.focus()
              }}
              t={t}
              autoFocus={isFocussed}
              domReference={descriptionFieldRef}
            />
          </li>
          <li data-label={isMultipliedByItemQuantity ? 'Qty/Item' : 'Qty'} className='m-table__cell m-table__cell--qty m-table__cell--qty--sml'>
            <Field
              name='units'
              className='m-table__input m-table__input--qty'
              testKey='units'
              placeholder='0'
              inputMode='decimal'
              maxLength='7'
              domReference={unitInput}
              selectOnFocus
              format={significantDigits}
              formatOnBlur
              autoFocus={fromSearch}
            />
          </li>
          <li data-label='Units' className='m-table__cell m-table__cell--units'>
            <UnitTypeSelect
              name='unitType'
              testKey='unit-type'
              className='pwrk-frm__input m-table__input m-table__input--units'
            />
          </li>
          <li data-label={`${currencyUnit} per unit`} className='m-table__cell m-table__cell--price'>
            <MoneyField name='rate' className='m-table__input m-table__input--price' testKey='rate' placeholder='0.00' selectOnFocus />
          </li>
          <li data-label='% markup' className='m-table__cell m-table__cell--markup m-table__cell--markup--sml'>
            <span className='input--percentage'>
              <Field
                name='markupPercentage'
                className='m-table__input m-table__input--markup'
                testKey='markup-percentage'
                placeholder='0'
                selectOnFocus
                format={significantDigits}
                formatOnBlur
                defaultValue={defaultLabourMarkupPercentage}
              />
            </span>
          </li>
          <li data-label='Total' className='m-table__cell m-table__cell--total'>
            <span className='m-table__input--total' data-test='labour-item-total'>
              <LabourItemTotal name='total' />
            </span>
          </li>
          {isMultipliedByItemQuantity && (
            <li data-label='Total qty' className='m-table__cell m-table__cell--total m-table__cell--multiple'>
              <span className='m-table__input--total' data-test='item-labour-item-total'>
                <ItemLabourItemTotal name='total' />
              </span>
            </li>
          )}
          <li className='m-table__cell m-table__cell--actions'>
            <SaveButton />
            <button
              data-test='duplicate-labour-item-button'
              data-tooltip='Duplicate this item'
              title='Duplicate this item'
              className='btn btn--tiny btn--icon-only'
              type='button'
              onClick={handleDuplicate}
            >
              <DuplicateSvg className='icon icon--tiny' />
            </button>
            <button
              data-test='remove-labour-item-button'
              className='btn btn--tiny btn--icon-only'
              type='button'
              onClick={handleRemove}
            >
              <TrashSvg className='icon icon--tiny' />
            </button>
          </li>
        </ul>
      </Form>
    </LabourItemContext.Provider>
  )
}

Item.propTypes = {
  labourItemUUID: PropTypes.string.isRequired,
  isFirstItem: PropTypes.bool.isRequired
}

export default Item
