import {
  SHOW_MESSAGE_TEMPLATE_PANEL,
  HIDE_MESSAGE_TEMPLATE_PANEL,
  TEMPLATE_CHOSEN
} from '../types'

const showTemplatePanel = (state = false, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_TEMPLATE_PANEL:
      return true
    case HIDE_MESSAGE_TEMPLATE_PANEL:
      return false
    case TEMPLATE_CHOSEN:
      return false
    default:
      return state
  }
}

export default showTemplatePanel
