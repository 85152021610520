/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from '@ytb/axios'

import InactiveMessage from './inactive-message'
import SubmittingMessage from './submitting-message'
import ReactivatedMessage from './reactivated-message'
import FailedToReactivateMessage from './failed-to-reactivate-message'

class BouncedEmail extends Component {
  constructor (props) {
    super(props)
    this.state = { status: 'inactive' }
    this.reactivate = this.reactivate.bind(this)
  }

  reactivate () {
    const { email } = this.props
    this.setState({ status: 'submitting' })
    axios.post('/reactivate_email', {
      email
    }).then(() => {
      this.setState({ status: 'active' })
    }).catch(() => {
      this.setState({ status: 'failed' })
    })
  }

  render () {
    const { status } = this.state
    const { email } = this.props
    switch (status) {
      case 'submitting':
        return (
          <SubmittingMessage
            email={email}
          />
        )
      case 'active':
        return (
          <ReactivatedMessage
            email={email}
          />
        )
      case 'failed':
        return (
          <FailedToReactivateMessage
            email={email}
            onReactivate={this.reactivate}
          />
        )
      default:
        return (
          <InactiveMessage
            email={email}
            onReactivate={this.reactivate}
          />
        )
    }
  }
}

BouncedEmail.propTypes = {
  email: PropTypes.string.isRequired
}

export default BouncedEmail
