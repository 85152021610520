import { Controller } from 'stimulus'
import '@simonwep/pickr/dist/themes/nano.min.css'
import Pickr from '@simonwep/pickr'
import debounce from 'lodash/debounce'

export default class ColourSchemeController extends Controller {
  static targets = ['button', 'input', 'undoButton']

  static values = {
    swatches: Array,
    default: String,
    history: { type: Array, default: [] }
  }

  initialize () {
    this.onChange = debounce(this.onChange, 200).bind(this)
  }

  connect () {
    this.picker = Pickr.create({
      el: this.buttonTarget,
      default: this.defaultValue,
      appClass: 'no-shadow',
      theme: 'nano',
      swatches: this.swatchesValue,
      inline: true,
      padding: 20,
      showAlways: true,
      useAsButton: true,
      comparison: false,
      components: {
        preview: true,
        hue: true,

        interaction: {
          input: true,
          clear: false,
          save: false
        }
      }
    })

    this.picker.on('change', this.onChange)
  }

  disconnect () {
    this.picker.destroyAndRemove()
  }

  onChange (colour) {
    this.inputTarget.value = null

    if (colour) {
      const newColour = colour.toHEXA().toString()

      this.addToHistory(newColour)
      this.inputTarget.value = newColour
      this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  undo () {
    this.historyValue = [...this.historyValue.slice(1)]

    const newColour = this.historyValue[0]
    this.picker.setColor(newColour, true)
  }

  historyValueChanged () {
    if (this.historyValue.length > 1) {
      this.undoButtonTarget.classList.remove('hidden')
    } else {
      this.undoButtonTarget.classList.add('hidden')
    }
  }

  addToHistory (value) {
    const lastEntry = this.historyValue[0]

    if (lastEntry !== value) {
      this.historyValue = [value, ...this.historyValue]
    }
  }
}
