import { Controller } from 'stimulus'

export default class TurboFrameRefreshController extends Controller {
  static values = {
    delay: { type: Number, default: 1000 },
    src: String
  }

  connect () {
    setTimeout(() => this.refresh(), this.delayValue)
  }

  refresh () {
    this.element.setAttribute('src', this.srcValue)
  }
}
