import moment from 'moment'

window.App = window.App || {}

const localeDateFormat = window.App.locale === 'en-us' ? 'dddd, MMMM D' : 'dddd D MMMM'
const localeDateFormatWithYear = window.App.locale === 'en-us' ? 'dddd, MMMM D, YYYY' : 'dddd D MMMM YYYY'

const formatDate = date => (
  moment(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    lastWeek: localeDateFormat,
    nextWeek: localeDateFormat,
    sameElse: now => {
      if (moment(date).year() === now.year()) {
        return localeDateFormat
      }

      return localeDateFormatWithYear
    }
  })
)

export default formatDate
