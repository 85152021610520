import { combineReducers } from 'redux'

import notifications from './notifications'
import showPanel from './show-panel'

const appReducer = combineReducers({
  notifications,
  showPanel
})

export default appReducer
