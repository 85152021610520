/* eslint no-alert: 0 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { reset } from './actions'
import App from './app'

const Discard = ({ id, href }) => {
  const dispatch = useDispatch()
  const {
    subjectEdited,
    bodyEdited
  } = useSelector(state => state.content[state.content.current])

  const confirmDialog = () => window.confirm('Are you sure you want to discard this message?')

  const handleClick = event => {
    if ((bodyEdited || subjectEdited) && !confirmDialog()) {
      event.preventDefault()
      return
    }

    dispatch(reset(id))
  }

  return (
    <a
      href={href}
      onClick={handleClick}
      className='btn btn--lrg btn--white'
    >
      Discard
    </a>
  )
}

Discard.propTypes = {
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}

export default props => (
  <App>
    <Discard {...props} />
  </App>
)
