/* eslint camelcase:0 */
const itemValues = obj => {
  const {
    price,
    quantity,
    title,
    taxRate,
    optional
  } = obj

  const quantityNumber = Number(quantity)
  const quantityInteger = Math.floor(quantityNumber)
  const quantityFinal = quantityInteger >= 0 ? quantityInteger : 1

  return {
    price,
    title,
    quantity: quantityFinal,
    taxRate,
    optional
  }
}

export default itemValues
