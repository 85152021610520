/* eslint react/no-did-mount-set-state: 0 */
import React from 'react'
import { useSelector } from 'react-redux'

import FileUploaderEmailAttachment from '../../file-uploader/email-attachments'

const EmailAttachment = () => {
  const attachments = useSelector(
    state => state.content[state.content.current].attachments
  )

  return (
    <FileUploaderEmailAttachment
      initialFiles={attachments}
      uploadsEnabled={false}
      inputName='email_template_attachments'
      attachableType='EmailTemplate'
    />
  )
}

export default EmailAttachment
