/* global IntersectionObserver, getComputedStyle */
import { Controller } from 'stimulus'

export default class StickyElement extends Controller {
  connect () {
    this.element.classList.add(this.pinnedClass)
    this.setupObserver()
    this.intersectionObserver.observe(this.element)
  }

  disconnect () {
    this.element.classList.remove(this.pinnedClass)
    this.intersectionObserver.unobserve(this.element)
  }

  processIntersectionEntries (entries) {
    entries.forEach(entry => {
      entry.target.classList.toggle(
        'stuck',
        entry.intersectionRatio < 1
      )
    })
  }

  setupObserver () {
    const options = {
      rootMargin: `-${this.topMargin}px 0px 0px 0px`,
      threshold: 1
    }

    this.intersectionObserver = new IntersectionObserver(
      entries => this.processIntersectionEntries(entries),
      options
    )
  }

  // private

  get pinnedClass () {
    return this.data.get('sticky-class') || 'sticky-element'
  }

  get topMargin () {
    const topPosition = parseInt(getComputedStyle(this.element).top.slice(0, -2))
    return Number.isNaN(topPosition) ? 1 : topPosition + 1
  }
}
