import React from 'react'

import App from './app'
import Subject from './components/subject'

export default props => (
  <App>
    <Subject {...props} />
  </App>
)
