import { put, takeLatest, all, call } from 'redux-saga/effects'
import axios from '@ytb/axios'

import createFlashMessage from '@/application/flashes'
import { INIT, FETCH_COMPANY_USERS_FAILURE } from '../types'
import { fetchCompanyUsersSuccess, fetchCompanyUsersFailure } from '../actions'

export function * fetchCompanyUsersSaga () {
  try {
    const json = yield axios.get(
      '/schedule/company_users.json'
    ).then(response => response.data)

    yield put(fetchCompanyUsersSuccess(json))
  } catch (e) {
    yield put(fetchCompanyUsersFailure())
  }
}

export function * fetchCompanyUsersFailureSaga () {
  yield call(createFlashMessage, 'Unable to fetch assignees, please refresh the page', 'alert')
}

export default function * companyUsersSaga () {
  yield all([
    takeLatest(INIT, fetchCompanyUsersSaga),
    takeLatest(FETCH_COMPANY_USERS_FAILURE, fetchCompanyUsersFailureSaga)
  ])
}
