import React, { useRef, useCallback, useEffect, useReducer, Fragment } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { camelizeKeys } from 'humps'
import { Waypoint } from 'react-waypoint'

import { materialSearch } from '../../services'
import Results from './results'
import EmptyMaterials from './empty-materials'
import Loading from '../search/loading'
import NoMoreItems from '../search/no-more-items'
import NoResults from '../search/no-results'
import ClearSearchButton from '../search/clear-search-button'
import KeyboardHints from '../search/keyboard-hints'
import MaterialFilters from './filters'

const perPage = 25

const initialState = {
  page: 1,
  query: '',
  category: '',
  brand: '',
  results: [],
  atEnd: false,
  loading: true
}

function reducer (state, { type, payload }) {
  switch (type) {
    case 'search':
      return {
        ...initialState,
        query: payload,
        loading: true
      }
    case 'results':
      return {
        ...state,
        atEnd: payload.results.length < perPage,
        results: payload.results,
        loading: false
      }
    case 'filterByCategory':
      return {
        ...state,
        category: payload
      }
    case 'filterByBrand':
      return {
        ...state,
        brand: payload
      }
    case 'nextPage':
      return {
        ...state,
        page: state.page + 1,
        loading: true
      }
    default:
      throw new Error()
  }
}

const MaterialSearch = ({ onResultClick }) => {
  const inputRef = useRef(null)

  const [state, dispatch] = useReducer(reducer, initialState)
  const { results, query, page, loading, atEnd, category, brand } = state

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus()
    }, 200)
  }, [query])

  const performSearch = useCallback(debounce((newQuery, newPage, newPerPage, categoryFilter, brandFilter) => {
    materialSearch(newQuery, newPage, newPerPage, categoryFilter, brandFilter)
      .then(response => {
        dispatch({
          type: 'results',
          payload: {
            query: newQuery,
            results: camelizeKeys(response.data.items)
          }
        })
      })
  }, 400), [])

  useEffect(() => performSearch(query, page, perPage, category, brand), [query, page, category, brand])

  const search = str => dispatch({ type: 'search', payload: str })
  const filterByCategory = categoryFilter => dispatch({ type: 'filterByCategory', payload: categoryFilter })
  const filterByBrand = brandFilter => dispatch({ type: 'filterByBrand', payload: brandFilter })

  return (
    <div data-test='material-search-modal'>
      <div className='c-panel__header c-panel__header--fixed'>
        <h1 className='c-panel__header-title u-mrgn-btm--x6'>
          <strong>Add from material list</strong>
        </h1>
        <div className='srch-tlbr'>
          <div className='input--search'>
            <input
              ref={inputRef}
              value={query}
              data-test='material-search-query'
              onChange={event => search(event.target.value)}
              className='frm__input frm__input--light'
            />
            <span className='srch-tlbr__filters' data-test='filters'>
              {query !== '' && (
                <ClearSearchButton onClick={() => search('')} />
              )}

              <MaterialFilters
                onCategorySelect={categoryFilter => filterByCategory(categoryFilter)}
                selectedCategory={category}
                onBrandSelect={brandFilter => filterByBrand(brandFilter)}
                selectedBrand={state.brand}
              />
            </span>
          </div>
        </div>
        {results.length !== 0 && (
          <KeyboardHints />
        )}
      </div>
      <Results items={results} onResultClick={onResultClick} selectFirst={query !== ''} />
      {loading && <Loading />}
      {loading || (
        <>
          {query === '' && results.length === 0 && <EmptyMaterials />}
          {query !== '' && results.length === 0 && <NoResults resource='materials' query={query} />}
          {atEnd && (results.length > perPage) && <NoMoreItems />}
          {atEnd || <Waypoint onEnter={() => dispatch({ type: 'nextPage' })} bottomOffset='-750px' />}
        </>
      )}
    </div>
  )
}

MaterialSearch.propTypes = {
  onResultClick: PropTypes.func.isRequired
}

export default MaterialSearch
