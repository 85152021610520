const truncatedFileName = name => {
  if (name.length > 32) {
    const lastDotPosition = name.lastIndexOf('.')
    const extension = name.slice(lastDotPosition - 4)
    return `${name.slice(0, 32 - extension.length)}...${extension}`
  }

  return name
}

export default truncatedFileName
