/* global IntersectionObserver */

const postReactNativeMessage = (event, payload = {}) => {
  if (window.ReactNativeWebView === undefined) { return }

  window.ReactNativeWebView.postMessage(JSON.stringify({ event, payload }))
}

const appTopObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      postReactNativeMessage('onscroll', { scrollTop: 0 })
    } else {
      postReactNativeMessage('onscroll', { scrollTop: 1 })
    }
  })
})

document.addEventListener('app-connect', () => {
  const appTopElement = document.getElementById('app-top')
  if (appTopElement) { appTopObserver.observe(appTopElement) }
})

document.addEventListener('app-disconnect', () => {
  const appTopElement = document.getElementById('app-top')
  if (appTopElement) { appTopObserver.unobserve(appTopElement) }
})

document.addEventListener('modal-open', () => {
  postReactNativeMessage('modal-open')
})

document.addEventListener('modal-close', () => {
  postReactNativeMessage('modal-close')
})

document.addEventListener('app-foreground', () => {
  document.dispatchEvent(new Event('loader-refresh'))
})
