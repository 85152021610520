export const getConfiguration = state => state.configuration
export const getTranslation = (state, key) => {
  const translations = getConfiguration(state).translations || {}
  const translation = translations[key]

  if (translation) { return translation }

  return `translation '${key}' missing`
}

export const getHelpPrompt = (state, key) => {
  const helpPrompts = getConfiguration(state).helpPrompts || {}
  const helpPrompt = helpPrompts[key]

  if (helpPrompt) { return helpPrompt }

  return {
    name: '',
    orientation: '',
    visible: false,
    content: '',
    closeUrl: ''
  }
}
