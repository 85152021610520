import React from 'react'

const AttachmentSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M7.5 23.604c-2.003 0-3.887-.78-5.303-2.197S0 18.108 0 16.104s.78-3.887 2.197-5.303l9.016-9.015A5.208 5.208 0 0114.889.193l.12-.001a5.19 5.19 0 013.618 1.474 5.218 5.218 0 011.6 3.685 5.214 5.214 0 01-1.601 3.868l-9.004 9.01a3 3 0 01-2.097.852h-.047a2.984 2.984 0 01-2.107-.915 2.989 2.989 0 01-.001-4.168l9.025-9.028a.748.748 0 011.06-.001.75.75 0 010 1.061L6.44 15.048a1.49 1.49 0 00.009 2.075c.278.288.652.45 1.053.457h.032c.387 0 .754-.15 1.036-.422l9.006-9.011a3.755 3.755 0 00.009-5.403 3.73 3.73 0 00-2.604-1.053l-.066.001a3.726 3.726 0 00-2.632 1.144L3.258 11.86C2.124 12.995 1.5 14.501 1.5 16.104s.624 3.109 1.757 4.243c1.131 1.131 2.638 1.754 4.243 1.754s3.111-.623 4.243-1.754L22.72 9.369a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53L12.803 21.407A7.448 7.448 0 017.5 23.604z' />
    </svg>
  </span>
)

export default AttachmentSvg
