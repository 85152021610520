import { Controller } from 'stimulus'
import camelCase from 'lodash/camelCase'
import App from '../application/app'

export default class AddressAutocompleteController extends Controller {
  static targets = ['input', 'postcode']

  static values = {
    enabled: { type: Boolean, default: true }
  }

  initialize () {
    this.placeSelected = this.placeSelected.bind(this)
  }

  connect () {
    this.loadGooglePlaces()

    if (typeof window.google !== 'undefined') {
      this.initAutocomplete()
    }
  }

  disableAutocomplete (event) {
    if (event.code === 'Enter' && this.enabledValue) {
      this.enabledValue = false
      window.google.maps.event.removeListener(this.placeSelectedListener)
      window.google.maps.event.clearInstanceListeners(this.inputTarget)
      document.querySelector('.pac-container').remove()
    }
  }

  enableAutocomplete () {
    if (this.inputTarget.value.replace(/\s/g, '') === '' && !this.enabledValue) {
      this.enabledValue = true
      this.initAutocomplete()
    }
  }

  // Needs to be fired after the google places script loads and emits the google-maps-callback event:
  // data-action="google-maps-callback@window->address-autocomplete#initAutocomplete"
  initAutocomplete () {
    if (this.enabledValue) {
      this.autoComplete = new window.google.maps.places.Autocomplete(this.inputTarget, {
        fields: ['address_components'],
        types: ['address']
      })

      this.placeSelectedListener = this.autoComplete.addListener('place_changed', this.placeSelected)
    }
  }

  placeSelected () {
    const place = this.autoComplete.getPlace()

    if (!place.address_components) return

    const addressComponents = this.formattedAddress(place.address_components)
    const { streetNumber, route, locality, postalTown, postalCode, postalCodeSuffix } = addressComponents
    const firstLine = [streetNumber, route].filter(Boolean).join(' ')

    this.inputTarget.value = [firstLine, locality, postalTown].filter(Boolean).join('\n')
    this.postcodeTarget.value = [postalCode, postalCodeSuffix].filter(Boolean).join('-')
  }

  loadGooglePlaces () {
    if (document.getElementById('google-places-script')) return

    window.initMap = () => {
      const event = new Event('google-maps-callback', {
        bubbles: true,
        cancelable: true
      })
      window.dispatchEvent(event)
    }

    const script = document.createElement('script')
    script.id = 'google-places-script'
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=${App.googleMapsApiKey}&libraries=places&callback=initMap`
    document.body.appendChild(script)
  }

  formattedAddress (addressComponents) {
    if (!addressComponents) { return [] }

    return addressComponents.reduce((acc, component) => {
      const type = component.types[0]

      acc[camelCase(type)] = component.long_name

      return acc
    }, {})
  }
}
