/* global ga */

const trackClick = event => {
  const eventCategory = event.target.getAttribute('data-ga-event-category')
  const eventAction = event.target.getAttribute('data-ga-event-action')
  const eventLabel = event.target.innerHTML
  ga('send', {
    hitType: 'event',
    eventCategory,
    eventAction,
    eventLabel
  })
}

document.addEventListener('app-connect', () => {
  Array.from(document.querySelectorAll('[data-ga-event-action]')).forEach(element => {
    element.addEventListener('click', trackClick, false)
  })
})
