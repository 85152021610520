import { v4 as uuidv4 } from 'uuid'

import {
  INIT,
  RESET,
  ENTITIES_ADD,
  ITEM_ADD,
  ITEM_DUPLICATE,
  ITEM_FORM_CHANGED,
  ITEM_FIELD_CHANGED,
  ITEM_SAVE,
  ITEM_SAVE_FAILURE,
  ITEM_SAVE_SUCCESS,
  ITEM_SEARCH_CHOOSE,
  ITEM_TOGGLE_OPTIONAL,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_QUANTITY_INCREMENT,
  ITEM_QUANTITY_DECREMENT,
  LABOUR_AUTOCOMPLETE_CHOOSE,
  LABOUR_SEARCH_CHOOSE,
  LABOUR_ITEM_ADD,
  LABOUR_ITEM_FORM_CHANGED,
  LABOUR_ITEM_REMOVE,
  LABOUR_ITEM_POSITION_CHANGE,
  LABOUR_ITEM_SAVE,
  LABOUR_ITEM_SAVE_SUCCESS,
  LABOUR_ITEM_SAVE_FAILURE,
  MATERIAL_AUTOCOMPLETE_CHOOSE,
  MATERIAL_SEARCH_CHOOSE,
  MATERIAL_ITEM_ADD,
  MATERIAL_ITEM_FORM_CHANGED,
  MATERIAL_ITEM_REMOVE,
  MATERIAL_ITEM_POSITION_CHANGE,
  MATERIAL_ITEM_SAVE,
  MATERIAL_ITEM_SAVE_SUCCESS,
  MATERIAL_ITEM_SAVE_FAILURE,
  PAPERWORK_DISCARD,
  PAPERWORK_REMOVE,
  PAPERWORK_FETCH,
  PAPERWORK_FETCH_FAILURE,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_DUPLICATE,
  PAPERWORK_DUPLICATE_SUCCESS,
  PAPERWORK_DUPLICATE_FAILURE,
  PAPERWORK_FORM_CHANGED,
  PAPERWORK_FIELD_CHANGED,
  PAPERWORK_SAVE_FAILURE,
  PAPERWORK_SAVE_SUCCESS,
  PAPERWORK_SUBMIT,
  PAPERWORK_REFERENCE_UNAVAILABLE,
  PAPERWORK_DISCOUNT_CHANGED,
  PAPERWORK_DISCOUNT_REMOVE,
  SECTION_POSITION_CHANGE,
  HEADING_ADD,
  SECTION_REMOVE,
  SECTION_FORM_CHANGED,
  SECTION_FIELD_CHANGED,
  MATERIAL_ITEM_DUPLICATE,
  LABOUR_ITEM_DUPLICATE
} from '../types'

export const entitiesAdd = entities => ({
  type: ENTITIES_ADD,
  payload: {
    entities
  }
})

export const itemAdd = (paperworkUUID, byUser = true) => ({
  type: ITEM_ADD,
  payload: {
    itemUUID: uuidv4(),
    paperworkUUID,
    byUser
  }
})

export const itemDuplicate = itemUUID => ({
  type: ITEM_DUPLICATE,
  payload: {
    itemUUID
  }
})

export const headingAdd = (paperworkUUID, byUser = true) => ({
  type: HEADING_ADD,
  payload: {
    sectionUUID: uuidv4(),
    paperworkUUID,
    byUser
  }
})

export const sectionFormChanged = (paperworkUUID, sectionUUID, values) => ({
  type: SECTION_FORM_CHANGED,
  payload: {
    paperworkUUID,
    sectionUUID,
    values
  }
})

export const sectionFieldChanged = (paperworkUUID, sectionUUID, name, value) => ({
  type: SECTION_FIELD_CHANGED,
  payload: {
    paperworkUUID,
    sectionUUID,
    name,
    value
  }
})

export const sectionRemove = (paperworkUUID, sectionUUID) => ({
  type: SECTION_REMOVE,
  payload: {
    paperworkUUID,
    sectionUUID
  }
})

export const sectionPositionChange = (
  paperworkUUID,
  oldIndex,
  newIndex
) => ({
  type: SECTION_POSITION_CHANGE,
  payload: {
    paperworkUUID,
    oldIndex,
    newIndex
  }
})

export const itemSave = itemUUID => ({
  type: ITEM_SAVE,
  payload: {
    itemUUID
  }
})

export const itemSaveSuccess = itemUUID => ({
  type: ITEM_SAVE_SUCCESS,
  payload: {
    itemUUID
  }
})

export const itemSaveFailure = (itemUUID, error) => ({
  type: ITEM_SAVE_FAILURE,
  payload: {
    itemUUID,
    error
  }
})

export const itemToggleOptional = itemUUID => ({
  type: ITEM_TOGGLE_OPTIONAL,
  payload: {
    itemUUID
  }
})

export const itemQuantityIncrement = itemUUID => ({
  type: ITEM_QUANTITY_INCREMENT,
  payload: {
    itemUUID
  }
})

export const itemQuantityDecrement = itemUUID => ({
  type: ITEM_QUANTITY_DECREMENT,
  payload: {
    itemUUID
  }
})

export const itemFormChanged = (paperworkUUID, itemUUID, values) => ({
  type: ITEM_FORM_CHANGED,
  payload: {
    paperworkUUID,
    itemUUID,
    values
  }
})

export const itemFieldChanged = (paperworkUUID, itemUUID, name, value) => ({
  type: ITEM_FIELD_CHANGED,
  payload: {
    paperworkUUID,
    itemUUID,
    name,
    value
  }
})

export const itemAutocompleteChoose = (paperworkUUID, itemUUID, data) => ({
  type: ITEM_AUTOCOMPLETE_CHOOSE,
  payload: {
    paperworkUUID,
    itemUUID,
    data
  }
})

export const itemSearchChoose = (paperworkUUID, data) => ({
  type: ITEM_SEARCH_CHOOSE,
  payload: {
    paperworkUUID,
    data
  }
})

export const init = configuration => ({
  type: INIT,
  payload: {
    configuration
  }
})

export const reset = () => ({
  type: RESET
})

export const materialAutocompleteChoose = (materialItemUUID, item) => ({
  type: MATERIAL_AUTOCOMPLETE_CHOOSE,
  payload: {
    materialItemUUID,
    item
  }
})

export const materialSearchChoose = (itemUUID, data) => ({
  type: MATERIAL_SEARCH_CHOOSE,
  payload: {
    itemUUID,
    data
  }
})

export const materialItemAdd = (paperworkUUID, itemUUID, byUser = true) => ({
  type: MATERIAL_ITEM_ADD,
  payload: {
    materialItemUUID: uuidv4(),
    paperworkUUID,
    itemUUID,
    byUser
  }
})

export const materialItemFormChanged = (materialItemUUID, itemUUID, values) => ({
  type: MATERIAL_ITEM_FORM_CHANGED,
  payload: {
    materialItemUUID,
    itemUUID,
    values
  }
})

export const materialItemRemove = (itemUUID, materialItemUUID) => ({
  type: MATERIAL_ITEM_REMOVE,
  payload: {
    itemUUID,
    materialItemUUID
  }
})

export const materialItemDuplicate = (itemUUID, materialItemUUID) => ({
  type: MATERIAL_ITEM_DUPLICATE,
  payload: {
    itemUUID,
    materialItemUUID,
    newUUID: uuidv4()
  }
})

export const materialItemPositionChange = (
  itemUUID,
  oldIndex,
  newIndex
) => ({
  type: MATERIAL_ITEM_POSITION_CHANGE,
  payload: {
    itemUUID,
    oldIndex,
    newIndex
  }
})

export const materialItemSave = materialItemUUID => ({
  type: MATERIAL_ITEM_SAVE,
  payload: {
    materialItemUUID
  }
})

export const materialItemSaveSuccess = materialItemUUID => ({
  type: MATERIAL_ITEM_SAVE_SUCCESS,
  payload: {
    materialItemUUID
  }
})

export const materialItemSaveFailure = (materialItemUUID, error) => ({
  type: MATERIAL_ITEM_SAVE_FAILURE,
  payload: {
    materialItemUUID,
    error
  }
})

export const labourAutocompleteChoose = (labourItemUUID, item) => ({
  type: LABOUR_AUTOCOMPLETE_CHOOSE,
  payload: {
    labourItemUUID,
    item
  }
})

export const labourSearchChoose = (itemUUID, data) => ({
  type: LABOUR_SEARCH_CHOOSE,
  payload: {
    itemUUID,
    data
  }
})

export const labourItemAdd = (paperworkUUID, itemUUID, byUser = true) => ({
  type: LABOUR_ITEM_ADD,
  payload: {
    labourItemUUID: uuidv4(),
    paperworkUUID,
    itemUUID,
    byUser
  }
})

export const labourItemFormChanged = (labourItemUUID, itemUUID, values) => ({
  type: LABOUR_ITEM_FORM_CHANGED,
  payload: {
    labourItemUUID,
    itemUUID,
    values
  }
})

export const labourItemRemove = (itemUUID, labourItemUUID) => ({
  type: LABOUR_ITEM_REMOVE,
  payload: {
    itemUUID,
    labourItemUUID
  }
})

export const labourItemDuplicate = (itemUUID, labourItemUUID) => ({
  type: LABOUR_ITEM_DUPLICATE,
  payload: {
    itemUUID,
    labourItemUUID,
    newUUID: uuidv4()
  }
})

export const labourItemPositionChange = (itemUUID, oldIndex, newIndex) => ({
  type: LABOUR_ITEM_POSITION_CHANGE,
  payload: {
    itemUUID,
    oldIndex,
    newIndex
  }
})

export const labourItemSave = labourItemUUID => ({
  type: LABOUR_ITEM_SAVE,
  payload: {
    labourItemUUID
  }
})

export const labourItemSaveSuccess = labourItemUUID => ({
  type: LABOUR_ITEM_SAVE_SUCCESS,
  payload: {
    labourItemUUID
  }
})

export const labourItemSaveFailure = (labourItemUUID, error) => ({
  type: LABOUR_ITEM_SAVE_FAILURE,
  payload: {
    labourItemUUID,
    error
  }
})

export const paperworkFormChanged = (paperworkUUID, values, modifiedFields) => ({
  type: PAPERWORK_FORM_CHANGED,
  payload: {
    paperworkUUID,
    values,
    modifiedFields
  }
})

export const paperworkFieldChanged = (paperworkUUID, name, value) => ({
  type: PAPERWORK_FIELD_CHANGED,
  payload: {
    paperworkUUID,
    name,
    value
  }
})

export const paperworkSubmit = paperworkUUID => ({
  type: PAPERWORK_SUBMIT,
  payload: {
    paperworkUUID
  }
})

export const paperworkRemove = paperworkUUID => ({
  type: PAPERWORK_REMOVE,
  payload: {
    paperworkUUID
  }
})

export const paperworkDiscard = paperworkUUID => ({
  type: PAPERWORK_DISCARD,
  payload: {
    paperworkUUID
  }
})

export const paperworkSaveSuccess = (paperworkUUID, data) => ({
  type: PAPERWORK_SAVE_SUCCESS,
  payload: {
    paperworkUUID,
    data
  }
})

export const paperworkSaveFailure = (paperworkUUID, error) => ({
  type: PAPERWORK_SAVE_FAILURE,
  payload: {
    paperworkUUID,
    error
  }
})

export const paperworkFetch = () => ({
  type: PAPERWORK_FETCH
})

export const paperworkFetchSuccess = data => ({
  type: PAPERWORK_FETCH_SUCCESS,
  payload: {
    data
  }
})

export const paperworkDuplicate = path => ({
  type: PAPERWORK_DUPLICATE,
  payload: {
    path
  }
})

export const paperworkDuplicateSuccess = (data, paperworkType = 'default') => ({
  type: PAPERWORK_DUPLICATE_SUCCESS,
  payload: {
    data,
    paperworkType
  }
})

export const paperworkDuplicateFailure = error => ({
  type: PAPERWORK_DUPLICATE_FAILURE,
  payload: {
    error
  }
})

export const paperworkFetchFailure = error => ({
  type: PAPERWORK_FETCH_FAILURE,
  payload: {
    error
  }
})

export const paperworkReferenceUnavailable = (paperworkUUID, nextNumber) => ({
  type: PAPERWORK_REFERENCE_UNAVAILABLE,
  payload: {
    paperworkUUID,
    nextNumber
  }
})

export const paperworkDiscountRemove = paperworkUUID => ({
  type: PAPERWORK_DISCOUNT_REMOVE,
  payload: {
    paperworkUUID
  }
})

export const paperworkDiscountChanged = (paperworkUUID, value) => ({
  type: PAPERWORK_DISCOUNT_CHANGED,
  payload: {
    paperworkUUID,
    value
  }
})
