import React from 'react'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'

import RichText from '../../rich-text'
import fromMarkdown from '../../rich-text/helpers/from-markdown'

import { bodyChanged } from '../actions'

const startingParagraphPrefix = text => {
  if (/^\n+/.test(text)) {
    return '<p><br><br></p>'
  }

  return ''
}

const Body = () => {
  const dispatch = useDispatch()
  const {
    uuid,
    body,
    subject,
    subjectEdited,
    focussed
  } = useSelector(state => state.content[state.content.current])
  const autoFocus = (subject !== '' && !subjectEdited)
  const formattedBody = startingParagraphPrefix(body) + fromMarkdown(body)
  const debouncedBodyChange = debounce(text => {
    dispatch(bodyChanged(text))
  }, 400)

  return (
    <RichText
      key={uuid}
      value={formattedBody}
      onChange={debouncedBodyChange}
      autoFocus={autoFocus}
      focussed={focussed}
      name='message_form[body]'
      id='message_form_body'
    />
  )
}

export default Body
