import React from 'react'
import PropTypes from 'prop-types'

const Color = ({ activeColor, arrayIndex, color, initials, setColor }) => {
  const handleClick = () => {
    setColor(color)
  }

  return (
    <span
      onClick={handleClick}
      role='menuitem'
      tabIndex={arrayIndex}
      className={`company-user__initials company-user__initials--lrg company-user__initials--${color} ${activeColor === color ? 'company-user__initials--selected' : ''} u-mrgn-r`}
      data-test={`color-${arrayIndex}`}
    >
      {initials}
    </span>
  )
}

Color.propTypes = {
  activeColor: PropTypes.string.isRequired,
  arrayIndex: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  initials: PropTypes.string,
  setColor: PropTypes.func.isRequired
}

Color.defaultProps = {
  initials: ''
}

export default Color
