import { Controller } from 'stimulus'

export default class CheckboxTogglerController extends Controller {
  static targets = ['checkbox']

  toggle (e) {
    e.preventDefault()

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = !checkbox.checked
    })
  }
}
