import React from 'react'
import { useSelector } from 'react-redux'

import { getConfiguration } from '../selectors'
import { PaperworkContext } from '../contexts'
import Estimate from './estimate'
import Invoice from './invoice'
import Job from './job'
import PriceList from './price-list'
import Trade from './trade'
import Debug from './debug'

const paperworkComponents = {
  estimate: Estimate,
  invoice: Invoice,
  job: Job,
  priceList: PriceList,
  trade: Trade,
  test: Invoice,
  estimateTemplate: Estimate
}

const Paperwork = () => {
  const { paperworkUUID, paperworkType, debug } = useSelector(getConfiguration)
  const PaperworkType = paperworkComponents[paperworkType]

  return (
    <div id='paperwork'>
      <PaperworkContext.Provider value={{ paperworkUUID }}>
        {debug && <Debug />}
        <PaperworkType />
      </PaperworkContext.Provider>
    </div>
  )
}

export default Paperwork
