import React from 'react'
import PropTypes from 'prop-types'

const UploadDetails = ({ name, date, size, companyHasFieldStaff }) => (
  <p className='file-thumb__meta' data-test='upload-details'>
    {companyHasFieldStaff &&
      (
        <span>
          {`${name}`}
          <br />
        </span>
      )}
    {`${date}`}
    <br />
    {`${size}`}
  </p>
)

UploadDetails.defaultProps = {
  name: '',
  size: '',
  companyHasFieldStaff: false
}

UploadDetails.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.string,
  companyHasFieldStaff: PropTypes.bool
}

export default UploadDetails
