import React from 'react'
import PropTypes from 'prop-types'

const TradeAccreditation = ({ url, largeUrl, srcset, name, handleClick }) => (
  <div className='flex-grid__cell u-pad-all--x2'>
    <button type='button' className='btn--clear btn--no-border u-pad-all--x0' onClick={e => { e.preventDefault(); handleClick(largeUrl) }}>
      <span className='accreditation-preloaded'>
        <img src={url} srcSet={srcset} alt={name} />
      </span>
    </button>
  </div>
)

TradeAccreditation.propTypes = {
  url: PropTypes.string.isRequired,
  largeUrl: PropTypes.string.isRequired,
  srcset: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func
}

TradeAccreditation.defaultProps = {
  handleClick: () => {}
}

export default TradeAccreditation
