import arrayMove from 'array-move'
import capitalize from 'lodash/capitalize'
import camelCase from 'lodash/camelCase'
import moment from 'moment'

import {
  createReducer,
  updateEntity,
  removeObjectKey,
  removeStaleEntities,
  updateEntities
} from './util'
import { invoiceValuesUnduplicatable } from '../schemas/invoice-values'
import { estimateValuesUnduplicatable } from '../schemas/estimate-values'

import {
  RESET,
  ITEM_ADD,
  SECTION_REMOVE,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_FORM_CHANGED,
  ITEM_FIELD_CHANGED,
  ITEM_SEARCH_CHOOSE,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_DUPLICATE_SUCCESS,
  PAPERWORK_FORM_CHANGED,
  PAPERWORK_FIELD_CHANGED,
  PAPERWORK_REMOVE,
  PAPERWORK_SAVE_SUCCESS,
  PAPERWORK_DISCOUNT_CHANGED,
  PAPERWORK_DISCOUNT_REMOVE,
  PAPERWORK_REFERENCE_UNAVAILABLE,
  SECTION_POSITION_CHANGE,
  SECTION_FIELD_CHANGED,
  SECTION_FORM_CHANGED,
  HEADING_ADD,
  ENTITIES_ADD
} from '../types'

const reset = () => ({})

function calculateDateDifference (dateName, oldStartDate, newStartDate, endDate) {
  if (endDate === undefined || oldStartDate === newStartDate) { return {} }
  const dateFormat = 'YYYY-MM-DD'
  const duration = moment(endDate, dateFormat).diff(moment(oldStartDate, dateFormat), 'days')
  return { [dateName]: moment(newStartDate, dateFormat).add(duration, 'days').format(dateFormat) }
}

const paperworkFormChanged = (state, { payload: { paperworkUUID, values } }) => updateEntity(
  state,
  paperworkUUID,
  {
    ...values,
    ...calculateDateDifference(
      'dueDate',
      state[paperworkUUID].date,
      values.date,
      state[paperworkUUID].dueDate
    ),
    ...calculateDateDifference(
      'validUntil',
      state[paperworkUUID].date,
      values.date,
      state[paperworkUUID].validUntil
    )
  }
)

const entitiesAdd = (state, { payload: { entities: { paperwork } } }) => (
  updateEntities(state, paperwork)
)

const saveableFieldChanged = (
  state,
  {
    payload: {
      paperworkUUID,
      name,
      value
    }
  }
) => {
  if (state[paperworkUUID] && state[paperworkUUID][name] === value) { return state }
  return updateEntity(
    state,
    paperworkUUID,
    {
      [`${camelCase(name)}Saved`]: false,
      [`allowSave${capitalize(camelCase(name))}`]: value.length > 0
    }
  )
}

/* eslint no-shadow: 0 */
const paperworkFieldChanged = (state, action) => [
  (state, action) => saveableFieldChanged(state, action),
  (state, { payload: { paperworkUUID, name, value } }) => (
    updateEntity(state, paperworkUUID, { [name]: value, saved: false, changed: true })
  )
].reduce((newState, reducer) => reducer(newState, action), state)
/* eslint-enable */

const paperworkReferenceUnavailable = (
  state,
  { payload: { paperworkUUID, nextNumber } }
) => updateEntity(
  state,
  paperworkUUID,
  { reference: nextNumber }
)

const paperworkRemove = (state, { payload: { paperworkUUID } }) => (
  removeObjectKey(state, paperworkUUID)
)

const paperworkFetchSuccess = (state, action) => {
  const { paperwork: entities } = action.payload.data.entities

  return {
    ...state,
    ...removeStaleEntities(state, entities)
  }
}

const unduplicators = {
  invoice: invoiceValuesUnduplicatable,
  estimate: estimateValuesUnduplicatable,
  default: obj => ({ ...obj })
}

const paperworkDuplicateSuccess = (state, action) => {
  const { paperworkType, data } = action.payload
  const { entities: { paperwork: entities } } = data

  const newEntities = {}

  Object.keys(entities).forEach(key => {
    const filteredEntity = unduplicators[paperworkType](entities[key])

    newEntities[key] = {
      ...state[key],
      ...filteredEntity
    }
  })

  return {
    ...state,
    ...newEntities
  }
}

const paperworkSaveSuccess = (state, { payload: { paperworkUUID } }) => (
  removeObjectKey(state, paperworkUUID)
)

const paperworkDiscountChanged = (state, { payload: { paperworkUUID, value } }) => (
  updateEntity(state, paperworkUUID, { discount: value, saved: false, changed: true })
)

const paperworkDiscountRemove = (state, { payload: { paperworkUUID } }) => (
  updateEntity(state, paperworkUUID, { discount: '0.00', saved: false, changed: true })
)

const itemAdd = (state, { payload: { paperworkUUID, itemUUID } }) => updateEntity(
  state,
  paperworkUUID,
  {
    items: state[paperworkUUID].items.concat(itemUUID),
    sections: state[paperworkUUID].sections.concat(itemUUID)
  }
)

const sectionRemove = (state, { payload: { paperworkUUID, sectionUUID } }) => updateEntity(
  state,
  paperworkUUID,
  {
    items: state[paperworkUUID].items.filter(uuid => uuid !== sectionUUID),
    sections: state[paperworkUUID].sections.filter(uuid => uuid !== sectionUUID)
  }
)

const sectionPositionChange = (
  state,
  { payload: { paperworkUUID, oldIndex, newIndex } }
) => updateEntity(
  state,
  paperworkUUID,
  {
    sections: arrayMove(
      state[paperworkUUID].sections, oldIndex, newIndex
    )
  }
)

const sectionAdd = (state, { payload: { paperworkUUID, sectionUUID } }) => updateEntity(
  state,
  paperworkUUID,
  {
    sections: state[paperworkUUID].sections.concat(sectionUUID)
  }
)

const itemAutocompleteChoose = (state, action) => {
  const { paperworkUUID, itemUUID, data: { entities: { items } } } = action.payload
  const paperwork = state[paperworkUUID]

  const itemIndex = paperwork.items.indexOf(itemUUID)
  const newSectionPositions = [...paperwork.sections]
  newSectionPositions.splice(itemIndex, 0, ...Object.keys(items))

  return updateEntity(
    state,
    paperworkUUID,
    {
      items: paperwork.items.concat(Object.keys(items)),
      sections: newSectionPositions
    }
  )
}

const itemSearchChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { items } } } = action.payload
  const paperwork = state[paperworkUUID]

  return updateEntity(
    state,
    paperworkUUID,
    {
      items: paperwork.items.concat(Object.keys(items)),
      sections: [...paperwork.sections, ...Object.keys(items)]
    }
  )
}

const itemFormChanged = (state, action) => updateEntity(
  state,
  action.payload.paperworkUUID,
  { changed: true }
)

const sectionFormChanged = (state, action) => updateEntity(
  state,
  action.payload.paperworkUUID,
  { changed: true }
)

const itemFieldChanged = (state, action) => updateEntity(
  state,
  action.payload.paperworkUUID,
  { changed: true }
)

const sectionFieldChanged = (state, action) => updateEntity(
  state,
  action.payload.paperworkUUID,
  { changed: true }
)

export default createReducer({}, {
  [RESET]: reset,
  [ENTITIES_ADD]: entitiesAdd,
  [PAPERWORK_FORM_CHANGED]: paperworkFormChanged,
  [PAPERWORK_FIELD_CHANGED]: paperworkFieldChanged,
  [PAPERWORK_REMOVE]: paperworkRemove,
  [PAPERWORK_FETCH_SUCCESS]: paperworkFetchSuccess,
  [PAPERWORK_DUPLICATE_SUCCESS]: paperworkDuplicateSuccess,
  [PAPERWORK_SAVE_SUCCESS]: paperworkSaveSuccess,
  [PAPERWORK_DISCOUNT_CHANGED]: paperworkDiscountChanged,
  [PAPERWORK_DISCOUNT_REMOVE]: paperworkDiscountRemove,
  [PAPERWORK_REFERENCE_UNAVAILABLE]: paperworkReferenceUnavailable,
  [ITEM_ADD]: itemAdd,
  [SECTION_REMOVE]: sectionRemove,
  [ITEM_AUTOCOMPLETE_CHOOSE]: itemAutocompleteChoose,
  [ITEM_SEARCH_CHOOSE]: itemSearchChoose,
  [ITEM_FORM_CHANGED]: itemFormChanged,
  [ITEM_FIELD_CHANGED]: itemFieldChanged,
  [SECTION_FIELD_CHANGED]: sectionFieldChanged,
  [SECTION_FORM_CHANGED]: sectionFormChanged,
  [SECTION_POSITION_CHANGE]: sectionPositionChange,
  [HEADING_ADD]: sectionAdd
})
