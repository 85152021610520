import React from 'react'
import PropTypes from 'prop-types'

import AddEventButton from './add-event-button'
import DayDate from './day-date'

const DayWithoutEvents = ({ isFieldStaff, date, addEventPath }) => (
  <ul className='c-card__list'>
    <li className='c-card'>
      <div className='flex-grid'>
        <div className='flex-grid__cell'>
          <h3>
            <strong><DayDate date={date} /></strong>
            <span className='c--metal t--sml'>
              {' · No events'}
            </span>
          </h3>
        </div>
        {(!isFieldStaff) && (
          <div className='flex-grid__cell--1of6 u-align-right'>
            <AddEventButton addEventPath={addEventPath} currentDay={date} />
          </div>
        )}
      </div>
    </li>
  </ul>
)

DayWithoutEvents.defaultProps = {
  isFieldStaff: false,
  addEventPath: null
}

DayWithoutEvents.propTypes = {
  date: PropTypes.string.isRequired,
  addEventPath: PropTypes.string,
  isFieldStaff: PropTypes.bool
}

export default DayWithoutEvents
