/* global IntersectionObserver */

import { Controller } from 'stimulus'
import autosize from 'autosize'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'

class AutosizeController extends Controller {
  initialize () {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          autosize.update(entry.target)
        }
      })
    })
  }

  connect () {
    autosize(this.element)

    this.intersectionObserver.observe(this.element)
  }

  disconnect () {
    this.intersectionObserver.unobserve(this.element)
    autosize.destroy(this.element)
  }

  static afterLoad (identifier, application) {
    const { controllerAttribute } = application.schema

    function addToElement (element) {
      const controllers = (element.getAttribute(controllerAttribute) || '').split(' ')
      element.setAttribute(controllerAttribute, compact(uniq([...controllers, identifier])).join(' '))
      element.dataset.expandingInStimulus = true
    }

    function removeFromElement (element) {
      const controllers = (element.getAttribute(controllerAttribute) || '').split(' ')
      element.setAttribute(controllerAttribute, controllers.filter(controller => controller !== identifier).join(' '))
      delete element.dataset.expandingInStimulus
    }

    function addToExpanding () {
      document.querySelectorAll('.expanding').forEach(addToElement)
    }

    function removeFromExpanding () {
      document.removeEventListener('app-connect', addToExpanding)
      document.querySelectorAll('[data-expanding-in-stimulus]').forEach(removeFromElement)
    }

    document.addEventListener('app-connect', addToExpanding)
    document.addEventListener('app-disconnect', removeFromExpanding)
  }
}

export default AutosizeController
