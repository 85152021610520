import axios from 'axios'
import axiosRetry from 'axios-retry'

const elements = document.querySelectorAll('meta[name=csrf-token]')

let token
if (elements.length > 0) {
  token = elements[0].getAttribute('content')
} else {
  token = 'CSRF Token missing'
}

const axiosInstance = axios.create()

axiosInstance.defaults.headers.common['X-CSRF-Token'] = token
axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axiosRetry(axiosInstance, { retries: 3 })

export default axiosInstance
