import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { ItemContext } from '../../contexts'
import PseudoMoneyField from '../pseudo-money-field'
import { itemTotals } from '../../selectors'

const TotalField = () => {
  const { itemUUID } = useContext(ItemContext)
  const amount = useSelector(state => itemTotals(state, itemUUID)).labourItemsTotal

  return (
    <PseudoMoneyField amount={amount} />
  )
}

export default TotalField
