// toggle dropdown menu open/close
const menuOpenClass = 'menu--open'
let openBtnToClose = null

function positionMenu (element) {
  const el = element
  if (!el) return
  const elLeft = el.getBoundingClientRect().left
  const elRight = el.getBoundingClientRect().right
  const windowWidth = window.innerWidth || document.documentElement.clientWidth
  if (elRight > windowWidth) {
    el.style.marginLeft = '0'
    el.style.right = '0'
    el.style.left = 'auto'
  }
  if (elLeft < 0) {
    el.style.marginLeft = '0'
    el.style.left = '0'
  }
}

function preventTextSelection () {
  const sel = window.getSelection()
  if (document.selection && document.selection.empty) {
    document.selection.empty()
  } else if (window.getSelection) {
    sel.removeAllRanges()
  }
}

export default function toggle (e) {
  const btn = e.currentTarget
  const btnHolder = btn.parentNode
  const menu = btn.nextElementSibling

  e.stopPropagation()
  if (!btnHolder) return
  if (btnHolder.classList.contains !== menuOpenClass) {
    // menu isn't open
    btnHolder.classList.add(menuOpenClass)
    positionMenu(menu)
    preventTextSelection()
    if (openBtnToClose) {
      openBtnToClose.classList.remove(menuOpenClass)
      openBtnToClose = null
    }
    if (btnHolder.classList.contains(menuOpenClass)) {
      openBtnToClose = btnHolder
    }
  } else {
    // menu is already open
    btnHolder.classList.remove(menuOpenClass)
    openBtnToClose = null
  }
}

function closeAll () {
  openBtnToClose.classList.remove(menuOpenClass)
  openBtnToClose = null
}

window.onclick = function closeMenus () {
  if (openBtnToClose) {
    closeAll()
  }
}
