import { Controller } from 'stimulus'

export default class CustomDateRangeController extends Controller {
  static targets = ['form', 'range']

  initialize () {
    this.toggle()
  }

  toggle () {
    const formData = new FormData(this.formTarget)

    if (formData.get('date') === 'range') {
      this.rangeTarget.classList.remove('hidden')
    } else {
      this.rangeTarget.classList.add('hidden')
    }
  }
}
