import React, { useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import { useFormContext } from 'react-hook-form'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'

const PermissionCategory = ({ category, permissions, permissionIds, enabledPermissions }) => {
  const { setValue, getValues } = useFormContext()
  const [expanded, setExpanded] = useState(permissionIds)
  const [checked, setChecked] = useState(enabledPermissions)

  const handleCheck = (selected, node) => {
    const values = getValues()['company_user[selected_permissions]'] || []
    const otherCategoryPermissions = values.filter(value => !permissionIds.includes(value))
    let selectedPermissions = selected

    if (node.isChild && node.checked) {
      selectedPermissions.push(node.parent.value)
    }

    if (node.isParent && !node.checked) {
      const children = node.children.map(child => child.value)
      selectedPermissions = selectedPermissions.filter(value => !children.includes(value))
    }

    setChecked([...new Set(selectedPermissions)])
    setValue('company_user[selected_permissions]', [...new Set(selectedPermissions.concat(otherCategoryPermissions))])
  }

  return (
    <ul key={category} className='company-user__permissions-section'>
      <li className='company-user__permissions-section-header'>{capitalize(category)}</li>
      <CheckboxTree
        name='company_user[selected_permissions]'
        nodes={permissions}
        showNodeIcon={false}
        checked={checked}
        expanded={expanded}
        showExpandAll={false}
        onCheck={handleCheck}
        onExpand={setExpanded}
        nameAsArray
        noCascade
        nativeCheckboxes
        icons={{
          expandClose: null,
          expandOpen: null
        }}
      />
    </ul>
  )
}

PermissionCategory.propTypes = {
  category: PropTypes.string.isRequired,
  permissions: PropTypes.array.isRequired,
  permissionIds: PropTypes.array.isRequired,
  enabledPermissions: PropTypes.array.isRequired
}

export default PermissionCategory
