import { combineReducers } from 'redux'

import showTemplatePanel from './show-template-panel'
import emailTemplates from './email-templates'
import content from './content'

export default combineReducers({
  showTemplatePanel,
  emailTemplates,
  content
})
