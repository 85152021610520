import React from 'react'
import { Link } from 'react-router-dom'

const ViewSelector = () => (
  <ul className='pillbox pillbox--sml u-mrgn-l--x3 u-visible-lrg-scrn-only'>
    <li>
      <Link to='/'>
        <button type='button' className='pillbox__btn active'>
          Schedule
        </button>
      </Link>
    </li>
    <li>
      <a href='/calendar'>
        <button type='button' className='pillbox__btn '>
          Calendar
        </button>
      </a>
    </li>
  </ul>
)

export default ViewSelector
