import { connect } from 'react-redux'
import { togglePanel, fetch } from '../actions'
import Bell from '../components/bell'

const mapStateToProps = state => ({
  unreadCount: state.notifications.filter(n => !n.readAt).length
})

const mapDispatchToProps = (dispatch, props) => ({
  onBellLoad () {
    dispatch(fetch())
  },
  onBellClick (e) {
    if (props.mobile) { return }
    e.preventDefault()
    dispatch(togglePanel())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Bell)
