import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TrashSvg from '../../svg/trash'

const FileListItem = ({ file, onDelete, currentUserId, showFileSize }) => {
  const currentUserUploadedFile = file.createdById === currentUserId

  return (
    <li className='job-file file-list__item'>
      <a href={file.url} className='link' target='_blank' rel='noreferrer'>{file.name}</a>
      {showFileSize &&
        (
          <>
          &nbsp;&nbsp;
            <span className='file-list__file-size'>
              {file.sizePretty}
            </span>
          </>
        )}

      {currentUserUploadedFile &&
        (
          <button type='button' className='c--red u-float-right no-button-style' onClick={() => onDelete(file)}>
            <span className='icon icon--red'><TrashSvg /></span>
          </button>
        )}
    </li>
  )
}

FileListItem.defaultProps = {
  showFileSize: false
}

FileListItem.propTypes = {
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  showFileSize: PropTypes.bool
}

export default FileListItem
