import React from 'react'
import PropTypes from 'prop-types'

const Panel = ({ closeText, onClose, children }) => (
  <div>
    <div className='c-panel'>
      <button
        className='c-panel__close-btn btn btn--sml btn--white'
        type='button'
        onClick={e => { e.preventDefault(); onClose() }}
        data-test='close-button'
      >
        {closeText}
      </button>
      <div className='c-panel__inner'>
        {children}
      </div>
    </div>
    <div className='c-panel__overlay'>
      &nbsp;
    </div>
  </div>
)

Panel.propTypes = {
  closeText: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node
}

Panel.defaultProps = {
  closeText: 'Close',
  children: null,
  onClose: () => {}
}

export default Panel
