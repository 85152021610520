import React, { Fragment, useContext } from 'react'
import { useSelector } from 'react-redux'

import { PaperworkContext } from '../contexts'
import { getConfiguration, getPaperwork } from '../selectors'
import Errors from './errors'
import Sections from './sections'
import SaveBar from './save-bar'

const PriceList = () => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const { saveText, submittingText } = useSelector(getConfiguration)
  const { changed } = useSelector(state => getPaperwork(state, paperworkUUID))

  return (
    <>
      <Errors uuid={paperworkUUID} />
      <Sections
        allowAddItems={false}
        allowAddSections={false}
        descriptionPlaceholder='Description of work…'
      />
      <SaveBar
        saveText={saveText}
        changed={changed}
        submittingText={submittingText}
      />
    </>
  )
}

export default PriceList
