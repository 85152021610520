import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class DropdownController extends Controller {
  static targets = ['target', 'button']

  connect () {
    useClickOutside(this)
  }

  clickOutside (event) {
    if (
      this.hasTargetTarget &&
      this.targetTarget.classList.contains(this.data.get('class')) &&
      !this.isWithinFlatpickr(event.target) &&
      !event.target.tagName.match(/input/i) // fixes a bug with Rails ujs data-method on a link in the dropdowns
    ) {
      event.preventDefault()
      this.close()
    }
  }

  toggle () {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.toggle('active')
    }
    this.targetTarget.classList.toggle(this.data.get('class'))
    setTimeout(() => {
      document.body.classList.toggle('no-scroll')
    }, 50)
  }

  close () {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove('active')
    }
    this.targetTarget.classList.remove(this.data.get('class'))
    setTimeout(() => {
      document.body.classList.remove('no-scroll')
    }, 50)
  }

  // private

  isWithinFlatpickr (target) {
    return target.closest('.flatpickr-calendar')
  }
}
