const NAMESPACE = 'PAPERWORK_'

export const INIT = `${NAMESPACE}INIT`
export const RESET = `${NAMESPACE}RESET`
export const ENTITIES_ADD = `${NAMESPACE}ENTITIES_ADD`
export const ITEM_ADD = `${NAMESPACE}ITEM_ADD`
export const ITEM_DUPLICATE = `${NAMESPACE}ITEM_DUPLICATE`
export const ITEM_FORM_CHANGED = `${NAMESPACE}ITEM_FORM_CHANGED`
export const ITEM_FIELD_CHANGED = `${NAMESPACE}ITEM_FIELD_CHANGED`
export const PAPERWORK_DISCOUNT_CHANGED = `${NAMESPACE}PAPERWORK_DISCOUNT_CHANGED`
export const PAPERWORK_DISCOUNT_REMOVE = `${NAMESPACE}PAPERWORK_DISCOUNT_REMOVE`
export const SECTION_REMOVE = `${NAMESPACE}SECTION_REMOVE`
export const ITEM_SAVE = `${NAMESPACE}ITEM_SAVE`
export const ITEM_SAVE_SUCCESS = `${NAMESPACE}ITEM_SAVE_SUCCESS`
export const ITEM_SEARCH_CHOOSE = `${NAMESPACE}ITEM_SEARCH_CHOOSE`
export const ITEM_SAVE_FAILURE = `${NAMESPACE}ITEM_SAVE_FAILURE`
export const ITEM_TOGGLE_OPTIONAL = `${NAMESPACE}ITEM_TOGGLE_OPTIONAL`
export const ITEM_AUTOCOMPLETE_CHOOSE = `${NAMESPACE}ITEM_AUTOCOMPLETE_CHOOSE`
export const ITEM_QUANTITY_INCREMENT = `${NAMESPACE}ITEM_QUANTITY_INCREMENT`
export const ITEM_QUANTITY_DECREMENT = `${NAMESPACE}ITEM_QUANTITY_DECREMENT`
export const LABOUR_AUTOCOMPLETE_CHOOSE = `${NAMESPACE}LABOUR_AUTOCOMPLETE_CHOOSE`
export const LABOUR_SEARCH_CHOOSE = `${NAMESPACE}LABOUR_SEARCH_CHOOSE`
export const LABOUR_ITEM_ADD = `${NAMESPACE}LABOUR_ITEM_ADD`
export const LABOUR_ITEM_FORM_CHANGED = `${NAMESPACE}LABOUR_ITEM_FORM_CHANGED`
export const LABOUR_ITEM_REMOVE = `${NAMESPACE}LABOUR_ITEM_REMOVE`
export const LABOUR_ITEM_DUPLICATE = `${NAMESPACE}LABOUR_ITEM_DUPLICATE`
export const LABOUR_ITEM_POSITION_CHANGE = `${NAMESPACE}LABOUR_ITEM_POSITION_CHANGE`
export const LABOUR_ITEM_SAVE = `${NAMESPACE}LABOUR_ITEM_SAVE`
export const LABOUR_ITEM_SAVE_SUCCESS = `${NAMESPACE}LABOUR_ITEM_SAVE_SUCCESS`
export const LABOUR_ITEM_SAVE_FAILURE = `${NAMESPACE}LABOUR_ITEM_SAVE_FAILURE`
export const MATERIAL_AUTOCOMPLETE_CHOOSE = `${NAMESPACE}MATERIAL_AUTOCOMPLETE_CHOOSE`
export const MATERIAL_SEARCH_CHOOSE = `${NAMESPACE}MATERIAL_SEARCH_CHOOSE`
export const MATERIAL_ITEM_ADD = `${NAMESPACE}MATERIAL_ITEM_ADD`
export const MATERIAL_ITEM_FORM_CHANGED = `${NAMESPACE}MATERIAL_ITEM_FORM_CHANGED`
export const MATERIAL_ITEM_REMOVE = `${NAMESPACE}MATERIAL_ITEM_REMOVE`
export const MATERIAL_ITEM_DUPLICATE = `${NAMESPACE}MATERIAL_ITEM_DUPLICATE`
export const MATERIAL_ITEM_POSITION_CHANGE = `${NAMESPACE}MATERIAL_ITEM_POSITION_CHANGE`
export const MATERIAL_ITEM_SAVE = `${NAMESPACE}MATERIAL_ITEM_SAVE`
export const MATERIAL_ITEM_SAVE_SUCCESS = `${NAMESPACE}MATERIAL_ITEM_SAVE_SUCCESS`
export const MATERIAL_ITEM_SAVE_FAILURE = `${NAMESPACE}MATERIAL_ITEM_SAVE_FAILURE`
export const PAPERWORK_DISCARD = `${NAMESPACE}DISCARD`
export const PAPERWORK_REMOVE = `${NAMESPACE}REMOVE`
export const PAPERWORK_FETCH = `${NAMESPACE}FETCH`
export const PAPERWORK_FETCH_SUCCESS = `${NAMESPACE}FETCH_SUCCESS`
export const PAPERWORK_FETCH_FAILURE = `${NAMESPACE}FETCH_FAILURE`
export const PAPERWORK_DUPLICATE = `${NAMESPACE}DUPLICATE`
export const PAPERWORK_DUPLICATE_SUCCESS = `${NAMESPACE}DUPLICATE_SUCCESS`
export const PAPERWORK_DUPLICATE_FAILURE = `${NAMESPACE}DUPLICATE_FAILURE`
export const PAPERWORK_FORM_CHANGED = `${NAMESPACE}FORM_CHANGED`
export const PAPERWORK_FIELD_CHANGED = `${NAMESPACE}FIELD_CHANGED`
export const PAPERWORK_SAVE_SUCCESS = `${NAMESPACE}SAVE_SUCCESS`
export const PAPERWORK_SAVE_FAILURE = `${NAMESPACE}SAVE_FAILURE`
export const PAPERWORK_SUBMIT = `${NAMESPACE}SUBMIT`
export const PAPERWORK_REFERENCE_UNAVAILABLE = `${NAMESPACE}REFERENCE_UNAVAILABLE`
export const SECTION_POSITION_CHANGE = `${NAMESPACE}SECTION_POSITION_CHANGE`
export const HEADING_ADD = `${NAMESPACE}HEADING_ADD`
export const SECTION_FORM_CHANGED = `${NAMESPACE}SECTION_FORM_CHANGED`
export const SECTION_FIELD_CHANGED = `${NAMESPACE}SECTION_FIELD_CHANGED`
