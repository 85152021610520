import { Controller } from 'stimulus'
import groupBy from 'lodash/groupBy'
import debounce from 'lodash/debounce'

export default class GroupController extends Controller {
  static targets = ['item', 'heading']

  connect () {
    this.regroup = debounce(this.regroup, 100).bind(this)
    this.regroup()
  }

  headingTargetConnected () {
    this.regroup()
  }

  headingTargetDisconnected () {
    this.regroup()
  }

  itemTargetConnected () {
    this.regroup()
  }

  itemTargetDisconnected () {
    this.regroup()
  }

  // Private
  regroup () {
    if (!this.hasHeadingTarget) { return }

    this.headingTargets.forEach(heading => heading.classList.add('hidden'))

    const groupedItems = groupBy(this.itemTargets, 'dataset.group')

    Object.values(groupedItems).forEach(items => {
      const firstItemInGroup = items[0]
      const { group } = firstItemInGroup.dataset
      const heading = this.headingTargets.find(target => target.dataset.group === group)

      if (!heading) { return }

      heading.classList.remove('hidden')

      if (!firstItemInGroup.previousSibling.isEqualNode(heading)) {
        firstItemInGroup.parentNode.insertBefore(heading, firstItemInGroup)
      }
    })
  }
}
