/* eslint no-alert:0 */
import React, { useContext, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'

import {
  sectionFieldChanged,
  sectionFormChanged,
  sectionRemove
} from '../../actions'
import {
  getSection
} from '../../selectors'
import { SectionContext } from '../../contexts'
import Form from '../form'
import DragHandle from './drag-handle'
import RemoveButton from './remove-button'
import Field from '../final-form-field'
import RichText from '../../../rich-text'

const Heading = () => {
  const dispatch = useDispatch()
  const { sectionUUID } = useContext(SectionContext)
  const section = useSelector(state => getSection(state, sectionUUID))
  const { paperworkUUID, focussed, changed } = section
  const { title, description } = section.data

  const handleRemove = () => {
    if (changed && !window.confirm('Are you sure you want to remove this heading? It cannot be undone.')) {
      return
    }
    dispatch(sectionRemove(paperworkUUID, sectionUUID))
  }

  const onFieldChange = useCallback(debounce(event => {
    dispatch(
      sectionFieldChanged(
        paperworkUUID,
        sectionUUID,
        event.target.name,
        event.target.value
      )
    )
  }, 400), [paperworkUUID, sectionUUID])

  const onDescriptionChange = useCallback(debounce(text => {
    onFieldChange({ target: { name: 'description', value: text } })
  }, 400), [onFieldChange])

  const descriptionSelector = useCallback(state => getSection(state, sectionUUID).data.description, [sectionUUID])

  return (
    <Form
      initialValues={{ title, description }}
      onChange={values => dispatch(sectionFormChanged(paperworkUUID, sectionUUID, values))}
    >
      <div className='pwrk-frm__item pwrk-frm__item--heading' data-test='section'>
        <div className='pwrk-frm__input-list pwrk-frm__input-list--sml'>
          <Field
            name='title'
            testKey='title'
            placeholder='Add a heading'
            className='pwrk-frm__input--title'
            autoFocus={focussed}
          />
          <div className='pwrk-frm__block'>
            <RichText
              name='description'
              id='description'
              valueSelector={descriptionSelector}
              autoFocus={false}
              testKey='description'
              placeholder='Add any additional details…'
              onChange={onDescriptionChange}
            />
          </div>
        </div>
        <div className='pwrk-frm__item-options'>
          <DragHandle />
          <RemoveButton onClick={handleRemove} />
        </div>
      </div>
    </Form>
  )
}

export default Heading
