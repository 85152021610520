import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from '../modules/react-final-form'

const Select = ({
  name,
  label,
  options,
  testKey,
  validateFields,
  dispatch,
  ...props
}) => (
  <>
    {label !== '' && (
      <label htmlFor={name} className='pwrk-frm__label'>{label}</label>
    )}
    <Field
      {...props}
      name={name}
      id={name}
      validateFields={validateFields}
      component='select'
      data-test={`${testKey}-field`}
    >
      {
         options.map(option => (
           <option
             key={option.value}
             value={option.value}
           >
             {option.label}
           </option>
         ))
       }
    </Field>
  </>
)

Select.defaultProps = {
  label: '',
  options: [],
  validateFields: [],
  dispatch: () => {}
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  testKey: PropTypes.string.isRequired,
  validateFields: PropTypes.array,
  dispatch: PropTypes.func
}

export default Select
