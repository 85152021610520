/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import axios from '@ytb/axios'

import Blank from './components/blank'
import NotificationsList from './components/notifications-list'

const notificationsArrayToObject = array => (
  array.reduce((obj, item) => {
    obj[item.id] = item // eslint-disable-line no-param-reassign
    return obj
  }, {})
)

const notificationsObjectToArray = object => (
  Object.keys(object).map(e => object[e])
)

const sortNotificationsMostRecentFirst = notifications => [...notifications].sort((a, b) => {
  if (a.receivedAt < b.receivedAt) { return 1 }
  if (a.receivedAt > b.receivedAt) { return -1 }
  return 0
})

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.loadMoreNotifications = this.loadMoreNotifications.bind(this)
    this.state = {
      notifications: notificationsArrayToObject(props.notifications),
      nextPage: 2,
      loading: false,
      atEnd: false
    }
  }

  loadMoreNotifications () {
    const { nextPage, notifications } = this.state
    this.setState({ loading: true })
    axios.get(
      `/notifications.json?page=${nextPage}`
    ).then(response => {
      if (response.data.length === 0) {
        this.setState({ loading: false, atEnd: true })
        return null
      }

      const moreNotifications = notificationsArrayToObject(response.data)

      this.setState({
        loading: false,
        nextPage: nextPage + 1,
        notifications: { ...notifications, ...moreNotifications }
      })

      return true
    })
  }

  renderUnread (notifications) {
    const unreadNotifications = notifications.filter(n => !n.readAt)
    if (unreadNotifications.length === 0) { return null }

    return (
      <NotificationsList label='Unread' notifications={unreadNotifications} />
    )
  }

  renderPrevious (notifications) {
    const readNotifications = notifications.filter(n => n.readAt)
    if (readNotifications.length === 0) { return null }

    return (
      <NotificationsList label='Previous' notifications={readNotifications} />
    )
  }

  renderWaypoint () {
    const { atEnd, loading } = this.state
    if (loading) {
      return (
        <div className='u-align-center u-mrgn-btm'>

          <span className='animated-spinner'>
            <svg version='1.1' viewBox='0 0 50 50'>
              <path d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'>
                <animateTransform
                  attributeType='xml'
                  attributeName='transform'
                  type='rotate'
                  from='0 25 25'
                  to='360 25 25'
                  dur='0.8s'
                  repeatCount='indefinite'
                />
              </path>
            </svg>
          </span>

        </div>
      )
    }
    if (atEnd) {
      return (
        <div className='u-align-center t--sml c--metal u-mrgn-btm'>
          No more notifications - you&apos;re all caught up!
        </div>
      )
    }

    return (
      <Waypoint onEnter={this.loadMoreNotifications} bottomOffset='-100px' />
    )
  }

  render () {
    let { notifications } = this.state
    notifications = sortNotificationsMostRecentFirst(
      notificationsObjectToArray(notifications)
    )

    if (notifications.length === 0) { return <Blank /> }

    return (
      <div className='notifications'>
        {this.renderUnread(notifications)}
        {this.renderPrevious(notifications)}
        {this.renderWaypoint()}
      </div>
    )
  }
}

Notifications.defaultProps = {
  notifications: []
}

Notifications.propTypes = {
  notifications: PropTypes.array
}

export default Notifications
