import * as types from '../types'

export const fetchEventsSuccess = json => ({
  type: types.FETCH_EVENTS_SUCCESS,
  payload: json
})

export const fetchEventsFailure = () => ({
  type: types.FETCH_EVENTS_FAILURE
})

export const init = payload => ({
  type: types.INIT,
  payload
})

export const routeChanged = payload => ({
  type: types.ROUTE_CHANGED,
  payload
})

export const deleteEvent = id => ({
  type: types.DELETE_EVENT,
  payload: { id }
})

export const deleteEventSuccess = id => ({
  type: types.DELETE_EVENT_SUCCESS,
  payload: { id }
})

export const deleteEventFailure = id => ({
  type: types.DELETE_EVENT_FAILURE,
  payload: { id }
})

export const changeEventColor = (id, color) => ({
  type: types.CHANGE_EVENT_COLOR,
  payload: { id, color }
})

export const changeEventColorSuccess = (id, color) => ({
  type: types.CHANGE_EVENT_COLOR_SUCCESS,
  payload: { id, color }
})

export const changeEventColorFailure = id => ({
  type: types.CHANGE_EVENT_COLOR_FAILURE,
  payload: { id }
})

export const fetchCompanyUsersSuccess = json => ({
  type: types.FETCH_COMPANY_USERS_SUCCESS,
  payload: json
})

export const fetchCompanyUsersFailure = () => ({
  type: types.FETCH_COMPANY_USERS_FAILURE
})
