import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import moment from 'moment'

import ArrowLeftSvg from '@/components/svg/arrow-left'
import ArrowRightSvg from '@/components/svg/arrow-right'

const NextPrevious = ({ previousUrl, nextUrl }) => {
  const saveScheduleDate = path => (
    Cookies.set(
      'schedule_date_path',
      `/schedule${path}`,
      { expires: moment().add(2, 'hours').toDate() }
    )
  )

  return (
    <ul className='pillbox pillbox--sml'>
      <li>
        <Link to={previousUrl} onClick={() => saveScheduleDate(previousUrl)}>
          <button type='button' className='schedule__previous-button pillbox__btn btn--icon-only'>
            <ArrowLeftSvg className='icon icon--tiny icon--dark-metal' />
          </button>
        </Link>
      </li>
      <li>
        <Link to={nextUrl} onClick={() => saveScheduleDate(nextUrl)}>
          <button type='button' className='schedule__next-button pillbox__btn btn--icon-only'>
            <ArrowRightSvg className='icon icon--tiny icon--dark-metal' />
          </button>
        </Link>
      </li>
    </ul>
  )
}

NextPrevious.defaultProps = {
  previousUrl: '',
  nextUrl: ''
}

NextPrevious.propTypes = {
  previousUrl: PropTypes.string,
  nextUrl: PropTypes.string
}

export default NextPrevious
