import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ItemContext, LabourItemContext } from '../../../contexts'
import Money from '../../money'
import { itemLabourItemTotals as totals } from '../../../selectors'

const ItemLabourItemTotal = ({ name }) => {
  const { itemUUID } = useContext(ItemContext)
  const { labourItemUUID } = useContext(LabourItemContext)
  const amount = useSelector(state => totals(state, itemUUID, labourItemUUID))[name]

  return (
    <Money amount={amount} />
  )
}

ItemLabourItemTotal.propTypes = {
  name: PropTypes.string.isRequired
}

export default ItemLabourItemTotal
