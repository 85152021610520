import { Controller } from 'stimulus'
import * as getAddress from 'getaddress-find'
import App from '../application/app'

export default class UkPostcodeLookupController extends Controller {
  static targets = ['inputContainer', 'address', 'postcode', 'manualAddressToggle', 'searchAgainToggle']

  connect () {
    getAddress.find(
      this.inputContainerTarget.id,
      App.getAddressApiKey,
      {
        input: {
          'data-example': 'foo',
          class: 'frm__input frm__input--short frm__input--inline-block u-mrgn-r--x2',
          label: 'Enter postcode'
        },
        button: {
          label: 'Find address',
          class: 'btn btn--med btn--no-border'
        },
        dropdown: {
          id: 'address-select-dropdown',
          class: 'frm__input frm__input--med u-mrgn-top u-mrgn-btm',
          select_message: 'Select address'
        }
      }
    )

    this.element.dataset.action = `
      getaddress-find-suggestions@document->uk-postcode-lookup#updateManualAddressTogglePrompt
      getaddress-find-address-selected@document->uk-postcode-lookup#addressSelected
      ${this.element.dataset.action}
    `
  }

  addressSelected ({ address }) {
    this.addressTargets.forEach(addressField => {
      addressField.value = address.formatted_address.filter(Boolean).join('\n')
    })

    this.postcodeTarget.value = address.postcode
    this.element.querySelector('#address-select-dropdown').remove()

    this.searchAgainToggleTarget.innerText = 'Search again'
  }

  updateManualAddressTogglePrompt () {
    this.manualAddressToggleTarget.innerText = "Can't find the correct address? Enter it manually"
  }
}
