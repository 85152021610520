import {
  createReducer,
  updateEntity,
  removeStaleEntities,
  deepSetKeys,
  removeEntitiesByProperty,
  updateEntities
} from './util'

import {
  INIT,
  RESET,
  ENTITIES_ADD,
  LABOUR_SEARCH_CHOOSE,
  LABOUR_AUTOCOMPLETE_CHOOSE,
  LABOUR_ITEM_ADD,
  LABOUR_ITEM_REMOVE,
  LABOUR_ITEM_FORM_CHANGED,
  LABOUR_ITEM_SAVE_SUCCESS,
  LABOUR_ITEM_SAVE_FAILURE,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_DUPLICATE_SUCCESS,
  ITEM_AUTOCOMPLETE_CHOOSE,
  ITEM_SEARCH_CHOOSE,
  PAPERWORK_REMOVE,
  LABOUR_ITEM_DUPLICATE
} from '../types'

const init = (state, action) => {
  const { configuration } = action.payload
  const newState = deepSetKeys(state, { focussed: false })

  return {
    ...newState,
    template: {
      description: '',
      units: '1',
      unitType: configuration.defaultLabourUnitType,
      rate: '0.00',
      saved: false,
      fromAutocomplete: false,
      fromSearch: false
    }
  }
}

const reset = () => ({})

const labourSearchChoose = (state, action) => {
  const { itemUUID, data: { entities: { labourItems: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { itemUUID })
  const uuid = Object.keys(newEntities)[0]
  const entity = newEntities[uuid]

  return {
    ...state,
    [uuid]: {
      ...state.template,
      ...entity,
      fromSearch: true
    }
  }
}

const labourAutocompleteChoose = (
  state,
  { payload: { labourItemUUID, item } }
) => updateEntity(
  state,
  labourItemUUID,
  {
    ...item,
    fromAutocomplete: true
  }
)

const entitiesAdd = (state, { payload: { entities: { labourItems: entities } } }) => (
  updateEntities(state, entities)
)

const labourItemAdd = (
  state,
  { payload: { labourItemUUID, paperworkUUID, itemUUID, byUser } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  labourItemUUID,
  {
    ...state.template,
    paperworkUUID,
    itemUUID,
    focussed: byUser
  }
)

const labourItemFormChanged = (
  state,
  { payload: { labourItemUUID, values } }
) => updateEntity(
  state,
  labourItemUUID,
  {
    ...values,
    changed: true
  }
)

const labourItemSaveSuccess = (
  state,
  { payload: { labourItemUUID } }
) => updateEntity(
  state,
  labourItemUUID,
  { saved: true }
)

const labourItemSaveFailure = (
  state,
  { payload: { labourItemUUID } }
) => updateEntity(
  state,
  labourItemUUID,
  { saved: false }
)

const itemAutocompleteChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { labourItems: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  return {
    ...state,
    ...newEntities
  }
}

const itemSearchChoose = (state, action) => {
  const { paperworkUUID, data: { entities: { labourItems: entities } } } = action.payload
  const newEntities = deepSetKeys(entities, { paperworkUUID })

  return {
    ...state,
    ...newEntities
  }
}

const paperworkFetchSuccess = (state, action) => {
  const { labourItems: entities } = action.payload.data.entities

  return {
    ...state,
    ...removeStaleEntities(state, entities)
  }
}

const paperworkDuplicateSuccess = (state, action) => {
  const { labourItems: entities } = action.payload.data.entities

  return {
    ...state,
    ...entities
  }
}

const paperworkRemove = (state, { payload: { paperworkUUID } }) => (
  removeEntitiesByProperty(state, 'paperworkUUID', paperworkUUID)
)

const labourItemRemove = (state, { payload: { labourItemUUID } }) => (
  removeEntitiesByProperty(state, 'uuid', labourItemUUID)
)

const labourItemDuplicate = (
  state,
  { payload: { labourItemUUID, newUUID } }
) => updateEntity(
  deepSetKeys(state, { focussed: false }),
  newUUID,
  {
    ...state[labourItemUUID],
    uuid: newUUID,
    focussed: true
  }
)

export default createReducer({}, {
  [INIT]: init,
  [RESET]: reset,
  [ENTITIES_ADD]: entitiesAdd,
  [ITEM_AUTOCOMPLETE_CHOOSE]: itemAutocompleteChoose,
  [ITEM_SEARCH_CHOOSE]: itemSearchChoose,
  [LABOUR_ITEM_ADD]: labourItemAdd,
  [LABOUR_SEARCH_CHOOSE]: labourSearchChoose,
  [LABOUR_AUTOCOMPLETE_CHOOSE]: labourAutocompleteChoose,
  [LABOUR_ITEM_FORM_CHANGED]: labourItemFormChanged,
  [LABOUR_ITEM_SAVE_SUCCESS]: labourItemSaveSuccess,
  [LABOUR_ITEM_SAVE_FAILURE]: labourItemSaveFailure,
  [LABOUR_ITEM_REMOVE]: labourItemRemove,
  [LABOUR_ITEM_DUPLICATE]: labourItemDuplicate,
  [PAPERWORK_REMOVE]: paperworkRemove,
  [PAPERWORK_DUPLICATE_SUCCESS]: paperworkDuplicateSuccess,
  [PAPERWORK_FETCH_SUCCESS]: paperworkFetchSuccess
})
