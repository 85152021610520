import c0 from './app_controller';
import c1 from './sticky_element_controller';
import c2 from './toggle_vat_number_controller';
import c3 from './paperwork/colour_scheme_controller';
import c4 from './intercom_controller';
import c5 from './checked_list_controller';
import c6 from './paperwork_controller';
import c7 from './custom_date_range_controller';
import c8 from './switch_controller';
import c9 from './toggle_disabled_controller';
import c10 from './reveal_more_list_controller';
import c11 from './help_menu_controller';
import c12 from './search_controller';
import c13 from './reveal_controller';
import c14 from './expandable_controller';
import c15 from './toggle_controller';
import c16 from './charts/chart_controller';
import c17 from './charts/chart_with_axes_controller';
import c18 from './forms/checkbox_toggler_controller';
import c19 from './forms/signature_field_controller';
import c20 from './forms/date_picker_controller';
import c21 from './forms/percent_field_controller';
import c22 from './forms/auto_saved_field_controller';
import c23 from './modal_controller';
import c24 from './toggle_save_button_controller';
import c25 from './stripe_controller';
import c26 from './pin_to_bottom_controller';
import c27 from './bulk_controller';
import c28 from './upload_trigger_controller';
import c29 from './automation_controller';
import c30 from './radio_select_controller';
import c31 from './filter_list_controller';
import c32 from './destroy_checkbox_controller';
import c33 from './flash_message_controller';
import c34 from './inline_edit_controller';
import c35 from './remove_element_controller';
import c36 from './auto_submit_controller';
import c37 from './tasks_controller';
import c38 from './helpscout_controller';
import c39 from './focus_controller';
import c40 from './uk_postcode_lookup_controller';
import c41 from './infinite_scroll_controller';
import c42 from './billing_frequency_controller';
import c43 from './analytics_controller';
import c44 from './address_autocomplete_controller';
import c45 from './job_address_controller';
import c46 from './turbo_frame_refresh_controller';
import c47 from './group_controller';
import c48 from './stripe_billing_controller';
import c49 from './card_highlight_controller';
import c50 from './loader_controller';
import c51 from './send_to_self_controller';
import c52 from './auto_visit_controller';
import c53 from './autosize_controller';
import c54 from './clear_input_controller';
import c55 from './dropdown_controller';
import c56 from './mobile_menu_controller';
import c57 from './toggle_us_state_controller';
import c58 from './disable_during_upload_controller';
import c59 from './bulk_item_controller';
import c60 from './tsearch_controller';
import c61 from './tooltip_controller';
import c62 from './dialog_controller';
export const definitions = [
	{identifier: 'app', controllerConstructor: c0},
	{identifier: 'sticky-element', controllerConstructor: c1},
	{identifier: 'toggle-vat-number', controllerConstructor: c2},
	{identifier: 'paperwork--colour-scheme', controllerConstructor: c3},
	{identifier: 'intercom', controllerConstructor: c4},
	{identifier: 'checked-list', controllerConstructor: c5},
	{identifier: 'paperwork', controllerConstructor: c6},
	{identifier: 'custom-date-range', controllerConstructor: c7},
	{identifier: 'switch', controllerConstructor: c8},
	{identifier: 'toggle-disabled', controllerConstructor: c9},
	{identifier: 'reveal-more-list', controllerConstructor: c10},
	{identifier: 'help-menu', controllerConstructor: c11},
	{identifier: 'search', controllerConstructor: c12},
	{identifier: 'reveal', controllerConstructor: c13},
	{identifier: 'expandable', controllerConstructor: c14},
	{identifier: 'toggle', controllerConstructor: c15},
	{identifier: 'charts--chart', controllerConstructor: c16},
	{identifier: 'charts--chart-with-axes', controllerConstructor: c17},
	{identifier: 'forms--checkbox-toggler', controllerConstructor: c18},
	{identifier: 'forms--signature-field', controllerConstructor: c19},
	{identifier: 'forms--date-picker', controllerConstructor: c20},
	{identifier: 'forms--percent-field', controllerConstructor: c21},
	{identifier: 'forms--auto-saved-field', controllerConstructor: c22},
	{identifier: 'modal', controllerConstructor: c23},
	{identifier: 'toggle-save-button', controllerConstructor: c24},
	{identifier: 'stripe', controllerConstructor: c25},
	{identifier: 'pin-to-bottom', controllerConstructor: c26},
	{identifier: 'bulk', controllerConstructor: c27},
	{identifier: 'upload-trigger', controllerConstructor: c28},
	{identifier: 'automation', controllerConstructor: c29},
	{identifier: 'radio-select', controllerConstructor: c30},
	{identifier: 'filter-list', controllerConstructor: c31},
	{identifier: 'destroy-checkbox', controllerConstructor: c32},
	{identifier: 'flash-message', controllerConstructor: c33},
	{identifier: 'inline-edit', controllerConstructor: c34},
	{identifier: 'remove-element', controllerConstructor: c35},
	{identifier: 'auto-submit', controllerConstructor: c36},
	{identifier: 'tasks', controllerConstructor: c37},
	{identifier: 'helpscout', controllerConstructor: c38},
	{identifier: 'focus', controllerConstructor: c39},
	{identifier: 'uk-postcode-lookup', controllerConstructor: c40},
	{identifier: 'infinite-scroll', controllerConstructor: c41},
	{identifier: 'billing-frequency', controllerConstructor: c42},
	{identifier: 'analytics', controllerConstructor: c43},
	{identifier: 'address-autocomplete', controllerConstructor: c44},
	{identifier: 'job-address', controllerConstructor: c45},
	{identifier: 'turbo-frame-refresh', controllerConstructor: c46},
	{identifier: 'group', controllerConstructor: c47},
	{identifier: 'stripe-billing', controllerConstructor: c48},
	{identifier: 'card-highlight', controllerConstructor: c49},
	{identifier: 'loader', controllerConstructor: c50},
	{identifier: 'send-to-self', controllerConstructor: c51},
	{identifier: 'auto-visit', controllerConstructor: c52},
	{identifier: 'autosize', controllerConstructor: c53},
	{identifier: 'clear-input', controllerConstructor: c54},
	{identifier: 'dropdown', controllerConstructor: c55},
	{identifier: 'mobile-menu', controllerConstructor: c56},
	{identifier: 'toggle-us-state', controllerConstructor: c57},
	{identifier: 'disable-during-upload', controllerConstructor: c58},
	{identifier: 'bulk-item', controllerConstructor: c59},
	{identifier: 'tsearch', controllerConstructor: c60},
	{identifier: 'tooltip', controllerConstructor: c61},
	{identifier: 'dialog', controllerConstructor: c62},
];
