/* eslint-disable react/jsx-handler-names */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from '../modules/react-final-form'
import ExpandingTextArea from '../../expanding-textarea'

const TextArea = ({ name, label, validateFields, ...props }) => (
  <>
    {label !== '' && (
      <label htmlFor={name} className='pwrk-frm__label'>{label}</label>
    )}
    <Field
      {...props}
      name={name}
      validateFields={validateFields}
      component='textarea'
    >
      {({ input }) => (
        <ExpandingTextArea
          {...props}
          id={name}
          value={input.value}
          onChange={input.onChange}
        />
      )}
    </Field>
  </>
)

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  testKey: PropTypes.string.isRequired,
  validateFields: PropTypes.array
}

TextArea.defaultProps = {
  label: '',
  validateFields: []
}

export default TextArea
