import axios from '@ytb/axios'
import { setSessionCallback } from '@/channels/session_channel'

setSessionCallback(data => {
  if (data.action === 'logout') {
    axios.get('/user.json').catch(() => {
      window.location.href = '/login'
    })
  }
})
