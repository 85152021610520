import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Dropdown from '../../dropdown'
import DropdownItem from '../../dropdown/item'

import { selectedItemName, selectedButtonClassName, menuItemClassName } from '../helpers/filters'

const AssigneeSelector = ({ assignees }) => (
  <Dropdown
    label={selectedItemName(assignees)}
    menuTitle='Filter by assignee'
    containerClassName='u-mrgn-l--x4'
    buttonClassName={`btn__filter ${selectedButtonClassName(assignees, 'All Assignees')}`}
    buttonTestKey='schedule-assignee-selector-button'
  >
    {
      assignees.map(
        assignee => (
          <DropdownItem key={assignee.name} className={menuItemClassName(assignee.selected)}>
            <Link
              to={assignee.url}
              data-test={`schedule-assignee-selector-${assignee.name.toLowerCase()}`}
              data-action='dropdown#toggle'
            >
              {assignee.name}
            </Link>
          </DropdownItem>
        )
      )
    }
  </Dropdown>
)

AssigneeSelector.defaultProps = {
  assignees: []
}

AssigneeSelector.propTypes = {
  assignees: PropTypes.array
}

export default AssigneeSelector
