import React from 'react'

const KeyboardReturnSvg = props => (
  <span {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M9.75 17.997a.743.743 0 0 1-.53-.22l-3.733-3.733a.733.733 0 0 1-.237-.544l.001-.033a.737.737 0 0 1 .048-.231l.016-.04a.761.761 0 0 1 .113-.179.528.528 0 0 1 .042-.05l3.75-3.75a.744.744 0 0 1 1.06 0c.142.141.22.33.22.53s-.078.389-.22.53L7.808 12.75H13.5A3.754 3.754 0 0 0 17.25 9V6.747a.75.75 0 0 1 1.5 0V9a5.256 5.256 0 0 1-5.25 5.25H7.813l2.467 2.467a.752.752 0 0 1 0 1.061.746.746 0 0 1-.53.219z' />
      <path d='M4.5 23.247a3.754 3.754 0 0 1-3.75-3.75v-15A3.754 3.754 0 0 1 4.5.747h15a3.754 3.754 0 0 1 3.75 3.75v15a3.754 3.754 0 0 1-3.75 3.75h-15zm0-21a2.252 2.252 0 0 0-2.25 2.25v15a2.252 2.252 0 0 0 2.25 2.25h15a2.252 2.252 0 0 0 2.25-2.25v-15a2.252 2.252 0 0 0-2.25-2.25h-15z' />
    </svg>
  </span>
)

export default KeyboardReturnSvg
