import { Controller } from 'stimulus'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'

export default class BulkController extends Controller {
  static values = {
    all: { type: Array, default: [] },
    selected: { type: Array, default: [] }
  }

  static targets = ['allCount', 'selectedCount', 'selectAll', 'deselectAll', 'selectedInput', 'actionButton', 'actionComplete']

  static outlets = ['bulk-item']

  // Lifecycle
  disconnect () {
    this.bulkItemOutlets.forEach(outlet => outlet.disable())
  }

  // Actions
  selectAll (event) {
    this.selectedValue = [...this.allValue]
    event.preventDefault()
  }

  deselectAll (event) {
    this.selectedValue = []
    event.preventDefault()
  }

  actionCompleteTargetConnected () {
    this.dispatch('actionComplete')
  }

  // Outlet actions
  bulkItemToggle ({ detail: { id } }) {
    if (this.selectedValue.includes(id)) {
      this.removeSelected(id)
    } else {
      this.addSelected(id)
    }
  }

  // Value observers
  allValueChanged (current) {
    this.allCountTargets.forEach(target => {
      target.textContent = current.length
    })

    if (current.length === 0) {
      this.hide(this.selectAllTargets)
      this.hide(this.deselectAllTargets)
    }
  }

  selectedValueChanged (current, previous) {
    const currentSelected = (current || []).filter(id => !previous.includes(id))
    const currentDeselected = (previous || []).filter(id => !current.includes(id))

    this.selectedInputTargets.forEach(target => {
      target.value = (current || []).join(',')
    })

    this.actionButtonTargets.forEach(target => {
      target.disabled = current.length === 0
    })

    this.bulkItemOutlets.forEach(bulkItem => {
      const id = bulkItem.idValue
      if (currentSelected.includes(id)) {
        bulkItem.select()
      } else if (currentDeselected.includes(id)) {
        bulkItem.deselect()
      }
    })

    this.selectedCountTargets.forEach(target => {
      target.textContent = current.length
    })

    if (this.allValue.length === 0 || this.bulkItemOutlets.length === 0) {
      this.hide(this.selectAllTargets)
      this.hide(this.deselectAllTargets)
    } else if (difference(this.allValue, this.selectedValue).length === 0) {
      this.hide(this.selectAllTargets)
      this.show(this.deselectAllTargets)
    } else {
      this.show(this.selectAllTargets)
      this.hide(this.deselectAllTargets)
    }
  }

  // Outlet observers
  bulkItemOutletConnected (outlet) {
    const id = outlet.idValue
    if (this.selectedValue.includes(id)) {
      outlet.select()
    }
    outlet.enable()
  }

  bulkItemOutletDisconnected (outlet) {
    const id = outlet.idValue
    this.removeSelected(id)
  }

  // Private
  addSelected (id) {
    this.selectedValue = uniq([...this.selectedValue, id])
  }

  removeSelected (id) {
    this.selectedValue = this.selectedValue.filter(element => element !== id)
  }

  show (elements) {
    elements.forEach(element => {
      element.classList.remove('hidden')
    })
  }

  hide (elements) {
    elements.forEach(element => {
      element.classList.add('hidden')
    })
  }
}
