import axios from '@ytb/axios'

import { clickOutsideHandler, closePopups, createPopup } from '@/application/popper'
import createFlashMessage from '@/application/flashes'

const calElm = document.querySelector('[data-calendar]')

document.addEventListener('click', clickOutsideHandler)

export default function showEvent (url, eventElem) {
  axios.get(url).then(response => {
    closePopups()
    createPopup(response.data, eventElem, calElm)
  }).catch(() => {
    createFlashMessage('Sorry the event could not be loaded', 'alert')
  })
}
