/* eslint no-alert:0 */
/* eslint no-restricted-globals: 0 */
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'saveButton',
    'discardButton',
    'confirmDeletePanel',
    'dayLabel',
    'daySelect',
    'hourSelect',
    'minuteSelect',
    'templateSelect'
  ]

  static values = {
    sameDay: Boolean
  }

  connect () {
    this.templateId = this.templateSelectTarget.value
  }

  templateSelectTargetConnected (element) {
    if (!this.templateId) return

    if (element.value !== this.templateId) {
      this.saveButtonTarget.classList.remove('hidden')
    }
  }

  onChange () {
    this.saveButtonTarget.classList.remove('hidden')
    if (this.discardButtonTarget) {
      this.discardButtonTarget.classList.remove('js-hidden')
    }
  }

  onDayChange () {
    if (this.daySelectTarget.value === '0') {
      this.sameDayValue = true
    } else {
      this.sameDayValue = false
    }
  }

  sameDayValueChanged (sameDay) {
    if (sameDay) {
      this.dayLabelTarget.classList.add('invisible')
      this.hourSelectTarget.classList.add('js-hidden')
      this.minuteSelectTarget.classList.remove('js-hidden')
    } else {
      this.dayLabelTarget.classList.remove('invisible')
      this.minuteSelectTarget.classList.add('js-hidden')
      this.hourSelectTarget.classList.remove('js-hidden')
    }
  }

  confirmDelete (event) {
    event.preventDefault()
    this.confirmDeletePanelTarget.classList.remove('js-hidden')
  }

  cancelDelete () {
    this.confirmDeletePanelTarget.classList.add('js-hidden')
  }
}
