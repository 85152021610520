import {
  createReducer
} from './util'

import {
  INIT,
  ITEM_FIELD_CHANGED,
  RESET,
  PAPERWORK_FETCH,
  PAPERWORK_FETCH_SUCCESS,
  PAPERWORK_FETCH_FAILURE,
  PAPERWORK_DUPLICATE,
  PAPERWORK_DUPLICATE_SUCCESS,
  PAPERWORK_SAVE_SUCCESS,
  PAPERWORK_SAVE_FAILURE,
  PAPERWORK_DISCARD,
  PAPERWORK_SUBMIT
} from '../types'

const init = (state, action) => ({
  allowOptionalItems: false,
  allowTaxRate: true,
  allowItemDuplicate: true,
  allowItemQuantity: true,
  allowItemSave: true,
  allowItemReorder: true,
  allowItemRemove: true,
  debug: false,
  ...action.payload.configuration,
  paperworkFetched: false,
  paperworkFetchFailed: false,
  paperworkSubmitting: false,
  loaded: true
})

const reset = () => ({})

const itemFieldChanged = (state, { payload: { name, value } }) => {
  const newState = { ...state }
  if (name === 'taxRate') {
    newState.defaultTaxRate = value
  }
  if (name === 'quantity' && value > 1 && !state.allowItemQuantity) {
    newState.allowItemQuantity = true
  }
  return newState
}

const paperworkFetch = state => ({
  ...state,
  paperworkFetched: false,
  paperworkFetchFailed: false
})

const paperworkFetchSuccess = state => ({
  ...state,
  paperworkFetched: true,
  paperworkFetchFailed: false
})

const paperworkFetchFailure = state => ({
  ...state,
  paperworkFetchFailed: true
})

const paperworkDuplicate = state => ({
  ...state,
  paperworkFetched: false
})

const paperworkDuplicateSuccess = state => ({
  ...state,
  paperworkFetched: true
})

const paperworkSaveSuccess = state => ({
  ...state,
  paperworkFetched: false,
  paperworkSubmitting: false
})

const paperworkSaveFailure = state => ({
  ...state,
  paperworkSubmitting: false
})

const paperworkDiscard = state => ({
  ...state,
  paperworkFetched: false
})

const paperworkSubmitting = state => ({
  ...state,
  paperworkSubmitting: true
})

export default createReducer({}, {
  [INIT]: init,
  [RESET]: reset,
  [ITEM_FIELD_CHANGED]: itemFieldChanged,
  [PAPERWORK_DISCARD]: paperworkDiscard,
  [PAPERWORK_DUPLICATE]: paperworkDuplicate,
  [PAPERWORK_DUPLICATE_SUCCESS]: paperworkDuplicateSuccess,
  [PAPERWORK_FETCH]: paperworkFetch,
  [PAPERWORK_FETCH_SUCCESS]: paperworkFetchSuccess,
  [PAPERWORK_FETCH_FAILURE]: paperworkFetchFailure,
  [PAPERWORK_SAVE_SUCCESS]: paperworkSaveSuccess,
  [PAPERWORK_SAVE_FAILURE]: paperworkSaveFailure,
  [PAPERWORK_SUBMIT]: paperworkSubmitting
})
