import { Controller } from 'stimulus'

export default class ToggleController extends Controller {
  static targets = ['content']

  static classes = ['toggleable']

  toggle (e) {
    e.preventDefault()
    this.contentTargets.forEach(t => t.classList.toggle(this.toggleableClass))
  }

  // TODO: use prevent in the view instead, above https://stimulus.hotwired.dev/reference/actions#options
  toggleNoPrevent () {
    this.contentTargets.forEach(t => t.classList.toggle(this.toggleableClass))
  }
}
